import { useToggle } from "@dewo/app/util/hooks";
import { Button, ButtonProps } from "antd";
import React, { FC } from "react";
import { AddTokenModal } from "./AddTokenModal";

interface Props extends ButtonProps {
  organizationId: string;
}

export const AddTokenButton: FC<Props> = ({
  organizationId,
  ...buttonProps
}) => {
  const modal = useToggle();
  return (
    <>
      <Button children="Add token" onClick={modal.toggleOn} {...buttonProps} />
      <AddTokenModal
        organizationId={organizationId}
        visible={modal.isOn}
        onClose={modal.toggleOff}
      />
    </>
  );
};
