import { usePermissionFn } from "@dewo/app/contexts/PermissionsContext";
import { WorkspaceStatus } from "@dewo/app/graphql/types";
import { TreeProps } from "antd";
import { DataNode, EventDataNode } from "antd/lib/tree";
import { useCallback } from "react";
import { useUpsertGraphEdge } from "../../graph/hooks";
import { getSortKeyBetween } from "../../task/board/util";
import { useUpdateWorkspace } from "../../workspace/hooks";
import { GraphNodeMenuTreeNode, MenuTreeNode } from "./types";

export function useWorkspaceTreeDropHandler() {
  const updateWorkspace = useUpdateWorkspace();
  const upsertEdge = useUpsertGraphEdge();
  return useCallback<Exclude<TreeProps["onDrop"], undefined>>(
    async (info) => {
      const droppedBelow = info.node as EventDataNode & MenuTreeNode;
      const dragged = info.dragNode as EventDataNode & MenuTreeNode;

      if (
        !("node" in dragged) ||
        !("node" in droppedBelow) ||
        !dragged.node.workspace ||
        !droppedBelow.node.workspace
      ) {
        return;
      }

      if (info.dropPosition === -1) {
        const sortKey = getSortKeyBetween(
          undefined,
          droppedBelow.siblings[0],
          (x) => x.sortKey
        );
        await upsertEdge({
          parentId: droppedBelow.node.id,
          childId: dragged.node.id,
          sortKey,
        });
      } else if (info.dropToGap) {
        const droppedBelowIndex = droppedBelow.siblings.findIndex(
          (x) => x.node.id === droppedBelow.node.id
        );
        const nodeAbove = droppedBelow.siblings[droppedBelowIndex];
        const nodeBelow = droppedBelow.siblings[droppedBelowIndex + 1];
        const sortKey = getSortKeyBetween(
          nodeAbove,
          nodeBelow,
          (x) => x.sortKey
        );

        await upsertEdge({
          parentId: droppedBelow.parent.id,
          childId: dragged.node.id,
          sortKey,
        });

        if (
          nodeAbove.node.workspace &&
          dragged.node.workspace.status !== nodeAbove.node.workspace.status
        ) {
          await updateWorkspace({
            id: dragged.node.workspace.id,
            status: nodeAbove.node.workspace.status,
          });
        }
        // TODO(fant): consider auto-expanding droppedBelow.workspace.id
        // so that the workspace dropped in it is immediately visible
      } else {
        const firstChild = droppedBelow.children?.[0] as
          | MenuTreeNode
          | undefined;

        if (!!firstChild && "node" in firstChild) {
          const sortKey = getSortKeyBetween(
            undefined,
            firstChild.siblings[0],
            (x) => x.sortKey
          );

          await upsertEdge({
            parentId: firstChild.node.id,
            childId: dragged.node.id,
            sortKey,
          });

          if (
            firstChild.node.workspace &&
            dragged.node.workspace.status !== firstChild.node.workspace.status
          ) {
            await updateWorkspace({
              id: dragged.node.workspace.id,
              status: firstChild.node.workspace.status,
            });
          }
        } else {
          await updateWorkspace({
            id: dragged.node.workspace.id,
            status: WorkspaceStatus.ACTIVE,
          });
        }
      }
    },
    [updateWorkspace, upsertEdge]
  );
}

export function useAllowDrop() {
  return useCallback<Exclude<TreeProps<MenuTreeNode>["allowDrop"], undefined>>(
    ({ dragNode, dropNode, dropPosition }) => {
      if ("node" in dragNode && "node" in dropNode) {
        const matchesParent = dragNode.parent.id === dropNode.parent.id;
        if (matchesParent && dropPosition === 1) {
          return true;
        }

        const droppingToFirstPositionOfParentNode =
          dragNode.parent.id === dropNode.node.id && dropPosition === 0;
        if (droppingToFirstPositionOfParentNode) {
          return true;
        }
      }

      return false;
    },
    []
  );
}

export function usePreventDraggingWithoutPermission() {
  const hasPermission = usePermissionFn();
  return useCallback<
    Exclude<TreeProps<MenuTreeNode | DataNode>["onDragStart"], undefined>
  >(
    ({ node, event }) => {
      if ("node" in node) {
        const typedNode = node as any as GraphNodeMenuTreeNode;
        if (
          !typedNode.node.workspace ||
          !hasPermission("update", typedNode.node.workspace, "sortKey")
        ) {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }

      return false;
    },
    [hasPermission]
  );
}
