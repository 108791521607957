import {
  DollarCircleOutlined,
  PlusOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { useOrganizationTokens } from "@dewo/app/containers/organization/hooks";
import { CompensationFrequency } from "@dewo/app/graphql/types";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Radio,
  RadioProps,
  Row,
  Space,
  Typography,
} from "antd";
import { FormInstance, useForm, useWatch } from "antd/lib/form/Form";
import _ from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { TaskFormValues } from "../../form/types";
import { OpenToBidsTokenFormItem } from "./OpenToBidsTokenFormItem";
import { SetupPaymentMethodAlert } from "./SetupPaymentMethodAlert";
import { TaskRewardFormItem } from "./TaskRewardFormItem";
import { TaskRewardFormItemValue, TaskRewardFormValues } from "./types";
import { toTaskRewardFormItem, toTaskRewardInput } from "./util";

interface Props {
  visible: boolean;
  initialValues: Partial<Pick<TaskFormValues, "openToBids" | "rewards">>;
  workspaceId?: string;
  showOpenToBids?: boolean;
  organizationId: string;
  multiple?: boolean;
  onClose(): void;
  onSave(values: Pick<TaskFormValues, "openToBids" | "rewards">): void;
}

const CardRadioButtons: FC<RadioProps> = ({ value, onChange }) => {
  return (
    <Radio.Group style={{ width: "100%" }} value={value} onChange={onChange}>
      <Row gutter={[8, 8]} align="stretch">
        <Col sm={12}>
          <Radio.Button
            value={false}
            className="ant-card ant-card-bordered w-full h-full"
            style={{
              border: value === false ? undefined : "none",
              color: "white",
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <div
              className="w-full"
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography.Title style={{ margin: 0 }}>
                <WalletOutlined size={48} style={{ fontSize: 30 }} />
              </Typography.Title>
              Fixed budget
            </div>
          </Radio.Button>
        </Col>
        <Col sm={12}>
          <Radio.Button
            value={true}
            className="ant-card ant-card-bordered w-full h-full"
            style={{
              border: value === true ? undefined : "none",
              color: "white",
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <div
              className="w-full"
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography.Title style={{ margin: 0 }}>
                <DollarCircleOutlined size={48} style={{ fontSize: 30 }} />
              </Typography.Title>
              Open to bids
            </div>
          </Radio.Button>
        </Col>
      </Row>
    </Radio.Group>
  );
};

export const TaskRewardsForm: FC<Props> = ({
  visible,
  initialValues,
  workspaceId,
  organizationId,
  showOpenToBids = true,
  onClose,
  onSave,
  multiple = true,
}) => {
  const tokens = useOrganizationTokens(organizationId);
  const tokenById = useMemo(() => _.keyBy(tokens, "id"), [tokens]);

  const [form] = useForm<TaskRewardFormValues>();
  const handleOk = useCallback(
    () =>
      form.validateFields().then((values) =>
        onSave({
          openToBids: values.openToBids,
          rewards: (values.rewards ?? [])
            .filter(
              (r): r is TaskRewardFormItemValue => !!r.tokenId && !!r.amount
            )
            .filter((r) => !!tokenById[r.tokenId])
            .map((r) =>
              toTaskRewardInput(
                {
                  ...r,
                  type: values.type,
                  count:
                    values.type === CompensationFrequency.FIXED ? 1 : r.count,
                },
                tokenById[r.tokenId!]!
              )
            ),
        })
      ),
    [form, onSave, tokenById]
  );
  const openToBids = useWatch("openToBids", form);
  const rewardType = useWatch("type", form);

  return (
    <Modal
      visible={visible}
      title="Add Bounty"
      okText="Save"
      destroyOnClose
      onCancel={onClose}
      onOk={handleOk}
      width={480}
    >
      <Form<TaskRewardFormValues>
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          openToBids: initialValues.openToBids ?? false,
          rewards: !!initialValues.rewards?.length
            ? initialValues.rewards
                .filter((r) => !!tokenById[r.tokenId])
                .map((r) => toTaskRewardFormItem(r, tokenById[r.tokenId]!))
            : [{}],
        }}
      >
        <Form.Item
          hidden
          name="type"
          initialValue={
            initialValues.rewards?.[0]?.type ?? CompensationFrequency.FIXED
          }
        />
        <Form.Item
          label="Type"
          name="openToBids"
          initialValue={initialValues.openToBids ?? false}
          hidden={!showOpenToBids}
          normalize={(v) => {
            if (v === false) {
              form.setFieldsValue({ type: CompensationFrequency.FIXED });
            }
            return v;
          }}
        >
          <CardRadioButtons />
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {(form: FormInstance<TaskRewardFormValues>) => (
            <>
              {!!openToBids && (
                <>
                  <OpenToBidsTokenFormItem
                    organizationId={organizationId}
                    enabled
                  />

                  <Form.Item>
                    <Checkbox
                      onChange={(e) => {
                        form.setFieldsValue({
                          type: e.target.checked
                            ? CompensationFrequency.HOUR
                            : CompensationFrequency.FIXED,
                        });
                      }}
                      checked={rewardType === CompensationFrequency.HOUR}
                    >
                      Set an hourly rate
                    </Checkbox>
                  </Form.Item>
                </>
              )}

              {(!openToBids || rewardType === CompensationFrequency.HOUR) && (
                <Form.List name="rewards">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <TaskRewardFormItem
                          key={field.key}
                          path={["rewards", field.name]}
                          name={field.name}
                          organizationId={organizationId}
                          amountLabel={
                            rewardType === CompensationFrequency.HOUR
                              ? "Hourly Rate"
                              : "Amount"
                          }
                          onClear={() =>
                            fields.length > 1 &&
                            requestAnimationFrame(() => remove(index))
                          }
                        />
                      ))}

                      <HeadlessCollapse
                        expanded={
                          !!multiple &&
                          fields
                            .map(
                              (field): TaskRewardFormItemValue =>
                                form.getFieldValue(["rewards", field.name])
                            )
                            .every((r) => !!r.tokenId && !!r.amount)
                        }
                      >
                        <Space direction="vertical">
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => add({})}
                            size="small"
                          >
                            Add Another
                          </Button>
                          {!!workspaceId && (
                            <SetupPaymentMethodAlert
                              workspaceId={workspaceId}
                            />
                          )}
                        </Space>
                      </HeadlessCollapse>
                    </>
                  )}
                </Form.List>
              )}
            </>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
