import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { ThreadMessage, User } from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { Button } from "antd";
import React, { FC, useCallback, useState } from "react";

import { useCreateThreadMessage } from "./hooks";

interface Props {
  threadId: string | undefined;
  placeholder?: string;
  buttonText?: string;
  bordered?: boolean;
  mentionable?: User[];
  onBeforeSend?(): Promise<{ threadId: string }>;
  onDone?(message: ThreadMessage): void;
}

export const CreateThreadMessageForm: FC<Props> = ({
  threadId,
  placeholder,
  bordered,
  buttonText = "Send",
  mentionable,
  onBeforeSend,
  onDone,
}) => {
  const [content, setContent] = useState<string>();
  const createThreadMessage = useCreateThreadMessage();

  const [handleSubmit, submit] = useRunning(
    useCallback(async () => {
      if (!!content) {
        const actualThreadId =
          threadId ?? (await onBeforeSend?.().then((x) => x.threadId));
        if (!actualThreadId) throw new Error("No thread found");
        const message = await createThreadMessage({
          content,
          threadId: actualThreadId,
        });
        await onDone?.(message);
      }
    }, [createThreadMessage, onBeforeSend, content, threadId, onDone])
  );

  return (
    <RichMarkdownEditor
      simple
      initialValue={content ?? ""}
      editable
      mentionable={mentionable}
      bordered={bordered}
      placeholder={placeholder}
      onChange={setContent}
      onSave={handleSubmit}
      buttons={({ disabled, onSave }) => (
        <Button
          type="primary"
          disabled={disabled}
          onClick={onSave}
          loading={submit}
          children={buttonText}
        />
      )}
    />
  );
};
