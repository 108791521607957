import { RichMarkdownViewer } from "@dewo/app/components/richMarkdown/RichMarkdownViewer";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  TaskSubmissionStatus,
  Task,
  TaskGatingType,
  TaskStatus,
  TaskSubmission,
} from "@dewo/app/graphql/types";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { Button, Col, List, Row, Space, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { FC, ReactNode, useCallback, useMemo } from "react";
import {
  useCreateTask,
  useTaskDetails,
  useUpdateTask,
  useUpdateTaskSubmission,
} from "../hooks";
import { TaskRewardTag } from "../reward/TaskRewardTag";
import { taskRewardToInput } from "../reward/form/util";
import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { MessageOutlined } from "@ant-design/icons";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { LockIcon } from "@dewo/app/components/icons/task/Lock";
import { Divider } from "@dewo/app/components/Divider";
import { SubmissionDiscussion } from "../../thread/SubmissionDiscussion";
import _ from "lodash";

interface Props {
  task: Task;
  submission: TaskSubmission;
  disabled?: boolean;
  editable?: boolean;
}

export const TaskSubmissionListItem: FC<Props> = ({
  task,
  submission,
  disabled,
  editable,
}) => {
  const { user } = useAuthContext();
  const canApprove = usePermission("update", task, "submissions");
  const taskDetails = useTaskDetails(task.id).task;

  const updateTask = useUpdateTask();
  const updateSubmission = useUpdateTaskSubmission();
  const createTask = useCreateTask();

  const handleSave = useCallback(
    async (content: string) => {
      if (!user) return;
      await updateSubmission({
        id: submission.id,
        taskId: task.id,
        content,
        deletedAt: !content ? new Date().toISOString() : undefined,
      });
    },
    [user, updateSubmission, submission.id, task.id]
  );

  const approveSingle = useCallback(
    async (submission: TaskSubmission) => {
      await updateSubmission({
        id: submission.id,
        taskId: submission.taskId,
        approverId: user?.id,
        status: TaskSubmissionStatus.ACCEPTED,
      });

      await updateTask({
        id: submission.taskId,
        assigneeIds: [submission.userId],
        gating: TaskGatingType.ASSIGNEES,
        status: TaskStatus.DONE,
        rewards: submission.reward
          ? [taskRewardToInput(submission.reward)]
          : undefined,
      });
    },
    [updateSubmission, updateTask, user?.id]
  );

  const approveMultiple = useCallback(
    async (submission: TaskSubmission) => {
      const alreadyAcceptedSubmissions = taskDetails?.submissions.filter(
        (submission) => submission.status === TaskSubmissionStatus.ACCEPTED
      );

      await updateSubmission({
        id: submission.id,
        taskId: submission.taskId,
        approverId: user?.id,
        status: TaskSubmissionStatus.ACCEPTED,
      });

      await createTask({
        name: task.name,
        description:
          `Approved submission for [${task.name}](${
            taskDetails!.permalink
          })\n\n---\n\n` + submission.content,
        parentTaskId: submission.taskId,
        status: TaskStatus.DONE,

        gating: TaskGatingType.ASSIGNEES,
        assigneeIds: [submission.userId],
        workspaceId: task.workspaceId,
        storyPoints: task.storyPoints,
        tagIds: task.tags.map((tag) => tag.id),
        ownerIds: task.owners.map((owner) => owner.id),
        rewards: !!submission.reward
          ? [taskRewardToInput(submission.reward)]
          : task.rewards.map((reward) => ({
              amount: reward.amount,
              peggedToUsd: reward.peggedToUsd,
              tokenId: reward.token.id,
            })),
      });

      if (
        !!alreadyAcceptedSubmissions?.length &&
        !!taskDetails?.maxWinners &&
        alreadyAcceptedSubmissions.length + 1 >= taskDetails.maxWinners
      ) {
        await updateTask({ id: submission.taskId, status: TaskStatus.DONE });
      }
    },
    [
      createTask,
      task.name,
      task.owners,
      task.storyPoints,
      task.tags,
      task.workspaceId,
      task.rewards,
      taskDetails,
      updateSubmission,
      updateTask,
      user?.id,
    ]
  );

  const [handleApprove, approving] = useRunning(
    useCallback(
      async (submission: TaskSubmission) => {
        if (taskDetails?.gating === TaskGatingType.OPEN_SUBMISSION) {
          if (taskDetails?.maxWinners === 1) {
            await approveSingle(submission);
          } else {
            await approveMultiple(submission);
          }
        } else {
          await approveSingle(submission);
        }
      },
      [
        approveMultiple,
        approveSingle,
        taskDetails?.maxWinners,
        taskDetails?.gating,
      ]
    )
  );

  const handleReject = useCallback(
    (submission: TaskSubmission) =>
      updateSubmission({
        id: submission.id,
        taskId: submission.taskId,
        status: TaskSubmissionStatus.REJECTED,
      }),
    [updateSubmission]
  );

  const [handleUnapprove, unapproving] = useRunning(
    useCallback(
      (submission: TaskSubmission) =>
        updateSubmission({
          id: submission.id,
          taskId: submission.taskId,
          status: TaskSubmissionStatus.PENDING,
        }),
      [updateSubmission]
    )
  );

  const actions = useMemo((): ReactNode => {
    if (submission.status === TaskSubmissionStatus.PENDING && !disabled) {
      return (
        <Space
          size={4}
          direction="vertical"
          style={{ marginLeft: 8, textAlign: "center" }}
        >
          {!!canApprove && (
            <Button
              size="small"
              type="primary"
              loading={approving}
              onClick={() => handleApprove(submission)}
            >
              Approve
            </Button>
          )}
          {!!canApprove && (
            <Button
              key="remove"
              size="small"
              type="text"
              className="text-secondary"
              onClick={() => handleReject(submission)}
            >
              Reject
            </Button>
          )}
        </Space>
      );
    }

    if (
      submission.status === TaskSubmissionStatus.ACCEPTED &&
      canApprove &&
      !!user &&
      (submission.userId !== user.id || submission.approver?.id === user.id)
    ) {
      return (
        <Button
          size="small"
          type="text"
          loading={unapproving}
          onClick={() => handleUnapprove(submission)}
        >
          Unapprove
        </Button>
      );
    }

    return null;
  }, [
    submission,
    disabled,
    user,
    handleApprove,
    handleReject,
    handleUnapprove,
    approving,
    unapproving,
    canApprove,
  ]);
  const messages = submission.thread?.messages;
  const showDiscussion = useToggle();

  const mentionable = useMemo(
    () =>
      _.uniqBy(
        [...(task.owners || []), submission.user].filter((u) => !!u),
        (i) => i.id
      ),
    [submission.user, task.owners]
  );

  return (
    <>
      <List.Item>
        <Space direction="vertical" style={{ overflowX: "hidden", flex: 1 }}>
          <Row align="middle" wrap={false}>
            <Col flex={1}>
              <Space direction="vertical" className="w-full">
                <Tooltip title={submission.user.username}>
                  <a
                    href={submission.user.permalink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <List.Item.Meta
                      avatar={
                        <UserAvatar
                          user={submission.user}
                          tooltip={{ visible: false }}
                        />
                      }
                      title={submission.user.username}
                      description={moment(submission.createdAt).calendar()}
                    />
                  </a>
                </Tooltip>
                {!editable ? (
                  <RichMarkdownViewer value={submission.content} />
                ) : (
                  <RichMarkdownEditor
                    initialValue={submission.content}
                    editable
                    bordered
                    mode="update"
                    onSave={handleSave}
                    key={submission.content}
                    placeholder="Write your submission here. Type '/' for commands."
                  />
                )}
                {!!submission.reward && (
                  <TaskRewardTag
                    formatStyle="total"
                    reward={submission.reward}
                  />
                )}
                <Button
                  icon={<MessageOutlined />}
                  onClick={showDiscussion.toggle}
                  style={{ background: "rgba(255,255,255,0.15)" }}
                >
                  Discuss {messages?.length ? `(${messages?.length})` : ""}
                </Button>
              </Space>
            </Col>
            <Col>{actions}</Col>
          </Row>

          <HeadlessCollapse expanded={showDiscussion.isOn}>
            <Space direction="vertical" className="w-full" size="middle">
              <Divider size={0} />
              <Space align="center">
                <LockIcon style={{ width: 14, marginTop: 2 }} />
                <Typography.Text type="secondary">
                  {canApprove
                    ? "Only this submitter and other reviewers can see this discussion"
                    : "Only you and the reviewers can see this discussion"}
                </Typography.Text>
              </Space>
              <SubmissionDiscussion
                onThreadCreated={(threadId) =>
                  updateSubmission({
                    id: submission.id,
                    taskId: task.id,
                    threadId,
                  })
                }
                users={mentionable}
                thread={submission.thread}
              />
            </Space>
          </HeadlessCollapse>
        </Space>
      </List.Item>
    </>
  );
};
