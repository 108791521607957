import React, { FC, useCallback, useMemo } from "react";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { Button, ButtonProps, Space, Tooltip, Typography } from "antd";
import { useNavigateToTaskApplicationFn } from "@dewo/app/util/navigation";
import { LoginButton } from "@dewo/app/containers/auth/buttons/LoginButton";
import { useUpdateTaskApplication } from "../../hooks";
import { TaskApplicationStatus, TaskDetails } from "@dewo/app/graphql/types";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";

interface Props extends ButtonProps {
  task: TaskDetails;
}

export const ApplyToTaskButton: FC<Props> = ({ task, ...buttonProps }) => {
  const { user } = useAuthContext();
  const myApplication = useMemo(
    () =>
      (!!user &&
        task.applications.find(
          (ta) =>
            ta.userId === user.id && ta.status === TaskApplicationStatus.PENDING
        )) ||
      undefined,
    [task.applications, user]
  );
  const { logEvent } = useAmplitude();
  const logClick = useCallback(() => {
    logEvent("Click task apply button", {
      taskId: task.id,
      taskName: task.name,
      applicationLink: task.applicationLink,
      organizationSlug: task.workspace.organization.slug,
    });
  }, [logEvent, task]);

  const navigateToTaskApplication = useNavigateToTaskApplicationFn();
  const handleInterested = useCallback(() => {
    navigateToTaskApplication(task.id);
    logClick();
  }, [navigateToTaskApplication, logClick, task.id]);

  const updateTaskApplication = useUpdateTaskApplication();
  const handleUnclaimTask = useCallback(
    () =>
      !!myApplication &&
      updateTaskApplication({
        id: myApplication?.id,
        taskId: task.id,
        userId: myApplication.userId,
        deletedAt: new Date().toISOString(),
      }),
    [updateTaskApplication, myApplication, task.id]
  );

  if (!user) {
    return (
      <LoginButton
        {...buttonProps}
        icon={<UnlockOutlined />}
        name="Apply to task (unauthenticated)"
        onAuthedWithWallet={handleInterested}
        onClick={logClick}
      >
        I'm interested
      </LoginButton>
    );
  }

  if (myApplication) {
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Tooltip
          placement="bottom"
          title={
            <Space
              direction="vertical"
              size="small"
              style={{ alignItems: "center", maxWidth: 120 }}
            >
              <Typography.Text style={{ textAlign: "center" }}>
                You've applied to claim this task
              </Typography.Text>
              <Button
                size="small"
                name="Unclaim task"
                onClick={handleUnclaimTask}
                icon={<UnlockOutlined />}
              >
                Unclaim
              </Button>
            </Space>
          }
        >
          <Button {...buttonProps} disabled icon={<LockOutlined />}>
            Requested
          </Button>
        </Tooltip>
      </Space>
    );
  }

  if (!!task.applicationLink) {
    return (
      <Tooltip
        title={`Applications for this task are not handled on Dework. This button will open ${task.applicationLink}`}
      >
        <Button
          {...buttonProps}
          href={task.applicationLink}
          target="_blank"
          name="Apply to task (external link)"
          icon={<UnlockOutlined />}
          onClick={logClick}
        >
          I'm interested
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      {...buttonProps}
      name="Apply to task"
      icon={<UnlockOutlined />}
      onClick={handleInterested}
    >
      I'm interested
    </Button>
  );
};
