import React, { FC } from "react";
import { Avatar, AvatarProps, Tooltip, TooltipProps } from "antd";
import { Workspace } from "../../graphql/types";
import { colorFromUuid } from "../../util/colorFromUuid";
import { TeamOutlined } from "@ant-design/icons";

interface Props extends AvatarProps {
  workspace: Workspace;
  tooltip?: Partial<TooltipProps>;
}

export const WorkspaceAvatar: FC<Props> = ({
  workspace,
  tooltip,
  ...otherProps
}) => (
  <Tooltip title={workspace.name} placement="top" {...tooltip}>
    <Avatar
      // src={workspace.imageUrl}
      style={{
        backgroundColor: colorFromUuid(workspace.id),
        ...otherProps.style,
      }}
      icon={workspace.name?.[0]?.toUpperCase() ?? <TeamOutlined />}
      {...otherProps}
    />
  </Tooltip>
);
