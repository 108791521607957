import React, { createContext, FC, useContext, useState } from "react";

interface TaskViewSearchValue {
  query: string;
  setQuery(query: string): void;
}

const TaskViewSearchContext = createContext<TaskViewSearchValue>({
  query: "",
  setQuery: () => {},
});

export const TaskViewSearchProvider: FC = ({ children }) => {
  const [query, setQuery] = useState("");
  return (
    <TaskViewSearchContext.Provider value={{ query, setQuery }}>
      {children}
    </TaskViewSearchContext.Provider>
  );
};

export function useTaskViewSearchContext() {
  return useContext(TaskViewSearchContext);
}
