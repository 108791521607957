import React, { FC, useCallback } from "react";
import { CompensationFrequency } from "@dewo/app/graphql/types";
import { Form } from "antd";
import { TaskRewardFormItem } from "./TaskRewardFormItem";
import { TaskRewardFormItemValue } from "./types";

interface Props {
  organizationId: string | undefined;
  value?: TaskRewardFormItemValue | undefined;
  onChange?(value: TaskRewardFormItemValue | undefined): void;
  type: CompensationFrequency | undefined;
  isSubmission?: boolean;
  openToBids?: boolean;
}

export const TaskRewardRequestFunding: FC<Props> = ({
  organizationId,
  onChange,
  value,
  type,
  isSubmission,
  openToBids,
}) => {
  const [form] = Form.useForm<{ reward: TaskRewardFormItemValue }>();

  const handleChange = useCallback(() => {
    const { reward } = form.getFieldsValue();
    reward.amount ||= 0;
    reward.count ||= 1;
    onChange?.(reward);
  }, [form, onChange]);

  if (!organizationId) return null;

  return (
    <Form
      form={form}
      onFieldsChange={handleChange}
      initialValues={{
        reward: { ...(value || {}), type },
      }}
    >
      <Form.Item name={["reward", "type"]} hidden initialValue={type} />
      <TaskRewardFormItem
        organizationId={organizationId}
        type={type}
        name="reward"
        canSetToken={!!openToBids && type !== CompensationFrequency.HOUR}
        hourlyRateDisabled
        countLabel={isSubmission ? "Hours spent" : "Estimated hours"}
        showAddToken={false}
      />
    </Form>
  );
};
