import gql from "graphql-tag";
import { thread, threadMessage } from "../fragments/thread";

export const createThread = gql`
  mutation CreateThreadMutation {
    thread: createThread {
      ...Thread
    }
  }

  ${thread}
`;

export const createThreadMessage = gql`
  mutation CreateThreadMessageMutation($input: CreateThreadMessageInput!) {
    message: createThreadMessage(input: $input) {
      ...ThreadMessage
      thread {
        ...Thread
      }
    }
  }

  ${thread}
  ${threadMessage}
`;

export const updateThreadMessage = gql`
  mutation UpdateThreadMessageMutation($input: UpdateThreadMessageInput!) {
    message: updateThreadMessage(input: $input) {
      ...ThreadMessage
      thread {
        ...Thread
      }
    }
  }

  ${thread}
  ${threadMessage}
`;

export const deleteThreadMessage = gql`
  mutation DeleteThreadMessageMutation($id: UUID!) {
    message: deleteThreadMessage(id: $id) {
      ...ThreadMessage
      thread {
        ...Thread
      }
    }
  }

  ${thread}
  ${threadMessage}
`;
