import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  TaskStatus,
  TaskDetails,
  TaskGatingType,
} from "@dewo/app/graphql/types";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Dropdown,
  Menu,
  Button,
  Layout,
  Typography,
} from "antd";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { TaskApplicationList } from "../application/TaskApplicationList";
import { GithubIntegrationSection } from "../github/GithubIntegrationSection";
import { FormInstance, useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { SubtaskCreateInput } from "./subtask/SubtaskCreateInput";
import { TaskSubmissionsSection } from "./TaskSubmissionsSection";
import { useWorkspaceDetails } from "../../workspace/hooks";
import { TaskTwitterShareButton } from "./TaskTwitterShareButton";
import { TaskActionSection } from "../actions/TaskActionSection";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { TaskGithubBranchButton } from "../github/TaskGithubBranchButton";
import { TaskFormValues } from "./types";
import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { useToggle } from "@dewo/app/util/hooks";
import { TaskFormCreateButton } from "./TaskFormCreateButton";
import { CalendarOutlined, EllipsisOutlined } from "@ant-design/icons";
import { TaskSkillInput } from "./skills/TaskSkillInput";
import { TaskGatingInput } from "./gating/TaskGatingInput";
import styles from "./TaskForm.module.less";
import { DueDateInput } from "./DueDateInput";
import { CollapsibleSider } from "./CollapsibleSider";
import { ViewMoreContainer } from "@dewo/app/components/ViewMoreContainer";
import { TaskGatingModal } from "./gating/TaskGatingModal";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { TaskFormSider } from "./TaskFormSider";
import { SubtaskUpdateInput } from "./subtask/SubtaskUpdateInput";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useCanChangeTask } from "../hooks";
import { TaskBookmarkButton } from "./TaskBookmarkButton";
import { TaskTemplatePicker } from "./TaskTemplatePicker";
import { TaskCreatorSubtitle } from "@dewo/app/components/task/TaskCreatorSubtitle";
import { TaskRewardsForm } from "../reward/form/TaskRewardsForm";
import { TaskFormRewardTag } from "../reward/form/TaskFormRewardTag";
import { useOrganizationUsers } from "../../organization/hooks";
import { TaskThread } from "../../thread/task/TaskThread";
import { TaskGatingAlert } from "./TaskGatingAlert";

interface TaskFormProps {
  mode: "create" | "update";
  workspaceId: string;
  task?: TaskDetails;
  initialValues?: Partial<TaskFormValues>;
  showWorkspaceLink?: boolean;
  templateId?: string;
  onSubmit(values: TaskFormValues): unknown;
  onChange?(values: Partial<TaskFormValues>): unknown;
  onDescriptionDirty?(dirty: boolean): void;
}

export const TaskForm: FC<TaskFormProps> = ({
  mode,
  task,
  workspaceId,
  initialValues,
  onChange,
  onSubmit,
  onDescriptionDirty,
}) => {
  const screens = useBreakpoint();
  const { workspace } = useWorkspaceDetails(workspaceId);
  const { users } = useOrganizationUsers(workspace?.organizationId);

  const router = useRouter();
  const isOnWorkspacePage = "organizationSlug" in router.query;

  const [form] = useForm<TaskFormValues>();
  const [values, setValues] = useState<Partial<TaskFormValues>>(
    initialValues ?? {}
  );
  const canSubmit = usePermission(mode, !!task ? task : "Task");

  const isSuggestion = values.status === TaskStatus.COMMUNITY_SUGGESTIONS;

  const canChange = useCanChangeTask(values, workspaceId, mode);

  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (values: TaskFormValues) => {
      try {
        setLoading(true);
        await onSubmit(values);
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const debouncedSubmit = useMemo(
    () => _.debounce(handleSubmit, 500),
    [handleSubmit]
  );

  const handleChange = useCallback(
    (changed: Partial<TaskFormValues>, values: Partial<TaskFormValues>) => {
      changed.skillIds = changed.skillIds?.slice(0, 2);
      const shouldResetAssignees =
        !!changed.gating &&
        changed.gating !== TaskGatingType.ASSIGNEES &&
        !!values.assigneeIds?.length;

      const shouldSetAssigneesType =
        changed.assigneeIds && values.gating !== TaskGatingType.ASSIGNEES;

      const newValues: Partial<TaskFormValues> = {
        ...values,
        ...(shouldResetAssignees && { assigneeIds: [] }),
        ...(shouldSetAssigneesType && { gating: TaskGatingType.ASSIGNEES }),
      };
      const newChanged: Partial<TaskFormValues> = {
        ...changed,
        ...(shouldResetAssignees && { assigneeIds: [] }),
        ...(shouldSetAssigneesType && { gating: TaskGatingType.ASSIGNEES }),
      };

      form.setFieldsValue(newValues);
      setValues(newValues);
      onChange?.(newValues);

      if (mode === "update") {
        debouncedSubmit(newChanged as TaskFormValues);
      }
    },
    [form, mode, debouncedSubmit, onChange]
  );

  useEffect(() => {
    if (!!initialValues) {
      setValues({ ...values, ...initialValues });
      form.setFieldsValue(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const showDueDate = useToggle(!!values.dueDate);

  const modal = router.query.modal as "reward" | "gating" | undefined;
  const [submitFormAfterGating, setSubmitFormAfterGating] = useState(false);
  const showTaskGatingModal = useCallback(
    () => router.push({ query: { ...router.query, modal: "gating" } }),
    [router]
  );
  const showTaskRewardModal = useCallback(
    () => router.push({ query: { ...router.query, modal: "reward" } }),
    [router]
  );
  const hideModal = useCallback(
    () => router.push({ query: _.omit(router.query, "modal") }),
    [router]
  );

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<TaskFormValues>) => {
      const fields = new Set(errorInfo.errorFields.map((e) => e.name).flat());
      if (
        fields.has("gating") ||
        fields.has("claimRoleIds") ||
        fields.has("applyRoleIds") ||
        fields.has("assigneeIds")
      ) {
        showTaskGatingModal();
        setSubmitFormAfterGating(true);
      }
    },
    [showTaskGatingModal]
  );

  const siderCollapsed = useToggle(!canSubmit && !isOnWorkspacePage);
  const showingSider = screens.md && !isSuggestion;

  return (
    <Form
      form={form}
      name={`Task Form (${mode})`}
      layout="vertical"
      initialValues={initialValues}
      requiredMark={false}
      className={styles.form}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      onFinishFailed={onFinishFailed}
    >
      {mode === "create" && initialValues && !initialValues.template && (
        <TaskTemplatePicker
          workspaceId={workspaceId}
          templateId={initialValues.templateTaskId}
          isSuggestion={isSuggestion}
        />
      )}
      <Layout hasSider style={{ minHeight: 250 }}>
        <Col
          flex={1}
          className={showingSider ? "dewo-divider-right" : undefined}
          style={{ display: "flex", minWidth: 0 }}
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: showingSider ? 24 : 0,
            }}
            flex={1}
          >
            <Row style={{ rowGap: 8, columnGap: 8 }}>
              <Form.Item
                name="name"
                style={{ marginBottom: 0, flex: 1 }}
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input.TextArea
                  bordered={false}
                  disabled={!canChange("name")}
                  autoFocus={mode === "create"}
                  autoSize
                  className="dewo-field ant-typography-h3 font-semibold overflow-y-hidden"
                  placeholder={
                    values.template
                      ? "Enter a template name..."
                      : isSuggestion
                      ? "Enter your suggestion here"
                      : "Enter a task name..."
                  }
                  style={{ paddingLeft: 0 }}
                  rows={1}
                />
              </Form.Item>
              {!!task && !task.template && (
                <Space size="small">
                  {/* {isSuggestion && <UpvoteCard task={task} />} */}
                  {!isSuggestion && <TaskGithubBranchButton task={task} />}
                  <TaskBookmarkButton task={task} />
                  <TaskTwitterShareButton
                    task={task}
                    heading={
                      isSuggestion
                        ? "💡 Check out this Suggestion on @deworkxyz"
                        : undefined
                    }
                  />
                </Space>
              )}
            </Row>

            {!!task && isSuggestion && (
              <TaskCreatorSubtitle task={task} style={{ marginBottom: 8 }} />
            )}

            <Row
              hidden={isSuggestion}
              className={styles.topRowTags}
              gutter={[0, 8]}
              wrap
            >
              {!!workspace && (
                <TaskGatingInput
                  taskId={task?.id}
                  disabled={!canChange("gating")}
                  onClick={showTaskGatingModal}
                  workspace={workspace}
                />
              )}

              <div>
                <Form.Item noStyle shouldUpdate>
                  {(form: FormInstance<TaskFormValues>) => (
                    <TaskFormRewardTag
                      task={task}
                      rewards={form.getFieldValue("rewards")}
                      openToBids={form.getFieldValue("openToBids")}
                      onClick={showTaskRewardModal}
                      workspaceId={workspaceId}
                    />
                  )}
                </Form.Item>
              </div>

              <Form.Item noStyle name="skillIds">
                <TaskSkillInput disabled={!canChange("skillIds")} />
              </Form.Item>

              {!!showDueDate.isOn && (
                <Form.Item noStyle name="dueDate">
                  <DueDateInput
                    defaultOpen={!values?.dueDate}
                    disabled={!canChange("dueDate")}
                    onClear={showDueDate.toggleOff}
                  />
                </Form.Item>
              )}

              {!showDueDate.isOn && !!canChange("dueDate") && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={showDueDate.toggleOn}
                        icon={<CalendarOutlined />}
                      >
                        Set due date...
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <Button
                    size="small"
                    icon={<EllipsisOutlined />}
                    type="text"
                  />
                </Dropdown>
              )}
            </Row>

            <TaskGatingAlert
              task={task}
              organizationId={workspace?.organizationId}
            />

            <div className="mb-7">
              <ViewMoreContainer>
                <Form.Item name="description" noStyle>
                  {!canChange("description") &&
                  !values.description?.replaceAll("\\", "").trim() ? (
                    <Typography.Text type="secondary">
                      No description...
                    </Typography.Text>
                  ) : (
                    <RichMarkdownEditor
                      initialValue={initialValues?.description ?? ""}
                      mode={mode}
                      editable={!!canChange("description")}
                      placeholder={
                        isSuggestion
                          ? "Add more details about your suggestion here. Type ”/” to insert."
                          : undefined
                      }
                      mentionable={users}
                      onDirty={onDescriptionDirty}
                    />
                  )}
                </Form.Item>
              </ViewMoreContainer>
            </div>

            <Form.Item
              hidden={
                isSuggestion ||
                (!task?.subtasks?.length && !canChange("subtasks"))
              }
              name="subtasks"
              className="mb-7"
            >
              {!!task ? (
                <SubtaskUpdateInput parentTask={task} />
              ) : (
                <SubtaskCreateInput workspaceId={workspaceId} />
              )}
            </Form.Item>

            {!!task && !task.template && (
              <TaskActionSection
                task={task}
                className={classNames("mb-2", styles.grower)}
              />
            )}

            {mode === "create" && !!canSubmit && (
              <>
                <div style={{ flex: 1 }} />
                <Form.Item>
                  <TaskFormCreateButton
                    loading={loading}
                    showSkillsPrompt={
                      !!values.rewards?.length &&
                      !values.skillIds?.length &&
                      !!canChange("skillIds") &&
                      !isSuggestion
                    }
                    children={values.template ? "Save Template" : "Create"}
                    onSubmit={form.submit}
                  />
                </Form.Item>
              </>
            )}

            {!!task &&
              task.status === TaskStatus.TODO &&
              !!task.applications.length && <TaskApplicationList task={task} />}

            {!screens.md && (
              <TaskFormSider
                hidden={isSuggestion}
                task={task}
                mode={mode}
                workspaceId={workspaceId}
                values={values}
              />
            )}

            {!isSuggestion && !!task && !task.template && (
              <>
                <GithubIntegrationSection task={task} />
                <TaskSubmissionsSection task={task} />
                <TaskThread task={task} />
              </>
            )}
          </Col>
        </Col>

        {!!screens.md && (
          <CollapsibleSider
            hidden={isSuggestion}
            width={250}
            collapsedWidth={32}
            style={{ marginLeft: 24 }}
            collapsed={!canSubmit && siderCollapsed.isOn}
            collapsedToggle={!canSubmit ? siderCollapsed.toggle : undefined}
          >
            <TaskFormSider
              task={task}
              mode={mode}
              workspaceId={workspaceId}
              values={values}
            />
          </CollapsibleSider>
        )}
      </Layout>

      <TaskRewardsForm
        visible={modal === "reward"}
        workspaceId={workspaceId}
        organizationId={workspace?.organizationId!}
        onClose={hideModal}
        onSave={(changed) => {
          handleChange(changed, { ...values, ...changed });
          hideModal();
        }}
        initialValues={_.pick(values, ["rewards", "openToBids"])}
      />

      {!isSuggestion && (
        <TaskGatingModal
          visible={modal === "gating"}
          onHide={hideModal}
          onChange={({ gating, ...changed }) => {
            const changedValues = { ...changed, gating: gating?.[0] };
            handleChange(changedValues, { ...values, ...changedValues });
            hideModal();
            if (submitFormAfterGating) form.submit();
          }}
          task={task}
          mode={mode}
          workspaceId={workspaceId}
          values={values}
        />
      )}
      <Form.Item name="template" hidden />
      <Form.Item name="templateTaskId" hidden />
      <Form.Item name="openToBids" hidden />
      <Form.Item name="rewards" hidden />
      <Form.Item name="applyRoleIds" hidden />
      <Form.Item name="claimRoleIds" hidden />
      <Form.Item name="assigneeIds" hidden />
      <Form.Item name="maxWinners" hidden />
      <Form.Item
        name="gating"
        rules={[
          { required: values.status !== TaskStatus.COMMUNITY_SUGGESTIONS },
        ]}
        hidden
      />
      <Form.Item name="submissionTemplate" hidden />
      <Form.Item name="applicationTemplate" hidden />
      <Form.Item name="applicationLink" hidden />
      <Form.Item name="notionPageId" hidden />
    </Form>
  );
};
