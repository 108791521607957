import gql from "graphql-tag";
import { roleDetails, roleWithRules } from "./role";

export const organization = gql`
  fragment Organization on Organization {
    id
    name
    imageUrl
    slug
    tagline
    description
    permalink
    nodeId
  }
`;

export const organizationRoles = gql`
  fragment OrganizationRoles on Organization {
    id
    nodeId
    roles {
      ...RoleWithRules
    }
  }

  ${roleWithRules}
`;

export const organizationRoleDetails = gql`
  fragment OrganizationRoleDetails on Organization {
    id
    nodeId
    roles {
      ...RoleDetails
    }
  }

  ${roleDetails}
`;
