import { Divider as AntDivider, DividerProps } from "antd";
import React, { FC } from "react";

export const Divider: FC<
  DividerProps & { size?: number | [number, number] }
> = ({ size, ...props }) => (
  <AntDivider
    {...props}
    style={{
      ...(props.style || {}),
      ...{
        marginTop: typeof size === "object" ? size[0] : size,
        marginBottom: typeof size === "object" ? size[1] : size,
      },
    }}
  />
);
