import gql from "graphql-tag";
import { nodeChildrenDetails, nodeDetails } from "../fragments/graph";

export const getGraphNode = gql`
  query GetGraphNodeQuery($id: UUID!) {
    node: getGraphNode(id: $id) {
      ...GraphNodeDetails
      ...GraphNodeChildrenDetails
    }
  }

  ${nodeDetails}
  ${nodeChildrenDetails}
`;

export const getGraphNodeBySlug = gql`
  query GetGraphNodeBySlugQuery($slug: String!) {
    node: getGraphNodeBySlug(slug: $slug) {
      ...GraphNodeDetails
      ...GraphNodeChildrenDetails
    }
  }

  ${nodeDetails}
  ${nodeChildrenDetails}
`;
