import { useMutation, useQuery } from "@apollo/client";
import {
  createWorkspaceSection,
  updateWorkspaceSection,
} from "@dewo/app/graphql/mutations/workspace";
import { workspaceSectionTasks } from "@dewo/app/graphql/queries";
import { getWorkspaceSectionDetails } from "@dewo/app/graphql/queries/workspace";
import {
  CreateWorkspaceSectionInput,
  CreateWorkspaceSectionMutation,
  CreateWorkspaceSectionMutationVariables,
  WorkspaceSection,
  UpdateWorkspaceSectionInput,
  UpdateWorkspaceSectionMutation,
  UpdateWorkspaceSectionMutationVariables,
  WorkspaceSectionDetails,
  GetWorkspaceSectionDetailsQuery,
  GetWorkspaceSectionDetailsQueryVariables,
  Task,
  GetWorkspaceSectionTasksQuery,
  GetWorkspaceSectionTasksQueryVariables,
} from "@dewo/app/graphql/types";
import { useCallback } from "react";

export function useWorkspaceSectionDetails(
  slug: string | undefined
): WorkspaceSectionDetails | undefined {
  const { data } = useQuery<
    GetWorkspaceSectionDetailsQuery,
    GetWorkspaceSectionDetailsQueryVariables
  >(getWorkspaceSectionDetails, {
    variables: { slug: slug! },
    skip: !slug,
  });
  return data?.section;
}

export function useWorkspaceSectionTasks(
  slug: string | undefined
): Task[] | undefined {
  const { data } = useQuery<
    GetWorkspaceSectionTasksQuery,
    GetWorkspaceSectionTasksQueryVariables
  >(workspaceSectionTasks, {
    variables: { slug: slug! },
    skip: !slug,
  });
  return data?.section.tasks;
}

export function useCreateWorkspaceSection(): (
  input: CreateWorkspaceSectionInput
) => Promise<WorkspaceSection> {
  const [mutation] = useMutation<
    CreateWorkspaceSectionMutation,
    CreateWorkspaceSectionMutationVariables
  >(createWorkspaceSection);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.section;
    },
    [mutation]
  );
}

export function useUpdateWorkspaceSection(): (
  input: UpdateWorkspaceSectionInput
) => Promise<WorkspaceSection> {
  const [mutation] = useMutation<
    UpdateWorkspaceSectionMutation,
    UpdateWorkspaceSectionMutationVariables
  >(updateWorkspaceSection);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.section;
    },
    [mutation]
  );
}
