import React, { FC, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Modal,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";
import { Task, TaskGatingType } from "@dewo/app/graphql/types";
import { TaskFormValues } from "../types";
import { TaskRoleSelectField } from "./TaskRoleSelectField";
import { ApplicationIcon } from "@dewo/app/components/icons/task/Application";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { MoreSectionCollapse } from "@dewo/app/components/MoreSectionCollapse";
import { ApplicationTemplateField } from "./ApplicationTemplateField";
import { ApplicationLinkField } from "./ApplicationLinkField";
import { SubmissionTemplateField } from "./SubmissionTemplateField";
import {
  useOrganizationRoles,
  useUpdateRole,
} from "@dewo/app/containers/rbac/hooks";
import { useWorkspace } from "@dewo/app/containers/workspace/hooks";
import { TaskGatingTypeInput } from "./TaskGatingTypeInput";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { TaskGatingAssigneeInput } from "./TaskGatingAssigneeInput";
import { TaskGatingClaimingInput } from "./TaskGatingClaimingInput";
import { useOnboardingTip } from "@dewo/app/components/OnboardingAlert";
import _ from "lodash";
import { RoleTag } from "@dewo/app/components/tags/RoleTag";
import { StarOutlined } from "@ant-design/icons";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { SubmissionWinnersField } from "./SubmissionWinnersField";

interface GatingFormValues {
  gating: TaskGatingType[];
  assigneeIds: string[];
  claimRoleIds: string[];
  applyRoleIds: string[];
  applicationTemplate?: string;
  applicationLink?: string;
  submissionTemplate?: string;
  maxWinners?: number;
}

interface Props {
  onHide?: () => void;
  visible: boolean;
  task: Task | undefined;
  mode: "create" | "update";
  workspaceId: string;
  values?: Partial<TaskFormValues>;
  onChange?: (values: Partial<GatingFormValues>) => void;
}

function useDefaultAllowingEveryoneToApply(
  gatingForm: FormInstance<GatingFormValues>,
  organizationId: string | undefined
) {
  const gating = Form.useWatch("gating", gatingForm);
  const applyRoleIds = Form.useWatch("applyRoleIds", gatingForm);
  const roles = useOrganizationRoles(organizationId);
  const fallbackRole = roles?.find((role) => role.fallback);

  useEffect(() => {
    if (
      applyRoleIds &&
      applyRoleIds.length === 0 &&
      gating?.includes(TaskGatingType.APPLICATION)
    ) {
      return gatingForm.setFieldsValue({
        applyRoleIds: fallbackRole ? [fallbackRole.id] : [],
      });
    }
  }, [applyRoleIds, fallbackRole, fallbackRole?.id, gating, gatingForm]);
}

const useDefaultGating = (values: Partial<TaskFormValues> | undefined) => {
  return useMemo(() => {
    if (values?.gating === TaskGatingType.OPEN_SUBMISSION) {
      return [TaskGatingType.OPEN_SUBMISSION];
    }
    if (values?.gating === TaskGatingType.ASSIGNEES) {
      return [TaskGatingType.ASSIGNEES];
    }

    const types = [];
    if (!!values?.claimRoleIds?.length) types.push(TaskGatingType.ROLES);
    if (!!values?.applyRoleIds?.length) types.push(TaskGatingType.APPLICATION);
    if (!!types.length) return types;
    return [TaskGatingType.APPLICATION];
  }, [values?.applyRoleIds, values?.claimRoleIds, values?.gating]);
};

export const TaskGatingModal: FC<Props> = ({
  onHide,
  visible,
  task,
  workspaceId,
  mode,
  onChange,
  values,
}) => {
  const [gatingForm] = Form.useForm<GatingFormValues>();

  const { workspace } = useWorkspace(workspaceId);

  const gating = Form.useWatch("gating", gatingForm);
  const applyRoleIds = Form.useWatch("applyRoleIds", gatingForm);
  const claimRoleIds = Form.useWatch("claimRoleIds", gatingForm);

  const roles = useOrganizationRoles(workspace?.organizationId);
  const fallbackRole = roles?.find((role) => role.fallback);

  useDefaultAllowingEveryoneToApply(gatingForm, workspace?.organizationId);

  const defaultGating = useDefaultGating(values);

  const initialValues: GatingFormValues = useMemo(
    () => ({
      gating: defaultGating,
      assigneeIds: values?.assigneeIds ?? [],
      claimRoleIds: values?.claimRoleIds ?? [],
      applyRoleIds: values?.applyRoleIds ?? [],
      applicationTemplate: values?.applicationTemplate,
      applicationLink: values?.applicationLink,
      submissionTemplate: values?.submissionTemplate,
      maxWinners: values?.maxWinners,
    }),
    [defaultGating, values]
  );

  const showFeatureTip = useToggle();
  const roleById = useMemo(() => _.keyBy(roles ?? [], "id"), [roles]);
  const promptRole = useMemo(
    () =>
      [...(claimRoleIds || []), ...(applyRoleIds || [])]
        .map((roleId) => roleById[roleId])
        .find((role) => !!role && !role.fallback && !role.featured),
    [applyRoleIds, claimRoleIds, roleById]
  );
  const prompt = useOnboardingTip(`RoleGating.${promptRole?.id}`);
  const canChangeRole = usePermission("update", promptRole);

  useEffect(() => {
    if (!visible) showFeatureTip.toggleOff();
  }, [showFeatureTip, visible]);

  const handleHide = useCallback(() => {
    onHide?.();
    showFeatureTip.toggleOff();
  }, [onHide, showFeatureTip]);

  const [handleSave, isSaving] = useRunning(
    useCallback(
      async (bypassPrompt?: boolean) => {
        try {
          const newValues = await gatingForm?.validateFields();
          newValues.applyRoleIds ||= [];
          newValues.claimRoleIds ||= [];

          if (
            !bypassPrompt &&
            !!promptRole &&
            !prompt.hidden &&
            canChangeRole
          ) {
            return showFeatureTip.toggleOn();
          }

          await onChange?.(newValues);
          handleHide?.();
        } catch (e) {}
      },
      [
        canChangeRole,
        gatingForm,
        onChange,
        handleHide,
        prompt.hidden,
        promptRole,
        showFeatureTip,
      ]
    )
  );

  const handleClosePopover = useCallback(() => {
    prompt.onClose();
    showFeatureTip.toggleOff();
    handleSave(true);
  }, [handleSave, prompt, showFeatureTip]);

  const updateRole = useUpdateRole();

  return (
    <Modal
      onCancel={handleHide}
      visible={visible}
      title="Task Permissions"
      width={680}
      footer={
        <Space align="center" size="middle" wrap>
          {mode === "create" && (
            <Form.Item noStyle name="defaultGating" valuePropName="checked">
              <Checkbox>
                Set as default
                <QuestionmarkTooltip
                  name="Default task checkbox"
                  marginLeft={8}
                  title="If checked, this task gating will show as default when you create a task in this workspace. This default is only visible for you within this specific workspace."
                />
              </Checkbox>
            </Form.Item>
          )}
          <Popover
            visible={showFeatureTip.isOn && !prompt.hidden}
            placement="topRight"
            content={
              !!promptRole && (
                <div style={{ maxWidth: 320 }}>
                  <Typography.Paragraph>
                    The role <RoleTag role={promptRole} />
                    isn't featured on your Roles page. We recommend featuring it
                    so that contributors easily understand the path to gaining
                    access to this task.
                  </Typography.Paragraph>
                  <Row gutter={[8, 8]} justify="end">
                    <Col>
                      <Button size="small" onClick={handleClosePopover}>
                        Don't show again
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        icon={<StarOutlined />}
                        size="small"
                        type="primary"
                        onClick={() => {
                          handleClosePopover();
                          updateRole({ id: promptRole.id, featured: true });
                        }}
                      >
                        Feature Role
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
            }
          >
            <Button
              loading={isSaving}
              type="primary"
              onClick={() => handleSave()}
              style={{ minWidth: 140 }}
            >
              Save
            </Button>
          </Popover>
        </Space>
      }
    >
      <Form form={gatingForm} initialValues={initialValues} layout="vertical">
        <Form.Item label="Who can work on this task?">
          <Card
            style={{ background: "none", padding: 0 }}
            bodyStyle={{ padding: 16 }}
          >
            <Form.Item
              name="gating"
              style={{ margin: 0 }}
              rules={[
                {
                  type: "array",
                  min: 1,
                  message: "Please select who can work on this task",
                },
              ]}
            >
              <TaskGatingTypeInput />
            </Form.Item>
          </Card>
        </Form.Item>

        {gating?.includes(TaskGatingType.ASSIGNEES) && (
          <TaskGatingAssigneeInput
            mode={mode}
            task={task}
            workspaceId={workspaceId}
            name="assigneeIds"
            style={{ marginBottom: 0 }}
            label="Assignees"
            rules={[{ required: true, message: "Please choose an assignee" }]}
          />
        )}

        {(gating?.includes(TaskGatingType.APPLICATION) ||
          gating?.includes(TaskGatingType.ROLES)) && (
          <Form.Item label="Gating" style={{ marginBottom: 0 }}>
            <Card
              style={{ background: "none", padding: 0 }}
              bodyStyle={{ padding: "8px 16px" }}
            >
              <Row gutter={8}>
                {gating.includes(TaskGatingType.APPLICATION) && (
                  <Col sm={12} xs={24}>
                    <Space align="center">
                      <ApplicationIcon style={{ width: 16 }} />
                      <Typography.Text strong>Who can apply?</Typography.Text>
                    </Space>
                    <TaskRoleSelectField
                      workspaceId={workspaceId}
                      disabled={false}
                      name="applyRoleIds"
                      roleIds={applyRoleIds}
                      showFallback
                      normalize={(roleIds: string[], prevRoleIds: string[]) => {
                        if (!roleIds.length)
                          return fallbackRole ? [fallbackRole.id] : [];
                        if (!fallbackRole) return roleIds;

                        const isFallbackSelected = roleIds.includes(
                          fallbackRole?.id!
                        );
                        const wasFallbackSelected = prevRoleIds.includes(
                          fallbackRole?.id!
                        );
                        if (isFallbackSelected && !wasFallbackSelected) {
                          return [fallbackRole.id];
                        }
                        if (isFallbackSelected && wasFallbackSelected) {
                          return roleIds.filter((r) => r !== fallbackRole.id);
                        }
                        return roleIds;
                      }}
                    />
                  </Col>
                )}

                {gating.includes(TaskGatingType.ROLES) && (
                  <Col sm={12} xs={24}>
                    <TaskGatingClaimingInput workspaceId={workspaceId} />
                  </Col>
                )}
              </Row>
            </Card>
          </Form.Item>
        )}

        {gating?.includes(TaskGatingType.APPLICATION) && (
          <MoreSectionCollapse label="Advanced">
            <ApplicationTemplateField />
            <ApplicationLinkField />
          </MoreSectionCollapse>
        )}
        {gating?.includes(TaskGatingType.OPEN_SUBMISSION) && (
          <>
            <Row>
              <Col md={12} xs={24}>
                <SubmissionWinnersField />
              </Col>
            </Row>
            <MoreSectionCollapse label="Advanced">
              <SubmissionTemplateField />
            </MoreSectionCollapse>
          </>
        )}
      </Form>
    </Modal>
  );
};
