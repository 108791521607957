import {
  InternalRefetchQueriesInclude,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useDefaultAbility } from "@dewo/app/contexts/PermissionsContext";
import * as Mutations from "@dewo/app/graphql/mutations";
import * as Queries from "@dewo/app/graphql/queries";
import { getOrganizationRoleDetails } from "@dewo/app/graphql/queries/organization";
import {
  getOpenRoles,
  getRoleWithUsers,
} from "@dewo/app/graphql/queries/roles";
import {
  AddRoleMutation,
  AddRoleMutationVariables,
  CreateRoleInput,
  CreateRoleMutation,
  CreateRoleMutationVariables,
  CreateRuleInput,
  CreateRuleMutation,
  CreateRuleMutationVariables,
  DeleteRuleMutation,
  DeleteRuleMutationVariables,
  GetOrganizationRolesQuery,
  GetOrganizationRolesQueryVariables,
  Workspace,
  RemoveRoleMutation,
  RemoveRoleMutationVariables,
  Role,
  RoleWithRules,
  Rule,
  RulePermission,
  UpdateRoleInput,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
  GetOrganizationRoleDetailsQuery,
  GetOrganizationRoleDetailsQueryVariables,
  RoleDetails,
  GetRoleWithUsersQuery,
  GetRoleWithUsersQueryVariables,
  User,
  GetOpenRolesQuery,
  GetOpenRolesQueryVariables,
} from "@dewo/app/graphql/types";
import { useCallback, useMemo } from "react";
import { getRule, hasRule, RuleContext } from "./util";

export function useOrganizationRoles(
  organizationId: string | undefined
): RoleWithRules[] | undefined {
  const { data } = useQuery<
    GetOrganizationRolesQuery,
    GetOrganizationRolesQueryVariables
  >(Queries.getOrganizationRoles, {
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });
  return data?.organization?.roles;
}

export function useOrganizationRoleDetails(organizationId: string | undefined) {
  const { data, refetch } = useQuery<
    GetOrganizationRoleDetailsQuery,
    GetOrganizationRoleDetailsQueryVariables
  >(getOrganizationRoleDetails, {
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });

  return { roles: data?.organization.roles, refetch };
}

export function useRoleWithUsers(roleId: string | undefined) {
  const { data } = useQuery<
    GetRoleWithUsersQuery,
    GetRoleWithUsersQueryVariables
  >(getRoleWithUsers, { variables: { roleId: roleId! }, skip: !roleId });
  return data?.role;
}

export function useFetchFallbackRole(): (
  organizationId: string
) => Promise<Role | undefined> {
  const [fetchOrganizationRoles] = useLazyQuery<
    GetOrganizationRolesQuery,
    GetOrganizationRolesQueryVariables
  >(Queries.getOrganizationRoles, { ssr: false });
  return useCallback(
    async (organizationId) => {
      const res = await fetchOrganizationRoles({
        variables: { organizationId },
      });
      return res.data?.organization.roles.find((r) => !!r?.fallback);
    },
    [fetchOrganizationRoles]
  );
}

export function useIsWorkspacePrivate(
  workspace: Workspace | undefined,
  organizationId: string | undefined,
  skip: boolean = false
): boolean {
  const fn = useIsWorkspacePrivateFn(organizationId);
  return useMemo(
    () => !skip && !!workspace && fn(workspace),
    [fn, workspace, skip]
  );
}

export function useIsWorkspacePrivateFn(
  organizationId: string | undefined
): (workspace: Workspace) => boolean {
  const { ability } = useDefaultAbility(organizationId);
  return useCallback(
    (workspace) => !!ability && !ability.can("read", workspace),
    [ability]
  );
}

export function useAddRole(): (
  role: Role,
  userId: string,
  organizationId: string
) => Promise<User> {
  const { user } = useAuthContext();
  const [mutation] = useMutation<AddRoleMutation, AddRoleMutationVariables>(
    Mutations.addRole
  );
  return useCallback(
    async (role, userId, organizationId) => {
      const refetchQueries: InternalRefetchQueriesInclude = [
        { query: Queries.organizationUsers, variables: { organizationId } },
      ];
      if (user?.id === userId) {
        refetchQueries.push({ query: Queries.me });
        refetchQueries.push({
          query: Queries.permissions,
          variables: { organizationId },
        });
      }
      const res = await mutation({
        variables: { roleId: role.id, userId },
        refetchQueries,
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.addRole;
    },
    [mutation, user?.id]
  );
}

export function useRemoveRole(): (
  role: Role,
  userId: string,
  organizationId: string
) => Promise<User> {
  const { user } = useAuthContext();
  const [mutation] = useMutation<
    RemoveRoleMutation,
    RemoveRoleMutationVariables
  >(Mutations.removeRole);
  return useCallback(
    async (role, userId, organizationId) => {
      const refetchQueries: InternalRefetchQueriesInclude = [
        { query: Queries.organizationUsers, variables: { organizationId } },
      ];
      if (user?.id === userId) {
        refetchQueries.push({ query: Queries.me });
        refetchQueries.push({
          query: Queries.permissions,
          variables: { organizationId },
        });
      }
      const res = await mutation({
        variables: { roleId: role.id, userId },
        refetchQueries,
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.removeRole;
    },
    [mutation, user?.id]
  );
}

export function useCreateRole(): (
  input: CreateRoleInput
) => Promise<RoleWithRules> {
  const [mutation] = useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(Mutations.createRole);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.role;
    },
    [mutation]
  );
}

export function useUpdateRole(): (
  input: UpdateRoleInput,
  role?: Role
) => Promise<RoleDetails> {
  const [mutation] = useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(Mutations.updateRole);
  return useCallback(
    async (input, role) => {
      const res = await mutation({
        variables: { input },
        optimisticResponse: !!role
          ? { role: { ...role, ...(input as any) } }
          : undefined,
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.role;
    },
    [mutation]
  );
}

export function useCreateRule(): (input: CreateRuleInput) => Promise<Rule> {
  const [mutation] = useMutation<
    CreateRuleMutation,
    CreateRuleMutationVariables
  >(Mutations.createRule);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.rule;
    },
    [mutation]
  );
}

export function useDeleteRule(): (id: string) => Promise<void> {
  const [mutation] = useMutation<
    DeleteRuleMutation,
    DeleteRuleMutationVariables
  >(Mutations.deleteRule);
  return useCallback(
    async (id) => {
      const res = await mutation({ variables: { id } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useFollowOrganization(
  organizationId: string | undefined
): () => Promise<void> {
  const { user } = useAuthContext();
  const addRole = useAddRole();
  const fetchFallbackRole = useFetchFallbackRole();
  return useCallback(async () => {
    if (!organizationId || !user) return;
    const role = await fetchFallbackRole(organizationId);
    if (!role) return;
    await addRole(role, user.id, organizationId);
  }, [addRole, user, fetchFallbackRole, organizationId]);
}

export function useUnfollowOrganization(
  organizationId: string | undefined
): () => Promise<void> {
  const { user } = useAuthContext();
  const removeRole = useRemoveRole();
  const fetchFallbackRole = useFetchFallbackRole();
  return useCallback(async () => {
    if (!organizationId || !user) return;
    const role = await fetchFallbackRole(organizationId);
    if (!role) return;
    await removeRole(role, user.id, organizationId);
  }, [removeRole, user, fetchFallbackRole, organizationId]);
}

export function useRolesWithAccess(
  organizationId: string | undefined,
  workspaceId?: string
): RoleWithRules[] | undefined {
  const roles = useOrganizationRoles(organizationId);
  return useMemo(
    () =>
      roles?.filter((r) =>
        r.rules.some(
          (rule) =>
            !rule.inverted && (!workspaceId || rule.workspaceId === workspaceId)
        )
      ),
    [roles, workspaceId]
  );
}

export function useSyncRules(): (paylod: {
  organizationId: string;
  roleIds: string[];
  userIds: string[];
  permission: RulePermission;
  context: RuleContext;
  disallowEveryoneElse?: boolean;
}) => Promise<void> {
  const apolloClient = useApolloClient();

  const createRole = useCreateRole();
  const createRule = useCreateRule();
  const deleteRule = useDeleteRule();
  return useCallback(
    async ({
      roleIds,
      userIds,
      permission,
      context,
      organizationId,
      disallowEveryoneElse,
    }) => {
      const { data } = await apolloClient.query<
        GetOrganizationRolesQuery,
        GetOrganizationRolesQueryVariables
      >({
        query: Queries.getOrganizationRoles,
        fetchPolicy: "cache-first",
        variables: { organizationId },
      });
      const roles = data.organization.roles;
      const fallbackRole = roles.find((r) => r.fallback);
      const organizationRoles = roles.filter((r) => !r.userId);
      const userRoles = roles.filter((r) => !!r.userId);

      const removedOrganizationRoles = organizationRoles.filter(
        (r) => hasRule(r, permission, context) && !roleIds.includes(r.id)
      );
      const addedOrganizationRoles = organizationRoles.filter(
        (r) => !hasRule(r, permission, context) && roleIds.includes(r.id)
      );

      const removedUserRoles = userRoles.filter(
        (r) => hasRule(r, permission, context) && !userIds.includes(r.userId!)
      );

      const impactedUserRoles = await Promise.all(
        userIds.map(async (userId) => {
          const userRole = userRoles.find((r) => r.userId === userId);
          if (userRole) return userRole;
          return createRole({
            name: "",
            color: "",
            parentId: data?.organization.nodeId,
            userId,
            userIds: [userId],
          });
        })
      );

      const addedUserRoles = impactedUserRoles.filter(
        (role) =>
          !hasRule(role, permission, context) && userIds.includes(role.userId!)
      );

      const removedRoles = [...removedOrganizationRoles, ...removedUserRoles];
      const addedRoles = [...addedOrganizationRoles, ...addedUserRoles];

      for (const role of addedRoles) {
        await createRule({ ...context, permission, roleId: role.id });
      }

      for (const role of removedRoles) {
        const rule = getRule(role, permission, context);
        await deleteRule(rule!.id);
      }

      if (!!fallbackRole) {
        const rule = getRule(fallbackRole, permission, {
          ...context,
          inverted: true,
        });

        const shouldDisallow =
          !!disallowEveryoneElse && !roleIds.includes(fallbackRole.id);
        if (shouldDisallow) {
          if (!rule) {
            await createRule({
              ...context,
              permission,
              inverted: true,
              roleId: fallbackRole.id,
            });
          }
        } else {
          if (!!rule) {
            await deleteRule(rule.id);
          }
        }
      }
    },
    [apolloClient, createRole, createRule, deleteRule]
  );
}

export function useOpenRoles({
  limit,
  skillIds,
  commitments,
}: {
  limit: number;
  skillIds?: string[];
  commitments?: number[];
}) {
  const { data } = useQuery<GetOpenRolesQuery, GetOpenRolesQueryVariables>(
    getOpenRoles,
    { variables: { limit, skillIds, commitments } }
  );
  return data?.roles;
}
