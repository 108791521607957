import React, { FC, ReactNode } from "react";
import { Typography } from "antd";

export const FormItemPlaceholder: FC<{
  placeholder: string;
  icon: ReactNode;
}> = ({ placeholder, icon }) => (
  <>
    {icon}
    <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
      {placeholder}
    </Typography.Text>
  </>
);
