import { TaskDetails } from "@dewo/app/graphql/types";
import { NextSeo } from "next-seo";
import React, { FC } from "react";
import { createSEOImageUrl } from "./util";

interface Props {
  task: TaskDetails;
}

export const TaskSeo: FC<Props> = ({ task }) => {
  const title = `${task.name} | Dework`;
  const description = `Read more about this task from ${task.workspace.organization.name} on Dework!`;
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: "Dework",
        images: [
          {
            url: createSEOImageUrl({
              title: task.name,
              subtitle: [
                task.workspace.organization.name,
                task.workspace.name,
              ].join(" / "),
              imageUrl: task.workspace.organization.imageUrl,
            }),
          },
        ],
      }}
      twitter={{ site: "@deworkxyz", cardType: "summary_large_image" }}
    />
  );
};
