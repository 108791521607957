import React from "react";
import Icon from "@ant-design/icons";

const ClaimableSvg = () => (
  <svg viewBox="0 0 24 30" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1252 6H12.5C11.6007 6 11.1267 7.06555 11.7288 7.73351L18.2799 15L11.9543 21.9418C11.2587 22.7051 11.7881 23.9321 12.8207 23.9499L15.1109 23.9892C15.5113 23.9961 15.8955 23.8311 16.1664 23.536L23.5186 15.5248C23.791 15.228 23.791 14.772 23.5186 14.4752L16.1566 6.45339C15.8915 6.16447 15.5173 6 15.1252 6Z" />
    <path
      d="M5.12516 6H2.5C1.60067 6 1.12665 7.06555 1.72884 7.73351L8.27988 15L1.95429 21.9418C1.25874 22.7051 1.78814 23.9321 2.82066 23.9499L5.11086 23.9892C5.51132 23.9961 5.89555 23.8311 6.16636 23.536L13.5186 15.5248C13.791 15.228 13.791 14.772 13.5186 14.4752L6.15663 6.45339C5.89148 6.16447 5.51731 6 5.12516 6Z"
      fillOpacity="0.45"
    />
  </svg>
);

export const ClaimableIcon = (props: any) => (
  <Icon
    component={ClaimableSvg}
    {...props}
    style={{ fill: "#FF78B9", ...props.style }}
  />
);
