import React, { FC, Fragment, ReactNode, useCallback, useMemo } from "react";
import { Modal, Space, Typography } from "antd";
import { ThreepidSource, UserDetails } from "@dewo/app/graphql/types";
import {
  getThreepidName,
  ThreepidAuthButton,
} from "./buttons/ThreepidAuthButton";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { MetamaskAuthButton } from "./buttons/MetamaskAuthButton";
import { PhantomAuthButton } from "./buttons/PhantomAuthButton";
import { MoreSectionCollapse } from "@dewo/app/components/MoreSectionCollapse";
import { WalletConnectAuthButton } from "./buttons/WalletConnectAuthButton";

interface Props {
  redirect?: string;
  visible: boolean;
  preferredThreepidSources?: ThreepidSource[];
  onClose(): void;
  onAuthedWithWallet?(user: UserDetails): void;
}

const defaultPreferredThreepidSources: ThreepidSource[] = [
  ThreepidSource.discord,
  ThreepidSource.metamask,
];

export const LoginModal: FC<Props> = ({
  visible,
  redirect,
  preferredThreepidSources = defaultPreferredThreepidSources,
  onClose,
  onAuthedWithWallet,
}) => {
  const handleCancel = useCallback(
    (e) => {
      onClose();
      stopPropagation(e);
    },
    [onClose]
  );

  const threepidAuthButtons = useMemo(
    (): Partial<Record<ThreepidSource, ReactNode>> => ({
      [ThreepidSource.discord]: (
        <ThreepidAuthButton
          key="discord"
          source={ThreepidSource.discord}
          children={`${getThreepidName[ThreepidSource.discord]} (recommended)`}
          size="large"
          block
          redirect={redirect}
        />
      ),
      [ThreepidSource.metamask]: (
        <Fragment key="metamask">
          <MetamaskAuthButton
            children={getThreepidName[ThreepidSource.metamask]}
            size="large"
            block
            redirect={redirect}
            onAuthed={onAuthedWithWallet}
          />
          <WalletConnectAuthButton
            children="WalletConnect"
            size="large"
            block
            redirect={redirect}
            onAuthed={onAuthedWithWallet}
          />
        </Fragment>
      ),
      [ThreepidSource.phantom]: (
        <PhantomAuthButton
          key="phantom"
          children={getThreepidName[ThreepidSource.phantom]}
          size="large"
          block
          redirect={redirect}
          onAuthed={onAuthedWithWallet}
        />
      ),
      [ThreepidSource.github]: (
        <ThreepidAuthButton
          key="github"
          source={ThreepidSource.github}
          children={getThreepidName[ThreepidSource.github]}
          size="large"
          block
          redirect={redirect}
        />
      ),
    }),
    [onAuthedWithWallet, redirect]
  );
  const nonPreferredThreepidSources = useMemo(
    () =>
      (Object.keys(threepidAuthButtons) as ThreepidSource[]).filter(
        (source) => !preferredThreepidSources.includes(source)
      ),
    [preferredThreepidSources, threepidAuthButtons]
  );

  return (
    <Modal
      visible={visible}
      footer={null}
      bodyStyle={{ paddingTop: 80, paddingBottom: 80 }}
      onCancel={handleCancel}
    >
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Welcome to Dework
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        Sign-in to get started
      </Typography.Paragraph>
      <Space
        direction="vertical"
        className="mx-auto w-full"
        style={{ display: "flex", maxWidth: 368 }}
      >
        {preferredThreepidSources.map((source) => threepidAuthButtons[source])}
        {!!nonPreferredThreepidSources.length && (
          <MoreSectionCollapse label="More">
            <Space
              direction="vertical"
              style={{ width: "100%", marginTop: 16 }}
            >
              {nonPreferredThreepidSources.map(
                (source) => threepidAuthButtons[source]
              )}
            </Space>
          </MoreSectionCollapse>
        )}
      </Space>
    </Modal>
  );
};
