import gql from "graphql-tag";
import { workspaceSectionDetails } from "../fragments/workspace";

export const getWorkspaceSectionDetails = gql`
  query GetWorkspaceSectionDetailsQuery($slug: String!) {
    section: getWorkspaceSectionBySlug(slug: $slug) {
      ...WorkspaceSectionDetails
    }
  }

  ${workspaceSectionDetails}
`;
