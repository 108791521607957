import gql from "graphql-tag";

export const user = gql`
  fragment User on User {
    id
    username
    imageUrl
    permalink
    nodeId
  }
`;

export const userPrompt = gql`
  fragment UserPrompt on UserPrompt {
    id
    type
    createdAt
    completedAt
  }
`;
