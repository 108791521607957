import {
  ApolloError,
  useApolloClient,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import * as Mutations from "@dewo/app/graphql/mutations";
import * as Queries from "@dewo/app/graphql/queries";
import * as Fragments from "@dewo/app/graphql/fragments";
import {
  CreateWorkspaceInput,
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables,
  CreateWorkspaceTokenGateMutation,
  CreateWorkspaceTokenGateMutationVariables,
  CreateTaskSectionInput,
  CreateTaskSectionMutation,
  CreateTaskSectionMutationVariables,
  DeleteWorkspaceTokenGateMutation,
  DeleteWorkspaceTokenGateMutationVariables,
  GetWorkspaceIntegrationsQuery,
  GetWorkspaceIntegrationsQueryVariables,
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables,
  GetWorkspaceTasksQuery,
  GetWorkspaceTasksQueryVariables,
  GetWorkspaceTaskTagsQuery,
  GetWorkspaceTaskTagsQueryVariables,
  Workspace,
  WorkspaceDetails,
  WorkspaceIntegration,
  WorkspaceTokenGateInput,
  Task,
  TaskTag,
  UpdateWorkspaceInput,
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables,
  UpdateTaskSectionInput,
  UpdateTaskSectionMutation,
  UpdateTaskSectionMutationVariables,
  DeleteTaskSectionMutation,
  DeleteTaskSectionMutationVariables,
  TaskSection,
  GetWorkspaceDetailsQuery,
  GetWorkspaceDetailsQueryVariables,
  TaskGatingDefaultInput,
  SetTaskGatingDefault,
  SetTaskGatingDefaultVariables,
  GetWorkspaceIdBySlugQuery,
  GetWorkspaceIdBySlugQueryVariables,
  User,
  GetRelevantUsersQuery,
  GetRelevantUsersQueryVariables,
  RelevantUsersModes,
  SearchTasksInput,
  TaskViewSortByField,
  TaskViewSortByDirection,
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables,
  OrganizationDetails,
  GraphNodeChildrenDetails,
  GraphNodeDetails,
} from "@dewo/app/graphql/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { isSSR } from "@dewo/app/util/isSSR";
import { useTaskViewLayoutData } from "../task/views/hooks";
import { useOrganizationWorkspaces } from "../organization/hooks";

export function useCreateWorkspace(): (
  input: CreateWorkspaceInput
) => Promise<WorkspaceDetails> {
  const [mutation] = useMutation<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >(Mutations.createWorkspace);
  return useCallback(
    async (input) => {
      const res = await mutation({
        variables: { input },
        refetchQueries: !!input.parentId
          ? [
              {
                query: Queries.permissions,
                variables: { organizationId: input.organizationId },
              },
            ]
          : undefined,
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.workspace;
    },
    [mutation]
  );
}

export function useUpdateWorkspace(): (
  input: UpdateWorkspaceInput
) => Promise<Workspace> {
  const [mutation] = useMutation<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >(Mutations.updateWorkspace);
  const apolloClient = useApolloClient();
  return useCallback(
    async (input) => {
      const fragment = await (async () => {
        try {
          return apolloClient.readFragment<WorkspaceDetails>({
            id: `Workspace:${input.id}`,
            fragment: Fragments.workspaceDetails,
            fragmentName: "WorkspaceDetails",
          });
        } catch {
          return undefined;
        }
      })();

      const res = await mutation({
        variables: { input },
        optimisticResponse: !!fragment
          ? { workspace: _.merge({}, fragment, input) }
          : undefined,
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.workspace;
    },
    [mutation, apolloClient]
  );
}

export function useDeleteWorkspace(): (
  id: string
) => Promise<OrganizationDetails> {
  const [mutation] = useMutation<
    DeleteWorkspaceMutation,
    DeleteWorkspaceMutationVariables
  >(Mutations.deleteWorkspace);
  return useCallback(
    async (id) => {
      const res = await mutation({
        variables: { input: { id, deletedAt: new Date().toISOString() } },
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.workspace.organization;
    },
    [mutation]
  );
}

export function useCreateWorkspaceTokenGate(): (
  input: WorkspaceTokenGateInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateWorkspaceTokenGateMutation,
    CreateWorkspaceTokenGateMutationVariables
  >(Mutations.createWorkspaceTokenGate);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useDeleteWorkspaceTokenGate(): (
  input: WorkspaceTokenGateInput
) => Promise<void> {
  const [mutation] = useMutation<
    DeleteWorkspaceTokenGateMutation,
    DeleteWorkspaceTokenGateMutationVariables
  >(Mutations.deleteWorkspaceTokenGate);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useWorkspace(workspaceId: string | undefined): {
  workspace: Workspace | undefined;
  error: ApolloError | undefined;
} {
  const { data, error } = useQuery<
    GetWorkspaceQuery,
    GetWorkspaceQueryVariables
  >(Queries.getWorkspace, {
    variables: { workspaceId: workspaceId! },
    skip: !workspaceId,
  });
  return { workspace: data?.workspace ?? undefined, error };
}

export function useWorkspaceDetails(workspaceId: string | undefined): {
  workspace: WorkspaceDetails | undefined;
  error: ApolloError | undefined;
} {
  const { data, error } = useQuery<
    GetWorkspaceDetailsQuery,
    GetWorkspaceDetailsQueryVariables
  >(Queries.workspaceDetails, {
    variables: { workspaceId: workspaceId! },
    skip: !workspaceId,
  });
  return { workspace: data?.workspace ?? undefined, error };
}

export function useWorkspaceIdBySlug(
  workspaceSlug: string | undefined
): string | undefined {
  const { data } = useQuery<
    GetWorkspaceIdBySlugQuery,
    GetWorkspaceIdBySlugQueryVariables
  >(Queries.workspaceIdBySlug, {
    variables: { workspaceSlug: workspaceSlug! },
    skip: !workspaceSlug,
  });
  return data?.workspaceId;
}

export function useWorkspaceIntegrations(
  workspaceId: string | undefined
): WorkspaceIntegration[] | undefined {
  const { data } = useQuery<
    GetWorkspaceIntegrationsQuery,
    GetWorkspaceIntegrationsQueryVariables
  >(Queries.workspaceIntegrations, {
    variables: { workspaceId: workspaceId! },
    skip: !workspaceId,
  });
  return data?.workspace.integrations;
}

export function useWorkspaceTasks(
  workspaceId: string | undefined,
  fetchPolicy?: WatchQueryFetchPolicy
): Task[] | undefined {
  const { data, refetch } = useQuery<
    GetWorkspaceTasksQuery,
    GetWorkspaceTasksQueryVariables
  >(Queries.workspaceTasks, {
    variables: { workspaceId: workspaceId! },
    skip: !workspaceId || isSSR,
  });
  useEffect(() => {
    if (fetchPolicy === "cache-and-network" && !!data) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return data?.workspace.tasks ?? undefined;
}

export function useWorkspaceTaskTags(workspaceId: string | undefined): {
  taskTags: TaskTag[];
  rootWorkspaceId: string | undefined;
} {
  const organizationId = useWorkspace(workspaceId).workspace?.organizationId;
  const organization = useOrganizationWorkspaces(organizationId);
  const matchesIdOrHasChildMatchingId = useCallback(
    (
      node: GraphNodeDetails & Partial<GraphNodeChildrenDetails>,
      workspaceId: string
    ): boolean =>
      node.workspace?.id === workspaceId ||
      !!node.children?.some((edge) =>
        matchesIdOrHasChildMatchingId(edge.node, workspaceId)
      ),
    []
  );

  const rootWorkspaceId = useMemo(
    () =>
      !!workspaceId
        ? organization?.node.workspaceChildren.find((edge) =>
            matchesIdOrHasChildMatchingId(edge.node, workspaceId)
          )?.node.workspace?.id
        : undefined,
    [
      organization?.node.workspaceChildren,
      workspaceId,
      matchesIdOrHasChildMatchingId,
    ]
  );

  const { data } = useQuery<
    GetWorkspaceTaskTagsQuery,
    GetWorkspaceTaskTagsQueryVariables
  >(Queries.workspaceTaskTags, {
    variables: { workspaceId: rootWorkspaceId! },
    skip: !rootWorkspaceId,
  });

  return useMemo(
    () => ({
      rootWorkspaceId,
      taskTags: data?.workspace?.taskTags ?? [],
    }),
    [data?.workspace?.taskTags, rootWorkspaceId]
  );
}

export function useCreateTaskSection(): (
  input: CreateTaskSectionInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateTaskSectionMutation,
    CreateTaskSectionMutationVariables
  >(Mutations.createTaskSection);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useUpdateTaskSection(): (
  input: UpdateTaskSectionInput,
  section: TaskSection
) => Promise<void> {
  const [mutation] = useMutation<
    UpdateTaskSectionMutation,
    UpdateTaskSectionMutationVariables
  >(Mutations.updateTaskSection);
  return useCallback(
    async (input, section) => {
      const res = await mutation({
        variables: { input },
        optimisticResponse: { section: { ...section, ...(input as any) } },
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useDeleteTaskSection(): (
  input: UpdateTaskSectionInput
) => Promise<void> {
  const [mutation] = useMutation<
    DeleteTaskSectionMutation,
    DeleteTaskSectionMutationVariables
  >(Mutations.deleteTaskSection);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useSetTaskGatingDefault(): (
  input: TaskGatingDefaultInput
) => Promise<void> {
  const [mutation] = useMutation<
    SetTaskGatingDefault,
    SetTaskGatingDefaultVariables
  >(Mutations.setTaskGatingDefault);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useRelevantUsers(
  workspaceId: string,
  mode: RelevantUsersModes,
  options?: {
    lazy?: boolean;
    fallback?: User[];
  }
): {
  users: User[] | undefined;
  load(): void;
} {
  const [forceLoad, setForceLoad] = useState(false);
  const load = useCallback(() => setForceLoad(true), []);

  const { data } = useQuery<
    GetRelevantUsersQuery,
    GetRelevantUsersQueryVariables
  >(Queries.getRelevantUsers, {
    variables: { input: { workspaceId, mode } },
    skip: options?.lazy && !forceLoad,
  });
  const fallbackUsers = !!options?.fallback?.length
    ? options?.fallback
    : undefined;
  const users = useMemo(() => {
    if (!data?.users && !fallbackUsers) return undefined;
    return _.uniqBy([...(data?.users ?? []), ...(fallbackUsers ?? [])], "id");
  }, [data?.users, fallbackUsers]);
  return { users, load };
}

export function useWorkspaceTaskTemplates(
  workspaceId: string
): Task[] | undefined {
  const [data] = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] => [
        {
          template: true,
          workspaceIds: [workspaceId],
          sortBy: {
            field: TaskViewSortByField.createdAt,
            direction: TaskViewSortByDirection.ASC,
          },
        },
      ],
      [workspaceId]
    )
  );

  return data.tasks;
}
