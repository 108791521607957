import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { ThreadMessage } from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { Button } from "antd";
import React, { FC, useCallback, useState } from "react";
import { useUpdateThreadMessage } from "./hooks";

interface Props {
  message: ThreadMessage;
  onDone(message: ThreadMessage): void;
}

export const UpdateThreadMessageInput: FC<Props> = ({ message, onDone }) => {
  const [content, setContent] = useState<string | undefined>(message.content);
  const updateThreadMessage = useUpdateThreadMessage();

  const [handleSubmit, submitting] = useRunning(
    useCallback(
      (content) =>
        !!content &&
        updateThreadMessage({ id: message.id, content }).then(onDone),
      [updateThreadMessage, onDone, message.id]
    )
  );

  return (
    <RichMarkdownEditor
      simple
      initialValue={content ?? ""}
      editable
      onChange={setContent}
      onSave={handleSubmit}
      buttons={({ disabled, onSave }) =>
        !!content ? (
          <Button
            type="primary"
            disabled={disabled}
            onClick={onSave}
            loading={submitting}
          >
            Save
          </Button>
        ) : null
      }
    />
  );
};
