import { useCallback } from "react";
import {
  TaskDetails,
  RulePermission,
  Workspace,
  WorkspaceType,
} from "@dewo/app/graphql/types";
import _ from "lodash";
import { useSyncRules } from "../../rbac/hooks";
import { useCreateWorkspace, useWorkspaceDetails } from "../../workspace/hooks";

export function useCreateProjectFromTask(
  task: TaskDetails
): () => Promise<Workspace> {
  const syncRules = useSyncRules();
  const { workspace } = useWorkspaceDetails(task.workspaceId);
  const parent = workspace?.parent ?? task.workspace;

  const createWorkspace = useCreateWorkspace();
  return useCallback(async () => {
    const project = await createWorkspace({
      name: task.name,
      parentId: parent.id,
      type: WorkspaceType.PROJECT,
      organizationId: parent.organizationId,
    });

    await syncRules({
      roleIds: [],
      userIds: _.uniq([...task.assignees, ...task.owners].map((u) => u.id)),
      permission: RulePermission.MANAGE_PROJECTS,
      context: { workspaceId: project.id },
      organizationId: task.workspace.organizationId,
    });

    return project;
  }, [createWorkspace, syncRules, task, parent]);
}
