import { DeleteOutlined } from "@ant-design/icons";
import * as Colors from "@ant-design/colors";
import { eatClick } from "@dewo/app/util/eatClick";
import { Menu, Popconfirm } from "antd";
import { MenuItemProps } from "rc-menu";
import React, { FC } from "react";

interface Props extends MenuItemProps {
  onClick(): void;
}

export const DeleteMenuItem: FC<Props> = ({ onClick, ...menuItemProps }) => (
  <Popconfirm
    icon={<DeleteOutlined style={{ color: Colors.grey.primary }} />}
    title="Delete this task?"
    okType="danger"
    okText="Delete"
    onConfirm={onClick}
  >
    <Menu.Item
      icon={<DeleteOutlined />}
      children="Delete"
      danger
      onClick={(e) => eatClick(e.domEvent)}
      {...menuItemProps}
    />
  </Popconfirm>
);
