import { PlusOutlined } from "@ant-design/icons";
import { CreateTaskButtonWithWorkspaceDropdown } from "@dewo/app/components/task/CreateTaskButtonWithWorkspaceDropdown";
import {
  useOrganizationDetails,
  useOrganizationWorkspaces,
} from "@dewo/app/containers/organization/hooks";
import { usePermissionFn } from "@dewo/app/contexts/PermissionsContext";
import {
  GraphNodeDetails,
  TaskStatus,
  Workspace,
} from "@dewo/app/graphql/types";
import React, { FC, useCallback, useMemo } from "react";
import styles from "../Menu.module.less";

interface Props {
  organizationId: string;
}

export const CreateTaskButton: FC<Props> = ({ organizationId }) => {
  const { organization } = useOrganizationDetails(organizationId);
  const node = useOrganizationWorkspaces(organizationId)?.node;

  const hasPermission = usePermissionFn();
  const hasCreateTaskPermission = useCallback(
    (workspace: Workspace): boolean =>
      !!hasPermission("create", {
        __typename: "Task",
        workspaceId: workspace.id,
        status: TaskStatus.TODO,
      }),
    [hasPermission]
  );
  const hasCreateSuggestionPermission = useCallback(
    (workspace: Workspace): boolean =>
      !!hasPermission("create", {
        __typename: "Task",
        workspaceId: workspace.id,
        status: TaskStatus.COMMUNITY_SUGGESTIONS,
        // @ts-ignore
        ...{ ownerIds: [] },
      }),
    [hasPermission]
  );
  const hasCreateTaskPermissionForNode = useCallback(
    (node: GraphNodeDetails) =>
      !!node.workspace && hasCreateTaskPermission(node.workspace),
    [hasCreateTaskPermission]
  );
  const hasCreateSuggestionPermissionForNode = useCallback(
    (node: GraphNodeDetails) =>
      !!node.workspace && hasCreateSuggestionPermission(node.workspace),
    [hasCreateSuggestionPermission]
  );

  const canCreateTask = useMemo(
    () => !!organization?.workspaces.some(hasCreateTaskPermission),
    [organization, hasCreateTaskPermission]
  );
  const canCreateSuggestion = useMemo(
    () => !!organization?.workspaces.some(hasCreateSuggestionPermission),
    [organization, hasCreateSuggestionPermission]
  );

  if (canCreateTask) {
    return (
      <div className={styles.createTaskButtonContainer}>
        <CreateTaskButtonWithWorkspaceDropdown
          name="Create task from menu"
          values={{ status: TaskStatus.TODO }}
          parentNode={node}
          filter={hasCreateTaskPermissionForNode}
          block
          icon={<PlusOutlined />}
          children="New Task"
        />
      </div>
    );
  }

  if (canCreateSuggestion) {
    return (
      <div className={styles.createTaskButtonContainer}>
        <CreateTaskButtonWithWorkspaceDropdown
          name="Create suggestion from menu"
          values={{ status: TaskStatus.COMMUNITY_SUGGESTIONS }}
          parentNode={node}
          filter={hasCreateSuggestionPermissionForNode}
          block
          className={styles.createTaskButton}
          icon={<PlusOutlined />}
          children="New Suggestion"
        />
      </div>
    );
  }

  return null;
};
