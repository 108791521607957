import { Skill } from "@dewo/app/graphql/types";
import { Avatar, AvatarProps } from "antd";
import React, { FC } from "react";

export const SkillAvatar: FC<{ skill: Skill } & AvatarProps> = ({
  skill,
  ...avatarProps
}) => {
  if (!!skill.imageUrl) {
    return <Avatar src={skill.imageUrl} {...avatarProps} />;
  }

  if (!!skill.emoji) {
    return <span style={avatarProps.style}>{skill.emoji}</span>;
  }

  return null;
};
