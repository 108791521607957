import {
  DiscordGuildMembershipState,
  OrganizationIntegrationType,
  Task,
  ThreepidAuthSessionType,
} from "@dewo/app/graphql/types";
import {
  Alert,
  Modal,
  notification,
  Row,
  Spin,
  Typography,
  Form,
  Button,
  DatePicker,
  Input,
} from "antd";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useCreateTaskApplication, useTaskDetails } from "../../hooks";
import moment from "moment";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useWorkspace } from "../../../workspace/hooks";
import { useRouter } from "next/router";
import { ThreepidSource } from "@dewo/app/graphql/types";
import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import { Constants } from "@dewo/app/util/constants";
import {
  useAddUserToDiscordGuild,
  useDiscordGuildMembershipState,
} from "@dewo/app/containers/integrations/hooks";
import { useFollowOrganization } from "@dewo/app/containers/rbac/hooks";
import {
  useOrganization,
  useOrganizationIntegrations,
  useOrganizationTokens,
} from "@dewo/app/containers/organization/hooks";
import { useMounted, useRunning } from "@dewo/app/util/hooks";
import { RouterContext } from "next/dist/shared/lib/router-context";
import Link from "next/link";
import { OpenDiscordButton } from "@dewo/app/components/buttons/OpenDiscordButton";

import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import {
  toTaskRewardFormItem,
  toTaskRewardInput,
} from "../../reward/form/util";
import { useCreateThreepidAuthSession } from "@dewo/app/containers/auth/hooks";
import { TaskRewardRequestFunding } from "../../reward/form/TaskRewardRequestFunding";
import _ from "lodash";
import { TaskRewardFormItemValue } from "../../reward/form/types";

interface FormValues {
  message: string;
  dates: [moment.Moment, moment.Moment];
  reward: TaskRewardFormItemValue | undefined;
}

interface Props {
  taskId: string | undefined;
  visible: boolean;
  onCancel(event: any): void;
  onDone(task: Task): unknown;
}

const ApplyToTaskContent: FC<Props> = ({ taskId, onDone }) => {
  const { user } = useAuthContext();
  const router = useRouter();

  const { task } = useTaskDetails(taskId);
  const { workspace } = useWorkspace(task?.workspaceId);
  const organization = useOrganization(workspace?.organizationId);

  const tokens = useOrganizationTokens(workspace?.organizationId);
  const tokenById = useMemo(() => _.keyBy(tokens, "id"), [tokens]);

  const isConnectedToDiscord = useMemo(
    () => !!user?.threepids.some((t) => t.source === ThreepidSource.discord),
    [user]
  );
  const hasDiscordIntegration = !!useOrganizationIntegrations(
    workspace?.organizationId,
    OrganizationIntegrationType.DISCORD
  )?.length;
  const membershipState = useDiscordGuildMembershipState(
    workspace?.organizationId
  );
  const addUserToDiscordGuild = useAddUserToDiscordGuild(
    workspace?.organizationId
  );
  const followOrganization = useFollowOrganization(workspace?.organizationId);

  const initialReward = task?.rewards[0];
  const [form] = Form.useForm<FormValues>();

  const createTaskApplication = useCreateTaskApplication();
  const [handleSubmit, submitting] = useRunning(
    useCallback(
      async (input: FormValues) => {
        if (membershipState === DiscordGuildMembershipState.HAS_SCOPE) {
          await addUserToDiscordGuild().catch(() => {});
        }

        const application = await createTaskApplication({
          taskId: task!.id,
          userId: user!.id,
          message: input.message,
          startDate: input.dates[0].toISOString(),
          endDate: input.dates[1].toISOString(),
          reward:
            input.reward &&
            toTaskRewardInput(input.reward, tokenById[input.reward.tokenId]),
        });
        await followOrganization();
        await onDone(application.task);

        const content =
          hasDiscordIntegration && !!application.discordThreadUrl ? (
            <>
              <Typography.Paragraph>
                You will now be able to chat with the task reviewer in a Discord
                thread we created for you in {organization?.name}'s server
              </Typography.Paragraph>
              <Row style={{ columnGap: 8 }}>
                <OpenDiscordButton
                  type="primary"
                  size="small"
                  href={application.discordThreadUrl}
                >
                  Open Discord
                </OpenDiscordButton>
                <Link href={user!.permalink + "/board"}>
                  <a>
                    <Button size="small" onClick={notification.destroy}>
                      Go to My Task Board
                    </Button>
                  </a>
                </Link>
              </Row>
            </>
          ) : (
            <>
              <Typography.Paragraph>
                Next the task reviewer will review your application. If they
                assign you, you can start working on the task
              </Typography.Paragraph>
              <Link href={user!.permalink + "/board"}>
                <a>
                  <Button type="primary" onClick={notification.destroy}>
                    Go to My Task Board
                  </Button>
                </a>
              </Link>
            </>
          );

        notification.success({
          placement: "top",
          duration: 5,
          message: "Submitted",
          description: (
            <RouterContext.Provider value={router} children={content} />
          ),
        });
      },
      [
        membershipState,
        createTaskApplication,
        task,
        user,
        tokenById,
        followOrganization,
        onDone,
        hasDiscordIntegration,
        organization?.name,
        router,
        addUserToDiscordGuild,
      ]
    )
  );

  const header = (
    <Alert
      showIcon
      type="info"
      style={{ marginTop: 20, marginBottom: 20 }}
      message={`To be assigned to this task, talk with the task reviewer on Discord. Fill in the details below and we will create a thread in ${organization?.name}'s Discord server where you can discuss the details.`}
    />
  );

  const createSession = useCreateThreepidAuthSession();
  const handleConnectDiscord = useCallback(async () => {
    const sessionId = await createSession(
      ThreepidAuthSessionType.DISCORD,
      router.asPath
    );

    window.location.href = `${Constants.GRAPHQL_API_URL}/auth/discord-join-guild?state=${sessionId}`;
  }, [createSession, router.asPath]);

  if (!membershipState) {
    return (
      <div style={{ display: "grid" }}>
        <Spin />
      </div>
    );
  }

  if (membershipState === DiscordGuildMembershipState.MISSING_SCOPE) {
    return (
      <Row align="middle" style={{ flexDirection: "column" }}>
        {header}
        <Typography.Paragraph style={{ textAlign: "center" }}>
          {isConnectedToDiscord
            ? `First you need to join ${organization?.name}'s Discord server`
            : "First you need to connect your Discord account"}
        </Typography.Paragraph>
        <Button
          type="primary"
          size="large"
          icon={<DiscordIcon />}
          children={isConnectedToDiscord ? "Join server" : "Connect Discord"}
          onClick={handleConnectDiscord}
        />
      </Row>
    );
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        reward: !!initialReward
          ? toTaskRewardFormItem(initialReward, initialReward.token)
          : undefined,
        message: task?.applicationTemplate,
      }}
      requiredMark={false}
      onFinish={handleSubmit}
    >
      {header}
      <Form.Item
        name="dates"
        label="When can you work on this?"
        rules={[{ required: true, message: "Please enter a date" }]}
      >
        <DatePicker.RangePicker
          name="period"
          disabledDate={(current) => current <= moment().add(-1, "days")}
        />
      </Form.Item>
      <Form.Item name="message" label="Message">
        <Input.TextArea
          autoSize
          className="dewo-field"
          placeholder="Please tell us or show us something similar you've done in the past"
          style={{ minHeight: 120 }}
        />
      </Form.Item>

      {!!task?.openToBids && (
        <Form.Item name="reward">
          <TaskRewardRequestFunding
            type={task?.rewards[0]?.type}
            organizationId={organization?.id}
            onChange={(reward) => form.setFieldsValue({ reward })}
            openToBids={task?.openToBids}
          />
        </Form.Item>
      )}

      <Button
        loading={submitting}
        type="primary"
        htmlType="submit"
        size="large"
        block
      >
        I'm interested
      </Button>
    </Form>
  );
};

export const TaskApplyModal: FC<Props> = (props) => {
  const { logEvent } = useAmplitude();
  const mounted = useMounted();
  useEffect(() => {
    if (!mounted) return;

    if (props.visible) {
      logEvent("Close task apply modal", { taskId: props.taskId });
    } else {
      logEvent("Open task apply modal", { taskId: props.taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logEvent, props.visible, props.taskId]);

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      onCancel={props.onCancel}
      footer={null}
    >
      <ApplyToTaskContent {...props} />
    </Modal>
  );
};
