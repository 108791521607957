import React, { FC, useCallback, useMemo } from "react";
import {
  Button,
  Divider,
  message,
  Row,
  Select,
  SelectProps,
  Typography,
} from "antd";
import _ from "lodash";
import {
  OrganizationIntegrationType,
  Role,
  RoleSource,
} from "@dewo/app/graphql/types";
import { RoleTag } from "../tags/RoleTag";
import { useUpdateOrganizationDiscordRoles } from "@dewo/app/containers/integrations/hooks";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { DebouncedSelect } from "./DebouncedSelect";
import { useOrganizationIntegrations } from "@dewo/app/containers/organization/hooks";
import { ConnectOrganizationToDiscordButton } from "@dewo/app/containers/integrations/discord/ConnectOrganizationToDiscordButton";
import styles from "./RoleSelect.module.less";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { NewRoleButton } from "@dewo/app/containers/organization/roles/NewRoleButton";

interface Props extends SelectProps {
  roles?: Role[];
  organizationId: string;
  showFallback?: boolean;
  forceFeature?: boolean;
}

export const RoleSelect: FC<Props> = ({
  roles,
  organizationId,
  showFallback,
  onChange,
  onSelect,
  value,
  forceFeature,
  ...selectProps
}) => {
  const roleById = useMemo(() => _.keyBy(roles, (r) => r.id), [roles]);
  const updateOrganizationDiscordRoles = useUpdateOrganizationDiscordRoles();

  const [refresh, refreshing] = useRunning(
    useCallback(async () => {
      await updateOrganizationDiscordRoles(organizationId);
      message.success("Latest Discord roles fetched!");
    }, [organizationId, updateOrganizationDiscordRoles])
  );

  const hasDiscordIntegration = !!useOrganizationIntegrations(
    organizationId,
    OrganizationIntegrationType.DISCORD
  )?.length;

  const fallbackRole = useMemo(
    () => (showFallback ? roles?.find((role) => role.fallback) : undefined),
    [roles, showFallback]
  );
  const [discordRoles, restRoles] = useMemo(
    () =>
      _.partition(
        roles?.filter((role) => !role.fallback),
        (role) => role.source === RoleSource.DISCORD
      ),
    [roles]
  );
  const canCreate = usePermission("create", "Role");
  const showCreateRole = useToggle();
  const handleNewRoleCreated = useCallback(
    (role: Role) => {
      onChange?.([...value, role.id], { label: role.name, value: role.id });
      onSelect?.(role.id, { label: role.name, value: role.id });
      showCreateRole.toggleOff();
    },
    [onChange, onSelect, showCreateRole, value]
  );

  return (
    <DebouncedSelect
      mode="multiple"
      showSearch
      optionFilterProp="label"
      loading={!roles}
      onChange={onChange}
      onSelect={onSelect}
      value={value}
      dropdownClassName={styles.roleSelectDropdown}
      dropdownRender={(children) => (
        <>
          {children}
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 4 }}
          >
            <NewRoleButton
              hidden={!canCreate}
              type="primary"
              icon={<PlusOutlined />}
              block
              onSubmit={handleNewRoleCreated}
              organizationId={organizationId}
              forceFeature={forceFeature}
            >
              New role
            </NewRoleButton>
            {!hasDiscordIntegration ? (
              <ConnectOrganizationToDiscordButton
                block
                organizationId={organizationId}
                children="Import from Discord"
              />
            ) : (
              <Button
                loading={refreshing}
                onClick={refresh}
                size="small"
                type="text"
                icon={<ReloadOutlined />}
              >
                Fetch latest Discord roles
              </Button>
            )}
          </Row>
        </>
      )}
      tagRender={(props) =>
        props.value === fallbackRole?.id ? (
          <Typography.Text>Anyone</Typography.Text>
        ) : (
          !!roleById[props.value] && (
            <RoleTag {...props} role={roleById[props.value]} />
          )
        )
      }
      {...selectProps}
    >
      {!!fallbackRole && (
        <Select.Option key="everyone" value={fallbackRole.id} label="Anyone">
          Anyone
        </Select.Option>
      )}
      {restRoles?.map((role) => (
        <Select.Option key={role.id} value={role.id} label={role.name}>
          <RoleTag role={role} />
        </Select.Option>
      ))}
      {!!discordRoles?.length && <Select.OptGroup label="Discord Roles" />}
      {discordRoles?.map((role) => (
        <Select.Option key={role.id} value={role.id} label={role.name}>
          <RoleTag role={role} />
        </Select.Option>
      ))}
    </DebouncedSelect>
  );
};
