import React, { FC, useRef } from "react";
import { Button, Typography } from "antd";
import { useToggle } from "@dewo/app/util/hooks";
import styles from "./ViewMoreContainer.module.less";
import classNames from "classnames";

interface Props {
  height?: number;
  showButton?: boolean;
  showFadeOut?: boolean;
}

export const ViewMoreContainer: FC<Props> = ({
  height = 300,
  showButton = true,
  showFadeOut = true,
  children,
}) => {
  const viewMore = useToggle(false);
  const viewMoreRef = useRef<HTMLDivElement>(null);

  const currentHeight = viewMoreRef.current?.clientHeight;
  const shouldShowAll =
    viewMore.isOn || (!!currentHeight && currentHeight < height);

  return (
    <div className={styles.container}>
      <div
        onClick={showButton ? viewMore.toggleOn : undefined}
        style={{ maxHeight: shouldShowAll ? undefined : height }}
        className={classNames({
          [styles.viewMoreContainer]: true,
          [styles.fadeOut]: showFadeOut,
          [styles.open]: shouldShowAll,
        })}
        ref={viewMoreRef}
      >
        {children}
      </div>

      {showButton && !shouldShowAll && (
        <Button
          className="mx-auto"
          style={{ display: "block" }}
          type="text"
          onClick={viewMore.toggleOn}
        >
          <Typography.Text strong type="secondary">
            View more...
          </Typography.Text>
        </Button>
      )}
    </div>
  );
};
