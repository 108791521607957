import { LinkOutlined } from "@ant-design/icons";
import { Role } from "@dewo/app/graphql/types";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, ButtonProps } from "antd";
import React, { FC, useCallback } from "react";
import styles from "./GetRoleButton.module.less";
import { RoleModal } from "./RoleModal";

interface Props extends Omit<ButtonProps, "onSubmit"> {
  organizationId: string;
  onSubmit?: (role: Role) => void;
  forceFeature?: boolean;
}

export const NewRoleButton: FC<Props> = ({
  children = "New role",
  organizationId,
  onSubmit,
  forceFeature,
  ...props
}) => {
  const showCreateRole = useToggle();
  const handleSubmit = useCallback(
    (role: Role) => {
      onSubmit?.(role);
      showCreateRole.toggleOff();
    },
    [onSubmit, showCreateRole]
  );
  return (
    <>
      <Button
        name="Apply to role (external link)"
        type="primary"
        icon={<LinkOutlined />}
        className={styles.getRoleButton}
        onClick={showCreateRole.toggleOn}
        {...props}
      >
        {children}
      </Button>
      <RoleModal
        organizationId={organizationId}
        mode="create"
        visible={showCreateRole.isOn}
        onCancel={showCreateRole.toggleOff}
        onSubmit={handleSubmit}
        role={undefined}
        forceFeature={forceFeature}
      />
    </>
  );
};
