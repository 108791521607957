import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { useToggle } from "@dewo/app/util/hooks";
import { Form, Input, Row, Space, Switch, Typography } from "antd";
import React, { FC, useCallback } from "react";

interface Props {
  value?: string | null;
  onChange?(value?: string | null): void;
}

const ApplicationLinkFieldComponent: FC<Props> = ({ value, onChange }) => {
  const expanded = useToggle(!!value);
  const handleChange = useCallback(
    (checked: boolean) => {
      expanded.setToggle(checked);
      if (!checked) onChange?.(null);
    },
    [expanded, onChange]
  );
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row align="middle" style={{ columnGap: 8 }}>
        <Switch checked={expanded.isOn} size="small" onChange={handleChange} />
        <QuestionmarkTooltip
          marginLeft={8}
          title="If you already manage applications somewhere else (e.g. Airtable or Google Forms), link Dework applicants there directly."
        >
          <Typography.Text>{"External application form"}</Typography.Text>
        </QuestionmarkTooltip>
      </Row>
      <HeadlessCollapse expanded={expanded.isOn}>
        <Input
          key={String(expanded.isOn)}
          value={value ?? ""}
          placeholder="https://airtable.com/apply-to-me-here"
          onChange={(e) => onChange?.(e.target.value)}
        />
      </HeadlessCollapse>
    </Space>
  );
};

export const ApplicationLinkField: FC<Props> = (props) => (
  <Form.Item
    name="applicationLink"
    style={{ marginBottom: 0 }}
    children={<ApplicationLinkFieldComponent {...props} />}
    rules={[
      {
        type: "url",
        message: "Please enter a valid URL",
      },
    ]}
  />
);
