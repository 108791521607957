import gql from "graphql-tag";
import { skill } from "./skill";
import { taskReward } from "./task";

export const rule = gql`
  fragment Rule on Rule {
    id
    permission
    inverted
    taskId
    workspaceId
    fundingSessionId
  }
`;

export const role = gql`
  fragment Role on Role {
    id
    name
    color
    icon
    source
    fallback
    userId
    description
    applicationLink
    featured
    sortKey
    deletedAt
  }
`;

export const threepid = gql`
  fragment Threepid on Threepid {
    source
    threepid
  }
`;

export const roleWithRules = gql`
  fragment RoleWithRules on Role {
    ...Role
    rules {
      ...Rule
    }
  }
  ${role}
  ${rule}
`;

export const roleCompensation = gql`
  fragment RoleCompensation on RoleCompensation {
    id
    roleId
    frequency
    reward {
      ...TaskReward
    }
  }
  ${taskReward}
`;

export const roleDetails = gql`
  fragment RoleDetails on Role {
    ...RoleWithRules
    skills {
      ...Skill
    }
    compensation {
      ...RoleCompensation
    }
    commitment
    userCount
  }
  ${roleWithRules}
  ${skill}
  ${roleCompensation}
`;

export const roleWithUsers = gql`
  fragment RoleWithUsers on Role {
    ...RoleDetails
    users {
      id
    }
  }
  ${roleDetails}
`;
