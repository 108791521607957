import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Select, SelectProps } from "antd";
import _ from "lodash";
import { DefaultOptionType } from "antd/lib/select";

interface Props extends SelectProps {
  debounce?: number;
}
type MaybeArray<T> = T | T[];

export const DebouncedSelect: FC<Props> = ({
  debounce = 300,
  onChange,
  value: debouncedValue,
  ...props
}) => {
  const debouncedUpdate = useMemo(
    () => (onChange ? _.debounce(onChange, debounce) : () => {}),
    [debounce, onChange]
  );
  const [value, setValue] = useState(debouncedValue);
  useEffect(() => setValue(debouncedValue), [debouncedValue]);

  const handleChange = useCallback(
    (
      value: React.ChangeEvent<HTMLInputElement>,
      option: MaybeArray<DefaultOptionType>
    ) => {
      setValue(option);
      debouncedUpdate(value, option);
    },
    [debouncedUpdate]
  );

  return <Select {...props} value={value} onChange={handleChange} />;
};
