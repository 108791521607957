import gql from "graphql-tag";
import { task } from "../fragments";
import { user } from "../fragments/user";

export const getBookmarks = gql`
  query GetBookmarksQuery($id: UUID!) {
    user: getUser(id: $id) {
      id
      bookmarks {
        task {
          ...Task
        }
        userId
        taskId
      }
    }
  }

  ${task}
`;

export const getUsersByScore = gql`
  query GetUsersByScoreQuery($limit: Int, $offset: Int) {
    getUsersByScore(limit: $limit, offset: $offset) {
      users: items {
        ...User
        bio
        reputationScore
      }
      total
      hasMore
    }
  }
  ${user}
`;
