import { DropdownSelect } from "@dewo/app/components/form/DropdownSelect";
import { MedalIcon } from "@dewo/app/components/icons/Medal";
import { SkillTag } from "@dewo/app/components/tags/SkillTag";
import { useSkills } from "@dewo/app/containers/skills/hooks";
import { Skill } from "@dewo/app/graphql/types";
import _ from "lodash";
import React, { FC, useMemo } from "react";
import styles from "@dewo/app/components/tags/EditableTag.module.less";
import { EditableTag } from "@dewo/app/components/tags/EditableTag";
import { PlusOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { SkillAvatar } from "@dewo/app/components/avatars/SkillAvatar";

interface Props {
  value?: string[];
  onChange?: (skillIds: string[]) => void;
  disabled?: boolean;
}

const empty = [] as [];
export const TaskSkillInput: FC<Props> = ({
  value = empty,
  onChange,
  disabled,
}) => {
  const allSkills = useSkills();
  const skillById = useMemo(() => _.keyBy(allSkills, (s) => s.id), [allSkills]);

  const skills = useMemo(
    () => value.map((id) => skillById[id]).filter((a): a is Skill => !!a),
    [skillById, value]
  );

  const skillTags = useMemo(
    () =>
      skills.map((skill) => (
        <SkillTag
          className={!disabled ? styles.EditableTag : ""}
          style={{ fontWeight: 500, padding: "2px 8px" }}
          closable={!disabled}
          onClose={() => onChange?.(value.filter((id) => id !== skill.id))}
          skill={skill}
          key={skill.id}
        />
      )),
    [disabled, onChange, skills, value]
  );

  if (skills.length === 0 && disabled) return null;

  return (
    <DropdownSelect
      options={allSkills?.map((s) => ({
        value: s.id,
        label: (
          <Row align="middle">
            <SkillAvatar size={16} style={{ marginRight: 8 }} skill={s} />
            {s.name}
          </Row>
        ),
      }))}
      menuStyle={{ minWidth: 150 }}
      mode="multiple"
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      <Row>
        {skillTags}
        {skillTags.length ? (
          <EditableTag
            title="Add Skills"
            hidden={disabled}
            style={{ display: "inline-block" }}
          >
            <PlusOutlined />
          </EditableTag>
        ) : (
          <EditableTag icon={<MedalIcon className="text-secondary" />}>
            Add Skills
          </EditableTag>
        )}
      </Row>
    </DropdownSelect>
  );
};
