import { Button, Input, Typography } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";
import { UserPromptStepComponentProps } from "../../types";
import {
  useNewlyCreatedOrganization,
  useNewlyCreatedWorkspaceAndSection,
} from "./hooks";
import {
  useCopyToClipboardAndShowToast,
  useRunning,
} from "@dewo/app/util/hooks";
import { useRouter } from "next/router";
import { useWorkspaceDetails } from "@dewo/app/containers/workspace/hooks";
import { useCreateInvite } from "@dewo/app/containers/invite/hooks";
import { RulePermission } from "@dewo/app/graphql/types";

export const OnboardingOrganizationInvite: FC<UserPromptStepComponentProps> = ({
  active,
  onNext,
}) => {
  const router = useRouter();
  const organization = useNewlyCreatedOrganization();
  const { workspace } = useNewlyCreatedWorkspaceAndSection();
  const taskView = useWorkspaceDetails(workspace?.id).workspace?.taskViews[0];
  const redirect =
    taskView?.permalink ?? workspace?.permalink ?? organization?.permalink;

  const [handleNext, loading] = useRunning(
    useCallback(async () => {
      await onNext();
      if (!!redirect) {
        await router.push(redirect);
      }
    }, [onNext, router, redirect])
  );

  const copyToClipboardAndShowToast =
    useCopyToClipboardAndShowToast("Invite link copied");
  const createInvite = useCreateInvite();
  const [contributorLink, setContributorLink] = useState<string>();
  const [adminLink, setAdminLink] = useState<string>();

  const newlyCreatedOrganization = useNewlyCreatedOrganization();

  useEffect(() => {
    if (!newlyCreatedOrganization?.id || !active) return;

    createInvite({
      organizationId: newlyCreatedOrganization.id,
      permission: RulePermission.VIEW_PROJECTS,
    }).then(setContributorLink);
    createInvite({
      organizationId: newlyCreatedOrganization.id,
      permission: RulePermission.MANAGE_ORGANIZATION,
    }).then(setAdminLink);
  }, [createInvite, newlyCreatedOrganization?.id, active]);

  return (
    <div
      className="mx-auto w-full"
      style={{
        gap: 8,
        maxWidth: 368,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Let's add some people
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        Invite your team to help you test and explore!
      </Typography.Paragraph>
      <div style={{ flex: 1 }} />

      {[
        {
          link: adminLink,
          buttonText: "Invite Admins",
          analyticsId: "Onboarding Organization Invite: copy admin link",
        },
        {
          link: contributorLink,
          buttonText: "Invite Contributors",
          analyticsId: "Onboarding Organization Invite: copy contributor link",
        },
      ].map((row, index) => (
        <Input.Group
          key={index}
          compact
          style={{ display: "flex" }}
          size="small"
        >
          <div
            className="ant-input"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography.Paragraph
              style={{ margin: 0 }}
              ellipsis={{ rows: 1 }}
              className="ant-typography-caption"
            >
              {row.link}
            </Typography.Paragraph>
          </div>
          <Button
            type="primary"
            disabled={!contributorLink}
            loading={!contributorLink}
            name={row.analyticsId}
            style={{ width: 152, flexShrink: 0 }}
            onClick={() => copyToClipboardAndShowToast(contributorLink!)}
          >
            {row.buttonText}
          </Button>
        </Input.Group>
      ))}
      <div style={{ flex: 1 }} />

      <Button
        block
        type="text"
        size="large"
        className="text-secondary"
        name="Onboarding Organization Invite: close"
        loading={loading}
        onClick={handleNext}
      >
        Close
      </Button>
    </div>
  );
};
