import React from "react";
import Icon from "@ant-design/icons";

const LockSvg = () => (
  <svg viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1818 14H18.4432V8.27273C18.4432 6.46506 16.9781 5 15.1705 5H8.82955C7.02188 5 5.55682 6.46506 5.55682 8.27273V14H3.81818C3.36562 14 3 14.3656 3 14.8182V24.6364C3 25.0889 3.36562 25.4545 3.81818 25.4545H20.1818C20.6344 25.4545 21 25.0889 21 24.6364V14.8182C21 14.3656 20.6344 14 20.1818 14ZM12.7159 20.0597V21.4148C12.7159 21.5273 12.6239 21.6193 12.5114 21.6193H11.4886C11.3761 21.6193 11.2841 21.5273 11.2841 21.4148V20.0597C11.073 19.9081 10.9155 19.6935 10.8342 19.4468C10.7528 19.2 10.7519 18.9338 10.8315 18.6865C10.9111 18.4392 11.0671 18.2235 11.2771 18.0704C11.4871 17.9174 11.7402 17.835 12 17.835C12.2598 17.835 12.5129 17.9174 12.7229 18.0704C12.9329 18.2235 13.0889 18.4392 13.1685 18.6865C13.2481 18.9338 13.2472 19.2 13.1658 19.4468C13.0845 19.6935 12.927 19.9081 12.7159 20.0597V20.0597ZM16.6023 14H7.39773V8.27273C7.39773 7.48267 8.03949 6.84091 8.82955 6.84091H15.1705C15.9605 6.84091 16.6023 7.48267 16.6023 8.27273V14Z"
      fill="white"
      fillOpacity="0.46"
    />
  </svg>
);

export const LockIcon = (props: any) => <Icon component={LockSvg} {...props} />;
