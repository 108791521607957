import { TaskDetails, GraphNodeDetails } from "@dewo/app/graphql/types";
import { Button, Menu, message, Typography } from "antd";
import { useRouter } from "next/router";
import React, { FC, useCallback } from "react";
import { useUpdateTask } from "../hooks";
import { useCloseTaskDetails } from "@dewo/app/util/navigation";
import Link from "next/link";
import { RouterContext } from "next/dist/shared/lib/router-context";
import { useOrganizationWorkspaces } from "../../organization/hooks";
import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";
import { NestedGraphNodeMenuItem } from "../../graph/NestedGraphNodeMenuItem";

interface Props {
  task: TaskDetails;
}

export const MoveTaskSubmenu: FC<Props> = ({ task }) => {
  const organization = useOrganizationWorkspaces(
    task.workspace.organization.id
  );

  const router = useRouter();
  const closeTaskDetails = useCloseTaskDetails();
  const updateTask = useUpdateTask();
  const handleMoveTask = useCallback(
    async (node: GraphNodeDetails) => {
      const workspace = node.workspace;
      if (!workspace) return;

      await updateTask({ id: task.id, workspaceId: workspace.id, tagIds: [] });
      await Promise.all(
        task.subtasks.map(({ id }) =>
          updateTask({ id, workspaceId: workspace.id })
        )
      );
      await closeTaskDetails();
      message.success({
        content: (
          <RouterContext.Provider value={router}>
            <Typography.Text style={{ marginRight: 16 }}>
              Task moved to {workspace.name}
            </Typography.Text>
            <Link href={`${workspace.permalink}?taskId=${task.id}`}>
              <Button size="small">View</Button>
            </Link>
          </RouterContext.Provider>
        ),
      });
    },
    [updateTask, closeTaskDetails, router, task.id, task.subtasks]
  );

  if (!organization?.node.workspaceChildren.length) return null;
  return (
    <Menu.SubMenu
      title="Move Task"
      icon={!organization ? <LoadingOutlined /> : <ExportOutlined />}
    >
      {organization.node.workspaceChildren.map((edge) => (
        <NestedGraphNodeMenuItem
          key={edge.node.id}
          node={edge.node}
          onMenuItemClick={handleMoveTask}
        />
      ))}
    </Menu.SubMenu>
  );
};
