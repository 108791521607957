import {
  siteDescription,
  siteFullTitle,
  siteTitle,
} from "@dewo/app/util/constants";
import { DefaultSeo } from "next-seo";
import React, { FC } from "react";
import { createSEOImageUrl } from "./util";

export const FallbackSeo: FC = () => (
  <DefaultSeo
    title={siteFullTitle}
    description={siteDescription}
    openGraph={{
      type: "website",
      title: siteFullTitle,
      description: siteDescription,
      site_name: "Dework",
      images: [
        {
          url: createSEOImageUrl({
            title: siteTitle,
            subtitle: siteDescription,
          }),
        },
      ],
    }}
    twitter={{ cardType: "summary_large_image", site: "@deworkxyz" }}
  />
);
