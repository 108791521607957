import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GraphNodeType, MeQuery, UserDetails } from "../graphql/types";
import { clearAuthToken, setAuthToken } from "../util/authToken";
import { useApolloClient, useQuery } from "@apollo/client";
import * as Queries from "@dewo/app/graphql/queries";
import * as Sentry from "@sentry/nextjs";
import { useAmplitude } from "../util/analytics/AmplitudeContext";
import { useRouter } from "next/router";
import { hotjar } from "react-hotjar";
import { isLocalStorageSupported } from "../util/LocalStorage";
import { isSSR } from "@dewo/app/util/isSSR";

interface AuthContextValue {
  user: UserDetails | undefined;
  authenticated: boolean;
  refetch(): Promise<any>;
  logout(redirect?: string): void;
  onAuthenticated(authToken: string): void;
}

const AuthContext = createContext<AuthContextValue>({
  user: undefined,
  authenticated: false,
  refetch: Promise.resolve,
  logout: () => {},
  onAuthenticated: () => {},
});

export const AuthProvider: FC<{ initialAuthenticated: boolean }> = ({
  children,
  initialAuthenticated,
}) => {
  const [authenticated, setAuthenticated] = useState(initialAuthenticated);

  const { data, refetch } = useQuery<MeQuery>(Queries.me, {
    skip: !authenticated,
  });
  const user = data?.me;

  const amplitude = useAmplitude();
  useEffect(() => {
    if (!user) return;

    const properties = {
      username: user.username,
      organizations: user.node.children
        .filter((edge) => edge.node.type === GraphNodeType.ORGANIZATION)
        .map((edge) => edge.node.id),
      threepids: user.threepids.map((t) => t.source),
      onboarding: user.prompts.find((p) => p.type.startsWith("Onboarding"))
        ?.type,
      userAgent: navigator.userAgent,
      localStorage: isLocalStorageSupported(),
    };
    amplitude.identify(user.id, properties);

    Sentry.setUser({ id: user.id });
    try {
      hotjar.identify(user.id, properties);
    } catch {}
  }, [user, amplitude]);

  const router = useRouter();
  const apolloClient = useApolloClient();
  const logout = useCallback(
    (redirect: string = "/") => {
      clearAuthToken(undefined);
      setAuthenticated(false);
      apolloClient.reFetchObservableQueries();
      router.push(redirect);
    },
    [router, apolloClient]
  );

  if (!isSSR) {
    // @ts-ignore
    window.logout = logout;
  }

  const onAuthenticated = useCallback((authToken: string) => {
    setAuthToken(undefined, authToken);
    setAuthenticated(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, refetch, authenticated, logout, onAuthenticated }}
    >
      {useMemo(() => children, [children])}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): AuthContextValue {
  return useContext(AuthContext);
}
