import gql from "graphql-tag";
import * as Fragments from "../fragments";

export const authFromSession = gql`
  mutation AuthFromSessionMutation($sessionId: UUID!) {
    authFromSession(sessionId: $sessionId) {
      authToken
      redirect
      user {
        ...UserDetails
      }
    }
  }

  ${Fragments.userDetails}
`;

export const authWithEthereum = gql`
  mutation AuthWithEthereumMutation($input: EthereumAuthInput!) {
    auth: authWithEthereum(input: $input) {
      authToken
      user {
        ...UserDetails
      }
    }
  }

  ${Fragments.userDetails}
`;

export const authWithSolana = gql`
  mutation AuthWithSolanaMutation($input: SolanaAuthInput!) {
    auth: authWithSolana(input: $input) {
      authToken
      user {
        ...UserDetails
      }
    }
  }

  ${Fragments.userDetails}
`;

export const authWithUsername = gql`
  mutation AuthWithUsernameMutation($username: String!) {
    auth: authWithUsername(username: $username) {
      authToken
      user {
        ...UserDetails
      }
    }
  }

  ${Fragments.userDetails}
`;
