import { AppstoreAddOutlined } from "@ant-design/icons";
import * as Colors from "@ant-design/colors";
import { eatClick } from "@dewo/app/util/eatClick";
import { Menu, Popconfirm } from "antd";
import { MenuItemProps } from "rc-menu";
import React, { FC, useCallback } from "react";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { TaskDetails } from "@dewo/app/graphql/types";
import { useRouter } from "next/router";
import { useSubtasks } from "../views/hooks";
import { useUpdateTask } from "../hooks";
import { useCreateProjectFromTask } from "./hooks";

interface Props extends MenuItemProps {
  task: TaskDetails;
}

export const CreateProjectMenuItem: FC<Props> = ({
  task,
  ...menuItemProps
}) => {
  const createProjectFromTask = useCreateProjectFromTask(task);
  const updateTask = useUpdateTask();

  const router = useRouter();

  const subtasks = useSubtasks(task.id).tasks;
  const handleConfirm = useCallback(async () => {
    const project = await createProjectFromTask();

    for (const subtask of subtasks ?? []) {
      await updateTask(
        { id: subtask.id, workspaceId: project.id, parentTaskId: null },
        subtask
      );
    }

    await updateTask({
      id: task.id,
      description: [
        `This task has been converted into a project: <${project.permalink}>`,
        task.description,
      ]
        .filter((p) => !!p)
        .join("\n\n"),
    });

    router.push(project.permalink);
  }, [createProjectFromTask, router, subtasks, task, updateTask]);
  return (
    <Popconfirm
      icon={<AppstoreAddOutlined style={{ color: Colors.grey.primary }} />}
      title="Create project from task?"
      okText="Create"
      onConfirm={handleConfirm}
    >
      <Menu.Item
        {...menuItemProps}
        icon={<AppstoreAddOutlined />}
        children={
          <QuestionmarkTooltip
            title="When creating a project from a task, a new project will be created in the current space, subtasks will be moved to the new project, and assignees/reviewers will be able to manage the new project."
            marginLeft={8}
          >
            Create Project
          </QuestionmarkTooltip>
        }
        onClick={(e) => eatClick(e.domEvent)}
      />
    </Popconfirm>
  );
};
