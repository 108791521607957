import gql from "graphql-tag";
import { fundingSession } from "./funding";
import { nodeChildrenDetails, nodeDetails } from "./graph";
import { organization } from "./organization";
import { workspace, workspaceSection } from "./workspace";

export const organizationWorkspaces = gql`
  fragment OrganizationWorkspaces on Organization {
    ...Organization
    node {
      ...GraphNodeDetails
      workspaceChildren: children(types: [WORKSPACE]) {
        sortKey
        node: child {
          ...GraphNodeDetails
          ...GraphNodeChildrenDetails
        }
      }
    }
    workspaceSections {
      ...WorkspaceSection
    }

    # TODO(fant): remove! Needed to render menu
    options {
      roadmap
      roles
      hideLeaderboards
    }
    fundingSessions {
      ...FundingSession
    }
  }

  ${organization}
  ${workspace}
  ${workspaceSection}
  ${fundingSession}
  ${nodeDetails}
  ${nodeChildrenDetails}
`;
