import React, { FC, useCallback } from "react";
import { Task, TaskStatus } from "@dewo/app/graphql/types";
import { Button, ButtonProps, Form, message, Modal, Popconfirm } from "antd";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { useCreateTask, useUpdateTask } from "../../hooks";
import { CheckCircleOutlined, DollarOutlined } from "@ant-design/icons";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { TaskRewardFormItemValue } from "../../reward/form/types";
import { toTaskRewardInput } from "../../reward/form/util";
import { useWorkspace } from "@dewo/app/containers/workspace/hooks";
import { useOrganizationTokens } from "@dewo/app/containers/organization/hooks";
import { useForm } from "antd/lib/form/Form";
import { TaskRewardFormItem } from "../../reward/form/TaskRewardFormItem";

interface Props extends ButtonProps {
  task: Task;
}

interface FormValues {
  reward: TaskRewardFormItemValue;
}

export const AcceptSuggestionButton: FC<Props> = ({ task, ...buttonProps }) => {
  const { user } = useAuthContext();
  const updateTask = useUpdateTask();
  const createTask = useCreateTask();
  const { logEvent } = useAmplitude();

  const { workspace } = useWorkspace(task.workspaceId);
  const tokens = useOrganizationTokens(workspace?.organizationId);

  const [acceptTask, acceptingTask] = useRunning(
    useCallback(
      async (reward?: TaskRewardFormItemValue) => {
        if (!!reward) {
          const token = tokens.find((t) => t.id === reward.tokenId);
          await createTask({
            status: TaskStatus.DONE,
            workspaceId: task.workspaceId,
            parentTaskId: task.id,
            name: "Tip for creating suggestion",
            assigneeIds: [task.creator!.id],
            rewards:
              !!reward && !!token
                ? [toTaskRewardInput(reward, token)]
                : undefined,
          });
        }

        const ownerIds = task.owners.map((o) => o.id);
        if (!!user && !ownerIds.includes(user.id)) ownerIds.push(user.id);
        await updateTask(
          { id: task.id, status: TaskStatus.TODO, ownerIds },
          task
        );
        message.success("Suggestion accepted!");
        logEvent("Accept suggestion", { taskId: task.id, reward });
      },
      [updateTask, task, user, tokens, createTask, logEvent]
    )
  );

  const tippingModal = useToggle();
  const [form] = useForm<FormValues>();

  if (!task.creator) {
    return (
      <Button
        {...buttonProps}
        loading={acceptingTask}
        icon={<CheckCircleOutlined />}
        name="Accept suggestion (no creator)"
        onClick={() => acceptTask()}
      >
        Accept
      </Button>
    );
  }

  return (
    <>
      <Popconfirm
        title={`Would you like to add a tip? This will create a bounty to reward ${task.creator.username} for this suggestion`}
        okText="Add tip"
        cancelText="Continue without"
        icon={<DollarOutlined />}
        onConfirm={tippingModal.toggleOn}
        onCancel={() => acceptTask()}
      >
        <Button
          {...buttonProps}
          loading={acceptingTask}
          icon={<CheckCircleOutlined />}
          name="Accept suggestion"
        >
          Accept
        </Button>
      </Popconfirm>
      <Modal
        title="Add tip for suggestion"
        visible={tippingModal.isOn}
        closable={false}
        okText="Add tip"
        okButtonProps={{ loading: acceptingTask }}
        onOk={() =>
          form.validateFields().then((values) => acceptTask(values.reward))
        }
        cancelText="Continue without"
        onCancel={() => acceptTask()}
      >
        <Form form={form}>
          <Form.Item name="reward">
            {!!workspace && (
              <TaskRewardFormItem
                name="reward"
                showAddToken={false}
                organizationId={workspace.organizationId}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
