import { RoleWithRules, Task, TaskDetails } from "@dewo/app/graphql/types";
import moment from "moment";
import { TaskFormValues } from "./types";

export const toTaskFormValues = (
  task: TaskDetails,
  claimRoles: RoleWithRules[],
  applyRoles: RoleWithRules[],
  subtasks: Task[]
): TaskFormValues => ({
  workspaceId: task.workspaceId!,
  name: task.name ?? "",
  description: task.description ?? undefined,
  storyPoints: task.storyPoints ?? undefined,
  tagIds: task.tags.map((t) => t.id) ?? [],
  skillIds: task.skills.map((s) => s.id) ?? [],
  assigneeIds: task.assignees.map((a) => a.id) ?? [],
  ownerIds: task.owners.map((o) => o.id) ?? [],
  status: task.status!,
  template: task.template,
  priority: task.priority ?? null,
  dueDate: !!task.dueDate ? moment(task.dueDate) : undefined,
  rewards: task.rewards.map((reward) => ({
    amount: reward.amount,
    tokenId: reward.token.id,
    peggedToUsd: reward.peggedToUsd,
    type: reward.type,
    count: reward.count,
  })),
  openToBids: task.openToBids,
  gating: task.gating,
  claimRoleIds: claimRoles.map((r) => r.id),
  applyRoleIds: applyRoles.map((r) => r.id),
  subtasks: subtasks.map((s) => ({
    key: s.id,
    name: s.name,
    status: s.status,
    description: "",
    assigneeIds: [],
    dueDate: null,
    rewards: s.rewards.map((r) => ({
      amount: r.amount,
      tokenId: r.token.id,
      peggedToUsd: r.peggedToUsd,
    })),
  })),
  applicationLink: task.applicationLink ?? undefined,
  applicationTemplate: task.applicationTemplate ?? undefined,
  submissionTemplate: task.submissionTemplate ?? undefined,
  maxWinners: task.maxWinners ?? undefined,
});
