import React from "react";
import Icon from "@ant-design/icons";

const NotionSvg = () => (
  <svg width="1rem" height="1rem" viewBox="0 0 500 500">
    <path
      d="M180.993 10.3604C102.602 16.1578 35.9601 21.4894 32.8268 22.1137C24.0417 23.6795 16.0499 29.6355 12.2919 37.4744L9 44.3718L9.31729 208.76L9.78332 373.149L14.8005 383.336C17.6264 388.975 36.7534 415.306 57.6056 442.41C98.8439 495.846 101.819 498.828 117.495 500.394C122.046 500.86 156.225 499.294 193.229 497.104C230.392 494.755 289.964 491.148 325.719 489.117C478.427 479.851 470.584 480.634 479.359 473.112C490.336 464.024 489.552 478.909 490.018 279.112C490.336 109.709 490.177 98.5899 487.669 93.4168C484.694 87.6194 479.359 83.5464 409.743 34.65C363.18 2.05573 359.888 0.331377 339.026 0.0142542C330.409 -0.292958 259.226 4.40442 180.993 10.3604V10.3604ZM357.221 31.8256C363.805 34.7987 411.944 68.3344 418.994 74.766C420.878 76.6489 421.503 78.2147 420.561 79.1562C418.369 81.0391 120.46 98.7484 111.843 97.4899C107.926 96.8655 102.126 94.5168 98.834 92.3168C85.1903 83.0707 51.3291 55.3323 51.3291 53.3008C51.3291 47.9692 50.2284 47.9692 183.036 38.4158C208.439 36.6915 250.144 33.5599 275.547 31.5184C330.568 27.1282 347.038 27.2868 357.221 31.8256V31.8256ZM451.923 117.548C454.115 119.738 455.999 123.97 456.623 128.043C457.089 131.808 457.407 202.012 457.089 283.809C456.623 424.215 456.465 432.996 453.797 437.069C452.231 439.576 449.097 442.242 446.738 443.025C440.164 445.691 127.995 463.4 121.57 461.517C118.596 460.734 114.51 458.227 112.319 456.037L108.551 451.964L108.085 300.587C107.768 194.341 108.085 147.328 109.344 143.096C110.286 139.964 112.636 136.199 114.679 134.95C117.505 133.067 136.007 131.501 199.664 127.745C244.353 125.238 313.494 121.006 353.165 118.658C447.531 112.85 447.223 112.85 451.923 117.548V117.548Z"
      fill="currentColor"
    />
    <path
      d="M368.039 169.417C351.103 170.517 336.051 172.083 334.326 173.024C329.15 175.69 326.017 180.08 325.392 185.095C324.926 190.268 326.493 190.892 343.419 192.934L350.786 193.875V260.788C350.786 300.587 350.161 326.918 349.378 326.135C348.594 325.194 326.334 291.351 299.83 250.918C273.336 210.336 251.383 176.949 251.066 176.642C250.749 176.325 233.506 177.266 212.812 178.683C187.409 180.407 173.458 181.973 170.632 183.539C166.081 185.888 161.063 194.192 161.063 199.683C161.063 202.973 167.023 204.697 179.566 204.697H186.15V397.131L175.49 400.421C167.181 402.929 164.355 404.494 162.947 407.626C160.439 412.958 160.597 417.814 163.106 417.814C164.048 417.814 181.767 416.714 202.143 415.465C242.44 413.116 246.981 412.016 251.215 403.712C252.474 401.204 253.565 398.538 253.565 397.756C253.565 397.131 248.082 395.248 241.488 393.683C234.745 392.117 228.003 390.551 226.595 390.234C223.927 389.451 223.769 384.595 223.769 317.206V244.962L271.432 319.714C321.446 398.063 327.722 407.002 335.407 410.916C344.975 415.931 369.278 412.482 381.504 404.494L385.272 402.146L385.589 295.275L386.055 188.246L394.364 186.681C404.24 184.798 408.791 180.259 408.791 172.42C408.791 167.405 408.474 167.088 403.774 167.247C400.968 167.376 384.816 168.317 368.039 169.417V169.417Z"
      fill="currentColor"
    />
  </svg>
);

export const NotionIcon = (props: any) => (
  <Icon component={NotionSvg} {...props} />
);
