import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useTask } from "@dewo/app/containers/task/hooks";
import { Card, Skeleton, Typography } from "antd";
import Link from "next/link";
import React, { FC } from "react";
import { EmbedDescriptor } from "rich-markdown-editor/dist/types";
import { TaskStatusIcon } from "../../icons/task/TaskStatus";
import { EmbedComponentProps } from "./types";
import styles from "./DeworkTask.module.less";
import { RouterContext } from "next/dist/shared/lib/router-context";
import { NextRouter } from "next/router";

const regex =
  /https:\/\/app.dework.xyz(.*)?taskId=([0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i;

export const DeworkTaskComponent: FC<EmbedComponentProps> = ({ attrs }) => {
  const taskId = attrs.matches?.[2]!;
  const task = useTask(taskId);
  if (!task) return <Skeleton.Button active />;
  return (
    <Link href={attrs.href}>
      <a>
        <Card size="small" className={styles.card}>
          <TaskStatusIcon status={task.status} />
          <Typography.Text style={{ flex: 1 }}>{task.name}</Typography.Text>
        </Card>
      </a>
    </Link>
  );
};

export class DeworkTaskEmbed implements EmbedDescriptor {
  name = "Dework Task";
  title = "Dework Task";
  keywords = "dework";
  defaultHidden = true;
  matcher = (url: string) => url.match(regex) ?? false;
  component = (props: EmbedComponentProps) => (
    <ApolloProvider client={this.apolloClient}>
      <RouterContext.Provider value={this.router}>
        <DeworkTaskComponent {...props} />
      </RouterContext.Provider>
    </ApolloProvider>
  );

  constructor(
    private readonly apolloClient: ApolloClient<unknown>,
    private readonly router: NextRouter
  ) {}
}
