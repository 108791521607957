import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { Button, ButtonProps, Row, Tooltip, Typography } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { useCreateTaskReaction, useDeleteTaskReaction } from "../hooks";
import { useReactionModal } from "@dewo/app/src/util/reactions";
import { Task } from "@dewo/app/graphql/types";
import _ from "lodash";
import { useRunning } from "@dewo/app/util/hooks";
import { CaretUpOutlined } from "@ant-design/icons";
import { LoginButton } from "../../auth/buttons/LoginButton";

export const UPVOTE_REACTION = ":arrow_up_small:";

interface Props extends ButtonProps {
  task: Task;
}

export const UpvoteButton: FC<Props> = ({ task, ...buttonProps }) => {
  const { user } = useAuthContext();
  const canReact = usePermission("create", {
    __typename: "TaskReaction",
    userId: user?.id!,
    // @ts-ignore
    ...{ task },
  });
  const createReaction = useCreateTaskReaction();
  const deleteReaction = useDeleteTaskReaction();

  const upvotes = useMemo(
    () =>
      _.sumBy(task.reactions, (r) => Number(r.reaction === UPVOTE_REACTION)),
    [task]
  );

  const selected = useMemo(
    () =>
      task.reactions.some(
        (r) => r.reaction === UPVOTE_REACTION && r.userId === user?.id
      ),
    [task, user]
  );

  const [handleUpvote, upvoting] = useRunning(
    useCallback(
      async (event) => {
        stopPropagation(event);
        if (!canReact) return;
        if (selected) {
          await deleteReaction({ taskId: task.id, reaction: UPVOTE_REACTION });
        } else {
          await createReaction({ taskId: task.id, reaction: UPVOTE_REACTION });
        }
      },
      [createReaction, deleteReaction, task, selected, canReact]
    )
  );

  const [showReactions, ReactionsModal] = useReactionModal(
    task.id,
    UPVOTE_REACTION
  );

  const button = useMemo(() => {
    if (!user) {
      return (
        <LoginButton
          {...buttonProps}
          icon={<CaretUpOutlined />}
          onAuthedWithWallet={() =>
            createReaction({ taskId: task.id, reaction: UPVOTE_REACTION })
          }
        >
          Upvote ({upvotes})
        </LoginButton>
      );
    }

    return (
      <Button
        {...buttonProps}
        type={selected ? "primary" : "default"}
        icon={<CaretUpOutlined />}
        loading={upvoting}
        onClick={handleUpvote}
      >
        Upvote ({upvotes})
      </Button>
    );
  }, [
    buttonProps,
    createReaction,
    task,
    handleUpvote,
    selected,
    upvotes,
    upvoting,
    user,
  ]);

  return (
    <>
      <Tooltip
        title={
          <>
            <Row justify="center">
              <Typography.Paragraph
                style={{ marginBottom: 4, textAlign: "center" }}
              >
                {selected ? "Already upvoted" : "Upvote suggestion"}
              </Typography.Paragraph>
            </Row>
            <Row justify="center">
              <Button size="small" type="ghost" onClick={showReactions}>
                {upvotes === 1 ? "1 upvote" : `${upvotes} upvotes`}
              </Button>
            </Row>
          </>
        }
      >
        {button}
      </Tooltip>
      <ReactionsModal />
    </>
  );
};
