import { OnboardingAlert } from "@dewo/app/components/OnboardingAlert";
import { useWorkspacePaymentMethods } from "@dewo/app/containers/payment/hooks";
import { paymentMethodTypeToString } from "@dewo/app/containers/payment/util";
import { AddWorkspacePaymentMethodModal } from "@dewo/app/containers/payment/workspace/AddWorkspacePaymentMethodModal";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Row, Typography } from "antd";
import _ from "lodash";
import React, { FC } from "react";

interface Props {
  workspaceId: string;
}

export const SetupPaymentMethodAlert: FC<Props> = ({ workspaceId }) => {
  const pms = useWorkspacePaymentMethods(workspaceId);
  const modal = useToggle();

  return (
    <>
      {!!pms?.length ? (
        <OnboardingAlert
          name="Task Form: connected payment methods"
          message={
            <>
              {_.uniq(pms.map((pm) => paymentMethodTypeToString[pm.type])).join(
                ", "
              )}{" "}
              connected. <a onClick={modal.toggleOn}>Add more</a>
            </>
          }
        />
      ) : (
        <Row align="middle" style={{ columnGap: 12 }}>
          <Button size="small" type="primary" onClick={modal.toggleOn}>
            Setup payment
          </Button>
          <Typography.Text type="secondary" className="ant-typography-caption">
            (Can also be set up later)
          </Typography.Text>
        </Row>
      )}
      <AddWorkspacePaymentMethodModal
        workspaceId={workspaceId}
        visible={modal.isOn}
        onClose={modal.toggleOff}
      />
    </>
  );
};
