import React, { FC } from "react";
import { TaskDetails } from "@dewo/app/graphql/types";
import { Button, ButtonProps } from "antd";
import { NotionIcon } from "@dewo/app/components/icons/Notion";

interface Props extends ButtonProps {
  task: TaskDetails;
}

export const TaskNotionPageButton: FC<Props> = ({ task, ...btnProps }) => {
  if (!task.notionPage) return null;
  return (
    <Button
      target="_blank"
      href={task.notionPage.permalink}
      name="Open Notion page"
      icon={<NotionIcon />}
      {...btnProps}
    >
      Notion Page
    </Button>
  );
};
