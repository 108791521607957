import {
  TaskApplication,
  TaskApplicationStatus,
  TaskDetails,
  TaskGatingType,
  TaskStatus,
} from "@dewo/app/graphql/types";
import { Card, List, Typography, Modal } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { useUpdateTask, useUpdateTaskApplication } from "../hooks";
import { taskRewardToInput } from "../reward/form/util";

import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { Divider } from "@dewo/app/components/Divider";
import _ from "lodash";
import { TaskApplicationListItem } from "./TaskApplicationListItem";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as Colors from "@ant-design/colors";

interface Props {
  task: TaskDetails;
}

export const TaskApplicationList: FC<Props> = ({ task }) => {
  const updateApplication = useUpdateTaskApplication();
  const { logEvent } = useAmplitude();

  const { user } = useAuthContext();
  const canAssign = usePermission("update", task, "assigneeIds");

  const applications = useMemo(
    () =>
      _.sortBy(
        task.applications.filter(
          (a) =>
            a.status === TaskApplicationStatus.PENDING &&
            (canAssign || a.userId === user?.id)
        ),
        (a) => a.createdAt
      ),
    [canAssign, task.applications, user?.id]
  );

  const updateTask = useUpdateTask();
  const handleAssign = useCallback(
    async (application: TaskApplication) => {
      try {
        await updateTask(
          {
            id: task.id,
            assigneeIds: [
              ...task.assignees.map((a) => a.id),
              application.user.id,
            ],
            gating: TaskGatingType.ASSIGNEES,
            dueDate: application.endDate,
            rewards: application.reward && [
              taskRewardToInput(application.reward),
            ],
          },
          task
        );

        await updateApplication({
          id: application.id,
          userId: application.userId,
          taskId: task.id,
          status: TaskApplicationStatus.ACCEPTED,
        });

        const closeApplications = await new Promise((res) =>
          Modal.confirm({
            type: "success",
            icon: (
              <CheckCircleOutlined style={{ color: Colors.green.primary }} />
            ),
            closable: true,
            title: "Application approved",
            okText: "Close applications",
            okType: "primary",
            cancelText: "Approve more",
            onCancel: () => res(false),
            onOk: () => res(true),
            content: (
              <Typography.Paragraph style={{ margin: 0 }}>
                Would you like to approve more or close applications?
              </Typography.Paragraph>
            ),
          })
        );

        logEvent("Approve application", {
          closeApplications,
        });

        if (closeApplications) {
          await updateTask(
            {
              id: task.id,
              status: TaskStatus.IN_PROGRESS,
            },
            task
          );
          const indirectlyRejected = applications.filter(
            (a) =>
              a.status === TaskApplicationStatus.PENDING &&
              a.id !== application.id
          );
          for (const rejectApplication of indirectlyRejected) {
            await updateApplication({
              id: rejectApplication.id,
              userId: rejectApplication.userId,
              taskId: task.id,
              status: TaskApplicationStatus.REJECTED,
            });
          }
        }
      } finally {
      }
    },
    [updateTask, task, updateApplication, logEvent, applications]
  );

  if (task.status !== TaskStatus.TODO) return null;
  if (!applications.length) return null;
  return (
    <div>
      <Divider>{canAssign ? "Applications" : "Your Application"}</Divider>
      <Card
        size="small"
        className="dewo-card-highlighted"
        style={{ marginTop: 16, marginBottom: 24 }}
      >
        <List
          dataSource={applications}
          renderItem={(application) => (
            <TaskApplicationListItem
              application={application}
              onAssign={handleAssign}
              task={task}
            />
          )}
        />
      </Card>
    </div>
  );
};
