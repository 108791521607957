import { FormSection } from "@dewo/app/components/form/FormSection";
import { PaymentRow } from "@dewo/app/containers/payment/PaymentRow";
import { Task } from "@dewo/app/graphql/types";
import { Row, Space, Typography } from "antd";
import _ from "lodash";
import React, { FC, Fragment, useMemo } from "react";
import { formatTaskRewardAsUSD } from "../../hooks";
import { TaskRewardTag } from "../TaskRewardTag";

interface Props {
  task: Task;
}

export const PaidTaskRewardsSection: FC<Props> = ({ task }) => {
  const rewards = useMemo(
    () => task.rewards.filter((r) => !!r.payments.length),
    [task]
  );

  if (!rewards.length) return null;
  return (
    <FormSection label="Reward Payment">
      <Space direction="vertical" size={4}>
        {rewards.map((reward) => (
          <Fragment key={reward.id}>
            <Row>
              <TaskRewardTag reward={reward} />
              {!reward.payments.length &&
                !reward.peggedToUsd &&
                !!reward.token.usdPrice && (
                  <Typography.Text
                    type="secondary"
                    className="ant-typography-caption"
                  >
                    ({formatTaskRewardAsUSD(reward)})
                  </Typography.Text>
                )}
            </Row>
            {_.uniqBy(reward.payments, (p) => p.payment.id).map((p) => (
              <PaymentRow key={p.id} payment={p.payment} />
            ))}
          </Fragment>
        ))}
      </Space>
    </FormSection>
  );
};
