import { DeleteOutlined } from "@ant-design/icons";
import { EmojiPickerIcon } from "@dewo/app/components/emoji/EmojiPickerIcon";
import { SkillSelect } from "@dewo/app/components/form/SkillSelect";
import { UserSelect } from "@dewo/app/components/form/UserSelect";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import {
  CompensationFrequency,
  Role,
  RoleWithUsers,
  UpdateRoleCompensationInput,
  UpdateRoleInput,
} from "@dewo/app/graphql/types";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  Space,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { FC, useCallback, useEffect } from "react";
import {
  useAddRole,
  useCreateRole,
  useOrganizationRoleDetails,
  useRemoveRole,
  useRoleWithUsers,
  useUpdateRole,
} from "../../rbac/hooks";
import { TaskRewardsForm } from "../../task/reward/form/TaskRewardsForm";
import { useOrganization, useOrganizationUsers } from "../hooks";
import { CompensationList } from "./CompensationList";
import { commitmentOptions } from "./utils";

export interface RoleModalProps extends ModalProps {
  role: Role | undefined;
  organizationId: string;
  mode: "create" | "update";
  onSubmit: (role: Role) => void;
  forceFeature?: boolean;
}

interface RoleModalFormValues extends UpdateRoleInput {
  userIds: string[];
  name: string;
  color: string;
}

export const RoleModal: FC<RoleModalProps> = ({
  role,
  organizationId,
  mode,
  onSubmit,
  forceFeature,
  ...modalProps
}) => {
  const [form] = useForm<RoleModalFormValues>();
  const { refetch } = useOrganizationRoleDetails(organizationId);
  const roleWithUsers = useRoleWithUsers(role?.id);

  const updateRole = useUpdateRole();
  const createRole = useCreateRole();
  const addUserToRole = useAddRole();
  const removeUserFromRole = useRemoveRole();

  const updateRoleMembers = useCallback(
    async (role: RoleWithUsers, userIds: string[]) => {
      if (role && !role.source) {
        const currentUserIds = role?.users.map((u) => u.id);

        const removed = _.difference(currentUserIds, userIds);
        const added = _.difference(userIds, currentUserIds);
        await Promise.all([
          ...removed.map((uid) =>
            removeUserFromRole(role, uid, organizationId)
          ),
          ...added.map((uid) => addUserToRole(role, uid, organizationId)),
        ]);
      }
    },
    [addUserToRole, removeUserFromRole, organizationId]
  );

  const organization = useOrganization(organizationId);
  const [handleSubmit, submitting] = useRunning(
    useCallback(
      async ({ userIds, name, color, ...values }: RoleModalFormValues) => {
        if (!organizationId || !organization) return;
        let role: Role | undefined = roleWithUsers;

        if (mode === "create") {
          role = await createRole({
            name,
            color,
            userIds,
            parentId: organization.nodeId,
          });
        } else if (roleWithUsers) {
          await updateRoleMembers(roleWithUsers, userIds);
        }

        if (!role) return;

        const newRole = await updateRole({ ...values, id: role.id });
        await onSubmit(newRole);
        form.resetFields();
      },
      [
        createRole,
        form,
        mode,
        onSubmit,
        organizationId,
        roleWithUsers,
        organization,
        updateRole,
        updateRoleMembers,
      ]
    )
  );

  const handleDelete = useCallback(async () => {
    if (!role || role.source) return;

    Modal.confirm({
      content: "Are you sure you want to delete this role?",
      maskClosable: true,
      okText: "Yes",
      onOk: async () => {
        const deleteRole = { id: role.id, deletedAt: new Date().toISOString() };
        await updateRole(deleteRole);
        refetch();
        onSubmit({ ...role, ...deleteRole });
      },
    });
  }, [onSubmit, refetch, role, updateRole]);

  useEffect(() => {
    form.setFieldsValue({
      ...roleWithUsers,
      featured: forceFeature || roleWithUsers?.featured,
      skillIds: roleWithUsers?.skills.map((s) => s.id) || [],
      userIds: roleWithUsers?.users.map((u) => u.id) || [],
      compensation:
        roleWithUsers?.compensation.map(
          (comp): UpdateRoleCompensationInput => ({
            frequency: comp.frequency,
            reward: {
              amount: comp.reward.amount,
              tokenId: comp.reward.token.id,
              peggedToUsd: comp.reward.peggedToUsd,
              type: comp.reward.type,
              count: comp.reward.count,
            },
          })
        ) || [],
    });
  }, [forceFeature, form, roleWithUsers]);

  const { users } = useOrganizationUsers(organizationId);

  const compensation = Form.useWatch("compensation", form);
  const featured = Form.useWatch("featured", form);

  const showTaskRewardsModal = useToggle();

  return (
    <>
      <Modal
        title={mode === "create" ? "Add role" : "Update role"}
        onOk={form.submit}
        footer={
          <Space>
            <Button
              hidden={mode === "create" || !!role?.source}
              onClick={handleDelete}
              type="text"
              danger
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
            <Button onClick={modalProps.onCancel}>Cancel</Button>
            <Button type="primary" onClick={form.submit} loading={submitting}>
              OK
            </Button>
          </Space>
        }
        {...modalProps}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            label="🎖 Name"
            name="name"
            rules={[{ required: true, message: "Please enter a name..." }]}
          >
            <Input
              placeholder="Role name"
              disabled={!!role?.source}
              prefix={
                <Form.Item name="icon" style={{ margin: 0, paddingLeft: 8 }}>
                  <EmojiPickerIcon />
                </Form.Item>
              }
            />
          </Form.Item>

          <Form.Item
            name="userIds"
            label="👤 Users"
            hidden={!!roleWithUsers?.source}
          >
            <UserSelect
              mode="multiple"
              users={users}
              placeholder="Select users..."
            />
          </Form.Item>

          <Form.Item
            name="featured"
            label="⭐️ Featured"
            valuePropName="checked"
          >
            <Checkbox
              children="Feature this role on the roles page"
              disabled={forceFeature}
            />
          </Form.Item>

          <HeadlessCollapse expanded={!!featured}>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item
                  name="skillIds"
                  label="🎨 Skills"
                  rules={[
                    {
                      type: "array",
                      max: 2,
                      message: "Please select max 2 skills",
                    },
                  ]}
                >
                  <SkillSelect placeholder="Select relevant skills" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item name="commitment" label="⏱ Commitment">
                  <Select
                    options={commitmentOptions}
                    placeholder="Select commitment"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="📝 Description" name="description">
              <Input.TextArea placeholder="Add a description" rows={3} />
            </Form.Item>

            {!!compensation && (
              <Form.Item label="💰 Compensation">
                <CompensationList
                  onClick={showTaskRewardsModal.toggleOn}
                  organizationId={organizationId}
                  compensation={compensation}
                />
              </Form.Item>
            )}

            <Form.Item
              rules={[
                {
                  type: "url",
                  required: false,
                  message: "Please enter a valid link",
                },
              ]}
              label="📩 Application Link"
              name="applicationLink"
            >
              <Input placeholder="Add a link where users can apply (Airtable, Google Forms etc.)" />
            </Form.Item>
          </HeadlessCollapse>

          <Form.Item hidden name="compensation" />
          <Form.Item hidden name="id" />
          <Form.Item hidden name="featured" />
          <Form.Item hidden name="name" />
          <Form.Item hidden name="color" initialValue="" />
        </Form>
      </Modal>

      <TaskRewardsForm
        organizationId={organizationId}
        initialValues={{ rewards: compensation?.map((c) => c.reward) }}
        showOpenToBids={false}
        onClose={showTaskRewardsModal.toggleOff}
        visible={showTaskRewardsModal.isOn}
        onSave={(values) => {
          const update = {
            compensation: values.rewards.map((reward, index) => ({
              frequency:
                compensation?.[index]?.frequency || CompensationFrequency.HOUR,
              reward,
            })),
          };
          form.setFieldsValue(update);
          showTaskRewardsModal.toggleOff();
        }}
      />
    </>
  );
};
