import React, { FC, useCallback } from "react";
import { Dropdown, Menu, MenuItemProps } from "antd";
import { SidebarNavLink } from "./SidebarNavLink";
import { stopPropagation } from "@dewo/app/util/eatClick";
import styles from "./Sidebar.module.less";
import { useUnfollowGraphNode } from "../graph/hooks";
import { GraphNodeAvatar } from "@dewo/app/components/avatars/GraphNodeAvatar";
import { GraphNodeDetails, GraphNodeType } from "@dewo/app/graphql/types";
import { MinusCircleOutlined } from "@ant-design/icons";

interface Props {
  node: GraphNodeDetails;
}

export const SidebarGraphNodeListItem: FC<Props> = ({ node }) => {
  const entity = {
    [GraphNodeType.ORGANIZATION]: node.organization,
    [GraphNodeType.WORKSPACE]: node.workspace,
    [GraphNodeType.COLLECTION]: node,
    [GraphNodeType.SKILL]: node,
    [GraphNodeType.TASK]: undefined,
    [GraphNodeType.USER]: undefined,
    [GraphNodeType.ROLE]: undefined,
  }[node.type];

  if (!entity) return null;
  return (
    <SidebarNavLink href={entity.permalink ?? ""} className={styles.item}>
      <Dropdown
        trigger={["contextMenu"]}
        overlay={
          <Menu>
            <UnfollowGraphNodeMenuItem node={node} />
          </Menu>
        }
      >
        <GraphNodeAvatar size={48} tooltip={{ visible: false }} node={node} />
      </Dropdown>
    </SidebarNavLink>
  );
};

const UnfollowGraphNodeMenuItem: FC<Props & MenuItemProps> = ({
  node,
  ...menuItemProps
}) => {
  const unfollow = useUnfollowGraphNode();
  const handleClick = useCallback(
    async (e) => {
      stopPropagation(e.domEvent);
      await unfollow(node.id);
    },
    [unfollow, node.id]
  );

  return (
    <Menu.Item
      icon={<MinusCircleOutlined />}
      onClick={handleClick}
      danger
      {...menuItemProps}
    >
      Unfollow
    </Menu.Item>
  );
};
