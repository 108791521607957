import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { Button, Card, Row, Tooltip, Typography } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { useCreateTaskReaction, useDeleteTaskReaction } from "../hooks";
import { useReactionModal } from "@dewo/app/src/util/reactions";
import styles from "./TaskVoting.module.less";
import classNames from "classnames";
import { Task } from "@dewo/app/graphql/types";
import _ from "lodash";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { CaretUpOutlined, LoadingOutlined } from "@ant-design/icons";
import { LoginModal } from "../../auth/LoginModal";

export const UPVOTE_REACTION = ":arrow_up_small:";

interface UpvoteProps {
  task: Task;
}

export const UpvoteCard: FC<UpvoteProps> = ({ task }) => {
  const { user } = useAuthContext();
  const canReact = usePermission("create", {
    __typename: "TaskReaction",
    userId: user?.id!,
    // @ts-ignore
    ...{ task },
  });
  const createReaction = useCreateTaskReaction();
  const deleteReaction = useDeleteTaskReaction();

  const upvotes = useMemo(
    () =>
      _.sumBy(task.reactions, (r) => Number(r.reaction === UPVOTE_REACTION)),
    [task]
  );

  const selected = useMemo(
    () =>
      task.reactions.some(
        (r) => r.reaction === UPVOTE_REACTION && r.userId === user?.id
      ),
    [task, user]
  );

  const [handleUpvote, upvoting] = useRunning(
    useCallback(
      async (event) => {
        stopPropagation(event);
        if (!canReact) return;
        if (selected) {
          await deleteReaction({ taskId: task.id, reaction: UPVOTE_REACTION });
        } else {
          await createReaction({ taskId: task.id, reaction: UPVOTE_REACTION });
        }
      },
      [createReaction, deleteReaction, task, selected, canReact]
    )
  );

  const [showReactions, ReactionsModal] = useReactionModal(
    task.id,
    UPVOTE_REACTION
  );

  const loginVisible = useToggle();

  return (
    <div onClick={stopPropagation}>
      <Tooltip
        title={
          <>
            <Row justify="center">
              <Typography.Paragraph
                style={{ marginBottom: 4, textAlign: "center" }}
              >
                Upvote suggestion
              </Typography.Paragraph>
            </Row>
            <Row justify="center">
              <Button size="small" type="ghost" onClick={showReactions}>
                {upvotes === 1 ? "1 upvote" : `${upvotes} upvotes`}
              </Button>
            </Row>
          </>
        }
      >
        <Card
          bodyStyle={{
            padding: 4,
            paddingBottom: 0,
            margin: "auto",
            display: "grid",
            minWidth: 36,
            placeItems: "center",
          }}
          className={classNames(
            "pointer-cursor",
            styles.upvoteCard,
            selected && styles.upvoted
          )}
          onClick={!!user ? handleUpvote : loginVisible.toggleOn}
          hoverable
        >
          <CaretUpOutlined className="ant-typography-caption" />
          <Typography.Text className={styles.votingCardVote} strong>
            {upvoting ? <LoadingOutlined /> : upvotes}
          </Typography.Text>
        </Card>
      </Tooltip>
      <ReactionsModal />

      <LoginModal
        visible={loginVisible.isOn}
        onClose={loginVisible.toggleOff}
        onAuthedWithWallet={() => {
          loginVisible.toggleOff();
          createReaction({ taskId: task.id, reaction: UPVOTE_REACTION });
        }}
      />
    </div>
  );
};
