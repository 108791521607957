import dynamic from "next/dynamic";
import React from "react";
import { EmojiPickerProps } from "./EmojiPickerDynamic";

export const EmojiPicker = dynamic<EmojiPickerProps>(
  () =>
    import("./EmojiPickerDynamic").then((Module) => (props) => (
      <Module.EmojiPickerDynamic {...props} />
    )),
  { ssr: false }
);
