import { LockOutlined } from "@ant-design/icons";
import { Workspace } from "@dewo/app/graphql/types";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import React, { FC } from "react";
import { Emoji } from "../../components/emoji/Emoji";
import { useIsWorkspacePrivate } from "../rbac/hooks";

interface Props extends TextProps {
  workspace: Workspace;
  showPrivate?: boolean;
}

export const WorkspaceName: FC<Props> = ({
  workspace,
  showPrivate = true,
  ...textProps
}) => {
  const isPrivate = useIsWorkspacePrivate(
    workspace,
    workspace.organizationId,
    !showPrivate
  );
  return (
    <Typography.Text {...textProps}>
      {!!workspace.icon && (
        <span style={{ marginRight: 8 }}>
          <Emoji name={workspace.icon} />
        </span>
      )}
      {workspace.name}
      {showPrivate && isPrivate && (
        <LockOutlined className="text-secondary" style={{ marginLeft: 8 }} />
      )}
    </Typography.Text>
  );
};
