import gql from "graphql-tag";
import { taskTag, taskView } from "./task";

export const workspace = gql`
  fragment Workspace on Workspace {
    id
    slug
    name
    icon
    type
    status
    description
    startAt
    endAt
    deletedAt
    organizationId
    permalink
    sectionId
    parentId
    sortKey
    roadmapSortKey
    options {
      showCommunitySuggestions
    }
  }
`;

export const workspaceSection = gql`
  fragment WorkspaceSection on WorkspaceSection {
    id
    name
    slug
    layout
    sortKey
    organizationId
  }
`;

export const workspaceSectionDetails = gql`
  fragment WorkspaceSectionDetails on WorkspaceSection {
    ...WorkspaceSection
    taskViews {
      ...TaskView
    }
    workspaces {
      ...Workspace
      taskTags {
        ...TaskTag
      }
    }
  }

  ${workspaceSection}
  ${taskView}
  ${taskTag}
  ${workspace}
`;
