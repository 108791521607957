import gql from "graphql-tag";
import { organization } from "../fragments/organization";
import { roleWithUsers, role, roleCompensation } from "../fragments/role";
import { skill } from "../fragments/skill";

export const getRoleWithUsers = gql`
  query GetRoleWithUsersQuery($roleId: UUID!) {
    role: getRole(id: $roleId) {
      ...RoleWithUsers
    }
  }

  ${roleWithUsers}
`;

export const getOpenRoles = gql`
  query GetOpenRolesQuery(
    $limit: Int!
    $skillIds: [UUID!]
    $commitments: [Int]
  ) {
    roles: getOpenRoles(
      limit: $limit
      skillIds: $skillIds
      commitments: $commitments
    ) {
      ...Role
      commitment
      skills {
        ...Skill
      }
      compensation {
        ...RoleCompensation
      }
      node {
        id
        parents {
          node: parent {
            id
            organization {
              ...Organization
            }
          }
        }
      }
    }
  }

  ${role}
  ${skill}
  ${roleCompensation}
  ${organization}
`;
