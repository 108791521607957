import { PhantomIcon } from "@dewo/app/components/icons/Phantom";
import { ThreepidSource, UserDetails } from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { ButtonProps } from "antd";
import { useRouter } from "next/router";
import React, { FC, useCallback } from "react";
import { useAuthWithSolana } from "../hooks";
import { getThreepidName, ThreepidAuthButton } from "./ThreepidAuthButton";

interface Props extends ButtonProps {
  redirect?: string;
  onAuthed?(user: UserDetails): void;
}

export const PhantomAuthButton: FC<Props> = ({
  redirect,
  onAuthed,
  ...buttonProps
}) => {
  const router = useRouter();
  const authWithSolana = useAuthWithSolana();
  const [auth, authing] = useRunning(
    useCallback(async () => {
      try {
        const user = await authWithSolana();
        onAuthed?.(user);
        if (!!redirect) router.push(redirect);
      } catch (error) {
        alert((error as Error).message);
        throw error;
      }
    }, [authWithSolana, onAuthed, router, redirect])
  );
  return (
    <ThreepidAuthButton
      loading={authing}
      source={ThreepidSource.phantom}
      children={getThreepidName[ThreepidSource.phantom]}
      redirect={redirect}
      icon={<PhantomIcon />}
      href={undefined}
      onClick={auth}
      {...buttonProps}
    />
  );
};
