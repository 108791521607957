import { useToggle } from "@dewo/app/util/hooks";
import { Card, DatePicker, Dropdown, Tag } from "antd";
import React, { FC, useCallback } from "react";
import { EditableTag } from "@dewo/app/components/tags/EditableTag";
import { CalendarOutlined } from "@ant-design/icons";

interface Props {
  value?: moment.Moment;
  onChange?: (dueDate: moment.Moment | null) => void;
  onClear?(): void;
  disabled?: boolean;
  defaultOpen?: boolean;
}

export const DueDateInput: FC<Props> = ({
  value,
  onChange,
  onClear,
  defaultOpen = false,
  disabled,
}) => {
  const open = useToggle(defaultOpen);

  const handleChange = useCallback(
    (date: moment.Moment | null) => {
      onChange?.(date);
      open.toggleOff();
      if (!date) onClear?.();
    },
    [onChange, onClear, open]
  );
  const clear = useCallback(() => handleChange(null), [handleChange]);

  return (
    <Dropdown
      trigger={["click"]}
      onVisibleChange={open.setToggle}
      visible={open.isOn}
      disabled={disabled}
      overlay={
        <Card style={{ padding: 8 }}>
          <DatePicker
            open={!disabled && open.isOn}
            format="LL"
            style={{ minWidth: 200 }}
            value={value}
            onChange={handleChange}
            autoFocus
          />
        </Card>
      }
    >
      {disabled ? (
        <Tag icon={<CalendarOutlined />}>
          {value ? `Due ${value.format("L")}` : "Add due date"}
        </Tag>
      ) : (
        <EditableTag
          closable={!disabled && !!value}
          onClose={clear}
          icon={<CalendarOutlined />}
        >
          {value ? `Due ${value.format("L")}` : "Add due date"}
        </EditableTag>
      )}
    </Dropdown>
  );
};
