import { OpenDiscordButton } from "@dewo/app/components/buttons/OpenDiscordButton";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  Task,
  TaskApplication,
  TaskApplicationStatus,
} from "@dewo/app/graphql/types";
import { Button, List, Typography, Space, Tooltip, Row, Col } from "antd";
import moment from "moment";
import { TaskRewardTag } from "../reward/TaskRewardTag";
import React, { FC, useCallback, useMemo } from "react";
import { useUpdateTaskApplication } from "../hooks";
import { useRunning, useToggle } from "@dewo/app/util/hooks";
import { MessageOutlined } from "@ant-design/icons";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { Divider } from "@dewo/app/components/Divider";
import { LockIcon } from "@dewo/app/components/icons/task/Lock";
import { SubmissionDiscussion } from "../../thread/SubmissionDiscussion";
import _ from "lodash";

export const TaskApplicationListItem: FC<{
  application: TaskApplication;
  onAssign: (application: TaskApplication) => Promise<void>;
  task: Task;
}> = ({ application, onAssign, task }) => {
  const startDate = moment(application.startDate);
  const endDate = moment(application.endDate);
  const days = moment.duration(endDate.diff(startDate)).asDays();

  const canDelete = usePermission("delete", application);

  const [handleAssign, loading] = useRunning(() => onAssign(application));
  const updateApplication = useUpdateTaskApplication();

  const showDiscussion = useToggle();
  const messages = application.thread?.messages;
  const canAssign = usePermission("update", task, "assigneeIds");
  const mentionable = useMemo(
    () =>
      _.uniqBy(
        [...(task.owners || []), application.user].filter((u) => !!u),
        (i) => i.id
      ),
    [application.user, task.owners]
  );
  const handleThreadCreated = useCallback(
    async (threadId) => {
      await updateApplication({
        id: application.id,
        taskId: task.id,
        userId: application.userId,
        threadId,
      });
    },
    [application.id, application.userId, task.id, updateApplication]
  );

  return (
    <List.Item>
      <Space direction="vertical" style={{ overflowX: "hidden", flex: 1 }}>
        <Row align="middle" wrap={false}>
          <Col flex={1}>
            <Space direction="vertical" className="w-full">
              <Tooltip title={application.user.username}>
                <a
                  href={application.user.permalink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <List.Item.Meta
                    avatar={
                      <UserAvatar
                        user={application.user}
                        tooltip={{ visible: false }}
                      />
                    }
                    title={
                      <Space>
                        <>{application.user.username}</>
                        <Typography.Text type="secondary">·</Typography.Text>
                        <a
                          href={application.user.permalink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography.Link type="secondary">
                            View profile
                          </Typography.Link>
                        </a>
                      </Space>
                    }
                    description={
                      startDate.format("DD/MM/YYYY") +
                      " - " +
                      endDate.format("DD/MM/YYYY") +
                      " (" +
                      days +
                      " days)"
                    }
                  />
                </a>
              </Tooltip>
              <Typography.Paragraph
                style={{ wordBreak: "break-word", margin: 0 }}
              >
                {application.message}
              </Typography.Paragraph>
              {!!application.reward && (
                <TaskRewardTag
                  formatStyle="total"
                  reward={application.reward}
                />
              )}
              <Space>
                <Button
                  icon={<MessageOutlined />}
                  onClick={showDiscussion.toggle}
                  style={{ background: "rgba(255,255,255,0.15)" }}
                >
                  Discuss {messages?.length ? `(${messages?.length})` : ""}
                </Button>
                {!!application.discordThreadUrl && (
                  <OpenDiscordButton
                    style={{ background: "rgba(255,255,255,0.15)" }}
                    href={application.discordThreadUrl}
                  >
                    Go to discord
                  </OpenDiscordButton>
                )}
              </Space>
            </Space>
          </Col>
          <Col flex={0}>
            <Space
              style={{ textAlign: "center" }}
              size={4}
              direction="vertical"
              key={0}
              hidden={!canAssign}
            >
              <Button
                type="primary"
                size="small"
                loading={loading}
                onClick={handleAssign}
              >
                Assign
              </Button>
              {!!canDelete && (
                <Button
                  key="remove"
                  size="small"
                  type="text"
                  className="text-secondary"
                  onClick={async () =>
                    await updateApplication({
                      id: application.id,
                      taskId: task.id,
                      userId: application.userId,
                      status: TaskApplicationStatus.REJECTED,
                    })
                  }
                >
                  Reject
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <HeadlessCollapse expanded={showDiscussion.isOn}>
          <Space direction="vertical" className="w-full" size="middle">
            <Divider size={0} />
            <Space align="center">
              <LockIcon style={{ width: 14, marginTop: 2 }} />
              <Typography.Text type="secondary">
                {canAssign
                  ? "Only this applicant and other reviewers can see this discussion"
                  : "Only you and the reviewers can see this discussion"}
              </Typography.Text>
            </Space>
            <SubmissionDiscussion
              onThreadCreated={handleThreadCreated}
              users={mentionable}
              thread={application.thread}
            />
          </Space>
        </HeadlessCollapse>
      </Space>
    </List.Item>
  );
};
