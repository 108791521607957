import { Tag, TagProps } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import styles from "./EditableTag.module.less";

export const EditableTag: FC<TagProps> = ({ className, ...props }) => {
  return (
    <Tag {...props} className={classNames(className, styles.EditableTag)} />
  );
};
