import { WorkspaceIntegrationType, TaskDetails } from "@dewo/app/graphql/types";
import { Button, Dropdown, Menu, MenuItemProps, Typography } from "antd";
import React, { FC, useMemo } from "react";
import { useWorkspaceIntegrations } from "../../workspace/hooks";
import { useCopyToClipboardAndShowToast } from "@dewo/app/util/hooks";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { BranchesOutlined } from "@ant-design/icons";

interface Props {
  task: TaskDetails;
}

const MenuItem: FC<
  {
    text: string;
    tooltip?: string;
  } & MenuItemProps
> = ({ text, tooltip, ...menuItemProps }) => {
  const copy = useCopyToClipboardAndShowToast();
  return (
    <Menu.Item onClick={() => copy(text)} {...menuItemProps}>
      <Typography.Text code copyable>
        {text}
      </Typography.Text>
      {!!tooltip && <QuestionmarkTooltip title={tooltip} />}
    </Menu.Item>
  );
};

export const TaskGithubBranchButton: FC<Props> = ({ task }) => {
  const integrations = useWorkspaceIntegrations(
    task.workspace.parentId ?? task.workspaceId
  );
  const hasGithubIntegration = useMemo(
    () => integrations?.some((i) => i.type === WorkspaceIntegrationType.GITHUB),
    [integrations]
  );

  if (!hasGithubIntegration) return null;

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          <MenuItem
            text={task.gitBranchName}
            tooltip={`This is a suggested branch name. The Github integration links git branches to Dework tasks using the "dw-${task.number}" identifier, so as long as your branch name contains that, it will get linked to this task.`}
          />
          <MenuItem text={`git checkout -b ${task.gitBranchName}`} />
        </Menu>
      }
    >
      <Button type="text" size="large" icon={<BranchesOutlined />} />
    </Dropdown>
  );
};
