import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { Thread, User } from "@dewo/app/graphql/types";
import { Card, Timeline } from "antd";
import _ from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { CreateThreadMessageForm } from "./CreateThreadMessageForm";
import { useCreateThread } from "./hooks";
import { TaskThreadMessageTimelineItem } from "./task/hooks";
import { ThreadMessageComponent, ThreadTimeline } from "./ThreadTimeline";

export const SubmissionDiscussion: FC<{
  thread: Thread | null;
  users: User[];
  onThreadCreated: (threadId: string) => Promise<void>;
}> = ({ thread, users, onThreadCreated }) => {
  const { user } = useAuthContext();
  const items = useMemo(
    () =>
      _.sortBy(
        thread?.messages.map(
          (message): TaskThreadMessageTimelineItem => ({
            type: "message",
            message,
            date: message.createdAt,
            key: message.id,
            thread,
            user: message.sender,
          })
        ),
        (i) => i.date
      ),
    [thread]
  );

  const createThread = useCreateThread();
  const createThreadIfNotExists = useCallback(async () => {
    if (!!thread) {
      return { threadId: thread.id };
    }

    const newThread = await createThread();
    await onThreadCreated(newThread.id);
    return { threadId: newThread.id };
  }, [createThread, thread, onThreadCreated]);

  return (
    <ThreadTimeline>
      {items?.map((item) => (
        <HeadlessCollapse expanded expandOnCreation key={item.key}>
          <Timeline.Item
            dot={!!item.user && <UserAvatar size="small" user={item.user} />}
          >
            <ThreadMessageComponent item={item} />
          </Timeline.Item>
        </HeadlessCollapse>
      ))}

      {!!user && (
        <Timeline.Item dot={<UserAvatar size="small" user={user} />}>
          <Card size="small" bordered={false} bodyStyle={{ paddingRight: 0 }}>
            <CreateThreadMessageForm
              key={thread?.messages.length}
              threadId={thread?.id}
              bordered={false}
              mentionable={users}
              placeholder="Write a comment..."
              onBeforeSend={createThreadIfNotExists}
            />
          </Card>
        </Timeline.Item>
      )}
    </ThreadTimeline>
  );
};
