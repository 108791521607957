import { useApolloClient, useMutation } from "@apollo/client";
import React, { useCallback } from "react";
import * as Mutations from "@dewo/app/graphql/mutations/auth";
import {
  UserDetails,
  DeleteThreepidMutation,
  DeleteThreepidMutationVariables,
  AuthFromSessionMutation,
  AuthFromSessionMutationVariables,
  AuthWithEthereumMutation,
  AuthWithEthereumMutationVariables,
  AuthWithSolanaMutation,
  AuthWithSolanaMutationVariables,
  ThreepidAuthSessionType,
  CreateThreepidAuthSessionMutation,
  CreateThreepidAuthSessionMutationVariables,
} from "@dewo/app/graphql/types";
import { usePersonalSign, useRequestAddress } from "@dewo/app/util/ethereum";
import { Modal } from "antd";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import * as solana from "@dewo/app/util/solana";
import { useWalletConnectAddress } from "@dewo/app/util/walletconnect";
import {
  createThreepidAuthSession,
  deleteThreepid,
} from "@dewo/app/graphql/mutations/threepid";
import { PhantomIcon } from "@dewo/app/components/icons/Phantom";

function useAuthenticated(): (authToken: string) => void {
  const { onAuthenticated } = useAuthContext();
  const apolloClient = useApolloClient();
  return useCallback(
    (authToken) => {
      onAuthenticated(authToken);
      apolloClient.reFetchObservableQueries(); // async
    },
    [onAuthenticated, apolloClient]
  );
}

export function useCreateThreepidAuthSession(): (
  type: ThreepidAuthSessionType,
  redirect: string,
  state?: any
) => Promise<string> {
  const [mutation] = useMutation<
    CreateThreepidAuthSessionMutation,
    CreateThreepidAuthSessionMutationVariables
  >(createThreepidAuthSession);
  return useCallback(
    async (type, redirect, state) => {
      const res = await mutation({ variables: { type, redirect, state } });
      if (!res.data) throw new Error();
      return res.data.session.id;
    },
    [mutation]
  );
}

export function useAuthFromSession(): (
  sessionId: string
) => Promise<{ user: UserDetails; redirect: string | null }> {
  const handleAuthenticated = useAuthenticated();
  const [mutation] = useMutation<
    AuthFromSessionMutation,
    AuthFromSessionMutationVariables
  >(Mutations.authFromSession);
  return useCallback(
    async (sessionId: string) => {
      const res = await mutation({ variables: { sessionId } });
      if (!res.data) throw new Error();
      handleAuthenticated(res.data.authFromSession.authToken);
      return res.data.authFromSession;
    },
    [mutation, handleAuthenticated]
  );
}

export function useAuthWithMetamask(): () => Promise<UserDetails> {
  const requestAddress = useRequestAddress();
  const personalSign = usePersonalSign();
  const handleAuthenticated = useAuthenticated();

  const [authWithEthereumMutation] = useMutation<
    AuthWithEthereumMutation,
    AuthWithEthereumMutationVariables
  >(Mutations.authWithEthereum);

  return useCallback(async () => {
    const address = await requestAddress();
    const message = "Dework Sign In";
    const signature = await personalSign(message, address);

    const res = await authWithEthereumMutation({
      variables: { input: { address, message, signature } },
    });
    if (!res.data) {
      throw new Error("Failed to create Metamask threepid");
    }

    handleAuthenticated(res.data.auth.authToken);
    return res.data.auth.user;
  }, [
    personalSign,
    requestAddress,
    authWithEthereumMutation,
    handleAuthenticated,
  ]);
}

export function useAuthWithWalletConnect(): () => Promise<UserDetails> {
  const [authWithEthereumMutation] = useMutation<
    AuthWithEthereumMutation,
    AuthWithEthereumMutationVariables
  >(Mutations.authWithEthereum);
  const handleAuthenticated = useAuthenticated();
  const loadAddress = useWalletConnectAddress();
  return useCallback(async () => {
    const { address, connector } = await loadAddress();

    const message = "Dework Sign In";
    const signature = await connector.signPersonalMessage([message, address]);
    const { data } = await authWithEthereumMutation({
      variables: { input: { address, signature, message } },
    });
    if (!data) {
      throw new Error("Failed to create Wallet Connect threepid");
    }

    handleAuthenticated(data.auth.authToken);
    return data.auth.user;
  }, [loadAddress, authWithEthereumMutation, handleAuthenticated]);
}

export function useAuthWithSolana(): () => Promise<UserDetails> {
  const isPhantomAvailable = solana.useProvider();
  const requestAddress = solana.useRequestAddress();
  const personalSign = solana.usePersonalSign();
  const handleAuthenticated = useAuthenticated();

  const [authWithSolana] = useMutation<
    AuthWithSolanaMutation,
    AuthWithSolanaMutationVariables
  >(Mutations.authWithSolana);

  return useCallback(async () => {
    if (isPhantomAvailable) {
      const address = await requestAddress();
      const message = "Dework Sign In";
      const sign = await personalSign(message);
      const signature = Array.from(sign!);

      const res = await authWithSolana({
        variables: { input: { address, message, signature } },
      });
      if (!res.data) {
        throw new Error("Failed to create Phantom threepid");
      }

      handleAuthenticated(res.data.auth.authToken);
      return res.data.auth.user;
    } else {
      // Phantom doesn't support deep linking
      const phantomUrl = "https://phantom.app";

      await new Promise<void>((resolve, reject) => {
        Modal.confirm({
          icon: <PhantomIcon />,
          title: "Connect Phantom",
          okText: "Open Phantom",
          onOk: () => {
            window.open(phantomUrl);
            resolve();
          },
          onCancel: () => {
            reject(new Error("User did not open Phantom"));
          },
        });
      });

      throw new Error(
        "Phantom not available. Please get the Phantom browser extension to authenticate."
      );
    }
  }, [
    isPhantomAvailable,
    requestAddress,
    personalSign,
    authWithSolana,
    handleAuthenticated,
  ]);
}

export function useDeleteThreepid(): (id: string) => Promise<void> {
  const [mutation] = useMutation<
    DeleteThreepidMutation,
    DeleteThreepidMutationVariables
  >(deleteThreepid);
  return useCallback(
    async (id) => {
      const res = await mutation({ variables: { id } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}
