import React, { FC, useMemo } from "react";
import { Button, Col, Divider, Layout, Menu, Row } from "antd";
import { useOrganizationBySlug } from "../organization/hooks";
import { useRouter } from "next/router";
import { useSidebarContext } from "@dewo/app/contexts/SidebarContext";
import classNames from "classnames";
import styles from "./Sidebar.module.less";
import { OrganizationMenu } from "./menus/OrganizationMenu";
import { UserMenu } from "./menus/UserMenu";
import { MenuFooter } from "./MenuFooter";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { MenuSkeleton } from "./menus/MenuSkeleton";
import { useWorkspaceIdBySlug } from "../workspace/hooks";
import {
  DoubleLeftOutlined,
  FileSearchOutlined,
  MailOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import menuStyles from "./menus/Menu.module.less";
import { openSupportChat } from "@dewo/app/components/SupportChat";

export const NavigationList: FC = () => {
  const { user } = useAuthContext();
  const router = useRouter();
  const organizationSlug = router.query.organizationSlug as string | undefined;
  const organizationId =
    useOrganizationBySlug(organizationSlug).organization?.id;
  const { isOn, toggleOff } = useSidebarContext();

  const workspaceSlug = router.query.workspaceSlug as string | undefined;
  const workspaceId = useWorkspaceIdBySlug(workspaceSlug);

  const content = useMemo(() => {
    if (!!organizationSlug) {
      if (!organizationId) return <MenuSkeleton />;
      return (
        <OrganizationMenu
          organizationId={organizationId}
          workspaceId={workspaceId}
        />
      );
    } else {
      return <UserMenu />;
    }
  }, [organizationId, organizationSlug, workspaceId]);

  return (
    <Col
      flex={1}
      className={classNames("bg-body-secondary", styles.navigationList)}
    >
      <Row
        align="middle"
        className={classNames(!isOn && styles.minimized, "w-full")}
        style={{ paddingTop: 16, position: "absolute" }}
      >
        <Button
          onClick={toggleOff}
          type="text"
          className="mx-auto text-secondary"
          icon={<DoubleLeftOutlined rotate={isOn ? 180 : 0} />}
        />
      </Row>
      <div
        className={classNames(isOn ? styles.minimized : "", "flex", "h-full")}
        style={{ flexDirection: "column" }}
      >
        <Col
          flex={1}
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* wrap Menu in noop Layout.Sider to reset SiderContext's influence on Menu */}
          <Layout.Sider width="100%">
            <div
              className="h-full"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {content}
              <Col flex={1} />
              <Divider style={{ marginBottom: 8, marginTop: 8 }} />
              <Menu
                className={menuStyles.menu}
                mode="inline"
                style={{ marginBottom: 8 }}
                items={[
                  {
                    label: <div onClick={openSupportChat}>Ask a question</div>,
                    icon: <QuestionCircleOutlined />,
                    key: "question",
                  },
                  {
                    label: (
                      <div onClick={openSupportChat}>Give us feedback</div>
                    ),
                    icon: <MailOutlined />,
                    key: "feedback",
                  },
                  {
                    label: (
                      <a
                        href="https://dework.gitbook.io"
                        target="_blank"
                        rel="noreferrer"
                        // @ts-expect-error
                        name="Sidebar Footer: docs"
                      >
                        Read our docs
                      </a>
                    ),
                    icon: <FileSearchOutlined />,
                    key: "docs",
                  },
                ]}
              />
            </div>
          </Layout.Sider>
        </Col>

        {!!user && (
          <>
            <Divider style={{ margin: 0 }} />
            <MenuFooter />
          </>
        )}
      </div>
    </Col>
  );
};
