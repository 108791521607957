import gql from "graphql-tag";
import {
  organization,
  organizationRoleDetails,
} from "../fragments/organization";

export const getOrganizationRoleDetails = gql`
  query GetOrganizationRoleDetailsQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...OrganizationRoleDetails
    }
  }

  ${organizationRoleDetails}
`;

export const getLandingPageGrants = gql`
  query GetLandingPageGrantsQuery($nodeId: UUID!, $filter: SearchTasksInput!) {
    node: getGraphNode(id: $nodeId) {
      id
      children {
        node: child {
          id
          organization {
            ...Organization
            paginatedTasks(filter: $filter) {
              total
              tasks {
                id
                name
                permalink
                subtaskCount
              }
            }
          }
        }
      }
    }
  }

  ${organization}
`;
