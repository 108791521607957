import gql from "graphql-tag";
import { node, nodeChildrenDetails } from "../fragments/graph";

export const createGraphNode = gql`
  mutation CreateGraphNodeMutation($input: CreateGraphNodeInput!) {
    node: createGraphNode(input: $input) {
      ...GraphNode
    }
  }

  ${node}
`;

export const updateGraphNode = gql`
  mutation UpdateGraphNodeMutation($input: UpdateGraphNodeInput!) {
    node: updateGraphNode(input: $input) {
      ...GraphNode
    }
  }

  ${node}
`;

export const upsertGraphEdge = gql`
  mutation UpsertGraphEdgeMutation($input: UpsertGraphEdgeInput!) {
    edge: upsertGraphEdge(input: $input) {
      sortKey
      parent {
        ...GraphNodeChildrenDetails
      }
    }
  }

  ${nodeChildrenDetails}
`;

export const deleteGraphEdge = gql`
  mutation DeleteGraphEdgeMutation($input: DeleteGraphEdgeInput!) {
    edge: deleteGraphEdge(input: $input) {
      parent {
        ...GraphNodeChildrenDetails
      }
    }
  }

  ${nodeChildrenDetails}
`;
