import { Emoji } from "@dewo/app/components/emoji/Emoji";
import { Button, Row, Typography } from "antd";
import React, { FC, useCallback } from "react";
import { MetamaskAuthButton } from "../../auth/buttons/MetamaskAuthButton";
import { WalletConnectAuthButton } from "../../auth/buttons/WalletConnectAuthButton";
import { UserPromptStepComponentProps } from "../types";

export const ConnectWalletToReceiveTaskReward: FC<
  UserPromptStepComponentProps
> = ({ onNext }) => {
  const handleNext = useCallback(() => onNext(), [onNext]);
  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Connect your Wallet
      </Typography.Title>
      <Typography.Text
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        You have a task waiting to be paid!
      </Typography.Text>

      <Row align="middle" justify="center" style={{ flex: 1, fontSize: 100 }}>
        <Emoji name="💸" />
      </Row>

      <MetamaskAuthButton
        children="Connect Metamask"
        size="large"
        type="primary"
        name="Task Reward: connect Metamask to receive reward"
        style={{ alignSelf: "center" }}
        onAuthed={handleNext}
      />
      <WalletConnectAuthButton
        children="Connect WalletConnect"
        size="large"
        name="Task Reward: connect WalletConnect to receive reward"
        style={{ alignSelf: "center" }}
        onAuthed={handleNext}
      />
      <Button
        type="text"
        style={{ alignSelf: "center", marginTop: 8 }}
        className="text-secondary"
        name="Task Reward: skip connecting Metamask"
        onClick={handleNext}
      >
        Skip
      </Button>
    </>
  );
};
