import { CompensationFrequency } from "@dewo/app/graphql/types";

export const compensationFrequencyMap: Record<CompensationFrequency, string> = {
  [CompensationFrequency.HOUR]: "Hour",
  [CompensationFrequency.DAY]: "Day",
  [CompensationFrequency.WEEK]: "Week",
  [CompensationFrequency.MONTH]: "Month",
  [CompensationFrequency.STORY_POINT]: "Task point",
  [CompensationFrequency.FIXED]: "Fixed",
};

export const commitmentOptions = [
  {
    label: "No commitment",
    value: null,
    color: "lime",
    icon: "☁️",
  },
  {
    label: "0-10h/week",
    value: 10,
    color: "gold",
    icon: "⏱",
  },
  {
    label: "10-20h/week",
    value: 25,
    color: "red",
    icon: "⏱",
  },
  {
    label: "20-40h/week",
    value: 40,
    color: "cyan",
    icon: "⏱",
  },
  {
    label: "40h+/week",
    value: 50,
    color: "purple",
    icon: "⏱",
  },
];
