import { Popover } from "antd";
import React, { FC, useCallback } from "react";
import { EmojiPicker } from "./EmojiPicker";
import styles from "./EmojiPicker.module.less";
import { Emoji } from "./Emoji";
import { EmojiData } from "./types";
import { SmileOutlined } from "@ant-design/icons";

interface Props {
  value?: string;
  onChange?(value: string): void;
}

export const EmojiPickerIcon: FC<Props> = ({ value, onChange }) => {
  const select = useCallback(
    (emoji: EmojiData) => onChange?.(emoji.shortcodes),
    [onChange]
  );
  return (
    <Popover
      content={<EmojiPicker onEmojiSelect={select} />}
      trigger="click"
      overlayClassName={styles.buttonPopover}
    >
      <div
        style={{
          paddingRight: 8,
          cursor: "pointer",
          fontSize: 20,
          lineHeight: "20px",
        }}
      >
        {!!value ? (
          <Emoji name={value} />
        ) : (
          <SmileOutlined className="text-secondary" />
        )}
      </div>
    </Popover>
  );
};
