import { ThreepidAuthSessionType } from "@dewo/app/graphql/types";
import { Constants } from "@dewo/app/util/constants";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useCreateThreepidAuthSession } from "../../auth/hooks";

export function useConnectToNotion() {
  const router = useRouter();
  const createSession = useCreateThreepidAuthSession();
  return useCallback(
    async (organizationId: string, redirect: string = router.asPath) => {
      const sessionId = await createSession(
        ThreepidAuthSessionType.NOTION,
        redirect,
        { organizationId }
      );

      window.location.href = `${Constants.GRAPHQL_API_URL}/auth/notion?state=${sessionId}`;
    },
    [createSession, router.asPath]
  );
}
