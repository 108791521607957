import {
  CompensationFrequency,
  PaymentToken,
  TaskReward,
  UpdateTaskRewardInput,
} from "@dewo/app/graphql/types";
import { Constants } from "@dewo/app/util/constants";
import { formatFixed, parseFixed } from "@ethersproject/bignumber";
import _ from "lodash";
import { TaskRewardFormItemValue } from "./types";

export const toTaskRewardInput = (
  reward: TaskRewardFormItemValue,
  token: PaymentToken
): UpdateTaskRewardInput => ({
  amount: parseFixed(
    String(reward.amount),
    reward.peggedToUsd ? Constants.NUM_DECIMALS_IN_USD_PEG : token.exp
  ).toString(),
  tokenId: reward.tokenId,
  peggedToUsd: reward.peggedToUsd,
  type: reward.type,
  count: reward.count,
});

export const toTaskRewardFormItem = (
  input: UpdateTaskRewardInput,
  token: PaymentToken
): TaskRewardFormItemValue => ({
  amount: Number(
    formatFixed(
      input.amount,
      input.peggedToUsd ? Constants.NUM_DECIMALS_IN_USD_PEG : token.exp
    )
  ),
  peggedToUsd: !!input.peggedToUsd,
  tokenId: input.tokenId,
  type: input.type ?? CompensationFrequency.FIXED,
  count: input.count ?? undefined,
});

export const taskRewardToInput = (input: TaskReward): UpdateTaskRewardInput =>
  _.pick(input, "amount", "tokenId", "peggedToUsd", "count", "type");
