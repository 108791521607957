import {
  FolderFilled,
  InsertRowAboveOutlined,
  RocketFilled,
} from "@ant-design/icons";
import { WorkspaceType } from "@dewo/app/graphql/types";
import { Col, Radio, RadioGroupProps, Row, Space, Typography } from "antd";
import React, { FC } from "react";
import * as Colors from "@ant-design/colors";

interface Props extends RadioGroupProps {
  showSection?: boolean;
}

export const WorkspaceTypeCheckbox: FC<Props> = ({
  showSection,
  ...injectedProps
}) => (
  <Radio.Group {...injectedProps}>
    <Row>
      <Col md={12}>
        <Radio value={WorkspaceType.FOLDER}>
          <Typography.Text strong>
            <Space align="center">
              <FolderFilled style={{ color: Colors.purple[3] }} />
              Space
            </Space>
          </Typography.Text>
          <Typography.Paragraph type="secondary">
            Use Spaces as containers to organize and categorize work.
            Recommended for your Guilds, Teams, Pods...
          </Typography.Paragraph>
        </Radio>
      </Col>
      <Col md={12}>
        <Radio value={WorkspaceType.PROJECT}>
          <Typography.Text strong>
            <Space align="center">
              <RocketFilled style={{ color: Colors.blue[3] }} />
              Project
            </Space>
          </Typography.Text>
          <Typography.Paragraph type="secondary">
            Use Projects to manage tasks that are part of a larger goal.
            Projects can have a Status Finish Date and Budget.
          </Typography.Paragraph>
        </Radio>
      </Col>
      {showSection && (
        <Col md={12}>
          <Radio value="SECTION">
            <Typography.Text strong>
              <Space align="center">
                <InsertRowAboveOutlined style={{ color: Colors.gold[3] }} />
                Section
              </Space>
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              Use Sections to separate Projects and Spaces
            </Typography.Paragraph>
          </Radio>
        </Col>
      )}
    </Row>
  </Radio.Group>
);
