import { PlusCircleOutlined } from "@ant-design/icons";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, ConfigProvider, Empty, Select, SelectProps } from "antd";
import React, { FC } from "react";
import { useOrganizationTokens } from "../../organization/hooks";
import { AddTokenModal } from "./AddTokenModal";

interface Props extends SelectProps {
  organizationId: string;
  showAddToken?: boolean;
  disableSelecting?: boolean;
}

export const TokenSelect: FC<Props> = ({
  organizationId,
  onChange,
  disableSelecting,
  showAddToken = true,
  ...selectProps
}) => {
  const add = useToggle();
  const tokens = useOrganizationTokens(organizationId);
  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          imageStyle={{ display: "none" }}
          description="To add a bounty, you need to add tokens to pay with"
        >
          <Button
            type="primary"
            size="small"
            children="Add token"
            onClick={add.toggleOn}
          />
        </Empty>
      )}
    >
      <Select
        placeholder="Select a token"
        {...selectProps}
        onChange={onChange}
        showSearch
        optionFilterProp="label"
        dropdownRender={(menu) => (
          <>
            {menu}
            {!!tokens.length && !!showAddToken && (
              <Button
                block
                type="text"
                style={{ textAlign: "left", marginTop: 4 }}
                className="text-secondary"
                children="Add token"
                icon={<PlusCircleOutlined />}
                onClick={add.toggleOn}
              />
            )}
          </>
        )}
      >
        {tokens.map((token) => (
          <Select.Option
            key={token.id}
            value={token.id}
            disabled={disableSelecting}
            label={`${token.symbol} (${token.network.name})`}
          >
            {`${token.symbol} (${token.network.name})`}
          </Select.Option>
        ))}
      </Select>
      <AddTokenModal
        organizationId={organizationId}
        visible={add.isOn}
        onClose={add.toggleOff}
      />
    </ConfigProvider>
  );
};
