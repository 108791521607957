import { NotionIcon } from "@dewo/app/components/icons/Notion";
import { TrelloIcon } from "@dewo/app/components/icons/Trello";
import { Constants } from "@dewo/app/util/constants";
import { Dropdown, Menu, Space, Tooltip } from "antd";
import { useRouter } from "next/router";
import React, { FC, useCallback } from "react";
import { useConnectToGithubUrlFn } from "../../integrations/hooks";
import {
  OrganizationIntegrationType,
  ThreepidAuthSessionType,
} from "@dewo/app/graphql/types";
import {
  useOrganization,
  useOrganizationIntegrations,
} from "../../organization/hooks";
import {
  GithubOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useCreateThreepidAuthSession } from "../../auth/hooks";
import { useConnectToNotion } from "../../integrations/notion/hooks";

interface Props {
  organizationId: string;
  mode?: "all" | "import";
  onCreate?(): void;
}

export const ImportWorkspaceDropdown: FC<Props> = ({
  organizationId,
  children,
  mode = "import",
  onCreate,
}) => {
  const router = useRouter();
  const organization = useOrganization(organizationId);
  const hasGithubIntegration = !!useOrganizationIntegrations(
    organizationId,
    OrganizationIntegrationType.GITHUB
  )?.length;

  const createSession = useCreateThreepidAuthSession();
  const connectToNotion = useConnectToNotion();

  const goToNotionOauthFlow = useCallback(async () => {
    if (!organization) return;
    await connectToNotion(
      organization.id,
      `${organization.permalink}/import/notion`
    );
  }, [connectToNotion, organization]);
  const goToTrelloOauthFlow = useCallback(async () => {
    if (!organization) return;
    const sessionId = await createSession(
      ThreepidAuthSessionType.TRELLO,
      `${organization.permalink}/import/trello`
    );
    const url = `${Constants.GRAPHQL_API_URL}/auth/trello?state=${sessionId}`;
    window.location.href = url;
  }, [createSession, organization]);

  const createConnectToGithubUrl = useConnectToGithubUrlFn();
  const goToGithubOauthFlow = useCallback(() => {
    if (!organization) return;
    const redirectUrl = `${router.asPath}/import/github`;
    if (hasGithubIntegration) {
      router.push(redirectUrl);
    } else {
      window.location.href = createConnectToGithubUrl(organization.id, {
        appUrl: redirectUrl,
      });
    }
  }, [router, hasGithubIntegration, organization, createConnectToGithubUrl]);

  if (!organization) return null;
  return (
    <Dropdown
      trigger={["click"]}
      placement="bottom"
      overlay={
        <Menu>
          {mode === "all" && (
            <>
              <Menu.Item icon={<PlusOutlined />} onClick={onCreate}>
                Create without importing
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item onClick={goToNotionOauthFlow}>
            <Space>
              <NotionIcon />
              Import tasks from Notion
            </Space>
          </Menu.Item>
          <Menu.Item onClick={goToTrelloOauthFlow}>
            <Space>
              <TrelloIcon />
              Import tasks from Trello
            </Space>
          </Menu.Item>
          <Menu.Item onClick={goToGithubOauthFlow}>
            <Space>
              <GithubOutlined />
              1-1 Sync with Github
              <Tooltip title="Everything that happens in Dework syncs w Github, and vice versa. Creating tasks in Dework creates open issues in Github - merging the branch in Github moves the task to Done in Dework, etc.">
                <QuestionCircleOutlined className="ant-form-item-tooltip" />
              </Tooltip>
            </Space>
          </Menu.Item>
        </Menu>
      }
    >
      {children}
    </Dropdown>
  );
};
