import {
  ApolloClient,
  ApolloError,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import * as Mutations from "@dewo/app/graphql/mutations";
import * as Queries from "@dewo/app/graphql/queries";
import {
  CreateTaskApplicationInput,
  CreateTaskMutation,
  CreateTaskMutationVariables,
  CreateTaskTagInput,
  CreateTaskTagMutation,
  CreateTaskTagMutationVariables,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  GetWorkspaceTasksQuery,
  GetWorkspaceTasksQueryVariables,
  GetTaskQuery,
  GetTaskQueryVariables,
  Task,
  TaskTag,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
  UserTasksQuery,
  UserTasksQueryVariables,
  TaskReward,
  UpdateTaskInput,
  TaskReactionInput,
  CreateTaskReactionMutation,
  CreateTaskReactionMutationVariables,
  DeleteTaskReactionMutation,
  DeleteTaskReactionMutationVariables,
  GetTaskReactionUsersQuery,
  GetTaskReactionUsersQueryVariables,
  OrganizationTag,
  CreateTaskApplicationMutation,
  CreateTaskApplicationMutationVariables,
  TaskStatus,
  CreateTaskSubmissionInput,
  CreateTaskSubmissionMutation,
  CreateTaskSubmissionMutationVariables,
  UpdateTaskSubmissionInput,
  UpdateTaskSubmissionMutation,
  UpdateTaskSubmissionMutationVariables,
  CreateTaskDiscordLinkMutation,
  CreateTaskDiscordLinkMutationVariables,
  CreateTaskInput,
  UpdateTaskTagInput,
  UpdateTaskTagMutation,
  UpdateTaskTagMutationVariables,
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables,
  RulePermission,
  RoleWithRules,
  GetTaskCountQuery,
  GetTaskCountQueryVariables,
  CountTasksInput,
  UpdateTaskApplicationMutation,
  UpdateTaskApplicationMutationVariables,
  UpdateTaskApplicationInput,
  TaskDetails,
  GetTaskDetailsQuery,
  GetTaskDetailsQueryVariables,
  PaymentToken,
  PaymentNetwork,
  CompensationFrequency,
} from "@dewo/app/graphql/types";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { formatFixed } from "@ethersproject/bignumber";
import { useWorkspace, useSetTaskGatingDefault } from "../workspace/hooks";
import { TaskFormValues } from "./form/types";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useOrganizationRoles, useSyncRules } from "../rbac/hooks";
import { hasRule } from "../rbac/util";
import { Constants } from "@dewo/app/util/constants";
import { usePermissionFn } from "@dewo/app/contexts/PermissionsContext";
import { AtLeast } from "@dewo/app/types/general";

export const formatTaskReward = (
  reward: {
    amount: string;
    token: PaymentToken;
    peggedToUsd?: boolean;
    type?: CompensationFrequency | null;
    count?: number | null;
  },
  network?: PaymentNetwork,
  { showFull, summarize } = { showFull: false, summarize: false }
) => {
  const networkSuffix = !!network ? ["on", network.name] : [];
  const showHourly = !summarize && reward.type === CompensationFrequency.HOUR;

  const partAmount = parseFloat(
    typeof reward.amount === "string"
      ? formatFixed(
          reward.amount,
          reward.peggedToUsd
            ? Constants.NUM_DECIMALS_IN_USD_PEG
            : reward.token.exp
        )
      : reward.amount
  );
  const amount = showHourly ? partAmount : partAmount * (reward.count || 1);

  if (reward.peggedToUsd) {
    return [
      !!showFull && !!showHourly && `${reward.count} hours ×`,
      "$" + amount,
      "in",
      reward.token.symbol,
      !!showHourly && "/ hour",
      ...networkSuffix,
    ]
      .filter((a) => a !== false)
      .join(" ");
  } else {
    return [
      !!showFull && !!showHourly && `${reward.count} hours ×`,
      _.round(amount, 6),
      reward.token.symbol,
      !!showHourly && "/ hour",
      ...networkSuffix,
    ]
      .filter((a) => a !== false)
      .join(" ");
  }
};

export const formatTaskRewardAsUSD = (
  reward: AtLeast<TaskReward, "amount" | "token">
): string | undefined => {
  if (!reward.token.usdPrice) return undefined;

  const amount = reward.peggedToUsd
    ? Number(formatFixed(reward.amount, Constants.NUM_DECIMALS_IN_USD_PEG))
    : Number(formatFixed(reward.amount, reward.token.exp)) *
      reward.token.usdPrice;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const calculateTaskRewardAsUSD = (
  reward: TaskReward | undefined
): number | undefined => {
  if (!reward?.token.usdPrice) return undefined;
  if (reward.peggedToUsd) {
    return Number(
      formatFixed(reward.amount, Constants.NUM_DECIMALS_IN_USD_PEG)
    );
  } else {
    const amount = Number(formatFixed(reward.amount, reward.token.exp));
    return amount * reward.token.usdPrice;
  }
};

const clearPaginatedTaskQueries = (apolloClient: ApolloClient<unknown>) => {
  const hackyDurationForElasticsearchToIndexTask = 2000;
  setTimeout(() => {
    apolloClient.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getPaginatedTasks",
    });
  }, hackyDurationForElasticsearchToIndexTask);
};

export function useAddTaskToApolloCache(
  options: {
    resetTasksCache?: boolean;
  } = {
    resetTasksCache: true,
  }
): (task: Task) => void {
  const apolloClient = useApolloClient();
  return useCallback(
    (task: Task) => {
      if (options.resetTasksCache) {
        clearPaginatedTaskQueries(apolloClient);
      }

      const isDoneAssignedAndHasReward =
        !!task.assignees.length &&
        !!task.rewards.length &&
        task.status === TaskStatus.DONE;
      if (
        !task.template &&
        (!task.parentTaskId || isDoneAssignedAndHasReward)
      ) {
        try {
          const data = apolloClient.readQuery<
            GetWorkspaceTasksQuery,
            GetWorkspaceTasksQueryVariables
          >({
            query: Queries.workspaceTasks,
            variables: { workspaceId: task.workspaceId },
          });

          if (!!data && !data.workspace.tasks.some((t) => t.id === task.id)) {
            apolloClient.writeQuery({
              query: Queries.workspaceTasks,
              variables: { workspaceId: task.workspaceId },
              data: {
                workspace: {
                  ...data.workspace,
                  tasks: [task, ...data.workspace.tasks],
                },
              },
            });
          }
        } catch {}
      }

      task.assignees.forEach((user) => {
        try {
          const data = apolloClient.readQuery<
            UserTasksQuery,
            UserTasksQueryVariables
          >({
            query: Queries.userTasks,
            variables: { id: user.id },
          });

          if (!!data && !data.user.tasks.some((t) => t.id === task.id)) {
            apolloClient.writeQuery({
              query: Queries.userTasks,
              variables: { userId: user.id },
              data: {
                user: {
                  ...data.user,
                  tasks: [task, ...data.user.tasks],
                },
              },
            });
          }
        } catch {}
      });
    },
    [apolloClient, options.resetTasksCache]
  );
}

export function useCreateTask(): (input: CreateTaskInput) => Promise<Task> {
  const [mutation] = useMutation<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >(Mutations.createTask);
  const addTaskToApolloCache = useAddTaskToApolloCache();
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      addTaskToApolloCache(res.data.task);
      return res.data.task;
    },
    [mutation, addTaskToApolloCache]
  );
}

export function useCreateTaskFromFormValues(): (
  values: TaskFormValues,
  workspaceId: string
) => Promise<Task> {
  const { user } = useAuthContext();
  const createTask = useCreateTask();
  const createTaskReaction = useCreateTaskReaction();
  const updateTaskRoles = useUpdateTaskRoles();
  const setTaskGatingDefault = useSetTaskGatingDefault();

  return useCallback(
    async (
      {
        subtasks,
        claimRoleIds,
        applyRoleIds,
        defaultGating,
        notionPageId,
        ...values
      },
      workspaceId
    ) => {
      const task = await createTask({
        ...values,
        workspaceId,
        name: values.name?.trim(),
        dueDate: values.dueDate?.toISOString(),
        context: { notionPageId },
      });
      if (values.status === TaskStatus.COMMUNITY_SUGGESTIONS) {
        await createTaskReaction({
          taskId: task.id,
          reaction: ":arrow_up_small:",
        });
      }

      if (!!claimRoleIds || !!applyRoleIds) {
        await updateTaskRoles(task, claimRoleIds || [], applyRoleIds || []);
      }

      if (defaultGating) {
        await setTaskGatingDefault({
          workspaceId,
          type: values.gating,
          claimRoleIds: claimRoleIds ?? [],
          applyRoleIds: applyRoleIds ?? [],
        });
      }

      for (const subtask of subtasks ?? []) {
        await createTask({
          parentTaskId: task.id,
          name: subtask.name?.trim(),
          description: subtask.description,
          ownerIds: !!user ? [user.id] : [],
          assigneeIds: subtask.assigneeIds,
          gating: task.gating,
          status: subtask.status,
          tagIds: [],
          workspaceId: task.workspaceId,
          rewards: subtask.rewards,
        });
      }

      return task;
    },
    [
      createTask,
      createTaskReaction,
      updateTaskRoles,
      setTaskGatingDefault,
      user,
    ]
  );
}
const getTaskOptimisticReponse = (
  task: Task | undefined,
  input: UpdateTaskInput
) => {
  if (!task) return undefined;
  return {
    // TODO: find a better way to merge optimistic task updates
    task: _.merge({}, task, _.omit(input, ["rewards"])),
  };
};

export function useUpdateTask(): (
  input: UpdateTaskInput,
  task?: Task
) => Promise<Task> {
  const [mutation] = useMutation<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >(Mutations.updateTask);
  const addTaskToApolloCache = useAddTaskToApolloCache({
    resetTasksCache: false,
  });
  return useCallback(
    async (input, task) => {
      const res = await mutation({
        variables: { input },
        optimisticResponse: getTaskOptimisticReponse(task, input),
      });

      if (!res.data) throw new Error(JSON.stringify(res.errors));
      addTaskToApolloCache(res.data.task);
      return res.data?.task;
    },
    [mutation, addTaskToApolloCache]
  );
}

export function useUpdateTaskFromFormValues(
  task: Task | undefined
): (values: TaskFormValues) => Promise<void> {
  const updateTask = useUpdateTask();
  const updateTaskRoles = useUpdateTaskRoles();
  return useCallback(
    async ({
      subtasks,
      claimRoleIds,
      applyRoleIds,
      ...values
    }: TaskFormValues) => {
      if (!_.isEmpty(values)) {
        const dueDate =
          values.dueDate === null ? null : values.dueDate?.toISOString();
        await updateTask(
          {
            id: task!.id,
            ...values,
            name: values.name?.trim(),
            dueDate,
          },
          task!
        );
      }

      if (!!claimRoleIds || !!applyRoleIds) {
        await updateTaskRoles(task!, claimRoleIds, applyRoleIds);
      }
    },
    [updateTask, updateTaskRoles, task]
  );
}

export function useCreateTaskDiscordLink(): (
  taskId: string
) => Promise<string> {
  const [mutation] = useMutation<
    CreateTaskDiscordLinkMutation,
    CreateTaskDiscordLinkMutationVariables
  >(Mutations.createTaskDiscordLink);
  return useCallback(
    async (taskId) => {
      const res = await mutation({ variables: { taskId } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.link;
    },
    [mutation]
  );
}

export function useCreateTaskApplication(): (
  input: CreateTaskApplicationInput
) => Promise<CreateTaskApplicationMutation["application"]> {
  const [mutation] = useMutation<
    CreateTaskApplicationMutation,
    CreateTaskApplicationMutationVariables
  >(Mutations.createTaskApplication);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.application;
    },
    [mutation]
  );
}

export function useUpdateTaskApplication(): (
  input: UpdateTaskApplicationInput
) => Promise<Task> {
  const [mutation] = useMutation<
    UpdateTaskApplicationMutation,
    UpdateTaskApplicationMutationVariables
  >(Mutations.updateTaskApplication);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.task;
    },
    [mutation]
  );
}

export function useCreateTaskSubmission(): (
  input: CreateTaskSubmissionInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateTaskSubmissionMutation,
    CreateTaskSubmissionMutationVariables
  >(Mutations.createTaskSubmission);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useUpdateTaskSubmission(): (
  input: UpdateTaskSubmissionInput
) => Promise<void> {
  const [mutation] = useMutation<
    UpdateTaskSubmissionMutation,
    UpdateTaskSubmissionMutationVariables
  >(Mutations.updateTaskSubmission);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useCreateTaskReaction(): (
  input: TaskReactionInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateTaskReactionMutation,
    CreateTaskReactionMutationVariables
  >(Mutations.createTaskReaction);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useDeleteTaskReaction(): (
  input: TaskReactionInput
) => Promise<void> {
  const [mutation] = useMutation<
    DeleteTaskReactionMutation,
    DeleteTaskReactionMutationVariables
  >(Mutations.deleteTaskReaction);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useDeleteTask(): (taskId: string) => Promise<void> {
  const apolloClient = useApolloClient();
  const [deleteTask] = useMutation<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >(Mutations.deleteTask);
  return useCallback(
    async (taskId) => {
      const res = await deleteTask({
        variables: { taskId },
        optimisticResponse: {
          task: {
            __typename: "Task",
            id: taskId,
            deletedAt: new Date().toISOString(),
          },
        },
      });

      clearPaginatedTaskQueries(apolloClient);

      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [deleteTask, apolloClient]
  );
}

export function useCreateTaskTag(): (
  input: CreateTaskTagInput
) => Promise<TaskTag> {
  const [createTaskTag] = useMutation<
    CreateTaskTagMutation,
    CreateTaskTagMutationVariables
  >(Mutations.createTaskTag);
  return useCallback(
    async (input) => {
      const res = await createTaskTag({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.taskTag;
    },
    [createTaskTag]
  );
}

export function useUpdateTaskTag(): (
  input: UpdateTaskTagInput
) => Promise<TaskTag> {
  const [createTaskTag] = useMutation<
    UpdateTaskTagMutation,
    UpdateTaskTagMutationVariables
  >(Mutations.updateTaskTag);
  return useCallback(
    async (input) => {
      const res = await createTaskTag({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.taskTag;
    },
    [createTaskTag]
  );
}

export function useGenerateRandomTagColor(
  existingTags: TaskTag[] | OrganizationTag[] | undefined
): () => string {
  return useCallback(() => {
    const colors = [
      "red",
      "green",
      "gold",
      "geekblue",

      "volcano",
      "cyan",
      "yellow",
      "purple",

      "orange",
      "blue",
      "lime",
      "magenta",
    ];

    const unusedColors = colors.filter(
      (color) => !existingTags?.some((tag) => tag.color === color)
    );
    if (!!unusedColors.length) return unusedColors[0];
    return colors[Math.floor(Math.random() * colors.length)];
  }, [existingTags]);
}

export function useTask(taskId: string | undefined): Task | undefined {
  const { data } = useQuery<GetTaskQuery, GetTaskQueryVariables>(
    Queries.getTask,
    { variables: { taskId: taskId! }, skip: !taskId }
  );
  return data?.task ?? undefined;
}

export function useTaskDetails(
  taskId: string | undefined,
  fetchPolicy?: WatchQueryFetchPolicy
): {
  task: TaskDetails | undefined;
  error: ApolloError | undefined;
  refetch: () => Promise<unknown>;
} {
  const { data, error, refetch } = useQuery<
    GetTaskDetailsQuery,
    GetTaskDetailsQueryVariables
  >(Queries.getTaskDetails, {
    variables: { taskId: taskId! },
    skip: !taskId,
    fetchPolicy,
  });
  const task = data?.task ?? undefined;
  if (!task || task.id !== taskId) return { task: undefined, error, refetch };
  return { task, error, refetch };
}

export function useTaskCount(filter: CountTasksInput): number | undefined {
  const { data } = useQuery<GetTaskCountQuery, GetTaskCountQueryVariables>(
    Queries.countTasks,
    { variables: { filter } }
  );
  return data?.count;
}

export function useLazyTaskReactionUsers(taskId: string) {
  return useLazyQuery<
    GetTaskReactionUsersQuery,
    GetTaskReactionUsersQueryVariables
  >(Queries.taskReactionUsers, { variables: { taskId }, ssr: false });
}

export function useTaskRoles(task: Task | undefined): {
  applyRoles: RoleWithRules[] | undefined;
  claimRoles: RoleWithRules[] | undefined;
} {
  const { workspace } = useWorkspace(task?.workspaceId);
  const roles = useOrganizationRoles(workspace?.organizationId);
  return useMemo(
    () => ({
      applyRoles: roles?.filter(
        (r) =>
          !!task &&
          hasRule(r, RulePermission.APPLY_TO_TASKS, { taskId: task.id })
      ),
      claimRoles: roles?.filter(
        (r) =>
          !!task && hasRule(r, RulePermission.MANAGE_TASKS, { taskId: task.id })
      ),
    }),
    [roles, task]
  );
}

export function useUpdateTaskRoles(): (
  task: Task,
  claimRoleIds: string[] | undefined,
  applyRoleIds: string[] | undefined
) => Promise<void> {
  const apolloClient = useApolloClient();
  const syncRules = useSyncRules();
  return useCallback(
    async (task, claimRoleIds, applyRoleIds) => {
      const organizationId = await apolloClient
        .query<GetWorkspaceQuery, GetWorkspaceQueryVariables>({
          query: Queries.getWorkspace,
          fetchPolicy: "cache-first",
          variables: { workspaceId: task.workspaceId },
        })
        .then((res) => res.data.workspace.organizationId);

      if (claimRoleIds) {
        await syncRules({
          roleIds: claimRoleIds,
          userIds: [],
          organizationId,
          permission: RulePermission.MANAGE_TASKS,
          context: { taskId: task.id },
        });
      }
      if (applyRoleIds) {
        await syncRules({
          roleIds: applyRoleIds ?? [],
          userIds: [],
          organizationId,
          permission: RulePermission.APPLY_TO_TASKS,
          context: { taskId: task.id },
          disallowEveryoneElse: true,
        });
      }
    },
    [syncRules, apolloClient]
  );
}

export const useCanChangeTask = (
  values: Partial<TaskFormValues>,
  workspaceId: string,
  mode: "create" | "update"
) => {
  const hasPermission = usePermissionFn();
  return useCallback(
    (field: keyof TaskFormValues | `status[${TaskStatus}]`) =>
      hasPermission(
        mode,
        {
          __typename: "Task",
          status: TaskStatus.TODO,
          ...values,
          dueDate: values.dueDate?.toISOString(),
          owners: values.ownerIds?.map((id): any => ({ id })) ?? [],
          assignees: values.assigneeIds?.map((id): any => ({ id })) ?? [],
          subtasks: [],
          rewards: [],
          workspaceId,
        },
        field
      ),
    [hasPermission, mode, values, workspaceId]
  );
};
