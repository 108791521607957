import gql from "graphql-tag";
import { organization } from "./organization";
import { skill } from "./skill";
import { workspace } from "./workspace";

export const node = gql`
  fragment GraphNode on GraphNode {
    id
    type
    name
    icon
    imageUrl
    createdAt
    permalink
  }
`;

export const nodeDetails = gql`
  fragment GraphNodeDetails on GraphNode {
    ...GraphNode
    description
    organization {
      ...Organization
    }
    workspace {
      ...Workspace
    }
    skill {
      ...Skill
    }
  }

  ${node}
  ${organization}
  ${workspace}
  ${skill}
`;

export const nodeChildrenDetails = gql`
  fragment GraphNodeChildrenDetails on GraphNode {
    ...GraphNode
    children {
      sortKey
      node: child {
        ...GraphNodeDetails
      }
    }
  }

  ${node}
  ${nodeDetails}
`;
