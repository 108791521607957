import gql from "graphql-tag";
import { user } from "./user";

export const threadMessage = gql`
  fragment ThreadMessage on ThreadMessage {
    id
    createdAt
    content
    threadId
    senderId
    sender {
      ...User
    }
  }

  ${user}
`;

export const thread = gql`
  fragment Thread on Thread {
    id
    createdAt
    messages {
      ...ThreadMessage
    }
    # for permissions
    task {
      id
      workspaceId
    }
    workspace {
      id
    }
  }

  ${threadMessage}
`;
