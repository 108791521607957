import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { useOrganizationTokens } from "@dewo/app/containers/organization/hooks";
import { TokenSelect } from "@dewo/app/containers/payment/token/TokenSelect";
import { CompensationFrequency } from "@dewo/app/graphql/types";
import { Form, InputNumber } from "antd";
import { InternalNamePath } from "antd/lib/form/interface";
import React, { FC, useMemo } from "react";
import { PegToUsdCheckbox } from "./PegToUsdCheckbox";
import { TaskRewardHourlyFormItems } from "./TaskRewardHourlyFormItems";

interface Props {
  path?: InternalNamePath;
  name: string | number;
  organizationId: string;
  showAddToken?: boolean;
  onClear?(): void;
  type?: CompensationFrequency;
  canSetToken?: boolean;
  countLabel?: string;
  amountLabel?: string;
  hourlyRateDisabled?: boolean;
}

export const TaskRewardFormItem: FC<Props> = ({
  name,
  path = [name],
  organizationId,
  showAddToken = true,
  onClear,
  type = CompensationFrequency.FIXED,
  canSetToken = true,
  countLabel,
  amountLabel = "Amount",
  hourlyRateDisabled,
}) => {
  const tokens = useOrganizationTokens(organizationId);

  const form = Form.useFormInstance();
  const tokenId = Form.useWatch([...path, "tokenId"], form);
  const peggedToUsd = Form.useWatch([...path, "peggedToUsd"], form);
  const token = useMemo(
    () => tokens?.find((t) => t.id === tokenId),
    [tokens, tokenId]
  );

  return (
    <>
      <Form.Item label="Token" name={[name, "tokenId"]} hidden={!canSetToken}>
        <TokenSelect
          allowClear
          showAddToken={showAddToken}
          organizationId={organizationId}
          onClear={onClear}
        />
      </Form.Item>
      <HeadlessCollapse expanded={!!token}>
        {type === CompensationFrequency.HOUR ? (
          <TaskRewardHourlyFormItems
            name={name}
            path={path}
            token={token!}
            countLabel={countLabel}
            hourlyRateDisabled={hourlyRateDisabled}
          />
        ) : (
          <Form.Item name={[name, "amount"]} label={amountLabel}>
            <InputNumber
              className="w-full"
              placeholder="Enter amount..."
              addonAfter={
                !!token
                  ? peggedToUsd
                    ? `$ in ${token.symbol}`
                    : token.symbol
                  : undefined
              }
            />
          </Form.Item>
        )}
      </HeadlessCollapse>

      <Form.Item hidden={!token || !canSetToken} name={[name, "peggedToUsd"]}>
        {!!token && <PegToUsdCheckbox token={token} />}
      </Form.Item>
    </>
  );
};
