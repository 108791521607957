import { useMutation } from "@apollo/client";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import {
  createUserPrompt,
  updateUserPrompt,
} from "@dewo/app/graphql/mutations/prompt";
import {
  CreateUserPromptFlow,
  CreateUserPromptMutation,
  CreateUserPromptMutationVariables,
  ThreepidSource,
  UpdateUserPromptInput,
  UpdateUserPromptMutation,
  UpdateUserPromptMutationVariables,
  UserDetails,
} from "@dewo/app/graphql/types";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import _ from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { CreateOrJoinCollective } from "./announcements/CreateOrJoinCollective";
import { OnboardingOrganizationInvite } from "./onboarding/organization/OnboardingOrganizationInvite";
import {
  OnboardingOrganizationProfile,
  OnboardingOrganizationProfileWithMainWorkspace,
  OnboardingOrganizationProfileWithOnboardingTasks,
} from "./onboarding/organization/OnboardingOrganizationProfile";
import { OnboardingOrganizationTasks } from "./onboarding/organization/OnboardingOrganizationTasks";
import { OnboardingConnectDiscord } from "./onboarding/user/OnboardingConnectDiscord";
import { OnboardingConnectWallet } from "./onboarding/user/OnboardingConnectWallet";
import { OnboardingDone } from "./onboarding/user/OnboardingDone";
import { OnboardingProfile } from "./onboarding/user/OnboardingProfile";
import { OnboardingSkills } from "./onboarding/user/OnboardingSkills";
import { OnboardingUseCase } from "./onboarding/user/OnboardingUseCase";
import { RankingClosestUsers } from "./ranking/RankingClosestUsers";
import { ConnectWalletToReceiveTaskReward } from "./task/ConnectWalletToReceiveTaskReward";
import { UserPromptStep } from "./types";

const UserPromptStepMapping: Record<
  string,
  (user: UserDetails, routerQuery: Record<string, any>) => UserPromptStep[]
> = {
  "Onboarding.v2.ConnectWallet": (_user, routerQuery) => {
    const isViewingOrApplyingToTask =
      !!routerQuery.taskId || !!routerQuery.applyToTaskId;
    return [
      { name: "profile", component: OnboardingProfile },
      { name: "wallet", component: OnboardingConnectWallet },
      { name: "skills", component: OnboardingSkills },
      !isViewingOrApplyingToTask && { name: "done", component: OnboardingDone },
    ].filter((step): step is UserPromptStep => !!step);
  },
  "Onboarding.v2.ConnectDiscord": (user, routerQuery) => {
    const isConnectedToDiscord = user.threepids.some(
      (t) => t.source === ThreepidSource.discord
    );
    const isViewingOrApplyingToTask =
      !!routerQuery.taskId || !!routerQuery.applyToTaskId;
    return [
      { name: "profile", component: OnboardingProfile },
      !isConnectedToDiscord && {
        name: "discord",
        component: OnboardingConnectDiscord,
      },
      { name: "skills", component: OnboardingSkills },
      !isViewingOrApplyingToTask && { name: "done", component: OnboardingDone },
    ].filter((step): step is UserPromptStep => !!step);
  },
  "Onboarding.v3.ConnectWallet": (_user, routerQuery) => {
    const isViewingOrApplyingToTask =
      !!routerQuery.taskId || !!routerQuery.applyToTaskId;
    return [
      { name: "profile", component: OnboardingProfile },
      !isViewingOrApplyingToTask && {
        name: "use-case",
        component: OnboardingUseCase,
      },
      { name: "wallet", component: OnboardingConnectWallet },
      !isViewingOrApplyingToTask && { name: "done", component: OnboardingDone },
    ].filter((step): step is UserPromptStep => !!step);
  },
  "Onboarding.v3.ConnectDiscord": (user, routerQuery) => {
    const isConnectedToDiscord = user.threepids.some(
      (t) => t.source === ThreepidSource.discord
    );
    const isViewingOrApplyingToTask =
      !!routerQuery.taskId || !!routerQuery.applyToTaskId;
    return [
      { name: "profile", component: OnboardingProfile },
      !isViewingOrApplyingToTask && {
        name: "use-case",
        component: OnboardingUseCase,
      },
      !isConnectedToDiscord && {
        name: "discord",
        component: OnboardingConnectDiscord,
      },
      !isViewingOrApplyingToTask && { name: "done", component: OnboardingDone },
    ].filter((step): step is UserPromptStep => !!step);
  },
  "Task.v1.ConnectWalletToReceiveReward": (user) => {
    if (user.threepids.some((t) => t.source === ThreepidSource.metamask)) {
      return [];
    }
    return [
      {
        name: "task-reward-wallet",
        component: ConnectWalletToReceiveTaskReward,
      },
    ];
  },
  "UserRanking.v1.RankingClosestUsers": () => [
    { name: "ranking", component: RankingClosestUsers },
  ],
  "Collectives.v1.CreateOrJoinForms": () => [
    { name: "collectives", component: CreateOrJoinCollective },
  ],
  "OrganizationOnboarding.v1.Profile": () => [
    {
      name: "profile",
      component: OnboardingOrganizationProfileWithMainWorkspace,
    },
  ],
  "OrganizationOnboarding.v1.ProfileAndOnboardingTasks": () => [
    {
      name: "org-profile-tasks",
      component: OnboardingOrganizationProfileWithOnboardingTasks,
    },
  ],
  "OrganizationOnboarding.v1.ProfileCreateTasksAndInvite": () => [
    { name: "profile", component: OnboardingOrganizationProfile },
    {
      name: "tasks",
      component: OnboardingOrganizationTasks,
      progress: true,
      back: false,
    },
    {
      name: "invite",
      component: OnboardingOrganizationInvite,
      progress: true,
    },
  ],
};

export function usePrompt(): {
  step: UserPromptStep | undefined;
  steps: UserPromptStep[];
  onNext(state?: Scalar.JSONObject): Promise<void>;
  onPrev(): Promise<void>;
  onCancel(): Promise<void>;
} {
  const router = useRouter();
  const routerRef = useRef(router);
  routerRef.current = router;

  const { logEvent } = useAmplitude();

  const { user } = useAuthContext();
  const prompt = useMemo(
    () =>
      _.sortBy(
        user?.prompts.filter((p) => !p.completedAt),
        (p) => p.createdAt
      )[0],
    [user?.prompts]
  );

  const steps = useMemo(
    () =>
      (!!user
        ? UserPromptStepMapping[prompt?.type!]?.(user, router.query)
        : undefined) ?? [],
    [prompt?.type, user, router.query]
  );

  const currentStepName = router.query.step as string | undefined;
  const currentStep = steps.find((s) => s.name === currentStepName);

  const isSigningIn = !!router.query.threepidId;
  const shouldShow = useMemo(
    () => !!prompt && !isSigningIn,
    [prompt, isSigningIn]
  );

  const updatePrompt = useUpdateUserPrompt();
  const onNext = useCallback(
    async (state?: Scalar.JSONObject) => {
      const stepIndex = steps.findIndex((s) => s.name === currentStepName);
      if (stepIndex === steps.length - 1) {
        await updatePrompt({
          id: prompt?.id!,
          completedAt: new Date().toISOString(),
          state,
        });
      } else if (!!state) {
        await updatePrompt({ id: prompt?.id!, state });
      }

      const nextStep = steps[stepIndex + 1] as UserPromptStep | undefined;
      if (!!nextStep) {
        routerRef.current.push({
          query: { ...routerRef.current.query, step: nextStep.name },
        });
      } else if (!!currentStepName) {
        routerRef.current.push({
          query: _.omit(routerRef.current.query, "step"),
        });
      }

      logEvent("Prompt step changed", {
        type: prompt?.type,
        step: nextStep?.name,
        direction: "next",
      });
    },
    [logEvent, updatePrompt, currentStepName, steps, prompt?.id, prompt?.type]
  );

  const onPrev = useCallback(async () => {
    const stepIndex = steps.findIndex((s) => s.name === currentStepName);
    const prevStep = steps[stepIndex - 1];
    router.push({ query: { ...router.query, step: prevStep.name } });
    logEvent("Prompt step changed", {
      type: prompt?.type,
      step: prevStep.name,
      direction: "prev",
    });
  }, [logEvent, steps, router, currentStepName, prompt?.type]);

  const onCancel = useCallback(async () => {
    await updatePrompt({
      id: prompt?.id!,
      completedAt: new Date().toISOString(),
    });
    routerRef.current.push({ query: _.omit(routerRef.current.query, "step") });
    logEvent("Cancel user prompt", {
      type: prompt?.type,
      step: currentStep?.name,
    });
  }, [logEvent, updatePrompt, currentStep, prompt?.type, prompt?.id]);

  useEffect(() => {
    if (shouldShow && !currentStepName) {
      onNext();
    }
  }, [shouldShow, currentStepName, onNext]);

  return { step: currentStep, steps, onNext, onPrev, onCancel };
}

export function useUpdateUserPrompt(): (
  input: UpdateUserPromptInput
) => Promise<void> {
  const [mutation] = useMutation<
    UpdateUserPromptMutation,
    UpdateUserPromptMutationVariables
  >(updateUserPrompt);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useCreateUserPrompt(): (
  flow: CreateUserPromptFlow
) => Promise<void> {
  const [mutation] = useMutation<
    CreateUserPromptMutation,
    CreateUserPromptMutationVariables
  >(createUserPrompt);
  return useCallback(
    async (flow) => {
      const res = await mutation({ variables: { flow } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}
