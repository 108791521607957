import { RoleSelect } from "@dewo/app/components/form/RoleSelect";
import { useWorkspace } from "@dewo/app/containers/workspace/hooks";
import { useOrganizationRoles } from "@dewo/app/containers/rbac/hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { RulePermission } from "@dewo/app/graphql/types";
import { Form, FormItemProps } from "antd";
import React, { FC, useMemo } from "react";

interface Props extends FormItemProps {
  roleIds?: string[];
  disabled: boolean;
  workspaceId: string;
  name: string;
  showFallback?: boolean;
}

export const TaskRoleSelectField: FC<Props> = ({
  roleIds,
  disabled,
  workspaceId,
  name,
  showFallback,
  ...formItemProps
}) => {
  const canManageRoles = usePermission("create", {
    __typename: "Rule",
    permission: RulePermission.MANAGE_TASKS,
    // @ts-ignore
    __task__: { workspaceId },
  });

  const { workspace } = useWorkspace(workspaceId);
  const roles = useOrganizationRoles(workspace?.organizationId);
  const organizationRoles = useMemo(
    () => roles?.filter((role) => !role.userId),
    [roles]
  );

  if (!workspace || (!canManageRoles && !roleIds?.length)) return null;
  return (
    <Form.Item
      name={name}
      rules={[{ type: "array", min: 1, message: "Select at least one role" }]}
      {...formItemProps}
    >
      <RoleSelect
        roles={organizationRoles}
        organizationId={workspace.organizationId}
        placeholder="Select roles..."
        disabled={disabled || !canManageRoles}
        showFallback={showFallback}
      />
    </Form.Item>
  );
};
