import { useOrganizationWorkspaces } from "@dewo/app/containers/organization/hooks";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { Organization } from "@dewo/app/graphql/types";
import { useMemo } from "react";

export function useNewlyCreatedOrganization() {
  const { user } = useAuthContext();
  return useMemo(
    () =>
      user?.node.children
        .map((edge) => edge.node.organization)
        .slice()
        .reverse()
        .find((o): o is Organization => !!o),
    [user?.node.children]
  );
}

export function useNewlyCreatedWorkspaceAndSection() {
  const organization = useNewlyCreatedOrganization();
  const workspaces = useOrganizationWorkspaces(organization?.id);
  const workspace = workspaces?.node.workspaceChildren[0]?.node.workspace;
  const section = workspaces?.workspaceSections[0];
  return { workspace, section };
}
