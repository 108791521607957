import gql from "graphql-tag";
import { network, payment, token } from "./payment";
import { user } from "./user";

const taskViewFilter = gql`
  fragment TaskViewFilter on TaskViewFilter {
    type
    tagIds
    roleIds
    ownerIds
    assigneeIds
    applicantIds
    statuses
    priorities
    skillIds
    subtasks
    templateIds
    organizationIds
    dueDate {
      gte
      lt
    }
  }
`;

const taskViewFilterSortBy = gql`
  fragment TaskViewSortBy on TaskViewSortBy {
    field
    direction
  }
`;

export const taskView = gql`
  fragment TaskView on TaskView {
    id
    name
    slug
    type
    icon
    groupBy
    permalink
    workspaceId
    workspaceSectionId
    organizationId
    userId
    fields
    sortKey
    filters {
      ...TaskViewFilter
    }
    sortBys {
      ...TaskViewSortBy
    }
  }

  ${taskViewFilter}
  ${taskViewFilterSortBy}
`;

export const subtask = gql`
  fragment Subtask on Task {
    id
    name
    status
    sortKey
  }
`;

export const taskTag = gql`
  fragment TaskTag on TaskTag {
    id
    label
    color
    createdAt
    deletedAt
    workspaceId
  }
`;

export const taskReward = gql`
  fragment TaskReward on TaskReward {
    id
    amount
    peggedToUsd
    fundingSessionId
    token {
      ...PaymentToken
      network {
        ...PaymentNetwork
      }
    }
    tokenId
    payments {
      id
      amount
      user {
        ...User
      }
      payment {
        ...Payment
      }
    }
    count
    type
  }

  ${payment}
  ${token}
  ${user}
  ${network}
`;
