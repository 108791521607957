import {
  GraphNodeChildrenDetails,
  GraphNodeDetails,
  WorkspaceStatus,
} from "@dewo/app/graphql/types";
import { Menu, SubMenuProps, Typography } from "antd";
import _ from "lodash";
import React, { FC, useCallback, useMemo } from "react";

interface Props extends SubMenuProps {
  node: GraphNodeDetails & Partial<GraphNodeChildrenDetails>;
  filter?(node: GraphNodeDetails): boolean;
  itemDisabled?(node: GraphNodeDetails): boolean;
  onMenuItemClick(node: GraphNodeDetails): void;
}

export const NestedGraphNodeMenuItem: FC<Props> = ({
  node,
  filter,
  itemDisabled,
  onMenuItemClick,
  ...subMenuProps
}) => {
  const children = !!filter
    ? node.children?.filter((edge) => filter(edge.node))
    : node.children;
  const handleClick = useCallback(
    () => onMenuItemClick(node),
    [onMenuItemClick, node]
  );

  const disabled = useMemo(() => itemDisabled?.(node), [itemDisabled, node]);

  const entity = node.workspace ?? node.organization;
  const name = entity?.name;

  if (!entity) return null;
  if (!children?.length) {
    return (
      <Menu.Item {...subMenuProps} key={node.id} onClick={handleClick}>
        {name}
      </Menu.Item>
    );
  }

  const [archived, active] = _.partition(
    children,
    (edge) => edge.node.workspace?.status === WorkspaceStatus.ARCHIVED
  );

  return (
    <Menu.SubMenu
      {...subMenuProps}
      key={node.id}
      title={name}
      disabled={disabled}
      onTitleClick={handleClick}
    >
      {active.map((edge) => (
        <NestedGraphNodeMenuItem
          key={edge.node.id}
          node={edge.node}
          filter={filter}
          onMenuItemClick={onMenuItemClick}
        />
      ))}
      {!!archived.length && (
        <Menu.ItemGroup
          key={["archived", node.id].join("-")}
          title={
            <Typography.Text
              strong
              type="secondary"
              className="ant-typography-caption"
              style={{ textTransform: "uppercase" }}
            >
              Archived
            </Typography.Text>
          }
        />
      )}
      {archived.map((edge) => (
        <NestedGraphNodeMenuItem
          key={edge.node.id}
          node={edge.node}
          filter={filter}
          onMenuItemClick={onMenuItemClick}
        />
      ))}
    </Menu.SubMenu>
  );
};
