import { Modal } from "antd";
import React, { FC } from "react";
import {
  FormValues,
  WorkspacePaymentMethodForm,
} from "./WorkspacePaymentMethodForm";

interface Props {
  visible: boolean;
  workspaceId: string;
  onClose(): void;
  initialValues?: Partial<FormValues>;
}

export const AddWorkspacePaymentMethodModal: FC<Props> = ({
  workspaceId,
  visible,
  onClose,
  initialValues,
}) => (
  <Modal
    visible={visible}
    title="Add Payment Method"
    footer={null}
    onCancel={onClose}
  >
    <WorkspacePaymentMethodForm
      initialValues={initialValues}
      workspaceId={workspaceId}
      onDone={onClose}
    />
  </Modal>
);
