import React, { FC, useCallback } from "react";
import { TaskDetails } from "@dewo/app/graphql/types";
import { Button, message, Tooltip, Typography } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { useBookmarks } from "../../user/hooks";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import Link from "next/link";

interface Props {
  task: TaskDetails;
}

export const TaskBookmarkButton: FC<Props> = ({ task }) => {
  const { user } = useAuthContext();
  const { bookmarks, create, remove } = useBookmarks(user?.id);
  const isBookmarked = bookmarks?.some((b) => b.task.id === task.id);

  // router is outside of message context https://ant.design/components/message/#FAQ
  const [messageApi, contextHolder] = message.useMessage();

  const handleClick = useCallback(async () => {
    if (isBookmarked) {
      await remove({ variables: { taskIds: [task.id] } });
      return;
    }

    await create({ variables: { taskIds: [task.id] } });
    messageApi.success(
      <Typography.Text>
        Bookmark added.{" "}
        <Link href={`${user?.permalink}/bookmarks`}>
          <Button size="small">See my bookmarks</Button>
        </Link>
      </Typography.Text>
    );
  }, [create, isBookmarked, messageApi, remove, task.id, user?.permalink]);

  return (
    <>
      {contextHolder}
      <Tooltip title="Bookmark Task">
        <Button
          type="text"
          size="large"
          icon={<BookOutlined className={isBookmarked ? "text-primary" : ""} />}
          onClick={handleClick}
        />
      </Tooltip>
    </>
  );
};
