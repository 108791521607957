import React, { ComponentType, CSSProperties, FC } from "react";
import {
  Notification,
  TaskGatingType,
  TaskStatus,
} from "@dewo/app/graphql/types";
import * as Colors from "@ant-design/colors";
import { TaskStatusIcon } from "@dewo/app/components/icons/task/TaskStatus";
import { ApplicationIcon } from "@dewo/app/components/icons/task/Application";
import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  DollarCircleOutlined,
  FormOutlined,
  LinkOutlined,
  SubnodeOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

const CustomIcons = {
  SubnodeOutlined,
  UserAddOutlined,
  CloseCircleOutlined,
  FormOutlined,
  LinkOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  CommentOutlined,
};

export const NotificationListItemIcon: FC<{ notification: Notification }> = ({
  notification,
}) => {
  if (!!notification.icon) {
    if (Object.keys(TaskStatus).includes(notification.icon)) {
      return (
        <TaskStatusIcon status={notification.icon as TaskStatus} size={14} />
      );
    }

    if (notification.icon === TaskGatingType.APPLICATION) {
      return <ApplicationIcon />;
    }

    const IconComponent = CustomIcons[
      notification.icon as any as keyof typeof CustomIcons
    ] as ComponentType<{ style: CSSProperties }>;
    if (!!IconComponent) {
      const color = Colors[(notification.iconColor as "red") ?? "grey"]?.[3];
      return <IconComponent style={{ color }} />;
    }
  }

  return null;
};
