import { Tooltip } from "antd";
import React, { FC, useCallback } from "react";
import { TooltipPropsWithTitle } from "antd/lib/tooltip";

import { useAmplitude } from "../util/analytics/AmplitudeContext";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface Props extends TooltipPropsWithTitle {
  marginLeft?: number;
  readMoreText?: string;
  readMoreUrl?: string;
  name?: string;
}

export const QuestionmarkTooltip: FC<Props> = ({
  readMoreText = "Read More",
  readMoreUrl,
  marginLeft,
  children,
  title,
  name,
  ...tooltipProps
}) => {
  const { logEvent } = useAmplitude();
  const handleEventLogging = useCallback(() => {
    if (!!name) {
      logEvent(`Questionmark tooltip shown: ${name}`);
    }
  }, [logEvent, name]);

  return (
    <Tooltip
      onVisibleChange={handleEventLogging}
      {...tooltipProps}
      title={
        !!readMoreUrl ? (
          <>
            {title}{" "}
            <a href={readMoreUrl} target="_blank" rel="noreferrer">
              {readMoreText}
            </a>
          </>
        ) : (
          title
        )
      }
    >
      {children}
      <QuestionCircleOutlined style={{ marginLeft }} />
    </Tooltip>
  );
};
