import { useOrganizationTokens } from "@dewo/app/containers/organization/hooks";
import { AddTokenButton } from "@dewo/app/containers/payment/token/AddTokenButton";
import { Form, Typography } from "antd";
import React, { FC } from "react";

interface Props {
  enabled: boolean;
  organizationId: string;
}

export const OpenToBidsTokenFormItem: FC<Props> = ({
  enabled,
  organizationId,
}) => {
  const tokens = useOrganizationTokens(organizationId);

  return (
    <Form.Item
      name="tokens"
      rules={[
        {
          required: true,
          validator: async () => {
            if (!tokens.length && enabled) {
              throw new Error("Please add at least one token");
            }
          },
        },
      ]}
    >
      <Typography.Paragraph type="secondary">
        {!!tokens?.length
          ? `Contributors can submit bids in ${tokens
              ?.map((t) => t.symbol)
              .join(", ")}.`
          : "Select which tokens you want to use to accept bids"}{" "}
        {!!organizationId && (
          <AddTokenButton
            type="link"
            size="small"
            organizationId={organizationId}
          />
        )}
      </Typography.Paragraph>
    </Form.Item>
  );
};
