import React, { FC, useCallback } from "react";
import { UserSelect } from "@dewo/app/components/form/UserSelect";
import { FormItemPlaceholder } from "@dewo/app/components/form/FormItemPlaceholder";
import { Button, Form, FormItemProps, Spin } from "antd";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  RelevantUsersModes,
  RulePermission,
  Task,
} from "@dewo/app/graphql/types";
import { LinkOutlined, UserOutlined } from "@ant-design/icons";
import {
  useCopyToClipboardAndShowToast,
  useRunning,
} from "@dewo/app/util/hooks";
import { useCreateInvite } from "@dewo/app/containers/invite/hooks";
import { useRelevantUsers } from "@dewo/app/containers/workspace/hooks";

interface Props extends FormItemProps {
  mode: "create" | "update";
  task: Task | undefined;
  workspaceId: string;
}
export const TaskGatingAssigneeInput: FC<Props> = ({
  mode,
  task,
  workspaceId,
  ...formItemProps
}) => {
  const canChangeAssignees = usePermission(mode, task ?? "Task", "assigneeIds");
  const suggested = useRelevantUsers(
    workspaceId,
    RelevantUsersModes.ASSIGNEES,
    { lazy: !!task, fallback: task?.assignees }
  );

  const copyToClipboardAndShowToast =
    useCopyToClipboardAndShowToast("Invite link copied");
  const createInvite = useCreateInvite();
  const [inviteToTask, inviting] = useRunning(
    useCallback(async () => {
      if (!task) return;
      const inviteLink = await createInvite({
        taskId: task.id,
        permission: RulePermission.VIEW_PROJECTS,
      });
      copyToClipboardAndShowToast(inviteLink);
    }, [createInvite, copyToClipboardAndShowToast, task])
  );

  return (
    <Form.Item name="assigneeIds" label="Assignee" {...formItemProps}>
      <UserSelect
        placeholder={
          <FormItemPlaceholder
            placeholder={
              canChangeAssignees ? "Select assignee..." : "No task assignee..."
            }
            icon={<UserOutlined />}
          />
        }
        disabled={!canChangeAssignees}
        mode="multiple"
        users={suggested.users}
        dropdownRender={(menu) => (
          <>
            {!!suggested.users ? (
              menu
            ) : (
              <Spin
                style={{ display: "grid", placeItems: "center", padding: 8 }}
              />
            )}
            {!!task && (
              <Button
                block
                type="text"
                style={{ textAlign: "left", marginTop: 4 }}
                className="text-secondary"
                loading={inviting}
                icon={<LinkOutlined />}
                children="Invite contributor by link"
                onClick={inviteToTask}
              />
            )}
          </>
        )}
        onDropdownVisibleChange={suggested.load}
      />
    </Form.Item>
  );
};
