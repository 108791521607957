import { Button, Card, Col, Row, Typography } from "antd";
import Link from "next/link";
import React, { FC, useCallback } from "react";
import JoinGraphic from "@dewo/app/assets/collectives/join.jpeg";
import CreateGraphic from "@dewo/app/assets/collectives/create.jpeg";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { UserPromptStepComponentProps } from "../types";

export const CreateOrJoinCollective: FC<UserPromptStepComponentProps> = ({
  onNext,
}) => {
  const { user } = useAuthContext();
  const handleNext = useCallback(() => onNext(), [onNext]);
  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        🏘 Collectives
      </Typography.Title>
      <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
        Collectives are flexible groups of contributors who join forces while
        preserving their independence.
      </Typography.Paragraph>

      <Row gutter={[16, 16]}>
        {[
          {
            title: "Create a collective",
            description:
              "Start a collective, join forces with like-minded people and share work opportunities",
            analyticsId: "User Prompt: Create Collective",
            image: CreateGraphic,
            formLink: "https://tally.so/r/31AzOg",
          },
          {
            title: "Join a collective",
            description:
              "Join an existing collective, get access to new work opportunities, find team-mates",
            analyticsId: "User Prompt: Join Collective",
            image: JoinGraphic,
            formLink: "https://tally.so/r/wgDXgP",
          },
        ].map((tile, index, all) => (
          <Col key={index} span={24} sm={24 / all.length}>
            <Link href={`${tile.formLink}?username=${user?.username ?? ""}`}>
              {/* @ts-ignore */}
              <a name={tile.analyticsId} target="_blank">
                <Card
                  style={{
                    aspectRatio: String(tile.image.width / tile.image.height),
                    background: `url(${tile.image.src})`,
                    backgroundSize: "cover",
                  }}
                  bordered={false}
                  hoverable
                >
                  <Typography.Title level={4} style={{ textAlign: "center" }}>
                    {tile.title}
                  </Typography.Title>
                  <Typography.Paragraph
                    type="secondary"
                    style={{ textAlign: "center" }}
                  >
                    {tile.description}
                  </Typography.Paragraph>
                </Card>
              </a>
            </Link>
          </Col>
        ))}
      </Row>
      <Button
        type="text"
        style={{ marginTop: 20 }}
        className="text-secondary"
        name="Create or Join Collective: close"
        onClick={handleNext}
      >
        Close
      </Button>
    </>
  );
};
