import { Tag, TagProps } from "antd";
import React, { FC } from "react";
import { EditableTag } from "./tags/EditableTag";
import styles from "./StyledTag.module.less";

export const StyledTag: FC<
  {
    editable?: boolean;
    suffix?: JSX.Element;
    secondaryColor?: string;
  } & TagProps
> = ({
  children,
  editable = false,
  color,
  secondaryColor,
  icon,
  suffix,
  ...props
}) => {
  const TagToUse = editable ? EditableTag : Tag;
  const style: React.CSSProperties = !editable
    ? { background: color }
    : { background: `${color}10`, borderColor: color };

  const suffixElem = suffix ? (
    <span className={styles.tagSuffix} style={{ background: secondaryColor }}>
      {suffix}
    </span>
  ) : null;

  return (
    <TagToUse icon={icon} style={style} {...props}>
      <div className={styles.tagContent}>
        {children}
        {suffixElem}
      </div>
    </TagToUse>
  );
};
