import gql from "graphql-tag";
import { userPrompt } from "../fragments/user";

export const updateUserPrompt = gql`
  mutation UpdateUserPromptMutation($input: UpdateUserPromptInput!) {
    prompt: updateUserPrompt(input: $input) {
      ...UserPrompt
      user {
        id
        prompts {
          ...UserPrompt
        }
      }
    }
  }

  ${userPrompt}
`;

export const createUserPrompt = gql`
  mutation CreateUserPromptMutation($flow: CreateUserPromptFlow!) {
    prompt: createUserPrompt(flow: $flow) {
      ...UserPrompt
      user {
        id
        prompts {
          ...UserPrompt
        }
      }
    }
  }

  ${userPrompt}
`;
