import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import {
  createGraphNode,
  deleteGraphEdge,
  updateGraphNode,
  upsertGraphEdge,
} from "@dewo/app/graphql/mutations/graph";
import { organizationBySlug } from "@dewo/app/graphql/queries";
import {
  getGraphNode,
  getGraphNodeBySlug,
} from "@dewo/app/graphql/queries/graph";
import {
  CreateGraphNodeInput,
  CreateGraphNodeMutation,
  CreateGraphNodeMutationVariables,
  DeleteGraphEdgeInput,
  DeleteGraphEdgeMutation,
  DeleteGraphEdgeMutationVariables,
  GetGraphNodeBySlugQuery,
  GetGraphNodeBySlugQueryVariables,
  GetGraphNodeQuery,
  GetGraphNodeQueryVariables,
  GetOrganizationBySlugQuery,
  GetOrganizationBySlugQueryVariables,
  GraphNode,
  GraphNodeType,
  Organization,
  UpdateGraphNodeInput,
  UpdateGraphNodeMutation,
  UpdateGraphNodeMutationVariables,
  UpsertGraphEdgeInput,
  UpsertGraphEdgeMutation,
  UpsertGraphEdgeMutationVariables,
  UserDetails,
} from "@dewo/app/graphql/types";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

export function useCreateGraphNode(): (
  input: CreateGraphNodeInput
) => Promise<GraphNode> {
  const [mutation] = useMutation<
    CreateGraphNodeMutation,
    CreateGraphNodeMutationVariables
  >(createGraphNode);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.node;
    },
    [mutation]
  );
}

export function useUpdateGraphNode(): (
  input: UpdateGraphNodeInput
) => Promise<GraphNode> {
  const [mutation] = useMutation<
    UpdateGraphNodeMutation,
    UpdateGraphNodeMutationVariables
  >(updateGraphNode);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.node;
    },
    [mutation]
  );
}

export function useUpsertGraphEdge(): (
  input: UpsertGraphEdgeInput
) => Promise<void> {
  const [mutation] = useMutation<
    UpsertGraphEdgeMutation,
    UpsertGraphEdgeMutationVariables
  >(upsertGraphEdge);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useDeleteGraphEdge(): (
  input: DeleteGraphEdgeInput
) => Promise<void> {
  const [mutation] = useMutation<
    DeleteGraphEdgeMutation,
    DeleteGraphEdgeMutationVariables
  >(deleteGraphEdge);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useFollowGraphNode(): (nodeId: string) => Promise<void> {
  const { user } = useAuthContext();
  const upsertEdge = useUpsertGraphEdge();
  return useCallback(
    (nodeId) => {
      if (!user) throw new Error("Must be authenticated to follow node");
      return upsertEdge({ parentId: user.node.id, childId: nodeId });
    },
    [user, upsertEdge]
  );
}

export function useUnfollowGraphNode(): (nodeId: string) => Promise<void> {
  const { user } = useAuthContext();
  const deleteEdge = useDeleteGraphEdge();
  return useCallback(
    (nodeId) => {
      if (!user) throw new Error("Must be authenticated to follow node");
      return deleteEdge({ parentId: user.node.id, childId: nodeId });
    },
    [user, deleteEdge]
  );
}

export function useGraphNode(
  id: string | undefined
): GetGraphNodeQuery["node"] | undefined {
  const { data } = useQuery<GetGraphNodeQuery, GetGraphNodeQueryVariables>(
    getGraphNode,
    { variables: { id: id! }, skip: !id }
  );
  return data?.node ?? undefined;
}

export function useGraphNodeBySlug(
  slug: string
): GetGraphNodeQuery["node"] | undefined {
  const { data } = useQuery<
    GetGraphNodeBySlugQuery,
    GetGraphNodeBySlugQueryVariables
  >(getGraphNodeBySlug, { variables: { slug } });
  return data?.node ?? undefined;
}

export function useUserOrganizations(
  user: UserDetails | undefined
): Organization[] | undefined {
  return useMemo(
    () =>
      user?.node.children
        .filter((edge) => edge.node.type === GraphNodeType.ORGANIZATION)
        .map((edge) => edge.node.organization)
        .filter((org): org is Organization => !!org),
    [user?.node.children]
  );
}

export function useGraphNodeFromRouterQuery() {
  const router = useRouter();
  const nodeSlug = router.query.nodeSlug as string;
  return useGraphNodeBySlug(nodeSlug);
}

export function useFetchGraphNodeFromOrganizationSlug(): (
  organizationSlug: string
) => Promise<string | undefined> {
  const [fetch] = useLazyQuery<
    GetOrganizationBySlugQuery,
    GetOrganizationBySlugQueryVariables
  >(organizationBySlug);
  return useCallback(
    async (organizationSlug) => {
      const res = await fetch({ variables: { organizationSlug } });
      return res.data?.organization?.nodeId;
    },
    [fetch]
  );
}
