import { RoleWithRules, Rule, RulePermission } from "@dewo/app/graphql/types";

export interface RuleContext {
  workspaceId?: string;
  fundingSessionId?: string;
  taskId?: string;
  inverted?: boolean;
}

export function getRule(
  role: RoleWithRules,
  permission: RulePermission,
  context: RuleContext = {}
): Rule | undefined {
  return role.rules.find((r) => {
    const matchesFundingSession =
      (r.fundingSessionId ?? undefined) === context.fundingSessionId;
    const matchesWorkspace =
      (r.workspaceId ?? undefined) === context.workspaceId;
    const matchesTask = (r.taskId ?? undefined) === context.taskId;
    const matchesInverted = r.inverted === (context.inverted ?? false);
    return (
      matchesFundingSession &&
      matchesWorkspace &&
      matchesTask &&
      matchesInverted &&
      r.permission === permission
    );
  });
}

export function hasRule(
  role: RoleWithRules,
  permission: RulePermission,
  context: RuleContext = {}
): boolean {
  return !!getRule(role, permission, context);
}
