import { CaretRightOutlined } from "@ant-design/icons";
import { Emoji } from "@dewo/app/components/emoji/Emoji";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { UserSkillSelect } from "@dewo/app/containers/user/UserSkillSelect";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { deworkSocialLinks } from "@dewo/app/util/constants";
import { usePrevious, useRunning, useToggle } from "@dewo/app/util/hooks";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import classNames from "classnames";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { UserPromptStepComponentProps } from "../../types";
import styles from "./Onboarding.module.less";

enum UseCaseType {
  contributor = "contributor",
  coreTeam = "coreTeam",
  projectLeader = "projectLeader",
}

enum UseCaseFeature {
  contributors = "contributors",
  roleGating = "roleGating",
  reporting = "reporting",
  payments = "payments",
  outsource = "outsource",
  findBounties = "findBounties",
  partTimeWork = "partTimeWork",
  fullTimeWork = "fullTimeWork",
  other = "other",
}

const UsageReasonRow: FC<{
  label: string;
  content?: ReactNode;
  description?: string;
  readMoreUrl?: string;
  checked: boolean;
  onChange(checked: boolean): void;
}> = ({ label, description, content, readMoreUrl, checked, onChange }) => {
  const expanded = useToggle();

  const prevChecked = usePrevious(checked);
  useEffect(() => {
    if (!prevChecked && checked) {
      expanded.toggleOn();
    }
  }, [checked, prevChecked, expanded]);
  return (
    <Card
      size="small"
      bordered={false}
      className={classNames(
        styles.usageReasonRow,
        checked && styles.usageReasonRowChecked
      )}
    >
      <Row align="middle" justify="space-between">
        <Checkbox
          checked={checked}
          className="font-semibold"
          onChange={(e) => onChange(e.target.checked)}
        >
          {label}
        </Checkbox>
        <Button
          style={{ marginLeft: 8 }}
          icon={<CaretRightOutlined rotate={expanded.isOn ? 90 : 0} />}
          type="text"
          size="small"
          className="text-secondary"
          onClick={expanded.toggle}
        />
      </Row>
      {!!description && (
        <HeadlessCollapse expanded={expanded.isOn}>
          <Typography.Paragraph
            type="secondary"
            style={{ marginTop: 4, marginBottom: 0 }}
          >
            {description}{" "}
            {!!readMoreUrl && (
              <a href={readMoreUrl} target="_blank" rel="noreferrer">
                Read more
              </a>
            )}
          </Typography.Paragraph>
        </HeadlessCollapse>
      )}
      {!!content && (
        <HeadlessCollapse expanded={expanded.isOn}>
          <div style={{ marginTop: 4 }}>{content}</div>
        </HeadlessCollapse>
      )}
    </Card>
  );
};

export const OnboardingUseCase: FC<UserPromptStepComponentProps> = ({
  onNext,
  active,
}) => {
  const { identify } = useAmplitude();
  const { user } = useAuthContext();

  const [useCase, setUseCase] = useState<UseCaseType>();
  const [useCaseFeatures, setUseCaseFeatures] = useState<UseCaseFeature[]>([]);
  const [otherDescription, setOtherDescription] = useState<string>();

  const handleChangeReason = useCallback(
    (reason: UseCaseFeature, checked: boolean) => {
      setUseCaseFeatures((prev) =>
        checked ? [...prev, reason] : prev.filter((r) => r !== reason)
      );
    },
    []
  );

  const [handleNext, loadingNext] = useRunning(
    useCallback(async () => {
      identify(user?.id!, {
        "Onboarding: type": useCase,
        "Onboarding: features": useCaseFeatures,
        "Onboarding: other": otherDescription,
      });
      await onNext({
        type: useCase,
        features: useCaseFeatures,
        other: otherDescription,
      });
    }, [onNext, useCase, useCaseFeatures, otherDescription, user?.id, identify])
  );
  return (
    <>
      <Typography.Title
        level={2}
        style={{ textAlign: "center", marginTop: 24 }}
      >
        What's your primary role?
      </Typography.Title>
      <Row gutter={[16, 16]} align="middle" style={{ flex: 1 }}>
        {[
          {
            value: UseCaseType.contributor,
            label: "Contributor",
            emoji: ":hammer_and_wrench:",
          },
          {
            value: UseCaseType.coreTeam,
            label: "Core Team",
            emoji: ":globe_with_meridians:",
          },
          {
            value: UseCaseType.projectLeader,
            label: "Project Leader",
            emoji: ":zap:",
          },
        ].map((col) => (
          <Col xs={24} sm={8} key={col.value}>
            <Card
              className={classNames(
                "hover:component-highlight",
                styles.useCaseTile,
                useCase === col.value && styles.useCaseTileActive
              )}
              style={{ textAlign: "center" }}
              onClick={() => setUseCase(col.value)}
            >
              <Typography.Title style={{ margin: 0 }}>
                <Emoji name={col.emoji} />
              </Typography.Title>
              <Typography.Paragraph strong style={{ margin: 0 }}>
                {col.label}
              </Typography.Paragraph>
            </Card>
          </Col>
        ))}
      </Row>

      <HeadlessCollapse expanded={!!useCase && active}>
        <Divider style={{ marginTop: 48, marginBottom: 48 }} />

        <Typography.Title level={4} style={{ textAlign: "center" }}>
          What made you want to try Dework?
        </Typography.Title>

        <Space direction="vertical" style={{ width: "100%" }}>
          {(useCase === UseCaseType.contributor
            ? [
                {
                  value: UseCaseFeature.findBounties,
                  label: "Find one-off bounties",
                  description:
                    "Bounties first - the project doesn't matter too much to you",
                },
                {
                  value: UseCaseFeature.partTimeWork,
                  label: "Work for 2-3 projects",
                  description: "Work part-time for a few different projects",
                },
                {
                  value: UseCaseFeature.fullTimeWork,
                  label: "Become very active in one project",
                  description:
                    "Find one project you want to contribute full-time to",
                },
                {
                  value: UseCaseFeature.other,
                  label: "Other",
                  content: (
                    <Input
                      value={otherDescription}
                      onChange={(e) => setOtherDescription(e.target.value)}
                      placeholder="Tell us more..."
                    />
                  ),
                },
              ]
            : [
                {
                  value: UseCaseFeature.payments,
                  label: "Manage Payments",
                  description:
                    "Handle bounties payments in Dework and batch pay through Gnosis Safe.",
                  readMoreUrl: deworkSocialLinks.gitbook.payments,
                },
                {
                  value: UseCaseFeature.roleGating,
                  label: "Role-gated work",
                  description:
                    "Use role-gating to let contributors pick-up work in a permissionless way.",
                  readMoreUrl: deworkSocialLinks.gitbook.permissions,
                },
                {
                  value: UseCaseFeature.reporting,
                  label: "Reporting",
                  description:
                    "Share with the broader community how your project is progressing, how funds are utilized and when important milestones are hit.",
                },
                {
                  value: UseCaseFeature.contributors,
                  label: "Find Contributors",
                  description:
                    "Grow your contributors talent pool by posting bounties and open roles on the Dework landing page.",
                  readMoreUrl: deworkSocialLinks.gitbook.browsingDaos,
                },
                {
                  value: UseCaseFeature.outsource,
                  label: "Outsource work",
                  description:
                    "Get short-term help for work that can't be carried out by your core team.",
                  readMoreUrl: deworkSocialLinks.gitbook.createBounty,
                },
                {
                  value: UseCaseFeature.other,
                  label: "Other",
                  content: (
                    <Input
                      value={otherDescription}
                      onChange={(e) => setOtherDescription(e.target.value)}
                      placeholder="Tell us more..."
                    />
                  ),
                },
              ]
          ).map((col) => (
            <UsageReasonRow
              key={col.value}
              checked={useCaseFeatures.includes(col.value)}
              onChange={(checked) => handleChangeReason(col.value, checked)}
              description={col.description}
              label={col.label}
              readMoreUrl={col.readMoreUrl}
              content={col.content}
            />
          ))}
        </Space>
      </HeadlessCollapse>
      <HeadlessCollapse
        expanded={
          useCase === UseCaseType.contributor &&
          !!useCaseFeatures.length &&
          active
        }
      >
        <Divider style={{ marginTop: 48, marginBottom: 48 }} />

        <Typography.Title level={4} style={{ textAlign: "center" }}>
          What are your main skills?
        </Typography.Title>
        <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
          Dework will show you matching bounties and tasks based on your skills
        </Typography.Paragraph>

        <UserSkillSelect
          style={{ width: "100%", marginBottom: 32 }}
          placeholder="Select skills"
        />
      </HeadlessCollapse>

      <Row justify="center" style={{ marginTop: 24 }}>
        <HeadlessCollapse expanded={!!useCaseFeatures.length}>
          <Button
            size="large"
            type="primary"
            className="mx-auto"
            loading={loadingNext}
            name="Onboarding Use Case: next"
            onClick={handleNext}
          >
            Next
          </Button>
        </HeadlessCollapse>
      </Row>
    </>
  );
};
