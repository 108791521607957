import React from "react";
import Icon from "@ant-design/icons";

const DiscordSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 256 256">
    <path
      d="M216.856 45.0966C200.285 37.3433 182.566 31.7085 164.042 28.5C161.767 32.6132 159.109 38.1455 157.276 42.5464C137.584 39.585 118.073 39.585 98.743 42.5464C96.9108 38.1455 94.1926 32.6132 91.8972 28.5C73.3526 31.7085 55.6134 37.364 39.0421 45.1377C5.61752 95.6465 -3.44332 144.901 1.08711 193.456C23.256 210.011 44.7404 220.068 65.8621 226.649C71.0772 219.471 75.7284 211.841 79.7352 203.8C72.104 200.901 64.795 197.322 57.8888 193.168C59.721 191.811 61.5131 190.391 63.2446 188.931C105.367 208.633 151.135 208.633 192.755 188.931C194.506 190.391 196.298 191.811 198.11 193.168C191.184 197.343 183.855 200.921 176.224 203.821C180.23 211.841 184.862 219.492 190.097 226.669C211.239 220.088 232.743 210.032 254.912 193.456C260.228 137.168 245.831 88.3662 216.856 45.0966ZM85.4739 163.595C72.829 163.595 62.4592 151.79 62.4592 137.415C62.4592 123.04 72.6076 111.215 85.4739 111.215C98.3405 111.215 108.71 123.019 108.489 137.415C108.509 151.79 98.3405 163.595 85.4739 163.595ZM170.525 163.595C157.88 163.595 147.511 151.79 147.511 137.415C147.511 123.04 157.659 111.215 170.525 111.215C183.392 111.215 193.761 123.019 193.54 137.415C193.54 151.79 183.392 163.595 170.525 163.595Z"
      fill="currentColor"
    />
  </svg>
);

export const DiscordIcon = (props: any) => (
  <Icon component={DiscordSvg} {...props} />
);
