import _ from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { useCreateTaskReaction } from "../hooks";
import { UPVOTE_REACTION } from "./UpvoteCard";

export function useUpvoteTaskFromQueryParam(taskId: string | undefined) {
  const router = useRouter();
  const shouldUpvote = router.query.upvote !== undefined;
  const createReaction = useCreateTaskReaction();

  const autoUpvote = useCallback(async () => {
    await createReaction({ taskId: taskId!, reaction: UPVOTE_REACTION });
    await router.push({
      pathname: router.pathname,
      query: _.omit(router.query, ["upvote"]),
    });
  }, [taskId, createReaction, router]);

  useEffect(() => {
    if (shouldUpvote && !!taskId) autoUpvote();
  }, [shouldUpvote, autoUpvote, taskId]);
}
