import React, { FC, useMemo } from "react";
import { Form, Space, Typography } from "antd";
import { OrganizationIntegrationType } from "@dewo/app/graphql/types";
import { ClaimableIcon } from "@dewo/app/components/icons/task/Claimable";
import { useOrganizationRoles } from "@dewo/app/containers/rbac/hooks";
import { useWorkspace } from "@dewo/app/containers/workspace/hooks";
import { RoleSelect } from "@dewo/app/components/form/RoleSelect";
import { useOrganizationIntegrations } from "@dewo/app/containers/organization/hooks";

export const TaskGatingClaimingInput: FC<{ workspaceId: string }> = ({
  workspaceId,
}) => {
  const { workspace } = useWorkspace(workspaceId);
  const roles = useOrganizationRoles(workspace?.organizationId);
  const organizationRoles = useMemo(
    () => roles?.filter((role) => !role.userId && !role.fallback),
    [roles]
  );

  const hasDiscordIntegration = !!useOrganizationIntegrations(
    workspace?.organizationId,
    OrganizationIntegrationType.DISCORD
  )?.length;

  return (
    <>
      <Space align="center">
        <ClaimableIcon style={{ width: 16 }} />
        <Typography.Text strong>Who can claim?</Typography.Text>
      </Space>
      <Form.Item
        name="claimRoleIds"
        rules={[
          {
            type: "array",
            min: 1,
            message: hasDiscordIntegration
              ? "Select at least one role"
              : "Please connect Discord to enable Direct Claiming",
          },
        ]}
      >
        {!!workspace && (
          <RoleSelect
            roles={organizationRoles}
            organizationId={workspace.organizationId}
            placeholder="Select roles..."
          />
        )}
      </Form.Item>
    </>
  );
};
