import { Col, Input, List, Typography } from "antd";
import React, { FC, useMemo, useState } from "react";
import { TaskBreadcrumb } from "../containers/task/form/TaskBreadcrumb";
import { useTaskViewLayoutData } from "../containers/task/views/hooks";
import {
  SearchTasksInput,
  Task,
  TaskViewSortByDirection,
  TaskViewSortByField,
} from "../graphql/types";
import { usePrevious } from "../util/hooks";
import { TaskStatusIcon } from "./icons/task/TaskStatus";

export const TaskPicker: FC<{
  workspaceId: string;
  onPick: (task: Task) => void;
  placeholder?: string;
  sort?: (a: Task, b: Task) => number;
  filter?: (task: Task) => boolean;
}> = ({
  workspaceId,
  onPick,
  sort,
  filter,
  placeholder = "Search for a task",
}) => {
  const [search, setSearch] = useState("");
  const [data] = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] => [
        {
          sortBy: {
            direction: TaskViewSortByDirection.DESC,
            field: TaskViewSortByField.createdAt,
          },
          name: !!search ? search : undefined,
          workspaceIds: [workspaceId],
        },
      ],
      [search, workspaceId]
    )
  );

  const tasks = useMemo(() => {
    let tasks = data.tasks?.slice();
    if (!tasks) return undefined;

    if (filter) tasks = tasks.filter(filter);
    if (sort) tasks = tasks.sort(sort);
    return tasks.slice(0, 5);
  }, [data.tasks, filter, sort]);

  const previousTasks = usePrevious(tasks);

  return (
    <div>
      <Input
        autoFocus
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: 8, padding: "8px 12px" }}
        size="large"
      />
      <List<Task>
        bordered
        dataSource={data.loading ? previousTasks : tasks}
        loading={data.loading}
        renderItem={(task) => (
          <List.Item
            onClick={() => onPick(task)}
            className="hover:component-highlight pointer-cursor"
          >
            <TaskStatusIcon status={task.status} />
            <Col className="pl-2" flex={1}>
              <Typography.Text>{task.name}</Typography.Text>
            </Col>
            <Col>
              <TaskBreadcrumb task={task} showWorkspaceLink={false} />
            </Col>
          </List.Item>
        )}
      />
    </div>
  );
};
