import { Button, ButtonProps } from "antd";
import React, { FC } from "react";
import { useToggle } from "@dewo/app/util/hooks";
import { FormValues } from "./WorkspaceCreateForm";
import { Workspace } from "@dewo/app/graphql/types";
import { CreateWorkspaceModal } from "./CreateWorkspaceModal";
import { ImportWorkspaceDropdown } from "./ImportWorkspaceDropdown";

interface Props extends ButtonProps {
  organizationId: string;
  initialValues?: Partial<FormValues>;
  onWorkspaceCreated?(workspace: Workspace): void;
}

export const CreateWorkspaceButton: FC<Props> = ({
  organizationId,
  initialValues,
  onWorkspaceCreated,
  ...buttonProps
}) => {
  const modal = useToggle();
  return (
    <>
      <Button {...buttonProps} onClick={modal.toggleOn} />
      <CreateWorkspaceModal
        organizationId={organizationId}
        initialValues={initialValues}
        visible={modal.isOn}
        onClose={modal.toggleOff}
        onWorkspaceCreated={onWorkspaceCreated}
      />
    </>
  );
};

export const CreateOrImportWorkspaceButton: FC<Props> = ({
  organizationId,
  initialValues,
  onWorkspaceCreated,
  ...buttonProps
}) => {
  const modal = useToggle();
  return (
    <>
      <ImportWorkspaceDropdown
        mode="all"
        organizationId={organizationId}
        onCreate={modal.toggleOn}
      >
        <Button {...buttonProps} />
      </ImportWorkspaceDropdown>
      <CreateWorkspaceModal
        organizationId={organizationId}
        initialValues={initialValues}
        visible={modal.isOn}
        onClose={modal.toggleOff}
        onWorkspaceCreated={onWorkspaceCreated}
      />
    </>
  );
};
