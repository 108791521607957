import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { Menu, MenuProps, Popover, Row, Tag, Tree, Typography } from "antd";
import { useOrganizationWorkspaces } from "../../organization/hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { MenuHeader } from "./MenuHeader";
import styles from "./Menu.module.less";
import { useRouter } from "next/router";
import { MenuSkeleton } from "./MenuSkeleton";
import { CreateFundingSessionButton } from "../../funding/create/CreateFundingSessionButton";
import moment from "moment";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { CreateTaskButton } from "./items/CreateTaskButton";
import { useOrganizationRoles } from "../../rbac/hooks";
import { Key } from "antd/lib/table/interface";
import _ from "lodash";
import {
  GraphNodeChildrenDetails,
  GraphNodeDetails,
  GraphNodeType,
  OrganizationWorkspaces,
  Workspace,
  WorkspaceStatus,
} from "@dewo/app/graphql/types";
import { CreateOrImportWorkspaceButton } from "../../workspace/create/CreateWorkspaceButton";
import {
  AppstoreOutlined,
  BulbOutlined,
  NodeIndexOutlined,
  PlusOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import {
  useAllowDrop,
  usePreventDraggingWithoutPermission,
  useWorkspaceTreeDropHandler,
} from "./hooks";
import Link from "next/link";
import { CreateWorkspaceModal } from "../../workspace/create/CreateWorkspaceModal";
import { WorkspaceTreeTitle } from "./items/WorkspaceTreeTitle";
import { useToggle } from "@dewo/app/util/hooks";
import { MenuTreeNode } from "./types";

interface Props {
  organizationId: string;
  workspaceId: string | undefined;
}

export const OrganizationMenu: FC<Props> = ({
  organizationId,
  workspaceId,
}) => {
  const organization = useOrganizationWorkspaces(organizationId);
  if (!organization) return <MenuSkeleton />;
  return (
    <OrganizationMenuContent
      organization={organization}
      workspaceId={workspaceId}
    />
  );
};

const OrganizationMenuContent: FC<{
  organization: OrganizationWorkspaces;
  workspaceId: string | undefined;
}> = ({ organization, workspaceId }) => {
  const canUpdateOrganization = usePermission("update", "Organization");
  const canCreateFundingSession = usePermission("create", "FundingSession");

  const router = useRouter();

  const canCreateWorkspace = usePermission("create", {
    __typename: "Workspace",
    organizationId: organization.id,
  });

  const showFunding =
    !!organization?.fundingSessions.length || canCreateFundingSession;

  const showCommunitySuggestions = useMemo(
    () =>
      organization.node.workspaceChildren.some(
        (edge) => !!edge.node.workspace?.options?.showCommunitySuggestions
      ),
    [organization.node.workspaceChildren]
  );

  const allRoles = useOrganizationRoles(organization.id);
  const hasFeaturedRoles = useMemo(
    () =>
      !!allRoles?.filter((r) => !r.fallback && !r.userId && r.featured).length,
    [allRoles]
  );

  const createMenuItemLabel = useCallback(
    (name: ReactNode, path: string = "") => (
      <Link href={`${organization.permalink}${path}`}>
        <a>{name}</a>
      </Link>
    ),
    [organization.permalink]
  );

  const menuItems = useMemo(
    (): ItemType[] => [
      {
        label: createMenuItemLabel("Overview"),
        icon: <AppstoreOutlined />,
        key: "/[organizationSlug]",
      },
      !!organization.options?.roles &&
      (hasFeaturedRoles || canUpdateOrganization)
        ? {
            label: createMenuItemLabel(
              <Row align="middle" style={{ columnGap: 8 }}>
                Roles
              </Row>,
              "/roles"
            ),
            icon: <TeamOutlined />,
            key: "/[organizationSlug]/roles",
          }
        : null,
      showCommunitySuggestions
        ? {
            label: createMenuItemLabel("Community Suggestions", "/suggestions"),
            icon: <BulbOutlined />,
            key: "/[organizationSlug]/suggestions",
          }
        : null,
      !organization.options?.hideLeaderboards
        ? {
            label: createMenuItemLabel("Leaderboards", "/contributors"),
            icon: <TrophyOutlined />,
            key: "/[organizationSlug]/contributors",
          }
        : null,
      !!organization.options?.roadmap
        ? {
            label: createMenuItemLabel("Roadmap", "/roadmap"),
            icon: <NodeIndexOutlined />,
            key: "/[organizationSlug]/roadmap",
          }
        : null,
      {
        label: createMenuItemLabel("Combined Board", "/board"),
        icon: <ProjectOutlined />,
        key: "/[organizationSlug]/board",
      },
      canUpdateOrganization
        ? {
            label: createMenuItemLabel("Settings", "/settings"),
            icon: <SettingOutlined />,
            key: "/[organizationSlug]/settings",
          }
        : null,
    ],
    [
      createMenuItemLabel,
      organization.options?.roles,
      organization.options?.hideLeaderboards,
      organization.options?.roadmap,
      hasFeaturedRoles,
      canUpdateOrganization,
      showCommunitySuggestions,
    ]
  );

  const treeData = useMemo(() => {
    const getNode = (
      node: GraphNodeDetails & Partial<GraphNodeChildrenDetails>,
      parent: GraphNodeDetails,
      siblings: GraphNodeChildrenDetails["children"]
    ): MenuTreeNode => {
      const [archived, active] = _.partition(
        node.children,
        (edge) => edge.node.workspace?.status === WorkspaceStatus.ARCHIVED
      );
      const workspace = node.workspace!;
      return {
        key: workspace.id,
        title: workspace.name,
        children: [
          ..._.sortBy(active, "sortKey")
            .filter((edge) => !!edge.node.workspace)
            .map((edge) => getNode(edge.node, node, active)),
          ...(!!archived.length
            ? [
                {
                  key: ["archived", workspace.id].join("-"),
                  title: "Archived",
                  children: _.sortBy(archived, "sortKey").map((edge) =>
                    getNode(edge.node, node, archived)
                  ),
                },
              ]
            : []),
        ],
        node,
        parent,
        siblings,
      };
    };

    return [{ name: "Spaces", id: null }, ...organization.workspaceSections]
      .map((section) => ({
        section,
        edges: organization.node.workspaceChildren.filter(
          (edge) =>
            !!edge.node.workspace &&
            edge.node.workspace?.sectionId === section.id
        ),
      }))
      .filter(({ edges }) => !!edges.length)
      .map(({ section, edges }, index) => [
        {
          key: ["top-padding", index].join("-"),
          disabled: true,
        },
        {
          key: ["title", index].join("-"),
          title: section.name,
          disabled: true,
        },
        ..._.sortBy(edges, "sortKey").map((edge) =>
          getNode(edge.node, organization.node, edges)
        ),
      ])
      .flat();
  }, [organization]);

  const selectedTreeKeys = useMemo(
    () => (!!workspaceId ? [workspaceId] : []),
    [workspaceId]
  );

  const [expandedKeys, setExpandedKeys] = useState<Key[]>(() =>
    !!workspaceId ? [workspaceId] : []
  );

  const menuProps: MenuProps = {
    mode: "inline",
    className: styles.menu,
    selectedKeys: [],
  };

  const dropHandler = useWorkspaceTreeDropHandler();
  const allowDrop = useAllowDrop();
  const preventDraggingWithoutPermission =
    usePreventDraggingWithoutPermission();

  const canReorderAnyWorkspace = usePermission(
    "update",
    "Workspace",
    "sortKey"
  );

  const childWorkspaceModal = useToggle();
  const [childWorkspaceParentId, setChildWorkspaceParentId] =
    useState<string>();
  const showChildWorkspaceModal = useCallback(
    (parent: Workspace) => {
      childWorkspaceModal.toggleOn();
      setChildWorkspaceParentId(parent.id);
    },
    [childWorkspaceModal]
  );

  return (
    <>
      <MenuHeader
        icon={<OrganizationAvatar organization={organization} />}
        href={organization.permalink}
        title={organization.name}
      />
      <CreateTaskButton organizationId={organization.id} />
      <Menu {...menuProps} activeKey={router.route} items={menuItems} />

      <Tree
        blockNode
        draggable={canReorderAnyWorkspace ? { icon: false } : false}
        onDrop={dropHandler}
        onDragStart={preventDraggingWithoutPermission}
        allowDrop={allowDrop}
        titleRender={(treeNode) =>
          "node" in treeNode ? (
            <WorkspaceTreeTitle
              workspace={treeNode.node.workspace!}
              showAddButton={
                treeNode.parent.type === GraphNodeType.ORGANIZATION
              }
              onCreateWorkspace={() =>
                showChildWorkspaceModal(treeNode.node.workspace!)
              }
            />
          ) : (
            <Typography.Text
              strong
              type="secondary"
              className="ant-typography-caption"
              style={{ textTransform: "uppercase" }}
            >
              {treeNode.title}
            </Typography.Text>
          )
        }
        selectedKeys={selectedTreeKeys}
        expandedKeys={expandedKeys}
        defaultExpandParent
        onExpand={setExpandedKeys}
        onClick={(_event, treeNode) => {
          const typedNode = treeNode as any as MenuTreeNode;
          if ("node" in typedNode && !!typedNode.node.workspace) {
            router.push(typedNode.node.workspace.permalink);
          }
          setExpandedKeys((prev) =>
            prev.includes(treeNode.key) ? prev : [...prev, treeNode.key]
          );
        }}
        treeData={treeData}
        className={styles.tree}
      />

      {canCreateWorkspace && (
        <CreateOrImportWorkspaceButton
          organizationId={organization?.id}
          type="text"
          block
          style={{ textAlign: "left", background: "transparent" }}
          className="text-secondary"
          icon={<PlusOutlined />}
          children="Create"
        />
      )}
      {showFunding && (
        <Menu
          {...menuProps}
          activeKey={router.query.fundingSessionId as string | undefined}
        >
          <Menu.Divider />
          <Menu.ItemGroup
            title={
              <Row align="middle" justify="space-between">
                <Popover content="Distribute funding to bounties retroactively - in a decentralized way!">
                  Retroactive Bounties
                </Popover>
                {canCreateFundingSession && (
                  <CreateFundingSessionButton
                    type="text"
                    size="small"
                    className="text-secondary"
                    icon={<PlusOutlined />}
                    organizationId={organization?.id}
                  />
                )}
              </Row>
            }
          >
            {(organization.fundingSessions ?? []).map((session) => (
              <Menu.Item key={session.id}>
                <Link href={session.permalink}>
                  <a>
                    <Row align="middle" justify="space-between">
                      {[session.startDate, session.endDate]
                        .map((d) => moment(d).format("MMM D"))
                        .join(" - ")}
                      {!!session.closedAt ? (
                        <Tag color="blue">Closed</Tag>
                      ) : (
                        <Tag color="green">Open</Tag>
                      )}
                    </Row>
                  </a>
                </Link>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        </Menu>
      )}
      <CreateWorkspaceModal
        organizationId={organization.id}
        initialValues={{ parentId: childWorkspaceParentId }}
        visible={childWorkspaceModal.isOn}
        onClose={childWorkspaceModal.toggleOff}
        onWorkspaceCreated={() =>
          setExpandedKeys((prev) =>
            !childWorkspaceParentId || prev.includes(childWorkspaceParentId)
              ? prev
              : [...prev, childWorkspaceParentId]
          )
        }
      />
    </>
  );
};
