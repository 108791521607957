import {
  Workspace,
  TaskGatingType,
  RoleWithRules,
} from "@dewo/app/graphql/types";
import { Form, TagProps, Tooltip, Typography } from "antd";
import React, { FC, useMemo } from "react";
import { useOrganizationRoles } from "@dewo/app/containers/rbac/hooks";
import { ContestIcon } from "@dewo/app/components/icons/task/Contest";
import * as Colors from "@ant-design/colors";
import { LockIcon } from "@dewo/app/components/icons/task/Lock";
import { ApplicationIcon } from "@dewo/app/components/icons/task/Application";
import { ClaimableIcon } from "@dewo/app/components/icons/task/Claimable";
import _ from "lodash";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { TaskFormValues } from "../types";
import { useTask } from "../../hooks";
import { CrownFilled, SafetyCertificateOutlined } from "@ant-design/icons";
import { StyledTag } from "@dewo/app/components/StyledTag";

interface GatingTagProps extends TagProps {
  gating: TaskGatingType | undefined;
  workspace: Workspace;
  disabled?: boolean;
  taskId?: string;
}

const GatingTag: FC<GatingTagProps> = ({
  disabled,
  gating,
  workspace,
  taskId,
  ...props
}) => {
  const allRoles = useOrganizationRoles(workspace.organizationId);
  const form = Form.useFormInstance<TaskFormValues>();
  const applyRoleIds = Form.useWatch("applyRoleIds", form);
  const claimRoleIds = Form.useWatch("claimRoleIds", form);
  const roleIds = useMemo(
    () => _.uniq([...(applyRoleIds || []), ...(claimRoleIds || [])]),
    [applyRoleIds, claimRoleIds]
  );
  const task = useTask(taskId);
  const canClaim =
    usePermission("update", task, "assigneeIds") && claimRoleIds?.length;
  const canApply = usePermission("apply", task);

  const roles = roleIds
    .map((id) => allRoles?.find((r) => r.id === id))
    .filter((role): role is RoleWithRules => !!role);

  const rolesString = roles.map((role) => role.name).join(", ") || "Everyone";
  const suffix = !!roleIds.length
    ? roleIds.length === 1
      ? `(${rolesString})`
      : `(${roleIds.length} roles)`
    : "";

  const anyoneCanApply = useMemo(
    () =>
      roles.some((role) => role.fallback && applyRoleIds?.includes(role.id)),
    [applyRoleIds, roles]
  );

  const fillStyle = disabled ? { fill: "white" } : {};

  if (!gating) {
    return (
      <StyledTag
        editable={!disabled}
        icon={<SafetyCertificateOutlined className="text-secondary" />}
        {...props}
      >
        Permissions
      </StyledTag>
    );
  } else if (gating === TaskGatingType.ASSIGNEES) {
    return (
      <StyledTag
        editable={!disabled}
        color={Colors.grey[9]}
        icon={<LockIcon style={{ width: 10 }} />}
        {...props}
      >
        Assigned
      </StyledTag>
    );
  } else if (gating === TaskGatingType.OPEN_SUBMISSION) {
    const showMaxWinners =
      !!disabled && task?.maxWinners && task.maxWinners > 1;
    return (
      <Tooltip
        title={
          <>
            You can start working right away on this task and submit work when
            you’re done.{" "}
            {!!task?.maxWinners &&
              `Among all submissions, ${task.maxWinners} will be approved`}
          </>
        }
      >
        <StyledTag
          editable={!disabled}
          color="#D6A11C"
          icon={<ContestIcon style={{ width: 12, ...fillStyle }} />}
          suffix={
            showMaxWinners ? (
              <Typography.Text>
                <CrownFilled /> {task.maxWinners}
              </Typography.Text>
            ) : undefined
          }
          secondaryColor={Colors.yellow[8]}
          {...props}
        >
          Open to Submissions
        </StyledTag>
      </Tooltip>
    );
  }

  if (canClaim || (!disabled && claimRoleIds?.length)) {
    return (
      <Tooltip title={rolesString}>
        <StyledTag
          editable={!disabled}
          color={Colors.magenta[4]}
          icon={<ClaimableIcon style={{ width: 12, ...fillStyle }} />}
          {...props}
        >
          Direct Claiming {suffix}
        </StyledTag>
      </Tooltip>
    );
  }
  if (canApply || !disabled) {
    return (
      <Tooltip title={rolesString}>
        <StyledTag
          editable={!disabled}
          color={Colors.blue[4]}
          icon={<ApplicationIcon style={{ width: 12, ...fillStyle }} />}
          {...props}
        >
          Open to Applications {anyoneCanApply ? "" : suffix}
        </StyledTag>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={`Only contributors with ${rolesString} roles can ${
        applyRoleIds?.length ? "apply to" : "claim"
      } this task`}
    >
      <StyledTag
        editable={!disabled}
        color={Colors.grey[9]}
        icon={<LockIcon style={{ width: 10 }} />}
        {...props}
      >
        Reserved to: {rolesString}
      </StyledTag>
    </Tooltip>
  );
};

export const TaskGatingInput: FC<{
  workspace: Workspace;
  onClick: () => void;
  disabled?: boolean;
  taskId?: string;
}> = ({ workspace, onClick, disabled, taskId }) => {
  const form = Form.useFormInstance<TaskFormValues>();
  const gatingType = Form.useWatch("gating", form);

  if (!workspace) return null;

  return (
    <GatingTag
      onClick={!disabled ? onClick : undefined}
      taskId={taskId}
      disabled={disabled}
      workspace={workspace}
      gating={gatingType}
    />
  );
};
