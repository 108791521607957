import { Badge, Button, Popover, Row, Typography } from "antd";
import Link from "next/link";
import React, { FC, useState } from "react";
import { useNotificationUnreadCount } from "./hooks";
import { NotificationList } from "./NotificationList";
import styles from "./NotificationList.module.less";
import {
  BellFilled,
  BellOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";

const zIndexToAppearBelowTaskModal = 899; // 900 (modal z-index) - 1

export const NotificationPopoverButton: FC = () => {
  const unreadCount = useNotificationUnreadCount();
  const [reset, setReset] = useState(0);

  return (
    <Popover
      key={reset}
      zIndex={zIndexToAppearBelowTaskModal}
      title={
        <Row align="middle">
          <Typography.Text style={{ flex: 1 }} className="font-semibold">
            Inbox
          </Typography.Text>
          <Link href="/notifications">
            <Typography.Link
              onClick={() => setReset((prev) => prev + 1)}
              // @ts-expect-error
              name="View all from notification popover"
            >
              View all <CaretRightOutlined />
            </Typography.Link>
          </Link>
        </Row>
      }
      content={<NotificationList max={20} />}
      overlayClassName={styles.popoverButtonPopover}
      trigger="click"
    >
      <div>
        <Button
          type="text"
          className="text-secondary"
          icon={!!unreadCount ? <BellFilled /> : <BellOutlined />}
          name="Notification popover"
        />
        <Badge
          count={unreadCount}
          className={styles.dropdownButtonBadge}
          size="small"
        />
      </div>
    </Popover>
  );
};
