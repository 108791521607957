import { Button, Form, Input, Typography } from "antd";
import React, { FC } from "react";
import { useForm } from "antd/lib/form/Form";
import { useRunningCallback } from "@dewo/app/util/hooks";
import { UserPromptStepComponentProps } from "../../types";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useCreateTask } from "@dewo/app/containers/task/hooks";
import { TaskStatus } from "@dewo/app/graphql/types";
import { PlusOutlined } from "@ant-design/icons";
import { useCreateWorkspace } from "@dewo/app/containers/workspace/hooks";
import { useCreateWorkspaceSection } from "@dewo/app/containers/workspace/section/hooks";
import {
  useNewlyCreatedOrganization,
  useNewlyCreatedWorkspaceAndSection,
} from "./hooks";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";

const placeholders = [
  "Airdrop token",
  "Host a community call",
  "Deploy smart contract",
];

interface FormValues {
  taskNames: string[];
}

export const OnboardingOrganizationTasks: FC<UserPromptStepComponentProps> = ({
  onNext,
}) => {
  const [form] = useForm<FormValues>();

  const { user } = useAuthContext();
  const newlyCreatedOrganization = useNewlyCreatedOrganization();
  const { workspace, section } = useNewlyCreatedWorkspaceAndSection();
  const { logEvent } = useAmplitude();

  const createSection = useCreateWorkspaceSection();
  const createWorkspace = useCreateWorkspace();
  const createTask = useCreateTask();
  const [submit, submitting] = useRunningCallback(
    async (values: FormValues) => {
      if (!newlyCreatedOrganization) return onNext();

      const actualWorkspace = await (async () => {
        if (!!workspace) return workspace;

        const actualSection =
          section ??
          (await createSection({
            name: "Spaces",
            organizationId: newlyCreatedOrganization.id,
          }));
        return createWorkspace({
          name: "Main Space",
          sectionId: actualSection.id,
          organizationId: newlyCreatedOrganization.id,
        });
      })();

      const taskNames = values.taskNames.filter((n) => !!n);
      for (const name of taskNames) {
        await createTask({
          name,
          workspaceId: actualWorkspace.id,
          status: TaskStatus.TODO,
          assigneeIds: !!user ? [user.id] : [],
          ownerIds: !!user ? [user.id] : [],
        });
      }

      logEvent("Organization Onboarding: create tasks", {
        count: taskNames.length,
        organizationId: newlyCreatedOrganization.id,
      });

      await onNext();
      setTimeout(() => form.resetFields(), 3000); // after navigation
    },
    [newlyCreatedOrganization, createWorkspace, createTask, onNext, logEvent]
  );

  return (
    <Form
      form={form}
      initialValues={{ taskNames: ["", "", ""] }}
      className="mx-auto w-full"
      style={{
        flex: 1,
        gap: 16,
        display: "flex",
        flexDirection: "column",
        maxWidth: 368,
      }}
      onFinish={submit}
    >
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        What are some tasks to do?
      </Typography.Title>

      <Form.List name="taskNames">
        {(fields, { add }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={index} name={field.name} noStyle>
                <Input
                  size="large"
                  type="text"
                  placeholder={placeholders[index] ?? "Enter task name..."}
                />
              </Form.Item>
            ))}
            <div style={{ flex: 1 }} />
            <Button icon={<PlusOutlined />} size="large" onClick={() => add()}>
              Add task
            </Button>
          </>
        )}
      </Form.List>

      <Button
        block
        size="large"
        type="primary"
        htmlType="submit"
        loading={submitting}
        style={{ alignSelf: "center" }}
        name="Onboarding Organization Tasks: next"
      >
        Next
      </Button>
    </Form>
  );
};
