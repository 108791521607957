import gql from "graphql-tag";
import { userDetails } from "../fragments";

export const deleteThreepid = gql`
  mutation DeleteThreepidMutation($id: UUID!) {
    deleteThreepid(id: $id) {
      ...UserDetails
    }
  }

  ${userDetails}
`;

export const createThreepidAuthSession = gql`
  mutation CreateThreepidAuthSessionMutation(
    $redirect: String
    $type: ThreepidAuthSessionType!
    $state: JSONObject
  ) {
    session: createThreepidAuthSession(
      redirect: $redirect
      type: $type
      state: $state
    ) {
      id
    }
  }
`;
