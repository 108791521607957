import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { Task } from "@dewo/app/graphql/types";
import { useMemo } from "react";

export function useCanUpdateTaskReward(task: Task | undefined): boolean {
  const canCreateReward = usePermission("create", "TaskReward");
  const hasPaidReward = useMemo(
    () => !!task?.rewards.some((r) => !!r.payments.length),
    [task?.rewards]
  );
  return !!canCreateReward && !hasPaidReward;
}
