import * as qs from "query-string";

export const createSEOImageUrl = (options: {
  title: string;
  subtitle?: string;
  imageUrl?: string | null;
}): string =>
  `https://dework-og-image-fant.vercel.app/${encodeURIComponent(
    options.title
  )}.png?${qs.stringify({
    subtitle: options.subtitle,
    md: 1,
    fontSize: "100px",
    images: options.imageUrl ?? "https://app.dework.xyz/logo.png",
    widths: 300,
    heights: 300,
  })}`;
