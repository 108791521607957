import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useRunning } from "@dewo/app/util/hooks";
import { Avatar, Button, Col, Row, Typography } from "antd";
import React, { FC, useCallback } from "react";
import styles from "./Onboarding.module.less";
import { useRouter } from "next/router";
import { SmileOutlined, TeamOutlined, TrophyOutlined } from "@ant-design/icons";
import { UserPromptStepComponentProps } from "../../types";
import { useCreateUserPrompt } from "../../hooks";
import { CreateUserPromptFlow } from "@dewo/app/graphql/types";

export const OnboardingDone: FC<UserPromptStepComponentProps> = ({
  onNext,
}) => {
  const { user } = useAuthContext();
  const router = useRouter();
  const [handleNext, loadingNext] = useRunning(
    useCallback(() => onNext(), [onNext])
  );

  const createUserPrompt = useCreateUserPrompt();
  const createOrganization = useCallback(async () => {
    await createUserPrompt(CreateUserPromptFlow.CREATE_ORGANIZATION);
    onNext();
  }, [createUserPrompt, onNext]);

  if (!user) return null;
  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Done!
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        What do you want to do next?
      </Typography.Paragraph>
      <Row
        gutter={[16, 16]}
        align="middle"
        style={{ flex: 1, marginBottom: 16 }}
      >
        <Col xs={24} sm={8}>
          <Button
            block
            className={styles.tile}
            name="Onboarding Done: create DAO"
            onClick={createOrganization}
          >
            <Avatar icon={<TeamOutlined />} size="large" />
            <Typography.Paragraph
              strong
              style={{ margin: 0, textAlign: "center" }}
            >
              Setup your first DAO
            </Typography.Paragraph>
          </Button>
        </Col>
        <Col xs={24} sm={8}>
          <Button
            block
            className={styles.tile}
            name="Onboarding Done: setup profile"
            onClick={() => onNext().then(() => router.push(user.permalink))}
          >
            <Avatar icon={<SmileOutlined />} size="large" />
            <Typography.Paragraph
              strong
              style={{ margin: 0, textAlign: "center" }}
            >
              Setup your profile
            </Typography.Paragraph>
          </Button>
        </Col>
        <Col xs={24} sm={8}>
          <Button
            block
            className={styles.tile}
            name="Onboarding Done: explore tasks and bounties"
            onClick={() => onNext().then(() => router.push("/bounties"))}
          >
            <Avatar icon={<TrophyOutlined />} size="large" />
            <Typography.Paragraph
              strong
              style={{ margin: 0, textAlign: "center" }}
            >
              Explore tasks and bounties
            </Typography.Paragraph>
          </Button>
        </Col>
      </Row>

      <Button
        size="large"
        type="primary"
        className="mx-auto"
        loading={loadingNext}
        name="Onboarding Done: close"
        onClick={handleNext}
      >
        Close
      </Button>
    </>
  );
};
