import _ from "lodash";
import { Modal, Skeleton } from "antd";
import { useRouter } from "next/router";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  useTaskDetails,
  useTaskRoles,
  useUpdateTaskFromFormValues,
} from "./hooks";
import { TaskForm } from "./form/TaskForm";
import { TaskOptionsButton } from "./optionsButton/TaskOptionsButton";
import { TaskApplyModal } from "./actions/apply/TaskApplyModal";
import { TaskSeo } from "../seo/TaskSeo";
import { TaskFormValues } from "./form/types";
import { isSSR } from "@dewo/app/util/isSSR";
import { toTaskFormValues } from "./form/util";
import { ResourceNotFoundModal } from "@dewo/app/components/modals/ResourceNotFoundModal";
import { WorkspaceAccessDeniedModal } from "@dewo/app/components/modals/WorkspaceAccessDeniedModal";
import { usePrevious, useWindowFocus } from "@dewo/app/util/hooks";
import { TaskBreadcrumb } from "./form/TaskBreadcrumb";
import { TaskStatus } from "@dewo/app/graphql/types";
import { useUpvoteTaskFromQueryParam } from "./voting/hooks";
import { useUnsavedChangesConfirmation } from "./form/useUnsavedChangesConfirmation";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";

interface Props {
  taskId: string | undefined;
  visible: boolean;
  onCancel(): void;
}

const TaskUpdateModalContent: FC<{
  taskId: string;
  onDescriptionDirty(dirty: boolean): void;
}> = ({ taskId, onDescriptionDirty }) => {
  const { task } = useTaskDetails(taskId);
  const updateTaskFromFormValues = useUpdateTaskFromFormValues(task);
  const { claimRoles, applyRoles } = useTaskRoles(task);
  const formValues = useMemo(
    (): TaskFormValues | undefined =>
      !!task && !!claimRoles && !!applyRoles
        ? toTaskFormValues(task, claimRoles, applyRoles, [])
        : undefined,
    [applyRoles, claimRoles, task]
  );

  const { logEvent } = useAmplitude();
  useEffect(() => {
    if (!!task) {
      logEvent("View task details", { taskId, taskName: task.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!task, taskId]);

  return (
    <Skeleton loading={!formValues} active paragraph={{ rows: 5 }}>
      {!!task && (
        <>
          {!task.template && <TaskBreadcrumb task={task} />}
          <TaskForm
            key={taskId}
            mode="update"
            task={task}
            workspaceId={task!.workspaceId}
            initialValues={formValues}
            onSubmit={updateTaskFromFormValues}
            onDescriptionDirty={onDescriptionDirty}
          />
        </>
      )}
    </Skeleton>
  );
};

export const TaskUpdateModal: FC<Props> = ({ taskId, visible, onCancel }) => {
  const router = useRouter();
  const isOnWorkspacePage = !!router.query.workspaceSlug;
  const { task, error, refetch } = useTaskDetails(
    taskId,
    isSSR ? undefined : "cache-and-network"
  );
  useWindowFocus(!!taskId ? refetch : undefined);
  useUpvoteTaskFromQueryParam(taskId);

  const forbiddenError = !!error?.graphQLErrors.some(
    (e) => e.extensions?.response?.statusCode === 403
  );
  const notFoundError = !!error?.graphQLErrors.some(
    (e) => e.extensions?.response?.statusCode === 404
  );

  const status = task?.status;
  const prevStatus = usePrevious(status);
  const isSuggestion =
    (status ?? prevStatus) === TaskStatus.COMMUNITY_SUGGESTIONS;

  const [dirty, setDirty] = useState(false);
  const handleConfirmUnsavedChanges = useUnsavedChangesConfirmation(
    dirty,
    onCancel
  );

  const shouldShow = visible && !forbiddenError && !notFoundError;
  useEffect(() => {
    if (shouldShow) setDirty(false);
  }, [shouldShow]);

  return (
    <>
      <Modal
        visible={shouldShow}
        onCancel={handleConfirmUnsavedChanges}
        footer={null}
        width={isSuggestion ? 720 : 1000}
        destroyOnClose
      >
        {!!task && <TaskOptionsButton task={task} />}
        {!!taskId && (
          <TaskUpdateModalContent
            key={taskId}
            taskId={taskId}
            onDescriptionDirty={setDirty}
          />
        )}
      </Modal>
      {visible && !!task && <TaskSeo task={task} />}
      <ResourceNotFoundModal
        visible={visible && notFoundError}
        message="This task does not exist or has been deleted."
        onClose={onCancel}
      />
      <WorkspaceAccessDeniedModal
        visible={visible && forbiddenError && !isOnWorkspacePage}
      />
    </>
  );
};

export const TaskUpdateModalListener: FC = () => {
  const router = useRouter();
  const taskId = router.query.taskId as string | undefined;
  const applyToTaskId = router.query.applyToTaskId as string | undefined;

  const closeTaskModal = useCallback(() => {
    return router.push({
      pathname: router.pathname,
      query: _.omit(router.query, ["taskId", "applyToTaskId"]),
    });
  }, [router]);

  const closeApplyTaskModal = useCallback(() => {
    return router.push({
      pathname: router.pathname,
      query: _.omit(router.query, ["applyToTaskId"]),
    });
  }, [router]);

  return (
    <>
      <TaskUpdateModal
        taskId={taskId}
        visible={!!taskId}
        onCancel={closeTaskModal}
      />
      <TaskApplyModal
        taskId={applyToTaskId}
        visible={!!applyToTaskId}
        onCancel={closeApplyTaskModal}
        onDone={closeApplyTaskModal}
      />
    </>
  );
};
