import dynamic from "next/dynamic";

interface Props {
  name: string;
}

export const Emoji = dynamic<Props>(
  () => import("./EmojiPickerDynamic").then((Module) => Module.EmojiDynamic),
  { ssr: false }
);
