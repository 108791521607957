import React, { FC, useCallback } from "react";
import { Avatar, Checkbox, Col, Row, Space, Typography } from "antd";
import { TaskGatingType } from "@dewo/app/graphql/types";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { UserAddOutlined } from "@ant-design/icons";
import { ContestIcon } from "@dewo/app/components/icons/task/Contest";
import { ApplicationIcon } from "@dewo/app/components/icons/task/Application";
import { ClaimableIcon } from "@dewo/app/components/icons/task/Claimable";

interface Props {
  value?: TaskGatingType[];
  onChange?: (value: TaskGatingType[]) => void;
}

export const TaskGatingTypeInput: FC<Props> = ({ onChange, value }) => {
  const handleChange = useCallback(
    (v: CheckboxValueType[]) => {
      const value = v as TaskGatingType[];
      if (
        value.length === 2 &&
        value.includes(TaskGatingType.ROLES) &&
        value.includes(TaskGatingType.APPLICATION)
      ) {
        return onChange?.(value);
      }

      if (value.length === 0) return;

      onChange?.(value.slice(-1));
    },
    [onChange]
  );

  return (
    <Checkbox.Group onChange={handleChange} value={value}>
      <Row>
        <Col md={12}>
          <Checkbox value={TaskGatingType.ASSIGNEES}>
            <Typography.Text strong>
              <Space align="center">
                <Avatar size={20} icon={<UserAddOutlined />} />
                Assign someone
              </Space>
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              Assign someone if you already know who’s going to work on this
              task
            </Typography.Paragraph>
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox value={TaskGatingType.OPEN_SUBMISSION}>
            <Typography.Text strong>
              <Space align="center">
                <ContestIcon style={{ width: 16 }} />
                Multiple Submissions
              </Space>
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              Contributors can submit work, one or more get chosen as winners
            </Typography.Paragraph>
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox value={TaskGatingType.APPLICATION}>
            <Typography.Text strong>
              <Space align="center">
                <ApplicationIcon style={{ width: 16 }} />
                Open to applications
              </Space>
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              Contributors can apply. Reviewer assigns the best applicant
            </Typography.Paragraph>
          </Checkbox>
        </Col>
        <Col md={12}>
          <Checkbox value={TaskGatingType.ROLES}>
            <Typography.Text strong>
              <Space>
                <ClaimableIcon style={{ width: 16 }} /> Direct Claiming
              </Space>
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              Trusted contributors with certain Discord Roles can self-assign
            </Typography.Paragraph>
          </Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
  );
};
