import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import {
  CreateWorkspaceIntegrationInput,
  CreateWorkspaceIntegrationMutation,
  CreateWorkspaceIntegrationMutationVariables,
  UpdateWorkspaceIntegrationInput,
  UpdateWorkspaceIntegrationMutation,
  UpdateWorkspaceIntegrationMutationVariables,
  GithubRepo,
  WorkspaceIntegration,
  WorkspaceIntegrationType,
  DiscordIntegrationChannel,
  CreateTasksFromGithubIssuesMutation,
  CreateTasksFromGithubIssuesMutationVariables,
  CreateWorkspacesFromNotionInput,
  CreateWorkspacesFromNotionMutation,
  CreateWorkspacesFromNotionMutationVariables,
  CreateWorkspacesFromTrelloInput,
  CreateWorkspacesFromTrelloMutation,
  CreateWorkspacesFromTrelloMutationVariables,
  GetTrelloBoardsQuery,
  GetTrelloBoardsQueryVariables,
  GetNotionDatabasesQuery,
  GetNotionDatabasesQueryVariables,
  AddUserToDiscordGuildMutation,
  AddUserToDiscordGuildMutationVariables,
  DiscordGuildMembershipState,
  GetDiscordGuildMembershipStateQuery,
  GetDiscordGuildMembershipStateQueryVariables,
  GetDiscordGuildRolesQuery,
  GetDiscordGuildRolesQueryVariables,
  OrganizationIntegration,
  DiscordIntegrationRole,
  CreateWorkspacesFromGithubInput,
  CreateWorkspacesFromGithubMutation,
  CreateWorkspacesFromGithubMutationVariables,
  CreateOrganizationIntegrationInput,
  CreateOrganizationIntegrationMutation,
  CreateOrganizationIntegrationMutationVariables,
  UpdateOrganizationRolesDiscordMutation,
  UpdateOrganizationRolesDiscordMutationVariables,
  WorkspaceIntegrationFeature,
  OrganizationIntegrationType,
  DeleteOrganizationIntegrationMutation,
  DeleteOrganizationIntegrationMutationVariables,
  NotionDatabase,
} from "@dewo/app/graphql/types";
import * as Queries from "@dewo/app/graphql/queries";
import * as Mutations from "@dewo/app/graphql/mutations";
import { Constants } from "@dewo/app/util/constants";
import { isSSR } from "@dewo/app/util/isSSR";
import { deleteOrganizationIntegration } from "@dewo/app/graphql/mutations/integration";

export const DiscordPermissionToString = {
  VIEW_CHANNEL: "View Channel",
  SEND_MESSAGES: "Send Messages",
  SEND_MESSAGES_IN_THREADS: "Send Messages in Threads",
  CREATE_PUBLIC_THREADS: "Create Public Threads",
  EMBED_LINKS: "Embed Links",
};

export type DiscordPermission = keyof typeof DiscordPermissionToString;

const DiscordPermissionsForFeature: Partial<
  Record<WorkspaceIntegrationFeature, DiscordPermission[]>
> = {
  [WorkspaceIntegrationFeature.DISCORD_POST_STATUS_BOARD_MESSAGE]: [
    "VIEW_CHANNEL",
    "SEND_MESSAGES",
    "EMBED_LINKS",
  ],
  [WorkspaceIntegrationFeature.DISCORD_POST_TASK_UPDATES_TO_THREAD_PER_TASK]: [
    "VIEW_CHANNEL",
    "SEND_MESSAGES",
    "SEND_MESSAGES_IN_THREADS",
    "CREATE_PUBLIC_THREADS",
    "EMBED_LINKS",
  ],
};

export function useConnectToGithubUrl(
  organizationId: string,
  stateOverride?: object
): string {
  const fn = useConnectToGithubUrlFn();
  return useMemo(
    () => fn(organizationId, stateOverride),
    [fn, organizationId, stateOverride]
  );
}

export function useConnectToGithubUrlFn(): (
  organizationId: string,
  stateOverride?: object
) => string {
  const { user } = useAuthContext();
  return useCallback(
    (organizationId, stateOverride) => {
      const origin = !isSSR ? window.location.origin : "";
      const state = JSON.stringify({
        origin,
        creatorId: user?.id,
        organizationId,
        ...stateOverride,
      });

      return `${Constants.GITHUB_APP_URL}?state=${encodeURIComponent(state)}`;
    },
    [user?.id]
  );
}

export function useUpdateOrganizationDiscordRoles(): (
  organizationId: string
) => Promise<void> {
  const [mutation] = useMutation<
    UpdateOrganizationRolesDiscordMutation,
    UpdateOrganizationRolesDiscordMutationVariables
  >(Mutations.updateOrganizationDiscordRoles);

  return useCallback(
    async (organizationId) => {
      const res = await mutation({
        variables: { organizationId },
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useCreateWorkspaceIntegration(): (
  input: CreateWorkspaceIntegrationInput
) => Promise<WorkspaceIntegration> {
  const [mutation] = useMutation<
    CreateWorkspaceIntegrationMutation,
    CreateWorkspaceIntegrationMutationVariables
  >(Mutations.createWorkspaceIntegration);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.integration;
    },
    [mutation]
  );
}

export function useCreateOrganizationIntegration(): (
  input: CreateOrganizationIntegrationInput
) => Promise<OrganizationIntegration> {
  const [mutation] = useMutation<
    CreateOrganizationIntegrationMutation,
    CreateOrganizationIntegrationMutationVariables
  >(Mutations.createOrganizationIntegration);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.integration;
    },
    [mutation]
  );
}

function useCreateTasksFromGithubIssues(): (
  workspaceId: string
) => Promise<void> {
  const [mutation] = useMutation<
    CreateTasksFromGithubIssuesMutation,
    CreateTasksFromGithubIssuesMutationVariables
  >(Mutations.createTasksFromGithubIssues);
  return useCallback(
    async (workspaceId) => {
      const res = await mutation({ variables: { workspaceId } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useCreateGithubWorkspaceIntegration(): (input: {
  workspaceId: string;
  repo: GithubRepo;
  labelIds?: string[];
  importIssues: boolean;
  postTasksToGithub: boolean | undefined;
  feature: WorkspaceIntegrationFeature;
}) => Promise<WorkspaceIntegration> {
  const createWorkspaceIntegration = useCreateWorkspaceIntegration();
  const createTasksFromGithubIssues = useCreateTasksFromGithubIssues();
  return useCallback(
    async (input) => {
      const integration = await createWorkspaceIntegration({
        workspaceId: input.workspaceId,
        type: WorkspaceIntegrationType.GITHUB,
        feature: input.feature,
        organizationIntegrationId: input.repo.integrationId,
        config: {
          repo: input.repo.name,
          organization: input.repo.organization,
          labelIds: input.labelIds,
          postTasksToGithub: input.postTasksToGithub,
        },
      });

      if (input.importIssues) {
        await createTasksFromGithubIssues(input.workspaceId);
      }

      return integration;
    },
    [createWorkspaceIntegration, createTasksFromGithubIssues]
  );
}

export function useCreateDiscordWorkspaceIntegration(): (input: {
  workspaceId: string;
  feature: WorkspaceIntegrationFeature;
  channel: DiscordIntegrationChannel;
  config?: Record<string, unknown>;
}) => Promise<WorkspaceIntegration> {
  const createWorkspaceIntegration = useCreateWorkspaceIntegration();
  return useCallback(
    ({ workspaceId, feature, channel, config = {} }) => {
      return createWorkspaceIntegration({
        workspaceId,
        type: WorkspaceIntegrationType.DISCORD,
        feature,
        organizationIntegrationId: channel.integrationId,
        config: {
          channelId: channel.id,
          name: channel.name,
          ...config,
        },
      });
    },
    [createWorkspaceIntegration]
  );
}

export function useCreateNotionWorkspaceIntegration(): (input: {
  workspaceId: string;
  database: NotionDatabase;
  feature: WorkspaceIntegrationFeature;
}) => Promise<WorkspaceIntegration> {
  const createWorkspaceIntegration = useCreateWorkspaceIntegration();
  return useCallback(
    async (input) => {
      const integration = await createWorkspaceIntegration({
        workspaceId: input.workspaceId,
        type: WorkspaceIntegrationType.NOTION,
        feature: input.feature,
        organizationIntegrationId: input.database.integrationId,
        config: { databaseId: input.database.id },
      });

      return integration;
    },
    [createWorkspaceIntegration]
  );
}

export function useUpdateWorkspaceIntegration(): (
  input: UpdateWorkspaceIntegrationInput
) => Promise<WorkspaceIntegration> {
  const [mutation] = useMutation<
    UpdateWorkspaceIntegrationMutation,
    UpdateWorkspaceIntegrationMutationVariables
  >(Mutations.updateWorkspaceIntegration);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data?.integration;
    },
    [mutation]
  );
}

export function useCreateWorkspacesFromNotion(): (
  input: CreateWorkspacesFromNotionInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateWorkspacesFromNotionMutation,
    CreateWorkspacesFromNotionMutationVariables
  >(Mutations.createWorkspacesFromNotion);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useCreateWorkspacesFromTrello(): (
  input: CreateWorkspacesFromTrelloInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateWorkspacesFromTrelloMutation,
    CreateWorkspacesFromTrelloMutationVariables
  >(Mutations.createWorkspacesFromTrello);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useCreateWorkspacesFromGithub(): (
  input: CreateWorkspacesFromGithubInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateWorkspacesFromGithubMutation,
    CreateWorkspacesFromGithubMutationVariables
  >(Mutations.createWorkspacesFromGithub);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useTrelloBoards(threepidId: string) {
  const { data } = useQuery<
    GetTrelloBoardsQuery,
    GetTrelloBoardsQueryVariables
  >(Queries.trelloBoards, { variables: { threepidId } });
  return data?.trelloBoards;
}

export function useNotionDatabases(organizationId: string) {
  const { data } = useQuery<
    GetNotionDatabasesQuery,
    GetNotionDatabasesQueryVariables
  >(Queries.getNotionDatabases, { variables: { organizationId } });
  return data?.notionDatabases;
}

export function useDiscordGuildMembershipState(
  organizationId: string | undefined
): DiscordGuildMembershipState | undefined {
  const { user } = useAuthContext();
  const { data } = useQuery<
    GetDiscordGuildMembershipStateQuery,
    GetDiscordGuildMembershipStateQueryVariables
  >(Queries.getDiscordGuildMembershipState, {
    variables: { organizationId: organizationId! },
    skip: !organizationId || !user,
  });
  return data?.state;
}

export function useDiscordGuildRoles(
  organizationId: string | undefined
): DiscordIntegrationRole[] | undefined {
  const { data } = useQuery<
    GetDiscordGuildRolesQuery,
    GetDiscordGuildRolesQueryVariables
  >(Queries.getDiscordGuildRoles, {
    variables: { organizationId: organizationId! },
    skip: !organizationId,
  });
  return data?.roles ?? undefined;
}

export function useAddUserToDiscordGuild(
  organizationId: string | undefined
): () => Promise<void> {
  const [mutation] = useMutation<
    AddUserToDiscordGuildMutation,
    AddUserToDiscordGuildMutationVariables
  >(Mutations.addUserToDiscordGuild);
  return useCallback(async () => {
    const res = await mutation({
      variables: { organizationId: organizationId! },
      refetchQueries: [
        {
          query: Queries.getDiscordGuildMembershipState,
          variables: { organizationId: organizationId! },
        },
      ],
      awaitRefetchQueries: true,
    });
    if (!res.data) throw new Error(JSON.stringify(res.errors));
  }, [mutation, organizationId]);
}

export function useMissingPermissions(
  channels: DiscordIntegrationChannel[] | undefined,
  discordFeature: WorkspaceIntegrationFeature | undefined,
  discordChannelId: string | undefined
) {
  const requiredPermissions = useMemo(
    () =>
      !!discordFeature ? DiscordPermissionsForFeature[discordFeature] : [],
    [discordFeature]
  );
  const channel = useMemo(
    () => channels?.find((c) => c.id === discordChannelId),
    [channels, discordChannelId]
  );
  return useMemo(
    () =>
      _.difference(
        requiredPermissions,
        channel?.permissions ?? []
      ) as DiscordPermission[],
    [requiredPermissions, channel?.permissions]
  );
}

export function useDeleteOrganizationIntegration(): (
  type: OrganizationIntegrationType,
  organizationId: string
) => Promise<void> {
  const [mutation] = useMutation<
    DeleteOrganizationIntegrationMutation,
    DeleteOrganizationIntegrationMutationVariables
  >(deleteOrganizationIntegration);

  return useCallback(
    async (type, organizationId) => {
      const res = await mutation({
        variables: { input: { type, organizationId } },
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}
