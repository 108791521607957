import { UserSelect } from "@dewo/app/components/form/UserSelect";
import { FormItemPlaceholder } from "@dewo/app/components/form/FormItemPlaceholder";
import { TaskPriorityIcon } from "@dewo/app/components/icons/task/TaskPriority";
import { TaskStatusIcon } from "@dewo/app/components/icons/task/TaskStatus";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  RelevantUsersModes,
  Task,
  TaskPriority,
} from "@dewo/app/graphql/types";
import { Col, Form, Row, Select, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import {
  useWorkspaceDetails,
  useWorkspaceTaskTags,
  useRelevantUsers,
} from "../../workspace/hooks";
import {
  getWorkspaceTaskStatuses,
  PRIORITY_LABEL,
  STATUS_LABEL,
} from "../board/util";
import { useCanChangeTask } from "../hooks";
import { TaskGatingAssigneeInput } from "./gating/TaskGatingAssigneeInput";
import { StoryPointsInput } from "./StoryPointsInput";
import { TaskTagSelectField } from "./TaskTagSelectField";
import { TaskFormValues } from "./types";
import { PaidTaskRewardsSection } from "../reward/form/PaidTaskRewardsSection";

interface Props {
  task?: Task;
  workspaceId: string;
  mode: "update" | "create";
  values: Partial<TaskFormValues>;
  hidden?: boolean;
}

export const TaskFormSider: FC<Props> = ({
  task,
  workspaceId,
  mode,
  values,
  hidden,
}) => {
  const { workspace } = useWorkspaceDetails(workspaceId);

  const suggestedOwners = useRelevantUsers(
    workspaceId,
    RelevantUsersModes.OWNERS,
    { lazy: !!task, fallback: task?.owners }
  );

  const { taskTags, rootWorkspaceId } = useWorkspaceTaskTags(workspaceId);
  const canCreateTag = usePermission("create", {
    __typename: "TaskTag",
    workspaceId,
  });
  const canChange = useCanChangeTask(values, workspaceId, mode);

  return (
    <Row hidden={hidden}>
      <Col flex={1}>
        {!!task && <PaidTaskRewardsSection task={task} />}

        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select
            placeholder="Select a task status"
            disabled={!canChange("status")}
          >
            {getWorkspaceTaskStatuses(workspace).map((status) => (
              <Select.Option
                key={status}
                value={status}
                disabled={!canChange(`status[${status}]`)}
              >
                <Row align="middle" style={{ columnGap: 8 }}>
                  <TaskStatusIcon status={status} />
                  {STATUS_LABEL[status]}
                </Row>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <TaskGatingAssigneeInput
          mode={mode}
          task={task}
          workspaceId={workspaceId}
        />

        <Form.Item
          name="priority"
          label="Priority"
          hidden={
            !canChange("priority") && values.priority === TaskPriority.NONE
          }
        >
          <Select
            placeholder="Select a priority"
            disabled={!canChange("priority")}
          >
            {(Object.keys(PRIORITY_LABEL) as TaskPriority[]).map((priority) => (
              <Select.Option key={priority} value={priority}>
                <Space align="center">
                  <div style={{ lineHeight: 1, width: 16 }}>
                    <TaskPriorityIcon priority={priority} size={13} />
                  </div>
                  {PRIORITY_LABEL[priority]}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="storyPoints"
          label="Task Points"
          tooltip="Can be used very flexibly, e.g for time accounting and more."
          hidden={!canChange("storyPoints") && !values.storyPoints}
        >
          <StoryPointsInput disabled={!canChange("storyPoints")} />
        </Form.Item>

        {(canChange("tagIds") || !!values.tagIds?.length) && (
          <TaskTagSelectField
            name="tagIds"
            label="Tags"
            disabled={!canChange("tagIds")}
            allowCreate={canCreateTag}
            workspaceId={rootWorkspaceId}
            tags={taskTags}
          />
        )}

        <Form.Item
          name="ownerIds"
          label="Reviewers"
          hidden={!canChange("ownerIds") && !values.ownerIds?.length}
        >
          <UserSelect
            placeholder={
              <FormItemPlaceholder
                placeholder="No task reviewer..."
                icon={<UserOutlined />}
              />
            }
            disabled={!canChange("ownerIds")}
            mode="multiple"
            users={suggestedOwners.users}
            onDropdownVisibleChange={suggestedOwners.load}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
