import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { Thread, ThreadMessage } from "@dewo/app/graphql/types";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Dropdown, Menu, Modal } from "antd";
import React, { FC, useMemo } from "react";
import { useDeleteThreadMessage } from "./hooks";
import { UpdateThreadMessageInput } from "./UpdateThreadMessageForm";
import { ItemType } from "antd/lib/menu/hooks/useItems";

interface Props {
  thread: Thread;
  message: ThreadMessage;
  extraMenuItems?: ItemType[];
}

export const ThreadMessageOptionButton: FC<Props> = ({
  thread,
  message,
  extraMenuItems,
}) => {
  const messageForPermissions = useMemo(
    () => ({
      ...message,
      __thread__: { __task__: thread.task, __workspace__: thread.workspace },
    }),
    [message, thread]
  );
  const canEdit = usePermission("update", messageForPermissions);
  const canDelete = usePermission("delete", messageForPermissions);

  const deleteMessage = useDeleteThreadMessage();
  const editing = useToggle();

  const menuItems = useMemo(() => {
    const items: ItemType[] = [];
    if (canEdit) {
      items.push({
        key: "edit",
        icon: <EditOutlined />,
        onClick: editing.toggleOn,
        label: "Edit",
      });
    }

    if (canDelete) {
      items.push({
        key: "delete",
        danger: true,
        icon: <DeleteOutlined />,
        onClick: () => deleteMessage(message.id),
        label: "Delete",
      });
    }

    if (!!extraMenuItems?.length) {
      items.push(...extraMenuItems);
    }

    return items;
  }, [
    canEdit,
    canDelete,
    extraMenuItems,
    editing.toggleOn,
    deleteMessage,
    message.id,
  ]);

  if (!menuItems.length) return null;
  return (
    <>
      <Dropdown overlay={<Menu items={menuItems} />}>
        <Button
          icon={<MoreOutlined />}
          size="small"
          type="text"
          className="text-secondary"
        />
      </Dropdown>
      <Modal
        visible={editing.isOn}
        onCancel={editing.toggleOff}
        footer={null}
        title="Edit"
        destroyOnClose
      >
        <UpdateThreadMessageInput
          message={message}
          onDone={editing.toggleOff}
        />
      </Modal>
    </>
  );
};
