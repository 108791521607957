import React, { FC } from "react";
import { Avatar, AvatarProps, Tooltip, TooltipProps } from "antd";
import { GraphNodeDetails, GraphNodeType } from "../../graphql/types";
import { OrganizationAvatar } from "./OrganizationAvatar";
import { WorkspaceAvatar } from "./WorkspaceAvatar";
import { TeamOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { Emoji } from "../emoji/Emoji";

interface Props extends AvatarProps {
  node: GraphNodeDetails;
  tooltip?: Partial<TooltipProps>;
}

export const GraphNodeAvatar: FC<Props> = ({ node, ...otherProps }) => {
  if (node.type === GraphNodeType.ORGANIZATION && !!node.organization) {
    return (
      <OrganizationAvatar organization={node.organization} {...otherProps} />
    );
  }

  if (node.type === GraphNodeType.WORKSPACE && !!node.workspace) {
    return <WorkspaceAvatar workspace={node.workspace} {...otherProps} />;
  }

  if (!!node.icon || !!node.imageUrl || !!node.name) {
    return (
      <Tooltip title={node.name} placement="top" {...otherProps.tooltip}>
        <Avatar
          src={node.imageUrl}
          icon={
            !!node.icon ? (
              <Emoji name={node.icon} />
            ) : (
              node.name?.[0]?.toUpperCase() ?? <TeamOutlined />
            )
          }
          {...otherProps}
          className={classNames("bg-component", otherProps.className)}
        />
      </Tooltip>
    );
  }

  return null;
};
