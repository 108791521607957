import {
  DeleteOutlined,
  ExportOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { usePermissionFn } from "@dewo/app/contexts/PermissionsContext";
import { Task } from "@dewo/app/graphql/types";
import { eatClick } from "@dewo/app/util/eatClick";
import { Button, Dropdown, Menu, MenuItemProps, Popconfirm } from "antd";
import React, {
  BaseSyntheticEvent,
  CSSProperties,
  FC,
  useCallback,
} from "react";
import { useUpdateTask } from "../../hooks";

interface Props {
  task?: Task;
  style?: CSSProperties;
  onDelete(): void;
}

const ConvertToNormalTaskMenuItem: FC<{ taskId: string } & MenuItemProps> = ({
  taskId,
  ...menuItemProps
}) => {
  const updateTask = useUpdateTask();
  const handleConfirm = useCallback(
    (e: BaseSyntheticEvent | undefined) => {
      !!e && eatClick(e);
      updateTask({ id: taskId, parentTaskId: null });
    },
    [updateTask, taskId]
  );
  return (
    <Popconfirm
      icon={null}
      title="Convert this task to normal?"
      okText="Yes"
      onConfirm={handleConfirm}
    >
      <Menu.Item
        key="normalTask"
        children="Convert to normal task"
        icon={<ExportOutlined />}
        {...menuItemProps}
      />
    </Popconfirm>
  );
};

const DeleteTaskMenuItem: FC<{ onDelete(): void } & MenuItemProps> = ({
  onDelete,
  ...menuItemProps
}) => (
  <Popconfirm
    icon={null}
    title="Delete this subtask?"
    okType="danger"
    okText="Delete"
    onConfirm={useCallback(
      (e) => {
        !!e && eatClick(e);
        onDelete();
      },
      [onDelete]
    )}
  >
    <Menu.Item
      key="delete"
      icon={<DeleteOutlined />}
      danger
      children="Delete"
      {...menuItemProps}
    />
  </Popconfirm>
);

export const SubtaskOptionButton: FC<Props> = ({ task, style, onDelete }) => {
  const hasPermission = usePermissionFn();

  const menuItems = [
    !!task?.parentTaskId && hasPermission("create", task) && (
      <ConvertToNormalTaskMenuItem key="convert" taskId={task.id} />
    ),
    (!task || hasPermission("delete", task)) && (
      <DeleteTaskMenuItem key="delete" onDelete={onDelete} />
    ),
  ].filter((menuItem) => !!menuItem);

  if (!menuItems.length) return null;
  return (
    <div onClick={eatClick}>
      <Dropdown
        key="avatar"
        placement="bottomRight"
        trigger={["click"]}
        overlay={<Menu>{menuItems}</Menu>}
      >
        <Button
          type="text"
          size="small"
          icon={<MoreOutlined />}
          style={style}
        />
      </Dropdown>
    </div>
  );
};
