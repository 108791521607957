import { DeleteOutlined } from "@ant-design/icons";
import {
  CompensationFrequency,
  UpdateRoleCompensationInput,
  UpdateRoleInput,
  UpdateTaskRewardInput,
} from "@dewo/app/graphql/types";
import { Button, Col, Form, Row, Select, Space, Tooltip } from "antd";
import _ from "lodash";
import React, { FC, useMemo } from "react";
import { MultipleTaskRewardsTag } from "../../task/reward/TaskRewardTag";
import { useOrganizationTokens } from "../hooks";
import { compensationFrequencyMap } from "./utils";

const RewardTag: FC<{
  organizationId: string;
  rewards?: UpdateTaskRewardInput[];
}> = ({ organizationId, rewards }) => {
  const tokens = useOrganizationTokens(organizationId);
  const tokenById = useMemo(() => _.keyBy(tokens, "id"), [tokens]);
  if (!rewards || !tokens) return null;
  return (
    <MultipleTaskRewardsTag
      style={{ marginTop: 2, backgroundColor: "white", color: "black" }}
      rewards={rewards
        .map((r) => ({
          amount: r.amount,
          peggedToUsd: !!r.peggedToUsd,
          token: tokenById[r.tokenId],
        }))
        .filter((r) => !!r.token)}
    />
  );
};

const CompensationFrequencySelect: FC<{
  onChange?: (comp: CompensationFrequency) => void;
  value?: UpdateRoleCompensationInput[];
}> = ({ onChange, value }) => {
  return (
    <Select
      size="small"
      style={{ minWidth: 100 }}
      options={_.entries(compensationFrequencyMap).map(([value, label]) => ({
        value,
        label,
      }))}
      value={value?.[0]?.frequency}
      onChange={onChange}
    />
  );
};

export const CompensationList: FC<{
  compensation: UpdateRoleCompensationInput[];
  organizationId: string;
  onClick: () => void;
}> = ({ organizationId, compensation, onClick }) => {
  return (
    <>
      <Row hidden={!compensation.length}>
        <Col flex={1}>
          <Space style={{ width: "100%" }} align="center">
            <RewardTag
              organizationId={organizationId}
              rewards={compensation.map((r) => r.reward)}
            />
            <Form.Item style={{ marginBottom: 4 }}>per</Form.Item>
            <Form.Item<UpdateRoleInput>
              name="compensation"
              noStyle
              normalize={(
                frequency: CompensationFrequency,
                compensation: UpdateRoleCompensationInput[]
              ) => {
                const result = compensation?.map((a) => ({
                  ...a,
                  frequency,
                }));
                return result;
              }}
            >
              <CompensationFrequencySelect />
            </Form.Item>
          </Space>
        </Col>
        <Col>
          <Tooltip title="Delete">
            <Form.Item
              style={{ margin: 0 }}
              name="compensation"
              getValueFromEvent={() => []}
              trigger="onClick"
            >
              <Button icon={<DeleteOutlined />} type="text" />
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>

      <Button hidden={!!compensation?.length} onClick={onClick}>
        + Add compensation
      </Button>
    </>
  );
};
