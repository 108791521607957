import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUploadFile } from "@dewo/app/containers/fileUploads/hooks";
import { MarkdownEditorButtons } from "./MarkdownEditorButtons";
import { useMounted, useRunning, useToggle } from "@dewo/app/util/hooks";
import { message, Row, Typography } from "antd";
import DefaultTooltip from "rich-markdown-editor/dist/components/Tooltip";
import { keydownHandler, useDropHandler } from "./utils";
import { isLocalURL } from "next/dist/shared/lib/router/router";
import { theme } from "./theme";
import { eatClick } from "@dewo/app/util/eatClick";
import classNames from "classnames";
import { RichMarkdownComponent } from "./RichMarkdownComponent";
import { useRouter } from "next/router";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { useEmbeds } from "./embeds";
import RRichMarkdownEditor from "rich-markdown-editor";
import { Mentionable } from "rich-markdown-editor/dist/components/MentionMenu";
import { User } from "@dewo/app/graphql/types";
import { UserAvatar } from "../avatars/UserAvatar";
import { HeadlessCollapse } from "../HeadlessCollapse";

interface RichMarkdownEditorProps {
  mentionable?: User[];
  initialValue?: string;
  onChange?(description: string | undefined): void;
  onSave?(description: string | undefined): void;
  onDirty?(dirty: boolean): void;
  mode?: "create" | "update";
  editable: boolean;
  placeholder?: string;
  buttons?: FC<{
    disabled?: boolean;
    onSave?(e?: SyntheticEvent): void;
    onCancel?(e?: SyntheticEvent): void;
  }>;
  bordered?: boolean;
  simple?: boolean;
}
export const RichMarkdownEditor: FC<RichMarkdownEditorProps> = ({
  initialValue = "",
  mentionable,
  onChange,
  onSave,
  onDirty,
  mode = "create",
  editable,
  placeholder = 'Write your description here. Type "/" for commands.',
  buttons: Buttons = mode === "update" ? MarkdownEditorButtons : undefined,
  bordered,
  simple,
}) => {
  const className = classNames([
    "ant-input",
    !bordered && "dewo-field",
    !bordered && "dewo-field-focus-border",
  ]);

  const [uploadFile, isUploading] = useRunning(useUploadFile());
  const autosave = mode === "create";

  const [value, setValue] = useState(initialValue);
  const [savedValue, setSavedValue] = useState(value);
  const isEditing = useToggle(editable && mode === "create");

  const mounted = useMounted();
  useEffect(() => {
    if (autosave && mounted) onChange?.(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, autosave]);

  const handleChange = useCallback((value: () => string) => {
    try {
      const val = value();
      setValue(val);
    } catch (e) {
      // Sometimes throws on first "edit". Keeps working after so no issue
      console.error(e);
    }
  }, []);

  const handleSave = useCallback(
    (e) => {
      if (e) eatClick(e);
      isEditing.toggleOff();
      onChange?.(value);
      onSave?.(value);
      setSavedValue(value);
    },
    [isEditing, onChange, onSave, value]
  );

  const [resetKey, setResetKey] = useState(0);
  const handleCancel = useCallback(
    (e) => {
      if (e) eatClick(e);
      isEditing.toggleOff();
      setValue(savedValue);
      setResetKey((i) => i + 1);
    },
    [isEditing, savedValue]
  );

  const router = useRouter();
  const { logEvent } = useAmplitude();
  const handleLinkClick = useCallback(
    (href: string) => {
      if (isEditing.isOn) return;
      if (isLocalURL(href)) {
        router.push(href);
      } else {
        logEvent("Click link in rich markdown", { href });
        window.open(href, "_blank");
      }
    },
    [isEditing.isOn, router, logEvent]
  );

  const showSave = isEditing.isOn && savedValue !== value;
  useEffect(() => onDirty?.(showSave), [showSave, onDirty]);

  const dropHandler = useDropHandler();
  const embeds = useEmbeds();

  const disabledExtensions: RRichMarkdownEditor["props"]["disableExtensions"] =
    useMemo(
      () =>
        !simple
          ? ["emoji"]
          : [
              "blockquote",
              "checkbox_item",
              "checkbox_list",
              "code_fence",
              "container_notice",
              "emoji",
              "heading",
              "highlight",
              "table",
              "td",
              "tr",
              "th",
            ],
      [simple]
    );

  const mentionableRef = useRef(mentionable);
  mentionableRef.current = mentionable;

  const editorMentionable = useMemo(
    (): Mentionable[] | undefined =>
      mentionable?.map((user) => ({
        id: user.id,
        title: user.username,
        name: "mention",
        href: user.permalink,
        children: (
          <Row align="middle" style={{ columnGap: 8 }}>
            <UserAvatar user={user} size="small" />
            <Typography.Text>{user.username}</Typography.Text>
          </Row>
        ),
        attrs: {
          href: user.permalink,
          "data-name": `@${user.username}`,
        },
      })),
    [mentionable]
  );

  const editor = (
    <RichMarkdownComponent
      key={resetKey}
      tooltip={DefaultTooltip}
      mentionable={editorMentionable}
      uploadImage={async (file) => {
        const isLt2M = file.size / 1024 / 1024 < 40;
        if (!isLt2M) {
          message.error("File must be smaller than 40MB.");
          throw new Error("File must be smaller than 40MB.");
        }
        const url = await uploadFile(file);
        return url;
      }}
      onChange={handleChange}
      defaultValue={savedValue}
      embeds={embeds}
      theme={theme}
      placeholder={placeholder}
      onClickLink={handleLinkClick}
      readOnly={!editable}
      disableExtensions={disabledExtensions}
      handleDOMEvents={{
        drop: dropHandler,
        keydown: keydownHandler,
      }}
    />
  );

  if (!editable) {
    return editor;
  }

  return (
    <div
      onClick={isEditing.toggleOn}
      className={className}
      style={{ paddingLeft: 4 }}
    >
      {editor}
      {!!Buttons && (
        <HeadlessCollapse expanded={showSave}>
          <Row style={{ marginTop: 8 }} align="middle" justify="end">
            <Buttons
              onSave={handleSave}
              onCancel={handleCancel}
              disabled={isUploading}
            />
          </Row>
        </HeadlessCollapse>
      )}
    </div>
  );
};
