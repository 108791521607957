import getConfig from "next/config";

const nextConfig = getConfig().publicRuntimeConfig;

export const Constants = {
  PRIMARY_COLOR: "#7458ff",
  GRAPHQL_API_URL: nextConfig.GRAPHQL_API_URL as string,
  GRAPHQL_WS_URL: nextConfig.GRAPHQL_WS_URL as string,
  GITHUB_APP_URL: nextConfig.GITHUB_APP_URL as string,
  APP_URL: nextConfig.APP_URL as string,
  SENTRY_DSN: nextConfig.SENTRY_DSN as string,
  DEV_ROLE_ID: nextConfig.DEV_ROLE_ID as string,
  AMPLITUDE_API_KEY: nextConfig.AMPLITUDE_API_KEY as string,
  APOLLO_QUERY_LOGGING: nextConfig.APOLLO_QUERY_LOGGING as boolean,
  COORDINAPE_INTEGRATION_USER_ID:
    nextConfig.COORDINAPE_INTEGRATION_USER_ID as string,
  LANDING_PAGE_GRANTS_NODE_ID: nextConfig.LANDING_PAGE_GRANTS_NODE_ID as string,
  LANDING_PAGE_FEATURED_ORGANIZATIONS_NODE_ID:
    nextConfig.LANDING_PAGE_FEATURED_ORGANIZATIONS_NODE_ID as string,
  ENVIRONMENT: nextConfig.ENVIRONMENT as "prod" | "demo" | "dev",
  NUM_DECIMALS_IN_USD_PEG: 6,
  hotjarConfig: { ID: 2731946, version: 6 },
};

export const siteTitle = "Dework";
export const siteFullTitle =
  "Dework — The task manager for DAOs and decentralized work";
export const siteDescription =
  "The task manager for DAOs and decentralized work";
export const siteURL = "https://dework.xyz";

const docsBase = "https://dework.gitbook.io/product-docs";

export const deworkSocialLinks = {
  twitter: "https://twitter.com/deworkxyz",
  discord: "https://discord.gg/rPEsPzShd7",
  gitbook: {
    index: docsBase,
    connectingToDiscord: `${docsBase}/guides/connecting-to-discord`,
    connectingToGithub: `${docsBase}/integrations/github`,
    bountyTypesAndGating: `${docsBase}/fundamentals/bounty-types-and-gating`,
    payments: `${docsBase}/guides-for-orgs/manage-payments`,
    permissions: `${docsBase}/guides-for-orgs/permissions`,
    createBounty: `${docsBase}/guides-for-orgs/creating-your-first-bounty`,
    browsingDaos: `${docsBase}/guides-for-contributors/browsing-daos-on-dework`,
    featureRole: `${docsBase}/guides-for-orgs/featuring-a-role`,
    revenueSharing: `${docsBase}/guides-for-contributors/reputation-staking`,
  },
};
