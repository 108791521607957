import { FormSection } from "@dewo/app/components/form/FormSection";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  TaskSubmissionStatus,
  TaskDetails,
  TaskGatingType,
  TaskStatus,
} from "@dewo/app/graphql/types";
import { Card, Divider, List, Typography } from "antd";
import _ from "lodash";
import React, { FC, useMemo } from "react";
import { TaskSubmissionListItem } from "./TaskSubmissionListItem";

interface Props {
  task: TaskDetails;
}

export const TaskSubmissionsSection: FC<Props> = ({ task }) => {
  const isDone = task.status === TaskStatus.DONE;

  const { user } = useAuthContext();
  const myRejectedSubmissions = useMemo(
    () =>
      task.submissions.filter(
        (s) =>
          s.user.id === user?.id && s.status === TaskSubmissionStatus.REJECTED
      ),
    [task.submissions, user?.id]
  );
  const currentSubmission = useMemo(
    () =>
      task.submissions.find(
        (s) =>
          s.user.id === user?.id && s.status === TaskSubmissionStatus.PENDING
      ),
    [task.submissions, user?.id]
  );

  const submissions = useMemo(
    () =>
      _.groupBy(
        _.sortBy(task.submissions, (s) => s.createdAt),
        (s) => s.status
      ),
    [task.submissions]
  );

  const canSubmit = usePermission("submit", task);
  const canManageSubmissions = usePermission("update", task, "submissions");

  if (!canSubmit && !submissions[TaskSubmissionStatus.ACCEPTED]?.length) {
    return null;
  }

  const components = [
    submissions[TaskSubmissionStatus.ACCEPTED]?.length && (
      <FormSection key="approved" label="Approved Submissions">
        <Card size="small" className="dewo-card-highlighted">
          <List
            dataSource={submissions[TaskSubmissionStatus.ACCEPTED]}
            renderItem={(submission) => (
              <TaskSubmissionListItem task={task} submission={submission} />
            )}
          />
        </Card>
      </FormSection>
    ),
    canManageSubmissions &&
      !task.submissions.length &&
      task.gating === TaskGatingType.OPEN_SUBMISSION && (
        <Typography.Paragraph key="empty" type="secondary">
          No submissions to review
        </Typography.Paragraph>
      ),
    canManageSubmissions &&
      !!submissions[TaskSubmissionStatus.PENDING]?.length && (
        <FormSection
          key="all"
          label={isDone ? "Other Submissions" : "Pending Submissions"}
        >
          <Card
            size="small"
            bordered={!isDone}
            className={!isDone ? "dewo-card-highlighted" : undefined}
          >
            <List
              dataSource={submissions[TaskSubmissionStatus.PENDING]}
              renderItem={(submission) => (
                <TaskSubmissionListItem
                  task={task}
                  submission={submission}
                  disabled={isDone}
                />
              )}
            />
          </Card>
        </FormSection>
      ),
    !isDone &&
      !!currentSubmission &&
      currentSubmission.status === TaskSubmissionStatus.PENDING && (
        <FormSection key="your" label="Your Submission">
          <Card size="small" className="dewo-card-highlighted">
            <TaskSubmissionListItem
              task={task}
              submission={currentSubmission}
              editable
            />
          </Card>
        </FormSection>
      ),
    !!myRejectedSubmissions.length && (
      <FormSection key="all" label="Your Rejected Submissions">
        <Card size="small" className="dewo-card-highlighted">
          <List
            dataSource={myRejectedSubmissions}
            renderItem={(submission) => (
              <TaskSubmissionListItem task={task} submission={submission} />
            )}
          />
        </Card>
      </FormSection>
    ),
  ].filter((c) => !!c);

  if (!components.length) return null;
  return (
    <div>
      <Divider>Submissions</Divider>
      {components}
    </div>
  );
};
