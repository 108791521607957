import { Modal } from "antd";
import { useCallback } from "react";

export function useUnsavedChangesConfirmation(
  enabled: boolean,
  onConfirm: () => void
) {
  return useCallback(() => {
    if (!enabled) {
      onConfirm();
    } else {
      Modal.confirm({
        title: "Unsaved Changes",
        content: "You have unsaved changes! Do you want to discard them?",
        maskClosable: true,
        icon: null,
        cancelText: "Keep editing",
        okText: "Discard",
        okButtonProps: { danger: true },
        onOk: () => onConfirm(),
      });
    }
  }, [enabled, onConfirm]);
}
