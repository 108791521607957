import { DollarOutlined } from "@ant-design/icons";
import { Tag, TagProps, Tooltip } from "antd";
import React, { FC } from "react";

export const TaskOpenToBidsTag: FC<TagProps> = (props) => (
  <Tooltip title="This is a paid task, but the issuer hasn't set a fixed bounty. You can request funding when applying to the task.">
    <Tag icon={<DollarOutlined />} {...props}>
      Open to Bids
    </Tag>
  </Tooltip>
);
