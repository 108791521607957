import { CreateUserPromptFlow } from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { Button, ButtonProps } from "antd";
import React, { FC, useCallback } from "react";
import { useCreateUserPrompt } from "../prompts/hooks";

export const CreateOrganizationButton: FC<ButtonProps> = (buttonProps) => {
  const createUserPrompt = useCreateUserPrompt();
  const [handleClick, loading] = useRunning(
    useCallback(
      () => createUserPrompt(CreateUserPromptFlow.CREATE_ORGANIZATION),
      [createUserPrompt]
    )
  );
  return <Button {...buttonProps} loading={loading} onClick={handleClick} />;
};
