import React, { FC, ReactElement, useMemo } from "react";
import { TaskGatingType, Task, TaskStatus } from "@dewo/app/graphql/types";
import { Form, Button, Typography, Space } from "antd";
import { TaskFormValues } from "./types";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useTaskRoles } from "../hooks";
import { useOrganization } from "../../organization/hooks";
import * as Colors from "@ant-design/colors";
import { LockIcon } from "@dewo/app/components/icons/task/Lock";
import Link from "next/link";
import { OnboardingAlert } from "@dewo/app/components/OnboardingAlert";
import { RoleTag } from "@dewo/app/components/tags/RoleTag";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import _ from "lodash";

const useTaskGatingType = (gating: TaskGatingType | undefined, task?: Task) => {
  const canClaim = usePermission("update", task, "assigneeIds");
  const canChangeTask = usePermission("update", task, "owners");
  const canApply = usePermission("apply", task);

  if (!task || !gating || canChangeTask) return undefined;
  if (gating === TaskGatingType.ASSIGNEES) return TaskGatingType.ASSIGNEES;
  if (gating === TaskGatingType.OPEN_SUBMISSION)
    return TaskGatingType.OPEN_SUBMISSION;

  if (canClaim) return TaskGatingType.ROLES;
  if (canApply) return TaskGatingType.APPLICATION;
  return "RESERVED";
};

const colors = {
  [TaskGatingType.ROLES]: Colors.magenta[4],
  [TaskGatingType.APPLICATION]: Colors.blue[4],
  [TaskGatingType.OPEN_SUBMISSION]: "#D6A11C",
  RESERVED: Colors.grey[9],
};

export const TaskGatingAlert: FC<{
  task: Task | undefined;
  organizationId: string | undefined;
}> = ({ task, organizationId }) => {
  const form = Form.useFormInstance<TaskFormValues>();
  const gating = Form.useWatch("gating", form);
  const taskGatingType = useTaskGatingType(gating, task);
  const { applyRoles, claimRoles } = useTaskRoles(task);
  const organization = useOrganization(organizationId);

  const allRoles = useMemo(
    () => _.uniqBy([...(applyRoles || []), ...(claimRoles || [])], (r) => r.id),
    [applyRoles, claimRoles]
  );

  const GatingAlert: FC<{
    description: string | ReactElement;
    message: string | ReactElement;
    color?: string;
    icon?: ReactElement;
    closeable?: boolean;
  }> = ({ description, message, color, icon, closeable = true }) => (
    <OnboardingAlert
      name={`TaskForm.v1.Gating.${taskGatingType}`}
      closable={closeable}
      closeText={
        !!closeable && (
          <div>
            Dismiss <CloseOutlined />
          </div>
        )
      }
      message={message}
      description={
        <Typography.Text type="secondary">{description}</Typography.Text>
      }
      showIcon
      style={{
        backgroundColor: `${color}20`,
        borderColor: color,
        marginBottom: 20,
      }}
      icon={icon}
    />
  );

  if (
    task?.status !== TaskStatus.TODO ||
    taskGatingType === TaskGatingType.ASSIGNEES
  ) {
    return null;
  }

  const color = taskGatingType && colors[taskGatingType];

  if (taskGatingType === TaskGatingType.APPLICATION) {
    const everyone = !applyRoles?.length || applyRoles.some((r) => r.fallback);
    return (
      <GatingAlert
        color={color}
        message={
          everyone ? (
            "This task is Open to Applications."
          ) : (
            <Typography.Text>
              You and members with{" "}
              {applyRoles?.map((r) => (
                <RoleTag role={r} key={r.id} />
              ))}
              can apply to this task.
            </Typography.Text>
          )
        }
        description="Click “I’m Interested” to express your interest to work on this task. If you’re a good fit, the task reviewer will assign you to the task. After being assigned, other contributors won’t be able to apply anymore and you will be able to start working and submit your work."
        icon={<InfoCircleOutlined style={{ color }} />}
      />
    );
  }
  if (taskGatingType === TaskGatingType.OPEN_SUBMISSION) {
    return (
      <GatingAlert
        color={color}
        message="This task is Open to Submissions"
        description="Anyone can work on this task and submit their work by clicking “Submit work”. The task reviewer will be able to approve one or more of these submissions. Unless specified otherwise, not all submissions will get rewarded."
        icon={<InfoCircleOutlined style={{ color }} />}
      />
    );
  }
  if (taskGatingType === TaskGatingType.ROLES) {
    return (
      <GatingAlert
        color={color}
        message={
          <Typography.Text>
            You and members with{" "}
            {claimRoles?.map((r) => (
              <RoleTag role={r} key={r.id} />
            ))}
            can claim this task
          </Typography.Text>
        }
        description="By clicking “Claim Task”, you can self-assign to this task to start working on it. You will become the task assignee and others won’t be able to claim it anymore."
        icon={<InfoCircleOutlined style={{ color }} />}
      />
    );
  }
  if (taskGatingType === "RESERVED") {
    const featuredRoles = allRoles.filter((r) => r.featured);
    const allNames = allRoles.map((r) => r.name);
    const allRoleString =
      allNames.length === 1
        ? allNames[0]
        : allNames.slice(0, -1).join(", ") + " or " + allNames.slice(-1);
    return (
      <GatingAlert
        color={color}
        message={
          <Typography.Text>
            This task is reserved to members with roles{" "}
            {allRoles?.map((r) => (
              <RoleTag role={r} key={r.id} />
            ))}
          </Typography.Text>
        }
        closeable={false}
        description={
          <>
            <Typography.Paragraph style={{ marginBottom: 8 }} type="secondary">
              To work on this task, first get {allRoleString} roles.
            </Typography.Paragraph>
            <Space>
              {featuredRoles.map((r) => (
                <Link
                  href={`${organization?.permalink}/roles/${r.id}`}
                  key={r.id}
                >
                  <Button size="small" icon="👉 ">
                    Get {r.name}
                  </Button>
                </Link>
              ))}
            </Space>
          </>
        }
        icon={<LockIcon style={{ width: 22 }} />}
      />
    );
  }

  return null;
};
