import { useApolloClient } from "@apollo/client";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { EmbedDescriptor } from "rich-markdown-editor/dist/types";
import { DeworkTaskEmbed } from "./DeworkTask";
import { FigmaDocumentEmbed } from "./FigmaDocument";

export function useEmbeds(): EmbedDescriptor[] {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const deworkTaskEmbed = useMemo(
    () => new DeworkTaskEmbed(apolloClient, router),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return useMemo(
    () => [FigmaDocumentEmbed, deworkTaskEmbed],
    [deworkTaskEmbed]
  );
}
