import gql from "graphql-tag";
import { workspaceSection } from "../fragments/workspace";

export const createWorkspaceSection = gql`
  mutation CreateWorkspaceSectionMutation(
    $input: CreateWorkspaceSectionInput!
  ) {
    section: createWorkspaceSection(input: $input) {
      ...WorkspaceSection
      organization {
        id
        workspaceSections {
          ...WorkspaceSection
        }
      }
    }
  }

  ${workspaceSection}
`;

export const updateWorkspaceSection = gql`
  mutation UpdateWorkspaceSectionMutation(
    $input: UpdateWorkspaceSectionInput!
  ) {
    section: updateWorkspaceSection(input: $input) {
      ...WorkspaceSection
      organization {
        id
        workspaceSections {
          ...WorkspaceSection
        }
      }
    }
  }

  ${workspaceSection}
`;
