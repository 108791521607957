import {
  CalendarOutlined,
  EditOutlined,
  ExportOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { RichMarkdownViewer } from "@dewo/app/components/richMarkdown/RichMarkdownViewer";
import { PaymentStatusTag } from "@dewo/app/components/tags/PaymentStatusTag";
import {
  usePermission,
  usePermissionFn,
} from "@dewo/app/contexts/PermissionsContext";
import {
  PaymentStatus,
  TaskApplicationStatus,
  TaskDetails,
  TaskReward,
  TaskStatus,
  TaskSubmissionStatus,
  Thread,
  ThreadMessage,
  User,
} from "@dewo/app/graphql/types";
import { AtLeast } from "@dewo/app/types/general";
import { Tag, Typography } from "antd";
import { Diff, DiffArray, DiffEdit, DiffNew } from "deep-diff";
import _ from "lodash";
import moment from "moment";
import React, { ComponentType, FC, ReactNode, useMemo } from "react";
import {
  useOrganizationTokens,
  useOrganizationUsers,
} from "../../organization/hooks";
import { STATUS_LABEL } from "../../task/board/util";
import { MultipleTaskRewardsTag } from "../../task/reward/TaskRewardTag";

export interface TaskThreadMessageTimelineItem {
  type: "message";
  key: string;
  date: string;
  thread: Thread;
  message: ThreadMessage;
  user?: User;
}

export interface TaskTextTimelineItem {
  type: "text";
  key: string;
  date: string;
  user?: User;
  icon?: ComponentType;
  text: ReactNode;
  details?: ReactNode;
}

export type TaskTimelineItem =
  | TaskThreadMessageTimelineItem
  | TaskTextTimelineItem;

const LazyMultipleTaskRewardsTag: FC<{
  rewards: AtLeast<TaskReward, "amount" | "tokenId">[];
  organizationId: string;
}> = ({ rewards, organizationId }) => {
  const tokens = useOrganizationTokens(organizationId);
  const tokenById = useMemo(() => _.keyBy(tokens, "id"), [tokens]);
  const newRewards = useMemo(
    () =>
      rewards
        .map((r) => ({ ...r, token: tokenById[r.tokenId] }))
        .filter((r) => !!r.token),
    [rewards, tokenById]
  );
  return <MultipleTaskRewardsTag rewards={newRewards} />;
};

export function useTaskThreadItems(task: TaskDetails): TaskTimelineItem[] {
  const hasPermission = usePermissionFn();
  const showSubmissions = usePermission("update", task, "submissions");

  const auditLogEventsByField = useMemo(
    () =>
      _.groupBy(task.auditLog, (l) => (l.diff as Diff<any>[])?.[0]?.path?.[0]),
    [task.auditLog]
  );

  const organizationId = task.workspace.organizationId;
  const { users } = useOrganizationUsers(organizationId);
  const userById = useMemo(() => _.keyBy(users, "id"), [users]);

  return useMemo(() => {
    const items: TaskTimelineItem[] = [
      {
        type: "text",
        key: "createdAt",
        date: task.createdAt,
        user: task.creator ?? undefined,
        icon: CalendarOutlined,
        text: `${task.creator?.username ?? "Someone"} created this task`,
      },
      ...[
        ...(auditLogEventsByField["assigneeIds"] || []),
        ...(auditLogEventsByField["ownerIds"] || []),
      ]?.map(
        (event): TaskTextTimelineItem => ({
          key: event.id,
          date: event.createdAt,
          type: "text",
          user: event.user ?? undefined,
          icon: EditOutlined,
          text: (() => {
            const diff = event.diff as Diff<string[]>[];
            const added = diff
              .filter((d) => d.kind === "N")
              .map((id) => userById[id.path?.[1]])
              .filter((user) => !!user);
            const removed = diff
              .filter((d) => d.kind === "D")
              .map((id) => userById[id.path?.[1]])
              .filter((user) => !!user);
            return (
              <>
                {event.user?.username ?? "Someone"}{" "}
                {added.length ? (
                  <>
                    added{" "}
                    {added.map((user) => (
                      <React.Fragment key={user.id}>
                        <UserAvatar size={16} user={user} /> {user.username}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}{" "}
                {removed.length && added.length ? "and " : null}
                {removed.length ? (
                  <>
                    removed{" "}
                    {removed.map((user) => (
                      <React.Fragment key={user.id}>
                        <UserAvatar size={16} user={user} /> {user.username}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}{" "}
                {removed.length ? "from" : "to"}{" "}
                {event.diff[0].path[0] === "ownerIds"
                  ? "reviewers"
                  : "assignees"}
              </>
            );
          })(),
        })
      ),
      ...(auditLogEventsByField["rewards"] || [])?.map(
        (event): TaskTextTimelineItem => ({
          key: event.id,
          date: event.createdAt,
          type: "text",
          user: event.user ?? undefined,
          icon: EditOutlined,
          text: (() => {
            const rewards = (event.diff as DiffArray<any>[])
              .flatMap((d) => (d.item as DiffNew<any>)?.rhs)
              .filter((r) => !!r);
            if (!rewards) return null;
            return (
              <>
                {event.user?.username ?? "Someone"} changed the reward to{" "}
                <LazyMultipleTaskRewardsTag
                  organizationId={organizationId}
                  rewards={rewards}
                />
              </>
            );
          })(),
        })
      ),
      ...(auditLogEventsByField["status"] || [])?.map(
        (event): TaskTextTimelineItem => ({
          key: event.id,
          date: event.createdAt,
          type: "text",
          user: event.user ?? undefined,
          icon: EditOutlined,
          text: (() => {
            const statusDiff = (event.diff as Diff<any>[]).find(
              (d): d is DiffEdit<any> =>
                d.kind === "E" && d.path?.[0] === "status"
            )!;
            return (
              <>
                {event.user?.username ?? "Someone"} changed the status to
                <Tag style={{ margin: "0 4px" }}>
                  {STATUS_LABEL[statusDiff.rhs as TaskStatus]}
                </Tag>
              </>
            );
          })(),
        })
      ),
      ...task.nfts.map(
        (nft): TaskTextTimelineItem => ({
          key: nft.id,
          date: nft.createdAt,
          type: "text",
          icon: LinkOutlined,
          text: (
            <>
              On-chain proof minted
              {nft.payment.status === PaymentStatus.CONFIRMED &&
              !!nft.explorerUrl ? (
                <a
                  target="_blank"
                  href={nft.explorerUrl}
                  rel="noreferrer"
                  style={{ marginLeft: 8 }}
                >
                  <Tag color="green" icon={<ExportOutlined />}>
                    View on OpenSea
                  </Tag>
                </a>
              ) : (
                <PaymentStatusTag
                  status={nft.payment.status}
                  style={{ marginLeft: 8 }}
                />
              )}
            </>
          ),
        })
      ),
      ...task.applications
        .filter((a) => hasPermission("read", a))
        .map(
          (a): TaskTimelineItem => ({
            type: "text",
            key: [a.id, "created"].join("-"),
            date: a.createdAt,
            user: a.user,
            text: `${a.user.username} applied to this task`,
            details: (
              <>
                <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
                  {moment(a.startDate).format("DD/MM/YYYY") +
                    " - " +
                    moment(a.endDate).format("DD/MM/YYYY") +
                    " (" +
                    moment
                      .duration(moment(a.endDate).diff(moment(a.startDate)))
                      .asDays() +
                    " days)"}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ margin: 0 }}>
                  {a.message}
                </Typography.Paragraph>
              </>
            ),
          })
        ),
      ...task.applications
        .filter((a) => hasPermission("read", a))
        .filter(
          (a) =>
            a.status === TaskApplicationStatus.REJECTED ||
            a.status === TaskApplicationStatus.ACCEPTED
        )
        .map(
          (a): TaskTimelineItem => ({
            type: "text",
            key: [a.id, "updated"].join("-"),
            date: a.updatedAt,
            user: a.user,
            text: `${a.user.username}'s application has been ${
              a.status === TaskApplicationStatus.REJECTED
                ? "rejected"
                : "accepted"
            }`,
            details: (
              <>
                <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
                  {moment(a.startDate).format("DD/MM/YYYY") +
                    " - " +
                    moment(a.endDate).format("DD/MM/YYYY") +
                    " (" +
                    moment
                      .duration(moment(a.endDate).diff(moment(a.startDate)))
                      .asDays() +
                    " days)"}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ margin: 0 }}>
                  {a.message}
                </Typography.Paragraph>
              </>
            ),
          })
        ),
    ];

    if (showSubmissions) {
      items.push(
        ...task.submissions.map(
          (submission): TaskTimelineItem => ({
            type: "text",
            key: [submission.id, "created"].join("-"),
            date: submission.createdAt,
            user: submission.user,
            text: `${submission.user.username} created a submission`,
            details: <RichMarkdownViewer value={submission.content} />,
          })
        )
      );

      items.push(
        ...task.submissions
          .filter(
            (submission) =>
              submission.updatedAt &&
              (submission.status === TaskSubmissionStatus.ACCEPTED ||
                submission.status === TaskSubmissionStatus.REJECTED)
          )
          .map(
            (submission): TaskTimelineItem => ({
              type: "text",
              key: [submission.id, "updated"].join("-"),
              date: submission.updatedAt,
              user: submission.user,
              text: `${submission.user.username}'s submission has been ${
                submission.status === TaskSubmissionStatus.ACCEPTED
                  ? "accepted"
                  : "rejected"
              }`,
              details: (
                <RichMarkdownEditor
                  initialValue={submission.content}
                  editable={false}
                />
              ),
            })
          )
      );
    }

    if (!!task.thread) {
      items.push(
        ...task.thread.messages.map(
          (message): TaskThreadMessageTimelineItem => ({
            type: "message",
            key: message.id,
            thread: task.thread!,
            message,
            date: message.createdAt,
            user: message.sender,
          })
        )
      );
    }

    return _.sortBy(items, (i) => i.date);
  }, [
    task,
    auditLogEventsByField,
    showSubmissions,
    userById,
    organizationId,
    hasPermission,
  ]);
}

export function useMentionableUsers(task: TaskDetails): User[] {
  const { users } = useOrganizationUsers(task.workspace.organizationId);
  return useMemo(
    () =>
      _.uniqBy(
        [
          ...task.assignees,
          ...(task.thread?.messages.map((m) => m.sender) ?? []),
          ...task.submissions.map((s) => s.user),
          ...task.applications.map((a) => a.user),
          ...task.owners,
          ...(users ?? []),
        ],
        (u) => u.id
      ),
    [
      task.applications,
      task.assignees,
      task.owners,
      task.submissions,
      task.thread?.messages,
      users,
    ]
  );
}
