import { useApolloClient, useMutation } from "@apollo/client";
import * as Queries from "@dewo/app/graphql/queries";
import {
  CreateTaskViewInput,
  TaskView,
  CreateTaskViewMutation,
  CreateTaskViewMutationVariables,
  Task,
  TaskViewFilterType,
  TaskViewGroupBy,
  TaskStatus,
  TaskViewSortByField,
  TaskViewSortByDirection,
  UpdateTaskViewInput,
  UpdateTaskViewMutation,
  UpdateTaskViewMutationVariables,
  TaskViewType,
  SearchTasksInput,
  TaskWithOrganization,
  GetPaginatedTasksQuery,
  GetPaginatedTasksQueryVariables,
} from "@dewo/app/graphql/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createTaskView,
  updateTaskView,
} from "@dewo/app/graphql/mutations/task";
import { useTaskViewContext } from "./TaskViewContext";

import _ from "lodash";
import { useTaskViewSearchContext } from "./TaskViewSearchContext";

export function useCreateTaskView(): (
  input: CreateTaskViewInput
) => Promise<TaskView> {
  const [mutation] = useMutation<
    CreateTaskViewMutation,
    CreateTaskViewMutationVariables
  >(createTaskView);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.taskView;
    },
    [mutation]
  );
}

export function useUpdateTaskView(): (
  input: UpdateTaskViewInput
) => Promise<TaskView> {
  const [mutation] = useMutation<
    UpdateTaskViewMutation,
    UpdateTaskViewMutationVariables
  >(updateTaskView);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.taskView;
    },
    [mutation]
  );
}

export function taskViewToSearchInput(
  view: TaskView,
  searchQuery: string | undefined
): SearchTasksInput {
  const filter = (type: TaskViewFilterType) =>
    view.filters.find((f) => f.type === type);
  const dueDate = filter(TaskViewFilterType.DUE_DATE)?.dueDate;
  return {
    organizationIds: !!view.organizationId
      ? [view.organizationId]
      : filter(TaskViewFilterType.ORGANIZATION)?.organizationIds,
    workspaceSectionIds: !!view.workspaceSectionId
      ? [view.workspaceSectionId]
      : undefined,
    workspaceIds: !!view.workspaceId ? [view.workspaceId] : undefined,
    priorities: filter(TaskViewFilterType.PRIORITIES)?.priorities,
    assigneeIds: filter(TaskViewFilterType.ASSIGNEES)?.assigneeIds,
    ownerIds: filter(TaskViewFilterType.OWNERS)?.ownerIds,
    tagIds: filter(TaskViewFilterType.TAGS)?.tagIds,
    roleIds: filter(TaskViewFilterType.ROLES)?.roleIds,
    skillIds: filter(TaskViewFilterType.SKILLS)?.skillIds,
    templateIds: filter(TaskViewFilterType.TEMPLATE)?.templateIds,
    name: !!searchQuery ? searchQuery : undefined,
    applicantIds: filter(TaskViewFilterType.APPLICANTS)?.applicantIds,
    parentTaskId:
      filter(TaskViewFilterType.SUBTASKS)?.subtasks === true ? undefined : null,
    sortBy: {
      field: view.sortBys[0]?.field ?? TaskViewSortByField.createdAt,
      direction: view.sortBys[0]?.direction ?? TaskViewSortByDirection.DESC,
    },
    dueDate: _.omit(dueDate, "__typename"),
  };
}

export interface TaskViewLayoutItem {
  value: TaskStatus;
  type: TaskViewGroupBy;
  query: SearchTasksInput;
}

export function useTaskViewLayoutItems() {
  const { currentView } = useTaskViewContext();
  const { query } = useTaskViewSearchContext();
  return useMemo<TaskViewLayoutItem[]>(() => {
    if (!currentView) return [];
    if (currentView.groupBy === TaskViewGroupBy.status) {
      const filter = (type: TaskViewFilterType) =>
        currentView.filters.find((f) => f.type === type);

      const statusFilter = filter(TaskViewFilterType.STATUSES);
      const statuses =
        currentView?.type === TaskViewType.LIST
          ? [
              TaskStatus.IN_REVIEW,
              TaskStatus.IN_PROGRESS,
              TaskStatus.TODO,
              TaskStatus.BACKLOG,
              TaskStatus.DONE,
            ]
          : [
              TaskStatus.BACKLOG,
              TaskStatus.TODO,
              TaskStatus.IN_PROGRESS,
              TaskStatus.IN_REVIEW,
              TaskStatus.DONE,
            ];

      return statuses
        .filter((s): s is TaskStatus => !!s)
        .filter(
          (s) => !statusFilter?.statuses || statusFilter?.statuses?.includes(s)
        )
        .map(
          (status): TaskViewLayoutItem => ({
            value: status,
            type: TaskViewGroupBy.status,
            query: {
              ...taskViewToSearchInput(currentView, query),
              statuses: [status],
            },
          })
        );
    }

    return [];
  }, [currentView, query]);
}

export interface TaskViewLayoutData<
  TaskType extends Task | TaskWithOrganization = Task | TaskWithOrganization
> {
  tasks?: TaskType[];
  cursor?: string;
  total?: number;
  filter: SearchTasksInput;
  hasMore: boolean;
  loading: boolean;
  fetchMore(): void;
  refetch(): void;
}

export function useTaskViewLayoutData<
  TaskType extends Task | TaskWithOrganization = Task | TaskWithOrganization
>(
  filters: SearchTasksInput[],
  options: {
    filter?(task: Task): boolean;
    sort?(a: Task, b: Task): number;
    withOrganization?: boolean;
  } = {}
): TaskViewLayoutData<TaskType>[] {
  const apollo = useApolloClient();

  const [fetchingMore, setFetchingMore] = useState<Record<string, boolean>>({});
  const [forceUpdater, setForceUpdater] = useState(0);
  const forceUpdate = useMemo(
    () => _.debounce(() => setForceUpdater((prev) => prev + 1), 100),
    []
  );

  const observables = useMemo(() => {
    return filters.map((filter) =>
      apollo.watchQuery<
        GetPaginatedTasksQuery,
        GetPaginatedTasksQueryVariables
      >({
        query: options.withOrganization
          ? Queries.paginatedTasksWithOrganization
          : Queries.paginatedTasks,
        variables: { filter },
        fetchPolicy: "cache-first",
      })
    );
  }, [apollo, filters, options.withOrganization]);

  useEffect(() => {
    const subscriptions = observables.map((o) => o.subscribe(forceUpdate));
    return () => {
      subscriptions.map((s) => s.unsubscribe());
    };
  }, [observables, forceUpdate]);

  return useMemo(
    () =>
      observables.map((obs) => {
        const res = obs.getCurrentResult();
        let tasks = res.data?.paginated.tasks ?? undefined;
        if (!!options.filter) tasks = tasks?.filter(options.filter);
        if (!!options.sort) tasks = tasks?.sort(options.sort);
        return {
          tasks: tasks as TaskType[] | undefined,
          filter: obs.variables?.filter!,
          cursor: res.data?.paginated.cursor ?? undefined,
          total: res.data?.paginated.total ?? undefined,
          hasMore: !res.data || !!res.data.paginated.cursor,
          loading: (res.loading && !res.data) || fetchingMore[obs.queryId],
          fetchMore: async () => {
            if (!!res.data?.paginated.cursor) {
              setFetchingMore((prev) => ({ ...prev, [obs.queryId]: true }));
              return obs
                .fetchMore({ variables: { cursor: res.data.paginated.cursor } })
                .finally(() =>
                  setFetchingMore((prev) => ({ ...prev, [obs.queryId]: false }))
                );
            }
          },
          refetch: () => obs.refetch(),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [observables, forceUpdater, fetchingMore, options.filter, options.sort]
  );
}

export function useSubtasks(parentTaskId: string | undefined): {
  tasks?: Task[];
  refetch(): unknown;
} {
  const queries = useMemo(
    (): SearchTasksInput[] =>
      !!parentTaskId
        ? [
            {
              parentTaskId,
              sortBy: {
                field: TaskViewSortByField.sortKey,
                direction: TaskViewSortByDirection.ASC,
              },
            },
          ]
        : [],
    [parentTaskId]
  );

  const [data] = useTaskViewLayoutData(queries);
  useEffect(() => {
    if (!data?.loading && data?.hasMore) {
      data?.fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.loading]);

  return useMemo(
    () => ({
      tasks: !!data?.tasks ? _.sortBy(data.tasks, "sortKey") : undefined,
      refetch: data?.refetch ?? (() => {}),
    }),
    [data]
  );
}
