import gql from "graphql-tag";
import { fundingSession } from "./fragments/funding";
import { network, payment, token } from "./fragments/payment";
import { workspace } from "./fragments/workspace";
import { skill } from "./fragments/skill";
import { subtask, taskReward, taskTag, taskView } from "./fragments/task";
import { user, userPrompt } from "./fragments/user";
import { workspaceSection } from "./fragments/workspace";
import { organization } from "./fragments/organization";
import { thread } from "./fragments/thread";
import { node, nodeChildrenDetails } from "./fragments/graph";
import { stakedReputation } from "./fragments/staking";

export const entityDetail = gql`
  fragment EntityDetail on EntityDetail {
    id
    type
    value
  }
`;

export const auditLogEvent = gql`
  fragment AuditLogEvent on AuditLogEvent {
    id
    createdAt
    user {
      ...User
    }
    sessionId
    diff
  }

  ${user}
`;

export const organizationWithTokens = gql`
  fragment OrganizationWithTokens on Organization {
    id
    tokens {
      ...PaymentToken
      network {
        ...PaymentNetwork
      }
    }
  }
  ${token}
  ${network}
`;

export const organizationTag = gql`
  fragment OrganizationTag on OrganizationTag {
    id
    label
    color
    createdAt
  }
`;

export const taskSection = gql`
  fragment TaskSection on TaskSection {
    id
    name
    status
    sortKey
    workspaceId
  }
`;

export const organizationIntegration = gql`
  fragment OrganizationIntegration on OrganizationIntegration {
    id
    type
    config
  }
`;

export const organizationWithIntegrations = gql`
  fragment OrganizationWithIntegrations on Organization {
    id
    integrations {
      ...OrganizationIntegration
    }
  }

  ${organizationIntegration}
`;

export const workspaceIntegration = gql`
  fragment WorkspaceIntegration on WorkspaceIntegration {
    id
    type
    feature
    config
  }
`;

export const workspaceTokenGate = gql`
  fragment WorkspaceTokenGate on WorkspaceTokenGate {
    id
    role
    workspaceId
    token {
      ...PaymentToken
      network {
        ...PaymentNetwork
      }
    }
  }

  ${token}
  ${network}
`;

export const invite = gql`
  fragment Invite on Invite {
    id
    permalink
    inviter {
      ...User
    }
    organization {
      ...Organization
    }
    workspace {
      ...Workspace
      tokenGates {
        ...WorkspaceTokenGate
      }
      organization {
        ...Organization
      }
    }
    task {
      id
      name
      permalink
    }
    permission
  }

  ${user}
  ${organization}
  ${workspace}
  ${workspaceTokenGate}
`;

export const workspaceDetails = gql`
  fragment WorkspaceDetails on Workspace {
    ...Workspace
    tokenGates {
      ...WorkspaceTokenGate
    }
    organization {
      ...Organization
    }
    taskSections {
      ...TaskSection
    }
    taskViews {
      ...TaskView
    }
    parent {
      ...Workspace
    }
    activityThread {
      ...Thread
    }
    contributors {
      ...User
    }
  }

  ${workspace}
  ${workspaceTokenGate}
  ${organization}
  ${taskSection}
  ${taskView}
  ${thread}
  ${user}
`;

export const taskReview = gql`
  fragment TaskReview on TaskReview {
    id
    message
    rating
    createdAt
  }
`;

export const githubPullRequest = gql`
  fragment GithubPullRequest on GithubPullRequest {
    id
    title
    link
    status
    number
    branchName
    createdAt
    updatedAt
  }
`;

export const githubIssue = gql`
  fragment GithubIssue on GithubIssue {
    id
    number
    link
  }
`;

export const githubBranch = gql`
  fragment GithubBranch on GithubBranch {
    id
    name
    link
    repo
    organization
    createdAt
    updatedAt
    deletedAt
  }
`;

export const githubRepo = gql`
  fragment GithubRepo on GithubRepo {
    id
    name
    organization
    integrationId
  }
`;

export const discordIntegrationChannel = gql`
  fragment DiscordIntegrationChannel on DiscordIntegrationChannel {
    id
    name
    integrationId
    permissions
  }
`;

export const discordIntegrationRole = gql`
  fragment DiscordIntegrationRole on DiscordIntegrationRole {
    id
    name
  }
`;

export const taskApplication = gql`
  fragment TaskApplication on TaskApplication {
    id
    message
    startDate
    endDate
    createdAt
    updatedAt
    userId
    discordThreadUrl
    reward {
      ...TaskReward
    }
    status
    user {
      ...User
      details {
        ...EntityDetail
      }
    }
    thread {
      ...Thread
    }
  }

  ${user}
  ${entityDetail}
  ${taskReward}
  ${thread}
`;

export const taskSubmission = gql`
  fragment TaskSubmission on TaskSubmission {
    id
    content
    createdAt
    updatedAt
    taskId
    userId
    status
    reward {
      ...TaskReward
    }
    user {
      ...User
    }
    approver {
      ...User
    }
    thread {
      ...Thread
    }
  }

  ${user}
  ${taskReward}
  ${thread}
`;

export const taskReaction = gql`
  fragment TaskReaction on TaskReaction {
    id
    userId
    reaction
  }
`;

export const task = gql`
  fragment Task on Task {
    id
    name
    description
    status
    priority
    sortKey
    storyPoints
    dueDate
    createdAt
    doneAt
    deletedAt
    template
    templateTaskId
    templateTask {
      id
      name
    }
    workspaceId
    workspace {
      ...Workspace
    }
    parentTaskId
    parentTask {
      id
      name
    }
    sectionId
    number
    gating
    openToBids
    submissionCount
    applicationCount
    maxWinners
    subtasks {
      ...Subtask
    }
    tags {
      ...TaskTag
    }
    skills {
      ...Skill
    }
    assignees {
      ...User
    }
    owners {
      ...User
    }
    creator {
      ...User
    }
    rewards {
      ...TaskReward
    }
    review {
      ...TaskReview
    }
    reactions {
      ...TaskReaction
    }
  }

  ${taskTag}
  ${skill}
  ${taskReward}
  ${user}
  ${subtask}
  ${taskReview}
  ${taskReaction}
  ${workspace}
`;

export const taskWithOrganization = gql`
  fragment TaskWithOrganization on Task {
    ...Task
    workspace {
      ...Workspace
      organization {
        ...Organization
      }
    }
  }

  ${task}
  ${workspace}
  ${organization}
`;

export const taskNft = gql`
  fragment TaskNFT on TaskNFT {
    id
    tokenId
    createdAt
    contractAddress
    explorerUrl
    payment {
      ...Payment
    }
  }

  ${payment}
`;

export const taskDetails = gql`
  fragment TaskDetails on Task {
    ...Task
    featured
    gitBranchName
    applicationLink
    applicationTemplate
    submissionTemplate
    permalink
    maxWinners
    workspace {
      ...Workspace
      organization {
        ...Organization
      }
    }
    parentTask {
      id
      name
    }
    owners {
      ...User
    }
    creator {
      ...User
    }
    githubPullRequests {
      ...GithubPullRequest
    }
    githubBranches {
      ...GithubBranch
    }
    githubIssue {
      ...GithubIssue
    }
    notionPage {
      permalink
    }
    applications {
      ...TaskApplication
    }
    submissions {
      ...TaskSubmission
    }
    nfts {
      ...TaskNFT
    }
    rewards {
      ...TaskReward
      payments {
        user {
          ...User
        }
        payment {
          ...Payment
        }
      }
    }
    auditLog {
      ...AuditLogEvent
    }
    thread {
      ...Thread
    }
  }

  ${task}
  ${taskNft}
  ${user}
  ${workspace}
  ${organization}
  ${githubPullRequest}
  ${githubBranch}
  ${githubIssue}
  ${taskApplication}
  ${taskSubmission}
  ${payment}
  ${auditLogEvent}
  ${thread}
`;

export const userProfile = gql`
  fragment UserProfile on User {
    ...User
    bio
    details {
      ...EntityDetail
    }
    threepids {
      source
      threepid
    }
    featuredWork {
      imageUrl
      link
      title
      description
    }
    reputationScore
    revenueShare
  }

  ${user}
  ${entityDetail}
`;

export const taskGatingDefault = gql`
  fragment TaskGatingDefault on TaskGatingDefault {
    id
    userId
    workspaceId
    type
    claimRoles {
      id
    }
    applyRoles {
      id
    }
  }
`;

export const userDetails = gql`
  fragment UserDetails on User {
    ...UserProfile
    createdAt
    skills {
      ...Skill
    }
    threepids {
      id
      source
      threepid
    }
    prompts {
      ...UserPrompt
    }
    taskGatingDefaults {
      ...TaskGatingDefault
    }
    taskViews {
      ...TaskView
    }
    openTasksNotifications
    node {
      ...GraphNodeChildrenDetails
    }

    reputationStakes {
      ...StakedReputation
    }
    reputationSupporters {
      ...StakedReputation
    }
    reputationCredits
  }

  ${userProfile}
  ${skill}
  ${userPrompt}
  ${taskGatingDefault}
  ${taskView}
  ${nodeChildrenDetails}
  ${stakedReputation}
`;

export const organizationDetails = gql`
  fragment OrganizationDetails on Organization {
    ...Organization
    tagline
    description
    options {
      roadmap
      roles
      mintTaskNFTs
      hideLeaderboards
    }
    workspaceCount
    workspaces {
      ...Workspace
    }
    workspaceSections {
      ...WorkspaceSection
    }
    tags {
      ...OrganizationTag
    }
    details {
      ...EntityDetail
    }
    workspaceTokenGates {
      ...WorkspaceTokenGate
    }
    taskViews {
      ...TaskView
    }
    fundingSessions {
      ...FundingSession
    }
    node {
      ...GraphNode
    }
    timeToPayment
    totalPaid
  }

  ${organization}
  ${workspace}
  ${workspaceSection}
  ${organizationTag}
  ${entityDetail}
  ${workspaceTokenGate}
  ${taskView}
  ${fundingSession}
  ${node}
`;
