import { Tag, TagProps, Tooltip } from "antd";
import React, { FC } from "react";
import { Skill } from "../../graphql/types";
import { SkillAvatar } from "../avatars/SkillAvatar";

interface Props extends TagProps {
  skill: Skill;
  mode?: "emoji" | "default";
}

export const SkillTag: FC<Props> = ({
  skill,
  mode = "default",
  ...tagProps
}) => {
  if (mode === "emoji") {
    return (
      <Tooltip title={skill.name} placement="bottom">
        <Tag {...tagProps}>
          <SkillAvatar skill={skill} size={16} />
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tag {...tagProps}>
      <SkillAvatar skill={skill} size={16} style={{ marginRight: 8 }} />
      {skill.name}
    </Tag>
  );
};
