import Icon from "@ant-design/icons";
import React from "react";

const svg = () => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.43762 1.62471C6.93859 0.858514 8.06126 0.858513 8.56223 1.62471L13.4082 9.03618C13.9602 9.88035 13.3545 11 12.3459 11H2.65396C1.64536 11 1.03969 9.88035 1.59165 9.03618L6.43762 1.62471Z"
      fill="#535353"
    />
    <path
      d="M7.5 1.42016C7.5 0.999604 8.04703 0.836638 8.27718 1.18863L13.4083 9.03618C13.9602 9.88035 13.3546 11 12.346 11H8.76923C8.06825 11 7.5 10.4317 7.5 9.73077L7.5 1.42016Z"
      fill="#858585"
    />
  </svg>
);

export const StoryPointIcon = (props: any) => (
  <Icon component={svg} {...props} />
);
