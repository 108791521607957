import { Modal } from "antd";
import React, { FC, useCallback } from "react";
import { FormValues, WorkspaceCreateForm } from "./WorkspaceCreateForm";
import { useRouter } from "next/router";
import { WorkspaceDetails } from "@dewo/app/graphql/types";

interface Props {
  organizationId: string;
  visible: boolean;
  initialValues?: Partial<FormValues>;
  onClose(): void;
  onWorkspaceCreated?(workspace: WorkspaceDetails): void | Promise<void>;
  title?: string;
  mode?: "duplicate" | "create";
}

export const CreateWorkspaceModal: FC<Props> = ({
  organizationId,
  initialValues,
  visible,
  onClose,
  onWorkspaceCreated,
  title = "Create",
  mode,
}) => {
  const router = useRouter();
  const handleWorkspaceCreated = useCallback(
    async (workspace: WorkspaceDetails) => {
      onClose();
      await onWorkspaceCreated?.(workspace);
      router.push(workspace.taskViews[0]?.permalink ?? workspace.permalink);
    },
    [router, onWorkspaceCreated, onClose]
  );
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <WorkspaceCreateForm
        organizationId={organizationId}
        initialValues={initialValues}
        onWorkspaceCreated={handleWorkspaceCreated}
        onWorkspaceSectionCreated={onClose}
        mode={mode}
      />
    </Modal>
  );
};
