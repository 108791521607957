import React, { FC } from "react";
import { TaskDetails } from "@dewo/app/graphql/types";
import { Button, ButtonProps } from "antd";
import { GithubFilled } from "@ant-design/icons";

interface Props extends ButtonProps {
  task: TaskDetails;
}

export const TaskGithubIssueButton: FC<Props> = ({ task, ...btnProps }) => {
  const link = task.githubIssue?.link;
  if (!link) return null;
  return (
    <Button
      target="_blank"
      href={link}
      name="Open Github issue"
      icon={<GithubFilled />}
      {...btnProps}
    >
      Github Issue
    </Button>
  );
};
