import React, { useMemo } from "react";
import { ChatWidget, Papercups } from "@papercups-io/chat-widget";
import { useAuthContext } from "../contexts/AuthContext";
import { usePermissionFn } from "../contexts/PermissionsContext";

export const openSupportChat = Papercups.open;

export const SupportChat = () => {
  const { user } = useAuthContext();
  const fn = usePermissionFn();

  const managerOf = useMemo(() => {
    return user?.node.children
      .map((e) => e.node.organization || e.node.workspace)
      .filter((x) => !!x && fn("update", x))
      .map((o) => `${o?.name} (${o?.slug})`);
  }, [fn, user?.node.children]);

  return (
    <ChatWidget
      token="3e69873b-8933-4ec2-8a46-aadbab316126"
      inbox="49c3fe82-123c-49d6-8161-757543230ab1"
      title="Welcome to Dework"
      subtitle="Ask us anything in the chat below 👋"
      primaryColor="#8570e4"
      newMessagePlaceholder="Start typing..."
      showAgentAvailability={false}
      agentAvailableText="We're online right now!"
      agentUnavailableText="We're away at the moment."
      requireEmailUpfront={false}
      iconVariant="filled"
      baseUrl="https://app.papercups.io"
      styles={{
        chatContainer: {
          colorScheme: "normal",
        },
        toggleContainer: {
          position: "static",
          marginLeft: 8,
        },
      }}
      customer={{
        name: user?.username,
        external_id: user?.id,
        metadata: {
          createdAt: user?.createdAt,
          managerOf: managerOf?.join(", "),
        },
      }}
    />
  );
};
