import { UserDetails } from "@dewo/app/graphql/types";
import { ButtonProps } from "antd";
import { FC } from "react";

interface Props extends ButtonProps {
  redirect?: string;
  onAuthed?(user: UserDetails): void;
}

export const WalletConnectAuthButton: FC<Props> = (
  {
    // redirect,
    // onAuthed,
    // ...buttonProps
  }
) => {
  return null;
  // const router = useRouter();
  // const authWithWalletConnect = useAuthWithWalletConnect();
  // const [auth, authing] = useRunning(
  //   useCallback(async () => {
  //     try {
  //       const user = await authWithWalletConnect();
  //       onAuthed?.(user);
  //       if (!!redirect) router.push(redirect);
  //     } catch (error) {
  //       alert((error as Error).message);
  //       throw error;
  //     }
  //   }, [authWithWalletConnect, onAuthed, router, redirect])
  // );

  // return (
  //   <ThreepidAuthButton
  //     loading={authing}
  //     source={ThreepidSource.metamask}
  //     redirect={redirect}
  //     icon={<WalletConnectIcon />}
  //     href={undefined}
  //     onClick={auth}
  //     {...buttonProps}
  //   />
  // );
};
