import React, { FC, useCallback, useMemo } from "react";
import { useRunning } from "@dewo/app/util/hooks";
import { DragDropContext, DragDropContextProps } from "react-beautiful-dnd";
import { Divider } from "antd";
import {
  TaskDetails,
  TaskGatingType,
  TaskStatus,
} from "@dewo/app/graphql/types";
import { useCreateTask, useDeleteTask, useUpdateTask } from "../../hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useNavigateToTaskFn } from "@dewo/app/util/navigation";
import { NewSubtaskInput } from "./NewSubtaskInput";
import { getSortKeyBetween } from "../../board/util";
import { SubtaskTable, SubtaskTableRowData } from "./SubtaskTable";
import { useSubtasks } from "../../views/hooks";
import { SubtaskFormValues } from "./types";

interface Props {
  parentTask: TaskDetails;
}

export const SubtaskUpdateInput: FC<Props> = ({ parentTask }) => {
  const { user } = useAuthContext();
  const createTask = useCreateTask();
  const updateTask = useUpdateTask();
  const canCreateSubtask = usePermission("create", {
    status: TaskStatus.TODO,
    workspaceId: parentTask.workspaceId,
    __typename: "Task",
    __parentTask__: parentTask,
  });

  const { tasks: subtasks, refetch: refetchSubtasks } = useSubtasks(
    parentTask.id
  );

  const [addSubtask, adding] = useRunning(
    useCallback(
      async (values: SubtaskFormValues) => {
        await createTask({
          ...values,
          parentTaskId: parentTask.id,
          workspaceId: parentTask.workspaceId,
          status: TaskStatus.TODO,
          ownerIds: !!user ? [user.id] : [],
          assigneeIds: [],
          gating: parentTask.gating,
        });
        const waitForTaskToBeIndexedByElasticsearch = await new Promise((r) =>
          setTimeout(r, 1000)
        );
        await waitForTaskToBeIndexedByElasticsearch;
        await refetchSubtasks();
      },
      [
        createTask,
        parentTask.id,
        parentTask.workspaceId,
        parentTask.gating,
        user,
        refetchSubtasks,
      ]
    )
  );

  const handleDragEnd = useCallback<DragDropContextProps["onDragEnd"]>(
    async (result) => {
      if (result.reason !== "DROP" || !result.destination || !subtasks) return;

      const {
        source: { index: oldIndex },
        destination: { index: newIndex },
      } = result;

      const indexExcludingItself =
        oldIndex < newIndex ? newIndex + 1 : newIndex;

      const taskAbove = subtasks[indexExcludingItself - 1];
      const taskBelow = subtasks[indexExcludingItself];
      const newKey = getSortKeyBetween(taskAbove, taskBelow, (t) => t.sortKey);
      await updateTask(
        { id: subtasks[oldIndex].id, sortKey: newKey },
        subtasks[oldIndex]
      );
    },
    [subtasks, updateTask]
  );

  const rows = useMemo(
    () =>
      subtasks
        ?.filter((t) => !t.deletedAt && !!t.parentTaskId)
        .map((task) => ({
          task,
          key: task.id,
          name: task.name,
          description: task.description ?? "",
          assigneeIds: task.assignees.map((u) => u.id),
          dueDate: task.dueDate ?? null,
          status: task.status,
        })),
    [subtasks]
  );

  const deleteTask = useDeleteTask();
  const handleDelete = useCallback(
    async (row: SubtaskTableRowData) => {
      if (!!row.task) {
        await deleteTask(row.task.id);
      }
    },
    [deleteTask]
  );

  const navigateToTask = useNavigateToTaskFn();
  const handleClick = useCallback(
    (row: SubtaskTableRowData) => !!row.task && navigateToTask(row.task.id),
    [navigateToTask]
  );

  const handleChange = useCallback(
    async (
      changed: Partial<SubtaskTableRowData> & { gating?: TaskGatingType },
      prevValue: SubtaskTableRowData
    ) => {
      const task = prevValue.task!;
      await updateTask({ id: task.id, ...changed }, task);
    },
    [updateTask]
  );

  if (!rows) return null;
  return (
    <>
      {!!rows.length && <Divider style={{ marginBottom: 0 }}>Subtasks</Divider>}
      <DragDropContext onDragEnd={handleDragEnd}>
        <SubtaskTable
          rows={rows}
          size="small"
          onClick={handleClick}
          showHeader={false}
          workspaceId={parentTask.workspaceId}
          canCreateSubtask={!!canCreateSubtask}
          onChange={handleChange}
          onDelete={handleDelete}
        />
      </DragDropContext>
      {canCreateSubtask && (
        <NewSubtaskInput onSubmit={addSubtask} disabled={adding} />
      )}
    </>
  );
};
