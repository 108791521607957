import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { FormSection } from "@dewo/app/components/form/FormSection";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { TaskDetails } from "@dewo/app/graphql/types";
import { Card, Timeline } from "antd";
import React, { FC, useCallback } from "react";
import { CreateThreadMessageForm } from "../CreateThreadMessageForm";
import { useCreateThread, useUpdateTaskThread } from "../hooks";
import {
  ThreadMessageComponent,
  ThreadTextComponent,
  ThreadTimeline,
} from "../ThreadTimeline";
import { useMentionableUsers, useTaskThreadItems } from "./hooks";

interface Props {
  task: TaskDetails;
}

export const TaskThread: FC<Props> = ({ task }) => {
  const { user } = useAuthContext();
  const items = useTaskThreadItems(task);

  const mentionable = useMentionableUsers(task);

  const createThread = useCreateThread();
  const updateTaskThread = useUpdateTaskThread();
  const createThreadIfNotExists = useCallback(async () => {
    if (!!task.thread) {
      return { threadId: task.thread.id };
    }

    const thread = await createThread();
    await updateTaskThread(task.id, thread.id);
    return { threadId: thread.id };
  }, [createThread, updateTaskThread, task]);

  const canComment = usePermission("create", {
    __typename: "ThreadMessage",
    // @ts-expect-error
    __thread__: { __task__: task },
    senderId: user?.id!,
  });

  return (
    <FormSection label="Activity">
      <ThreadTimeline>
        {items.map((item) => (
          <Timeline.Item
            key={item.key}
            dot={
              !!item.user ? (
                <UserAvatar size="small" user={item.user} linkToProfile />
              ) : undefined
            }
          >
            {item.type === "text" && <ThreadTextComponent item={item} />}
            {item.type === "message" && <ThreadMessageComponent item={item} />}
          </Timeline.Item>
        ))}

        {!!user && canComment && (
          <Timeline.Item dot={<UserAvatar size="small" user={user} />}>
            <Card size="small" bordered={false} bodyStyle={{ paddingRight: 0 }}>
              <CreateThreadMessageForm
                key={task.thread?.messages.length}
                threadId={task.thread?.id}
                bordered={false}
                mentionable={mentionable}
                placeholder="Write a comment..."
                onBeforeSend={createThreadIfNotExists}
              />
            </Card>
          </Timeline.Item>
        )}
      </ThreadTimeline>
    </FormSection>
  );
};
