import { Alert, AlertProps } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useAmplitude } from "../util/analytics/AmplitudeContext";
import { LocalStorage } from "../util/LocalStorage";

interface Props extends AlertProps {
  name: string;
}

export function useOnboardingTip(key: string): {
  hidden: boolean;
  onClose(): void;
} {
  const [refresh, setRefresh] = useState(0);
  const hidden = useMemo(
    () => !!LocalStorage.getItem(key),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, refresh]
  );
  const onClose = useCallback(() => {
    LocalStorage.setItem(key, String(true));
    setRefresh((prev) => prev + 1);
  }, [key]);
  return { hidden, onClose };
}

export const OnboardingAlert: FC<Props> = ({
  name,
  type = "info",
  ...alertProps
}) => {
  const { hidden, onClose } = useOnboardingTip(`OnboardingAlert.v1.${name}`);

  const { logEvent } = useAmplitude();
  const handleClose = useCallback(() => {
    logEvent("Close onboarding alert", { name });
    onClose();
  }, [onClose, logEvent, name]);

  if (hidden) return null;
  return <Alert closable onClose={handleClose} {...alertProps} />;
};
