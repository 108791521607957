import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { MetamaskAuthButton } from "@dewo/app/containers/auth/buttons/MetamaskAuthButton";
import { PhantomAuthButton } from "@dewo/app/containers/auth/buttons/PhantomAuthButton";
import { getThreepidName } from "@dewo/app/containers/auth/buttons/ThreepidAuthButton";
import { WalletConnectAuthButton } from "@dewo/app/containers/auth/buttons/WalletConnectAuthButton";
import { useOrganizationTokens } from "@dewo/app/containers/organization/hooks";
import { usePaymentNetworks } from "@dewo/app/containers/payment/hooks";
import { useFollowOrganization } from "@dewo/app/containers/rbac/hooks";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import {
  PaymentNetwork,
  TaskSubmissionStatus,
  ThreepidSource,
} from "@dewo/app/graphql/types";
import { useRunningCallback } from "@dewo/app/util/hooks";
import { Button, Form, message, Space, Spin, Typography } from "antd";
import _ from "lodash";
import React, { FC, useMemo, useState } from "react";
import {
  useCreateTaskSubmission,
  useTaskDetails,
  useUpdateTaskSubmission,
} from "../../hooks";
import { TaskRewardRequestFunding } from "../../reward/form/TaskRewardRequestFunding";
import { TaskRewardFormItemValue } from "../../reward/form/types";
import {
  toTaskRewardFormItem,
  toTaskRewardInput,
} from "../../reward/form/util";

interface Props {
  taskId: string;
  onDone(): void;
}

interface FormValues {
  reward: TaskRewardFormItemValue;
}

export const CreateSubmissionForm: FC<Props> = ({ taskId, onDone }) => {
  const { user } = useAuthContext();
  const { task } = useTaskDetails(taskId);
  const networks = usePaymentNetworks();
  const networkById = useMemo(
    () => _.keyBy(networks, (n) => n.id) as Record<string, PaymentNetwork>,
    [networks]
  );
  const tokens = useOrganizationTokens(task?.workspace.organizationId);
  const tokenById = useMemo(() => _.keyBy(tokens, "id"), [tokens]);

  const [form] = Form.useForm<FormValues>();

  const currentSubmission = useMemo(
    () =>
      task?.submissions.find(
        (s) =>
          s.userId === user?.id && s.status === TaskSubmissionStatus.PENDING
      ),
    [task?.submissions, user?.id]
  );

  const hasConnectedEthereum = useMemo(
    () => user?.threepids?.some((t) => t.source === ThreepidSource.metamask),
    [user]
  );
  const hasConnectedSolana = useMemo(
    () => user?.threepids?.some((t) => t.source === ThreepidSource.phantom),
    [user]
  );
  const hasSolanaReward = useMemo(
    () =>
      !!task?.rewards.some((r) =>
        networkById[r.token.networkId]?.slug.startsWith("solana")
      ),
    [task?.rewards, networkById]
  );
  const hasEthereumReward = useMemo(
    () =>
      !!task?.rewards.some(
        (r) =>
          networkById[r.token.networkId]?.slug.startsWith("solana") === false
      ),
    [task?.rewards, networkById]
  );

  const [content, setContent] = useState<string>();
  const followOrganization = useFollowOrganization(
    task?.workspace.organizationId
  );
  const createSubmission = useCreateTaskSubmission();
  const updateSubmission = useUpdateTaskSubmission();
  const [submit, submitting] = useRunningCallback(async () => {
    try {
      const rewardFormValue = form.getFieldValue("reward");
      const reward =
        rewardFormValue &&
        toTaskRewardInput(rewardFormValue, tokenById[rewardFormValue.tokenId]);
      if (!currentSubmission) {
        await createSubmission({
          taskId,
          content: content!,
          reward,
        });
        followOrganization();
        message.success("Submission created");
      } else {
        await updateSubmission({
          id: currentSubmission.id,
          taskId,
          content: content!,
          reward,
        });
        message.success("Submission updated");
      }
    } finally {
      onDone();
    }
  }, [
    content,
    createSubmission,
    updateSubmission,
    followOrganization,
    user,
    currentSubmission,
    taskId,
    onDone,
    form,
  ]);

  const organizationId = task?.workspace.organizationId;

  if (!task) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        <Spin />
      </div>
    );
  }

  const connectWalletHeader = (
    <>
      <Typography.Title
        level={3}
        style={{ textAlign: "center", marginBottom: 0 }}
      >
        Connect your Wallet
      </Typography.Title>

      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        To submit work, you need to connect a wallet so you can get paid.
      </Typography.Paragraph>
    </>
  );

  if (hasEthereumReward && !hasConnectedEthereum) {
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        {connectWalletHeader}
        <MetamaskAuthButton
          children={getThreepidName[ThreepidSource.metamask]}
          size="large"
          block
        />
        <WalletConnectAuthButton children="WalletConnect" size="large" block />
      </Space>
    );
  }

  if (hasSolanaReward && !hasConnectedSolana) {
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        {connectWalletHeader}
        <PhantomAuthButton
          children="Connect Phantom"
          size="large"
          block
          type="primary"
        />
      </Space>
    );
  }

  return (
    <Form layout="vertical" form={form}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Typography.Title
          level={3}
          style={{ textAlign: "center", marginBottom: 0 }}
        >
          Submission
        </Typography.Title>
        <RichMarkdownEditor
          initialValue={
            currentSubmission?.content ?? task.submissionTemplate ?? ""
          }
          placeholder="Type '/' for commands."
          editable
          bordered
          mode="create"
          onChange={setContent}
          key={currentSubmission?.content}
        />

        {!!task.openToBids && (
          <Form.Item
            name="reward"
            initialValue={
              task.rewards[0] &&
              toTaskRewardFormItem(
                task.rewards[0],
                tokenById[task.rewards[0].tokenId]
              )
            }
          >
            <TaskRewardRequestFunding
              type={task.rewards[0]?.type}
              organizationId={organizationId}
              isSubmission
              openToBids={task.openToBids}
            />
          </Form.Item>
        )}

        <Button
          block
          type="primary"
          onClick={submit}
          disabled={!content}
          loading={submitting}
        >
          {currentSubmission ? "Save" : "Submit"}
        </Button>
      </Space>
    </Form>
  );
};
