import { DoubleLeftOutlined } from "@ant-design/icons";
import { Button, Layout, Row, SiderProps } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import style from "./CollapsibleSider.module.less";

interface Props extends SiderProps {
  collapsedToggle?: () => void;
}
export const CollapsibleSider: FC<Props> = ({
  children,
  collapsedToggle,
  collapsed,
  width,
  ...rest
}) => {
  return (
    <Layout.Sider
      width={width}
      collapsed={collapsed}
      {...rest}
      className={classNames(style.sider, rest.className)}
    >
      <Row justify="end">
        <Button
          hidden={!collapsedToggle}
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 8,
          }}
          onClick={collapsedToggle}
          type="text"
          className="text-secondary"
          icon={<DoubleLeftOutlined rotate={collapsed ? 0 : 180} />}
        />
      </Row>

      <div
        style={{ width }}
        className={classNames(style.siderContent, collapsed && style.collapsed)}
      >
        {children}
      </div>
    </Layout.Sider>
  );
};
