import gql from "graphql-tag";
import { network, token } from "./payment";
import { workspace } from "./workspace";
import { user } from "./user";

export const fundingVote = gql`
  fragment FundingVote on FundingVote {
    id
    taskId
    points: weight
    userId
    sessionId
  }
`;

export const fundingSession = gql`
  fragment FundingSession on FundingSession {
    id
    startDate
    endDate
    closedAt
    amount
    permalink
    organizationId
    token {
      ...PaymentToken
      network {
        ...PaymentNetwork
      }
    }
  }

  ${token}
  ${network}
`;

export const fundingSessionDetails = gql`
  fragment FundingSessionDetails on FundingSession {
    ...FundingSession
    votes {
      ...FundingVote
    }
    voters {
      weight
      points
      votes {
        ...FundingVote
      }
      user {
        ...User
      }
    }
    workspaces {
      ...Workspace
    }
    rewards {
      id
      amount
      peggedToUsd
      token {
        ...PaymentToken
        network {
          ...PaymentNetwork
        }
      }
      task {
        id
        parentTask {
          id
          name
          permalink
        }
      }
    }
  }

  ${fundingSession}
  ${fundingVote}
  ${user}
  ${token}
  ${network}
  ${workspace}
`;
