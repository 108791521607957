/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserMutation
// ====================================================

export interface UpdateUserMutation_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UpdateUserMutation_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface UpdateUserMutation_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface UpdateUserMutation_user_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpdateUserMutation_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface UpdateUserMutation_user_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface UpdateUserMutation_user_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface UpdateUserMutation_user_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: UpdateUserMutation_user_taskGatingDefaults_claimRoles[];
  applyRoles: UpdateUserMutation_user_taskGatingDefaults_applyRoles[];
}

export interface UpdateUserMutation_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateUserMutation_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateUserMutation_user_taskViews_filters_dueDate | null;
}

export interface UpdateUserMutation_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateUserMutation_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateUserMutation_user_taskViews_filters[];
  sortBys: UpdateUserMutation_user_taskViews_sortBys[];
}

export interface UpdateUserMutation_user_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateUserMutation_user_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateUserMutation_user_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateUserMutation_user_node_children_node_workspace_options | null;
}

export interface UpdateUserMutation_user_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpdateUserMutation_user_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: UpdateUserMutation_user_node_children_node_organization | null;
  workspace: UpdateUserMutation_user_node_children_node_workspace | null;
  skill: UpdateUserMutation_user_node_children_node_skill | null;
}

export interface UpdateUserMutation_user_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: UpdateUserMutation_user_node_children_node;
}

export interface UpdateUserMutation_user_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: UpdateUserMutation_user_node_children[];
}

export interface UpdateUserMutation_user_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface UpdateUserMutation_user_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface UpdateUserMutation_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: UpdateUserMutation_user_details[];
  threepids: UpdateUserMutation_user_threepids[];
  featuredWork: UpdateUserMutation_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: UpdateUserMutation_user_skills[];
  prompts: UpdateUserMutation_user_prompts[];
  taskGatingDefaults: UpdateUserMutation_user_taskGatingDefaults[];
  taskViews: UpdateUserMutation_user_taskViews[];
  openTasksNotifications: boolean;
  node: UpdateUserMutation_user_node;
  reputationStakes: UpdateUserMutation_user_reputationStakes[];
  reputationSupporters: UpdateUserMutation_user_reputationSupporters[];
  reputationCredits: number;
}

export interface UpdateUserMutation {
  user: UpdateUserMutation_user;
}

export interface UpdateUserMutationVariables {
  input: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserRoleMutation
// ====================================================

export interface UpdateUserRoleMutation_user_userRoles {
  __typename: "UserRole";
  roleId: string;
  hidden: boolean;
}

export interface UpdateUserRoleMutation_user {
  __typename: "User";
  id: Scalar.UUID;
  userRoles: UpdateUserRoleMutation_user_userRoles[];
}

export interface UpdateUserRoleMutation {
  user: UpdateUserRoleMutation_user;
}

export interface UpdateUserRoleMutationVariables {
  input: UpdateUserRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganizationMutation
// ====================================================

export interface CreateOrganizationMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateOrganizationMutation {
  organization: CreateOrganizationMutation_organization;
}

export interface CreateOrganizationMutationVariables {
  input: CreateOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationMutation
// ====================================================

export interface UpdateOrganizationMutation_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface UpdateOrganizationMutation_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateOrganizationMutation_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateOrganizationMutation_organization_workspaces_options | null;
}

export interface UpdateOrganizationMutation_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface UpdateOrganizationMutation_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface UpdateOrganizationMutation_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UpdateOrganizationMutation_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateOrganizationMutation_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateOrganizationMutation_organization_workspaceTokenGates_token_network;
}

export interface UpdateOrganizationMutation_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: UpdateOrganizationMutation_organization_workspaceTokenGates_token;
}

export interface UpdateOrganizationMutation_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateOrganizationMutation_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateOrganizationMutation_organization_taskViews_filters_dueDate | null;
}

export interface UpdateOrganizationMutation_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateOrganizationMutation_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateOrganizationMutation_organization_taskViews_filters[];
  sortBys: UpdateOrganizationMutation_organization_taskViews_sortBys[];
}

export interface UpdateOrganizationMutation_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateOrganizationMutation_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateOrganizationMutation_organization_fundingSessions_token_network;
}

export interface UpdateOrganizationMutation_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: UpdateOrganizationMutation_organization_fundingSessions_token;
}

export interface UpdateOrganizationMutation_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

export interface UpdateOrganizationMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: UpdateOrganizationMutation_organization_options | null;
  workspaceCount: number;
  workspaces: UpdateOrganizationMutation_organization_workspaces[];
  workspaceSections: UpdateOrganizationMutation_organization_workspaceSections[];
  tags: UpdateOrganizationMutation_organization_tags[];
  details: UpdateOrganizationMutation_organization_details[];
  workspaceTokenGates: UpdateOrganizationMutation_organization_workspaceTokenGates[];
  taskViews: UpdateOrganizationMutation_organization_taskViews[];
  fundingSessions: UpdateOrganizationMutation_organization_fundingSessions[];
  node: UpdateOrganizationMutation_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface UpdateOrganizationMutation {
  organization: UpdateOrganizationMutation_organization;
}

export interface UpdateOrganizationMutationVariables {
  input: UpdateOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrganizationMutation
// ====================================================

export interface DeleteOrganizationMutation {
  deleteOrganization: boolean;
}

export interface DeleteOrganizationMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganizationTagMutation
// ====================================================

export interface CreateOrganizationTagMutation_organizationTag_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface CreateOrganizationTagMutation_organizationTag_organization_allTags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface CreateOrganizationTagMutation_organizationTag_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  tags: CreateOrganizationTagMutation_organizationTag_organization_tags[];
  allTags: CreateOrganizationTagMutation_organizationTag_organization_allTags[];
}

export interface CreateOrganizationTagMutation_organizationTag {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  organization: CreateOrganizationTagMutation_organizationTag_organization;
}

export interface CreateOrganizationTagMutation {
  organizationTag: CreateOrganizationTagMutation_organizationTag;
}

export interface CreateOrganizationTagMutationVariables {
  input: CreateOrganizationTagInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspaceMutation
// ====================================================

export interface CreateWorkspaceMutation_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspaceMutation_workspace_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspaceMutation_workspace_tokenGates_token_network;
}

export interface CreateWorkspaceMutation_workspace_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: CreateWorkspaceMutation_workspace_tokenGates_token;
}

export interface CreateWorkspaceMutation_workspace_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_organization_workspaces_options | null;
}

export interface CreateWorkspaceMutation_workspace_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface CreateWorkspaceMutation_workspace_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface CreateWorkspaceMutation_workspace_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateWorkspaceMutation_workspace_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspaceMutation_workspace_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspaceMutation_workspace_organization_workspaceTokenGates_token_network;
}

export interface CreateWorkspaceMutation_workspace_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: CreateWorkspaceMutation_workspace_organization_workspaceTokenGates_token;
}

export interface CreateWorkspaceMutation_workspace_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateWorkspaceMutation_workspace_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateWorkspaceMutation_workspace_organization_taskViews_filters_dueDate | null;
}

export interface CreateWorkspaceMutation_workspace_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateWorkspaceMutation_workspace_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateWorkspaceMutation_workspace_organization_taskViews_filters[];
  sortBys: CreateWorkspaceMutation_workspace_organization_taskViews_sortBys[];
}

export interface CreateWorkspaceMutation_workspace_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspaceMutation_workspace_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspaceMutation_workspace_organization_fundingSessions_token_network;
}

export interface CreateWorkspaceMutation_workspace_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CreateWorkspaceMutation_workspace_organization_fundingSessions_token;
}

export interface CreateWorkspaceMutation_workspace_organization_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_organization_node_workspace_options | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace_options | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_organization | null;
  workspace: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace | null;
  skill: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_skill | null;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node;
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_organization | null;
  workspace: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace | null;
  skill: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_skill | null;
  children: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children[];
}

export interface CreateWorkspaceMutation_workspace_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren_node;
}

export interface CreateWorkspaceMutation_workspace_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspaceMutation_workspace_organization_node_organization | null;
  workspace: CreateWorkspaceMutation_workspace_organization_node_workspace | null;
  skill: CreateWorkspaceMutation_workspace_organization_node_skill | null;
  workspaceChildren: CreateWorkspaceMutation_workspace_organization_node_workspaceChildren[];
}

export interface CreateWorkspaceMutation_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: CreateWorkspaceMutation_workspace_organization_options | null;
  workspaceCount: number;
  workspaces: CreateWorkspaceMutation_workspace_organization_workspaces[];
  workspaceSections: CreateWorkspaceMutation_workspace_organization_workspaceSections[];
  tags: CreateWorkspaceMutation_workspace_organization_tags[];
  details: CreateWorkspaceMutation_workspace_organization_details[];
  workspaceTokenGates: CreateWorkspaceMutation_workspace_organization_workspaceTokenGates[];
  taskViews: CreateWorkspaceMutation_workspace_organization_taskViews[];
  fundingSessions: CreateWorkspaceMutation_workspace_organization_fundingSessions[];
  node: CreateWorkspaceMutation_workspace_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface CreateWorkspaceMutation_workspace_taskSections {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface CreateWorkspaceMutation_workspace_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateWorkspaceMutation_workspace_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateWorkspaceMutation_workspace_taskViews_filters_dueDate | null;
}

export interface CreateWorkspaceMutation_workspace_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateWorkspaceMutation_workspace_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateWorkspaceMutation_workspace_taskViews_filters[];
  sortBys: CreateWorkspaceMutation_workspace_taskViews_sortBys[];
}

export interface CreateWorkspaceMutation_workspace_parent_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_parent {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_parent_options | null;
}

export interface CreateWorkspaceMutation_workspace_activityThread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspaceMutation_workspace_activityThread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateWorkspaceMutation_workspace_activityThread_messages_sender;
}

export interface CreateWorkspaceMutation_workspace_activityThread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateWorkspaceMutation_workspace_activityThread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateWorkspaceMutation_workspace_activityThread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateWorkspaceMutation_workspace_activityThread_messages[];
  task: CreateWorkspaceMutation_workspace_activityThread_task | null;
  workspace: CreateWorkspaceMutation_workspace_activityThread_workspace | null;
}

export interface CreateWorkspaceMutation_workspace_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspaceMutation_workspace_section_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateWorkspaceMutation_workspace_section_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateWorkspaceMutation_workspace_section_taskViews_filters_dueDate | null;
}

export interface CreateWorkspaceMutation_workspace_section_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateWorkspaceMutation_workspace_section_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateWorkspaceMutation_workspace_section_taskViews_filters[];
  sortBys: CreateWorkspaceMutation_workspace_section_taskViews_sortBys[];
}

export interface CreateWorkspaceMutation_workspace_section_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspaceMutation_workspace_section_workspaces_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateWorkspaceMutation_workspace_section_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_section_workspaces_options | null;
  taskTags: CreateWorkspaceMutation_workspace_section_workspaces_taskTags[];
}

export interface CreateWorkspaceMutation_workspace_section {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
  taskViews: CreateWorkspaceMutation_workspace_section_taskViews[];
  workspaces: CreateWorkspaceMutation_workspace_section_workspaces[];
}

export interface CreateWorkspaceMutation_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspaceMutation_workspace_options | null;
  tokenGates: CreateWorkspaceMutation_workspace_tokenGates[];
  organization: CreateWorkspaceMutation_workspace_organization;
  taskSections: CreateWorkspaceMutation_workspace_taskSections[];
  taskViews: CreateWorkspaceMutation_workspace_taskViews[];
  parent: CreateWorkspaceMutation_workspace_parent | null;
  activityThread: CreateWorkspaceMutation_workspace_activityThread | null;
  contributors: CreateWorkspaceMutation_workspace_contributors[];
  section: CreateWorkspaceMutation_workspace_section | null;
}

export interface CreateWorkspaceMutation {
  workspace: CreateWorkspaceMutation_workspace;
}

export interface CreateWorkspaceMutationVariables {
  input: CreateWorkspaceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWorkspaceMutation
// ====================================================

export interface UpdateWorkspaceMutation_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateWorkspaceMutation_workspace_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateWorkspaceMutation_workspace_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateWorkspaceMutation_workspace_tokenGates_token_network;
}

export interface UpdateWorkspaceMutation_workspace_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: UpdateWorkspaceMutation_workspace_tokenGates_token;
}

export interface UpdateWorkspaceMutation_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateWorkspaceMutation_workspace_taskSections {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface UpdateWorkspaceMutation_workspace_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateWorkspaceMutation_workspace_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateWorkspaceMutation_workspace_taskViews_filters_dueDate | null;
}

export interface UpdateWorkspaceMutation_workspace_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateWorkspaceMutation_workspace_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateWorkspaceMutation_workspace_taskViews_filters[];
  sortBys: UpdateWorkspaceMutation_workspace_taskViews_sortBys[];
}

export interface UpdateWorkspaceMutation_workspace_parent_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateWorkspaceMutation_workspace_parent {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateWorkspaceMutation_workspace_parent_options | null;
}

export interface UpdateWorkspaceMutation_workspace_activityThread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateWorkspaceMutation_workspace_activityThread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateWorkspaceMutation_workspace_activityThread_messages_sender;
}

export interface UpdateWorkspaceMutation_workspace_activityThread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateWorkspaceMutation_workspace_activityThread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateWorkspaceMutation_workspace_activityThread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateWorkspaceMutation_workspace_activityThread_messages[];
  task: UpdateWorkspaceMutation_workspace_activityThread_task | null;
  workspace: UpdateWorkspaceMutation_workspace_activityThread_workspace | null;
}

export interface UpdateWorkspaceMutation_workspace_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateWorkspaceMutation_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateWorkspaceMutation_workspace_options | null;
  tokenGates: UpdateWorkspaceMutation_workspace_tokenGates[];
  organization: UpdateWorkspaceMutation_workspace_organization;
  taskSections: UpdateWorkspaceMutation_workspace_taskSections[];
  taskViews: UpdateWorkspaceMutation_workspace_taskViews[];
  parent: UpdateWorkspaceMutation_workspace_parent | null;
  activityThread: UpdateWorkspaceMutation_workspace_activityThread | null;
  contributors: UpdateWorkspaceMutation_workspace_contributors[];
}

export interface UpdateWorkspaceMutation {
  workspace: UpdateWorkspaceMutation_workspace;
}

export interface UpdateWorkspaceMutationVariables {
  input: UpdateWorkspaceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWorkspaceMutation
// ====================================================

export interface DeleteWorkspaceMutation_workspace_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: DeleteWorkspaceMutation_workspace_organization_workspaces_options | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface DeleteWorkspaceMutation_workspace_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: DeleteWorkspaceMutation_workspace_organization_workspaceTokenGates_token_network;
}

export interface DeleteWorkspaceMutation_workspace_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: DeleteWorkspaceMutation_workspace_organization_workspaceTokenGates_token;
}

export interface DeleteWorkspaceMutation_workspace_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: DeleteWorkspaceMutation_workspace_organization_taskViews_filters_dueDate | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface DeleteWorkspaceMutation_workspace_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: DeleteWorkspaceMutation_workspace_organization_taskViews_filters[];
  sortBys: DeleteWorkspaceMutation_workspace_organization_taskViews_sortBys[];
}

export interface DeleteWorkspaceMutation_workspace_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: DeleteWorkspaceMutation_workspace_organization_fundingSessions_token_network;
}

export interface DeleteWorkspaceMutation_workspace_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: DeleteWorkspaceMutation_workspace_organization_fundingSessions_token;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: DeleteWorkspaceMutation_workspace_organization_node_workspace_options | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace_options | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_organization | null;
  workspace: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_workspace | null;
  skill: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node_skill | null;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children_node;
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_organization | null;
  workspace: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_workspace | null;
  skill: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_skill | null;
  children: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node_children[];
}

export interface DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren_node;
}

export interface DeleteWorkspaceMutation_workspace_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: DeleteWorkspaceMutation_workspace_organization_node_organization | null;
  workspace: DeleteWorkspaceMutation_workspace_organization_node_workspace | null;
  skill: DeleteWorkspaceMutation_workspace_organization_node_skill | null;
  workspaceChildren: DeleteWorkspaceMutation_workspace_organization_node_workspaceChildren[];
}

export interface DeleteWorkspaceMutation_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: DeleteWorkspaceMutation_workspace_organization_options | null;
  workspaceCount: number;
  workspaces: DeleteWorkspaceMutation_workspace_organization_workspaces[];
  workspaceSections: DeleteWorkspaceMutation_workspace_organization_workspaceSections[];
  tags: DeleteWorkspaceMutation_workspace_organization_tags[];
  details: DeleteWorkspaceMutation_workspace_organization_details[];
  workspaceTokenGates: DeleteWorkspaceMutation_workspace_organization_workspaceTokenGates[];
  taskViews: DeleteWorkspaceMutation_workspace_organization_taskViews[];
  fundingSessions: DeleteWorkspaceMutation_workspace_organization_fundingSessions[];
  node: DeleteWorkspaceMutation_workspace_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface DeleteWorkspaceMutation_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  organization: DeleteWorkspaceMutation_workspace_organization;
}

export interface DeleteWorkspaceMutation {
  workspace: DeleteWorkspaceMutation_workspace;
}

export interface DeleteWorkspaceMutationVariables {
  input: UpdateWorkspaceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskSectionMutation
// ====================================================

export interface CreateTaskSectionMutation_section_workspace_taskSections {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface CreateTaskSectionMutation_section_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskSections: CreateTaskSectionMutation_section_workspace_taskSections[];
}

export interface CreateTaskSectionMutation_section {
  __typename: "TaskSection";
  id: Scalar.UUID;
  workspace: CreateTaskSectionMutation_section_workspace;
}

export interface CreateTaskSectionMutation {
  section: CreateTaskSectionMutation_section;
}

export interface CreateTaskSectionMutationVariables {
  input: CreateTaskSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskSectionMutation
// ====================================================

export interface UpdateTaskSectionMutation_section {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface UpdateTaskSectionMutation {
  section: UpdateTaskSectionMutation_section;
}

export interface UpdateTaskSectionMutationVariables {
  input: UpdateTaskSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTaskSectionMutation
// ====================================================

export interface DeleteTaskSectionMutation_section_workspace_taskSections {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface DeleteTaskSectionMutation_section_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskSections: DeleteTaskSectionMutation_section_workspace_taskSections[];
}

export interface DeleteTaskSectionMutation_section {
  __typename: "TaskSection";
  id: Scalar.UUID;
  workspace: DeleteTaskSectionMutation_section_workspace;
}

export interface DeleteTaskSectionMutation {
  section: DeleteTaskSectionMutation_section;
}

export interface DeleteTaskSectionMutationVariables {
  input: UpdateTaskSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskMutation
// ====================================================

export interface CreateTaskMutation_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskMutation_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateTaskMutation_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateTaskMutation_task_workspace_options | null;
}

export interface CreateTaskMutation_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskMutation_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface CreateTaskMutation_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateTaskMutation_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateTaskMutation_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskMutation_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskMutation_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskMutation_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskMutation_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskMutation_task_rewards_token_network;
}

export interface CreateTaskMutation_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskMutation_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskMutation_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskMutation_task_rewards_payments_payment_paymentMethod_network;
}

export interface CreateTaskMutation_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskMutation_task_rewards_payments_payment_paymentMethod;
}

export interface CreateTaskMutation_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskMutation_task_rewards_payments_user;
  payment: CreateTaskMutation_task_rewards_payments_payment;
}

export interface CreateTaskMutation_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskMutation_task_rewards_token;
  tokenId: string;
  payments: CreateTaskMutation_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskMutation_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface CreateTaskMutation_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateTaskMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: CreateTaskMutation_task_templateTask | null;
  workspaceId: string;
  workspace: CreateTaskMutation_task_workspace;
  parentTaskId: string | null;
  parentTask: CreateTaskMutation_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: CreateTaskMutation_task_subtasks[];
  tags: CreateTaskMutation_task_tags[];
  skills: CreateTaskMutation_task_skills[];
  assignees: CreateTaskMutation_task_assignees[];
  owners: CreateTaskMutation_task_owners[];
  creator: CreateTaskMutation_task_creator | null;
  rewards: CreateTaskMutation_task_rewards[];
  review: CreateTaskMutation_task_review | null;
  reactions: CreateTaskMutation_task_reactions[];
}

export interface CreateTaskMutation {
  task: CreateTaskMutation_task;
}

export interface CreateTaskMutationVariables {
  input: CreateTaskInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskMutation
// ====================================================

export interface UpdateTaskMutation_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UpdateTaskMutation_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateTaskMutation_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateTaskMutation_task_workspace_options | null;
}

export interface UpdateTaskMutation_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UpdateTaskMutation_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface UpdateTaskMutation_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface UpdateTaskMutation_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpdateTaskMutation_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskMutation_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskMutation_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskMutation_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskMutation_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskMutation_task_rewards_token_network;
}

export interface UpdateTaskMutation_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskMutation_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskMutation_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskMutation_task_rewards_payments_payment_paymentMethod_network;
}

export interface UpdateTaskMutation_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskMutation_task_rewards_payments_payment_paymentMethod;
}

export interface UpdateTaskMutation_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskMutation_task_rewards_payments_user;
  payment: UpdateTaskMutation_task_rewards_payments_payment;
}

export interface UpdateTaskMutation_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskMutation_task_rewards_token;
  tokenId: string;
  payments: UpdateTaskMutation_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskMutation_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface UpdateTaskMutation_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface UpdateTaskMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: UpdateTaskMutation_task_templateTask | null;
  workspaceId: string;
  workspace: UpdateTaskMutation_task_workspace;
  parentTaskId: string | null;
  parentTask: UpdateTaskMutation_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: UpdateTaskMutation_task_subtasks[];
  tags: UpdateTaskMutation_task_tags[];
  skills: UpdateTaskMutation_task_skills[];
  assignees: UpdateTaskMutation_task_assignees[];
  owners: UpdateTaskMutation_task_owners[];
  creator: UpdateTaskMutation_task_creator | null;
  rewards: UpdateTaskMutation_task_rewards[];
  review: UpdateTaskMutation_task_review | null;
  reactions: UpdateTaskMutation_task_reactions[];
}

export interface UpdateTaskMutation {
  task: UpdateTaskMutation_task;
}

export interface UpdateTaskMutationVariables {
  input: UpdateTaskInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskApplicationMutation
// ====================================================

export interface CreateTaskApplicationMutation_application_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskApplicationMutation_application_reward_token_network;
}

export interface CreateTaskApplicationMutation_application_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskApplicationMutation_application_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskApplicationMutation_application_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskApplicationMutation_application_reward_payments_payment_paymentMethod;
}

export interface CreateTaskApplicationMutation_application_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskApplicationMutation_application_reward_payments_user;
  payment: CreateTaskApplicationMutation_application_reward_payments_payment;
}

export interface CreateTaskApplicationMutation_application_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskApplicationMutation_application_reward_token;
  tokenId: string;
  payments: CreateTaskApplicationMutation_application_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskApplicationMutation_application_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateTaskApplicationMutation_application_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: CreateTaskApplicationMutation_application_user_details[];
}

export interface CreateTaskApplicationMutation_application_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskApplicationMutation_application_thread_messages_sender;
}

export interface CreateTaskApplicationMutation_application_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskApplicationMutation_application_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskApplicationMutation_application_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskApplicationMutation_application_thread_messages[];
  task: CreateTaskApplicationMutation_application_thread_task | null;
  workspace: CreateTaskApplicationMutation_application_thread_workspace | null;
}

export interface CreateTaskApplicationMutation_application_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskApplicationMutation_application_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateTaskApplicationMutation_application_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateTaskApplicationMutation_application_task_workspace_options | null;
  organization: CreateTaskApplicationMutation_application_task_workspace_organization;
}

export interface CreateTaskApplicationMutation_application_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskApplicationMutation_application_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateTaskApplicationMutation_application_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateTaskApplicationMutation_application_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskApplicationMutation_application_task_rewards_token_network;
}

export interface CreateTaskApplicationMutation_application_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskApplicationMutation_application_task_rewards_payments_payment_paymentMethod_network;
}

export interface CreateTaskApplicationMutation_application_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskApplicationMutation_application_task_rewards_payments_payment_paymentMethod;
}

export interface CreateTaskApplicationMutation_application_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskApplicationMutation_application_task_rewards_payments_user;
  payment: CreateTaskApplicationMutation_application_task_rewards_payments_payment;
}

export interface CreateTaskApplicationMutation_application_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskApplicationMutation_application_task_rewards_token;
  tokenId: string;
  payments: CreateTaskApplicationMutation_application_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskApplicationMutation_application_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface CreateTaskApplicationMutation_application_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateTaskApplicationMutation_application_task_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface CreateTaskApplicationMutation_application_task_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface CreateTaskApplicationMutation_application_task_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface CreateTaskApplicationMutation_application_task_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskApplicationMutation_application_task_applications_reward_token_network;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskApplicationMutation_application_task_applications_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskApplicationMutation_application_task_applications_reward_payments_payment_paymentMethod;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskApplicationMutation_application_task_applications_reward_payments_user;
  payment: CreateTaskApplicationMutation_application_task_applications_reward_payments_payment;
}

export interface CreateTaskApplicationMutation_application_task_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskApplicationMutation_application_task_applications_reward_token;
  tokenId: string;
  payments: CreateTaskApplicationMutation_application_task_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskApplicationMutation_application_task_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: CreateTaskApplicationMutation_application_task_applications_user_details[];
}

export interface CreateTaskApplicationMutation_application_task_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskApplicationMutation_application_task_applications_thread_messages_sender;
}

export interface CreateTaskApplicationMutation_application_task_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskApplicationMutation_application_task_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskApplicationMutation_application_task_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskApplicationMutation_application_task_applications_thread_messages[];
  task: CreateTaskApplicationMutation_application_task_applications_thread_task | null;
  workspace: CreateTaskApplicationMutation_application_task_applications_thread_workspace | null;
}

export interface CreateTaskApplicationMutation_application_task_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: CreateTaskApplicationMutation_application_task_applications_reward | null;
  status: TaskApplicationStatus;
  user: CreateTaskApplicationMutation_application_task_applications_user;
  thread: CreateTaskApplicationMutation_application_task_applications_thread | null;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskApplicationMutation_application_task_submissions_reward_token_network;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskApplicationMutation_application_task_submissions_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskApplicationMutation_application_task_submissions_reward_payments_payment_paymentMethod;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskApplicationMutation_application_task_submissions_reward_payments_user;
  payment: CreateTaskApplicationMutation_application_task_submissions_reward_payments_payment;
}

export interface CreateTaskApplicationMutation_application_task_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskApplicationMutation_application_task_submissions_reward_token;
  tokenId: string;
  payments: CreateTaskApplicationMutation_application_task_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskApplicationMutation_application_task_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskApplicationMutation_application_task_submissions_thread_messages_sender;
}

export interface CreateTaskApplicationMutation_application_task_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskApplicationMutation_application_task_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskApplicationMutation_application_task_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskApplicationMutation_application_task_submissions_thread_messages[];
  task: CreateTaskApplicationMutation_application_task_submissions_thread_task | null;
  workspace: CreateTaskApplicationMutation_application_task_submissions_thread_workspace | null;
}

export interface CreateTaskApplicationMutation_application_task_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: CreateTaskApplicationMutation_application_task_submissions_reward | null;
  user: CreateTaskApplicationMutation_application_task_submissions_user;
  approver: CreateTaskApplicationMutation_application_task_submissions_approver | null;
  thread: CreateTaskApplicationMutation_application_task_submissions_thread | null;
}

export interface CreateTaskApplicationMutation_application_task_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskApplicationMutation_application_task_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskApplicationMutation_application_task_nfts_payment_paymentMethod_network;
}

export interface CreateTaskApplicationMutation_application_task_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskApplicationMutation_application_task_nfts_payment_paymentMethod;
}

export interface CreateTaskApplicationMutation_application_task_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: CreateTaskApplicationMutation_application_task_nfts_payment;
}

export interface CreateTaskApplicationMutation_application_task_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: CreateTaskApplicationMutation_application_task_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface CreateTaskApplicationMutation_application_task_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskApplicationMutation_application_task_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskApplicationMutation_application_task_thread_messages_sender;
}

export interface CreateTaskApplicationMutation_application_task_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskApplicationMutation_application_task_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskApplicationMutation_application_task_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskApplicationMutation_application_task_thread_messages[];
  task: CreateTaskApplicationMutation_application_task_thread_task | null;
  workspace: CreateTaskApplicationMutation_application_task_thread_workspace | null;
}

export interface CreateTaskApplicationMutation_application_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: CreateTaskApplicationMutation_application_task_templateTask | null;
  workspaceId: string;
  workspace: CreateTaskApplicationMutation_application_task_workspace;
  parentTaskId: string | null;
  parentTask: CreateTaskApplicationMutation_application_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: CreateTaskApplicationMutation_application_task_subtasks[];
  tags: CreateTaskApplicationMutation_application_task_tags[];
  skills: CreateTaskApplicationMutation_application_task_skills[];
  assignees: CreateTaskApplicationMutation_application_task_assignees[];
  owners: CreateTaskApplicationMutation_application_task_owners[];
  creator: CreateTaskApplicationMutation_application_task_creator | null;
  rewards: CreateTaskApplicationMutation_application_task_rewards[];
  review: CreateTaskApplicationMutation_application_task_review | null;
  reactions: CreateTaskApplicationMutation_application_task_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: CreateTaskApplicationMutation_application_task_githubPullRequests[];
  githubBranches: CreateTaskApplicationMutation_application_task_githubBranches[];
  githubIssue: CreateTaskApplicationMutation_application_task_githubIssue | null;
  notionPage: CreateTaskApplicationMutation_application_task_notionPage | null;
  applications: CreateTaskApplicationMutation_application_task_applications[];
  submissions: CreateTaskApplicationMutation_application_task_submissions[];
  nfts: CreateTaskApplicationMutation_application_task_nfts[];
  auditLog: CreateTaskApplicationMutation_application_task_auditLog[];
  thread: CreateTaskApplicationMutation_application_task_thread | null;
}

export interface CreateTaskApplicationMutation_application {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: CreateTaskApplicationMutation_application_reward | null;
  status: TaskApplicationStatus;
  user: CreateTaskApplicationMutation_application_user;
  thread: CreateTaskApplicationMutation_application_thread | null;
  task: CreateTaskApplicationMutation_application_task;
}

export interface CreateTaskApplicationMutation {
  application: CreateTaskApplicationMutation_application;
}

export interface CreateTaskApplicationMutationVariables {
  input: CreateTaskApplicationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskApplicationMutation
// ====================================================

export interface UpdateTaskApplicationMutation_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UpdateTaskApplicationMutation_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateTaskApplicationMutation_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateTaskApplicationMutation_task_workspace_options | null;
  organization: UpdateTaskApplicationMutation_task_workspace_organization;
}

export interface UpdateTaskApplicationMutation_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UpdateTaskApplicationMutation_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface UpdateTaskApplicationMutation_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpdateTaskApplicationMutation_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskApplicationMutation_task_rewards_token_network;
}

export interface UpdateTaskApplicationMutation_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskApplicationMutation_task_rewards_payments_payment_paymentMethod_network;
}

export interface UpdateTaskApplicationMutation_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskApplicationMutation_task_rewards_payments_payment_paymentMethod;
}

export interface UpdateTaskApplicationMutation_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskApplicationMutation_task_rewards_payments_user;
  payment: UpdateTaskApplicationMutation_task_rewards_payments_payment;
}

export interface UpdateTaskApplicationMutation_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskApplicationMutation_task_rewards_token;
  tokenId: string;
  payments: UpdateTaskApplicationMutation_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskApplicationMutation_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface UpdateTaskApplicationMutation_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface UpdateTaskApplicationMutation_task_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface UpdateTaskApplicationMutation_task_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface UpdateTaskApplicationMutation_task_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface UpdateTaskApplicationMutation_task_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskApplicationMutation_task_applications_reward_token_network;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskApplicationMutation_task_applications_reward_payments_payment_paymentMethod_network;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskApplicationMutation_task_applications_reward_payments_payment_paymentMethod;
}

export interface UpdateTaskApplicationMutation_task_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskApplicationMutation_task_applications_reward_payments_user;
  payment: UpdateTaskApplicationMutation_task_applications_reward_payments_payment;
}

export interface UpdateTaskApplicationMutation_task_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskApplicationMutation_task_applications_reward_token;
  tokenId: string;
  payments: UpdateTaskApplicationMutation_task_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskApplicationMutation_task_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UpdateTaskApplicationMutation_task_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: UpdateTaskApplicationMutation_task_applications_user_details[];
}

export interface UpdateTaskApplicationMutation_task_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskApplicationMutation_task_applications_thread_messages_sender;
}

export interface UpdateTaskApplicationMutation_task_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskApplicationMutation_task_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskApplicationMutation_task_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskApplicationMutation_task_applications_thread_messages[];
  task: UpdateTaskApplicationMutation_task_applications_thread_task | null;
  workspace: UpdateTaskApplicationMutation_task_applications_thread_workspace | null;
}

export interface UpdateTaskApplicationMutation_task_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: UpdateTaskApplicationMutation_task_applications_reward | null;
  status: TaskApplicationStatus;
  user: UpdateTaskApplicationMutation_task_applications_user;
  thread: UpdateTaskApplicationMutation_task_applications_thread | null;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskApplicationMutation_task_submissions_reward_token_network;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskApplicationMutation_task_submissions_reward_payments_payment_paymentMethod_network;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskApplicationMutation_task_submissions_reward_payments_payment_paymentMethod;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskApplicationMutation_task_submissions_reward_payments_user;
  payment: UpdateTaskApplicationMutation_task_submissions_reward_payments_payment;
}

export interface UpdateTaskApplicationMutation_task_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskApplicationMutation_task_submissions_reward_token;
  tokenId: string;
  payments: UpdateTaskApplicationMutation_task_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskApplicationMutation_task_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskApplicationMutation_task_submissions_thread_messages_sender;
}

export interface UpdateTaskApplicationMutation_task_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskApplicationMutation_task_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskApplicationMutation_task_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskApplicationMutation_task_submissions_thread_messages[];
  task: UpdateTaskApplicationMutation_task_submissions_thread_task | null;
  workspace: UpdateTaskApplicationMutation_task_submissions_thread_workspace | null;
}

export interface UpdateTaskApplicationMutation_task_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: UpdateTaskApplicationMutation_task_submissions_reward | null;
  user: UpdateTaskApplicationMutation_task_submissions_user;
  approver: UpdateTaskApplicationMutation_task_submissions_approver | null;
  thread: UpdateTaskApplicationMutation_task_submissions_thread | null;
}

export interface UpdateTaskApplicationMutation_task_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskApplicationMutation_task_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskApplicationMutation_task_nfts_payment_paymentMethod_network;
}

export interface UpdateTaskApplicationMutation_task_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskApplicationMutation_task_nfts_payment_paymentMethod;
}

export interface UpdateTaskApplicationMutation_task_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: UpdateTaskApplicationMutation_task_nfts_payment;
}

export interface UpdateTaskApplicationMutation_task_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: UpdateTaskApplicationMutation_task_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface UpdateTaskApplicationMutation_task_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskApplicationMutation_task_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskApplicationMutation_task_thread_messages_sender;
}

export interface UpdateTaskApplicationMutation_task_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskApplicationMutation_task_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskApplicationMutation_task_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskApplicationMutation_task_thread_messages[];
  task: UpdateTaskApplicationMutation_task_thread_task | null;
  workspace: UpdateTaskApplicationMutation_task_thread_workspace | null;
}

export interface UpdateTaskApplicationMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: UpdateTaskApplicationMutation_task_templateTask | null;
  workspaceId: string;
  workspace: UpdateTaskApplicationMutation_task_workspace;
  parentTaskId: string | null;
  parentTask: UpdateTaskApplicationMutation_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: UpdateTaskApplicationMutation_task_subtasks[];
  tags: UpdateTaskApplicationMutation_task_tags[];
  skills: UpdateTaskApplicationMutation_task_skills[];
  assignees: UpdateTaskApplicationMutation_task_assignees[];
  owners: UpdateTaskApplicationMutation_task_owners[];
  creator: UpdateTaskApplicationMutation_task_creator | null;
  rewards: UpdateTaskApplicationMutation_task_rewards[];
  review: UpdateTaskApplicationMutation_task_review | null;
  reactions: UpdateTaskApplicationMutation_task_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: UpdateTaskApplicationMutation_task_githubPullRequests[];
  githubBranches: UpdateTaskApplicationMutation_task_githubBranches[];
  githubIssue: UpdateTaskApplicationMutation_task_githubIssue | null;
  notionPage: UpdateTaskApplicationMutation_task_notionPage | null;
  applications: UpdateTaskApplicationMutation_task_applications[];
  submissions: UpdateTaskApplicationMutation_task_submissions[];
  nfts: UpdateTaskApplicationMutation_task_nfts[];
  auditLog: UpdateTaskApplicationMutation_task_auditLog[];
  thread: UpdateTaskApplicationMutation_task_thread | null;
}

export interface UpdateTaskApplicationMutation {
  task: UpdateTaskApplicationMutation_task;
}

export interface UpdateTaskApplicationMutationVariables {
  input: UpdateTaskApplicationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskSubmissionMutation
// ====================================================

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateTaskSubmissionMutation_createTaskSubmission_task_workspace_options | null;
  organization: CreateTaskSubmissionMutation_createTaskSubmission_task_workspace_organization;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_token_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_payment_paymentMethod_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_payment_paymentMethod;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_user;
  payment: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments_payment;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_token;
  tokenId: string;
  payments: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_token_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_payment_paymentMethod;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_user;
  payment: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments_payment;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_token;
  tokenId: string;
  payments: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_user_details[];
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_messages_sender;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_messages[];
  task: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_task | null;
  workspace: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread_workspace | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_reward | null;
  status: TaskApplicationStatus;
  user: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_user;
  thread: CreateTaskSubmissionMutation_createTaskSubmission_task_applications_thread | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_token_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_payment_paymentMethod;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_user;
  payment: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments_payment;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_token;
  tokenId: string;
  payments: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_messages_sender;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_messages[];
  task: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_task | null;
  workspace: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread_workspace | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_reward | null;
  user: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_user;
  approver: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_approver | null;
  thread: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions_thread | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskSubmissionMutation_createTaskSubmission_task_nfts_payment_paymentMethod_network;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskSubmissionMutation_createTaskSubmission_task_nfts_payment_paymentMethod;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: CreateTaskSubmissionMutation_createTaskSubmission_task_nfts_payment;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: CreateTaskSubmissionMutation_createTaskSubmission_task_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskSubmissionMutation_createTaskSubmission_task_thread_messages_sender;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskSubmissionMutation_createTaskSubmission_task_thread_messages[];
  task: CreateTaskSubmissionMutation_createTaskSubmission_task_thread_task | null;
  workspace: CreateTaskSubmissionMutation_createTaskSubmission_task_thread_workspace | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: CreateTaskSubmissionMutation_createTaskSubmission_task_templateTask | null;
  workspaceId: string;
  workspace: CreateTaskSubmissionMutation_createTaskSubmission_task_workspace;
  parentTaskId: string | null;
  parentTask: CreateTaskSubmissionMutation_createTaskSubmission_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: CreateTaskSubmissionMutation_createTaskSubmission_task_subtasks[];
  tags: CreateTaskSubmissionMutation_createTaskSubmission_task_tags[];
  skills: CreateTaskSubmissionMutation_createTaskSubmission_task_skills[];
  assignees: CreateTaskSubmissionMutation_createTaskSubmission_task_assignees[];
  owners: CreateTaskSubmissionMutation_createTaskSubmission_task_owners[];
  creator: CreateTaskSubmissionMutation_createTaskSubmission_task_creator | null;
  rewards: CreateTaskSubmissionMutation_createTaskSubmission_task_rewards[];
  review: CreateTaskSubmissionMutation_createTaskSubmission_task_review | null;
  reactions: CreateTaskSubmissionMutation_createTaskSubmission_task_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: CreateTaskSubmissionMutation_createTaskSubmission_task_githubPullRequests[];
  githubBranches: CreateTaskSubmissionMutation_createTaskSubmission_task_githubBranches[];
  githubIssue: CreateTaskSubmissionMutation_createTaskSubmission_task_githubIssue | null;
  notionPage: CreateTaskSubmissionMutation_createTaskSubmission_task_notionPage | null;
  applications: CreateTaskSubmissionMutation_createTaskSubmission_task_applications[];
  submissions: CreateTaskSubmissionMutation_createTaskSubmission_task_submissions[];
  nfts: CreateTaskSubmissionMutation_createTaskSubmission_task_nfts[];
  auditLog: CreateTaskSubmissionMutation_createTaskSubmission_task_auditLog[];
  thread: CreateTaskSubmissionMutation_createTaskSubmission_task_thread | null;
}

export interface CreateTaskSubmissionMutation_createTaskSubmission {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  task: CreateTaskSubmissionMutation_createTaskSubmission_task;
}

export interface CreateTaskSubmissionMutation {
  createTaskSubmission: CreateTaskSubmissionMutation_createTaskSubmission;
}

export interface CreateTaskSubmissionMutationVariables {
  input: CreateTaskSubmissionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskSubmissionMutation
// ====================================================

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpdateTaskSubmissionMutation_updateTaskSubmission_task_workspace_options | null;
  organization: UpdateTaskSubmissionMutation_updateTaskSubmission_task_workspace_organization;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_token_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_payment_paymentMethod_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_payment_paymentMethod;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_user;
  payment: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments_payment;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_token;
  tokenId: string;
  payments: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_token_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_payment_paymentMethod_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_payment_paymentMethod;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_user;
  payment: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments_payment;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_token;
  tokenId: string;
  payments: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_user_details[];
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_messages_sender;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_messages[];
  task: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_task | null;
  workspace: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread_workspace | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_reward | null;
  status: TaskApplicationStatus;
  user: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_user;
  thread: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications_thread | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_token_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_payment_paymentMethod_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_payment_paymentMethod;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_user;
  payment: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments_payment;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_token;
  tokenId: string;
  payments: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_messages_sender;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_messages[];
  task: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_task | null;
  workspace: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread_workspace | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_reward | null;
  user: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_user;
  approver: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_approver | null;
  thread: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions_thread | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts_payment_paymentMethod_network;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts_payment_paymentMethod;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts_payment;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: UpdateTaskSubmissionMutation_updateTaskSubmission_task_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_messages_sender;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_messages[];
  task: UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_task | null;
  workspace: UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread_workspace | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: UpdateTaskSubmissionMutation_updateTaskSubmission_task_templateTask | null;
  workspaceId: string;
  workspace: UpdateTaskSubmissionMutation_updateTaskSubmission_task_workspace;
  parentTaskId: string | null;
  parentTask: UpdateTaskSubmissionMutation_updateTaskSubmission_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: UpdateTaskSubmissionMutation_updateTaskSubmission_task_subtasks[];
  tags: UpdateTaskSubmissionMutation_updateTaskSubmission_task_tags[];
  skills: UpdateTaskSubmissionMutation_updateTaskSubmission_task_skills[];
  assignees: UpdateTaskSubmissionMutation_updateTaskSubmission_task_assignees[];
  owners: UpdateTaskSubmissionMutation_updateTaskSubmission_task_owners[];
  creator: UpdateTaskSubmissionMutation_updateTaskSubmission_task_creator | null;
  rewards: UpdateTaskSubmissionMutation_updateTaskSubmission_task_rewards[];
  review: UpdateTaskSubmissionMutation_updateTaskSubmission_task_review | null;
  reactions: UpdateTaskSubmissionMutation_updateTaskSubmission_task_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: UpdateTaskSubmissionMutation_updateTaskSubmission_task_githubPullRequests[];
  githubBranches: UpdateTaskSubmissionMutation_updateTaskSubmission_task_githubBranches[];
  githubIssue: UpdateTaskSubmissionMutation_updateTaskSubmission_task_githubIssue | null;
  notionPage: UpdateTaskSubmissionMutation_updateTaskSubmission_task_notionPage | null;
  applications: UpdateTaskSubmissionMutation_updateTaskSubmission_task_applications[];
  submissions: UpdateTaskSubmissionMutation_updateTaskSubmission_task_submissions[];
  nfts: UpdateTaskSubmissionMutation_updateTaskSubmission_task_nfts[];
  auditLog: UpdateTaskSubmissionMutation_updateTaskSubmission_task_auditLog[];
  thread: UpdateTaskSubmissionMutation_updateTaskSubmission_task_thread | null;
}

export interface UpdateTaskSubmissionMutation_updateTaskSubmission {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  task: UpdateTaskSubmissionMutation_updateTaskSubmission_task;
}

export interface UpdateTaskSubmissionMutation {
  updateTaskSubmission: UpdateTaskSubmissionMutation_updateTaskSubmission;
}

export interface UpdateTaskSubmissionMutationVariables {
  input: UpdateTaskSubmissionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTaskMutation
// ====================================================

export interface DeleteTaskMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  deletedAt: Scalar.DateTime | null;
}

export interface DeleteTaskMutation {
  task: DeleteTaskMutation_task;
}

export interface DeleteTaskMutationVariables {
  taskId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskReactionMutation
// ====================================================

export interface CreateTaskReactionMutation_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateTaskReactionMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  reactions: CreateTaskReactionMutation_task_reactions[];
}

export interface CreateTaskReactionMutation {
  task: CreateTaskReactionMutation_task;
}

export interface CreateTaskReactionMutationVariables {
  input: TaskReactionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTaskReactionMutation
// ====================================================

export interface DeleteTaskReactionMutation_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface DeleteTaskReactionMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  reactions: DeleteTaskReactionMutation_task_reactions[];
}

export interface DeleteTaskReactionMutation {
  task: DeleteTaskReactionMutation_task;
}

export interface DeleteTaskReactionMutationVariables {
  input: TaskReactionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskTagMutation
// ====================================================

export interface CreateTaskTagMutation_taskTag_workspace_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateTaskTagMutation_taskTag_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskTags: CreateTaskTagMutation_taskTag_workspace_taskTags[];
}

export interface CreateTaskTagMutation_taskTag {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
  workspace: CreateTaskTagMutation_taskTag_workspace;
}

export interface CreateTaskTagMutation {
  taskTag: CreateTaskTagMutation_taskTag;
}

export interface CreateTaskTagMutationVariables {
  input: CreateTaskTagInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskTagMutation
// ====================================================

export interface UpdateTaskTagMutation_taskTag_workspace_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface UpdateTaskTagMutation_taskTag_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskTags: UpdateTaskTagMutation_taskTag_workspace_taskTags[];
}

export interface UpdateTaskTagMutation_taskTag {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
  workspace: UpdateTaskTagMutation_taskTag_workspace;
}

export interface UpdateTaskTagMutation {
  taskTag: UpdateTaskTagMutation_taskTag;
}

export interface UpdateTaskTagMutationVariables {
  input: UpdateTaskTagInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspaceIntegrationMutation
// ====================================================

export interface CreateWorkspaceIntegrationMutation_integration_workspace_integrations {
  __typename: "WorkspaceIntegration";
  id: Scalar.UUID;
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
}

export interface CreateWorkspaceIntegrationMutation_integration_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  integrations: CreateWorkspaceIntegrationMutation_integration_workspace_integrations[];
}

export interface CreateWorkspaceIntegrationMutation_integration {
  __typename: "WorkspaceIntegration";
  id: Scalar.UUID;
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
  workspace: CreateWorkspaceIntegrationMutation_integration_workspace;
}

export interface CreateWorkspaceIntegrationMutation {
  integration: CreateWorkspaceIntegrationMutation_integration;
}

export interface CreateWorkspaceIntegrationMutationVariables {
  input: CreateWorkspaceIntegrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganizationIntegrationMutation
// ====================================================

export interface CreateOrganizationIntegrationMutation_integration_organization_integrations {
  __typename: "OrganizationIntegration";
  id: Scalar.UUID;
  type: OrganizationIntegrationType;
  config: Scalar.JSONObject | null;
}

export interface CreateOrganizationIntegrationMutation_integration_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  integrations: CreateOrganizationIntegrationMutation_integration_organization_integrations[];
}

export interface CreateOrganizationIntegrationMutation_integration {
  __typename: "OrganizationIntegration";
  id: Scalar.UUID;
  type: OrganizationIntegrationType;
  config: Scalar.JSONObject | null;
  organization: CreateOrganizationIntegrationMutation_integration_organization;
}

export interface CreateOrganizationIntegrationMutation {
  integration: CreateOrganizationIntegrationMutation_integration;
}

export interface CreateOrganizationIntegrationMutationVariables {
  input: CreateOrganizationIntegrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserDetailMutation
// ====================================================

export interface SetUserDetailMutation_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface SetUserDetailMutation_organization {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: SetUserDetailMutation_organization_details[];
}

export interface SetUserDetailMutation {
  organization: SetUserDetailMutation_organization;
}

export interface SetUserDetailMutationVariables {
  input: SetUserDetailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetOrganizationDetailMutation
// ====================================================

export interface SetOrganizationDetailMutation_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface SetOrganizationDetailMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  details: SetOrganizationDetailMutation_organization_details[];
}

export interface SetOrganizationDetailMutation {
  organization: SetOrganizationDetailMutation_organization;
}

export interface SetOrganizationDetailMutationVariables {
  input: SetOrganizationDetailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspaceTokenGateMutation
// ====================================================

export interface CreateWorkspaceTokenGateMutation_tokenGate_workspace_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspaceTokenGateMutation_tokenGate_workspace_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspaceTokenGateMutation_tokenGate_workspace_tokenGates_token_network;
}

export interface CreateWorkspaceTokenGateMutation_tokenGate_workspace_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: CreateWorkspaceTokenGateMutation_tokenGate_workspace_tokenGates_token;
}

export interface CreateWorkspaceTokenGateMutation_tokenGate_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  tokenGates: CreateWorkspaceTokenGateMutation_tokenGate_workspace_tokenGates[];
}

export interface CreateWorkspaceTokenGateMutation_tokenGate {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  workspace: CreateWorkspaceTokenGateMutation_tokenGate_workspace;
}

export interface CreateWorkspaceTokenGateMutation {
  tokenGate: CreateWorkspaceTokenGateMutation_tokenGate;
}

export interface CreateWorkspaceTokenGateMutationVariables {
  input: WorkspaceTokenGateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWorkspaceTokenGateMutation
// ====================================================

export interface DeleteWorkspaceTokenGateMutation_tokenGate_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface DeleteWorkspaceTokenGateMutation_tokenGate_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: DeleteWorkspaceTokenGateMutation_tokenGate_tokenGates_token_network;
}

export interface DeleteWorkspaceTokenGateMutation_tokenGate_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: DeleteWorkspaceTokenGateMutation_tokenGate_tokenGates_token;
}

export interface DeleteWorkspaceTokenGateMutation_tokenGate {
  __typename: "Workspace";
  id: Scalar.UUID;
  tokenGates: DeleteWorkspaceTokenGateMutation_tokenGate_tokenGates[];
}

export interface DeleteWorkspaceTokenGateMutation {
  tokenGate: DeleteWorkspaceTokenGateMutation_tokenGate;
}

export interface DeleteWorkspaceTokenGateMutationVariables {
  input: WorkspaceTokenGateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInviteMutation
// ====================================================

export interface CreateInviteMutation_invite {
  __typename: "Invite";
  id: Scalar.UUID;
  permalink: string;
}

export interface CreateInviteMutation {
  invite: CreateInviteMutation_invite;
}

export interface CreateInviteMutationVariables {
  input: CreateInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptInviteMutation
// ====================================================

export interface AcceptInviteMutation_invite_organization_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface AcceptInviteMutation_invite_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  users: AcceptInviteMutation_invite_organization_users[];
}

export interface AcceptInviteMutation_invite_workspace_organization_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface AcceptInviteMutation_invite_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  users: AcceptInviteMutation_invite_workspace_organization_users[];
}

export interface AcceptInviteMutation_invite_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  name: string;
  organization: AcceptInviteMutation_invite_workspace_organization;
}

export interface AcceptInviteMutation_invite {
  __typename: "Invite";
  id: Scalar.UUID;
  organization: AcceptInviteMutation_invite_organization | null;
  workspace: AcceptInviteMutation_invite_workspace | null;
}

export interface AcceptInviteMutation {
  invite: AcceptInviteMutation_invite;
}

export interface AcceptInviteMutationVariables {
  inviteId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: JoinWorkspaceWithTokenMutation
// ====================================================

export interface JoinWorkspaceWithTokenMutation_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface JoinWorkspaceWithTokenMutation_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: JoinWorkspaceWithTokenMutation_workspace_options | null;
}

export interface JoinWorkspaceWithTokenMutation {
  workspace: JoinWorkspaceWithTokenMutation_workspace;
}

export interface JoinWorkspaceWithTokenMutationVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePaymentMethodMutation
// ====================================================

export interface CreatePaymentMethodMutation_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreatePaymentMethodMutation_paymentMethod_workspace_paymentMethods_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreatePaymentMethodMutation_paymentMethod_workspace_paymentMethods {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreatePaymentMethodMutation_paymentMethod_workspace_paymentMethods_network;
}

export interface CreatePaymentMethodMutation_paymentMethod_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  paymentMethods: CreatePaymentMethodMutation_paymentMethod_workspace_paymentMethods[];
}

export interface CreatePaymentMethodMutation_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreatePaymentMethodMutation_paymentMethod_network;
  workspace: CreatePaymentMethodMutation_paymentMethod_workspace | null;
}

export interface CreatePaymentMethodMutation {
  paymentMethod: CreatePaymentMethodMutation_paymentMethod;
}

export interface CreatePaymentMethodMutationVariables {
  input: CreatePaymentMethodInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePaymentTokenMutation
// ====================================================

export interface CreatePaymentTokenMutation_token_network_tokens {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
}

export interface CreatePaymentTokenMutation_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
  tokens: CreatePaymentTokenMutation_token_network_tokens[];
}

export interface CreatePaymentTokenMutation_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreatePaymentTokenMutation_token_network;
}

export interface CreatePaymentTokenMutation {
  token: CreatePaymentTokenMutation_token;
}

export interface CreatePaymentTokenMutationVariables {
  input: CreatePaymentTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePaymentMethodMutation
// ====================================================

export interface UpdatePaymentMethodMutation_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdatePaymentMethodMutation_paymentMethod_workspace_paymentMethods_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdatePaymentMethodMutation_paymentMethod_workspace_paymentMethods {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdatePaymentMethodMutation_paymentMethod_workspace_paymentMethods_network;
}

export interface UpdatePaymentMethodMutation_paymentMethod_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  paymentMethods: UpdatePaymentMethodMutation_paymentMethod_workspace_paymentMethods[];
}

export interface UpdatePaymentMethodMutation_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdatePaymentMethodMutation_paymentMethod_network;
  workspace: UpdatePaymentMethodMutation_paymentMethod_workspace | null;
}

export interface UpdatePaymentMethodMutation {
  paymentMethod: UpdatePaymentMethodMutation_paymentMethod;
}

export interface UpdatePaymentMethodMutationVariables {
  input: UpdatePaymentMethodInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWorkspaceIntegrationMutation
// ====================================================

export interface UpdateWorkspaceIntegrationMutation_integration_workspace_integrations {
  __typename: "WorkspaceIntegration";
  id: Scalar.UUID;
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
}

export interface UpdateWorkspaceIntegrationMutation_integration_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  integrations: UpdateWorkspaceIntegrationMutation_integration_workspace_integrations[];
}

export interface UpdateWorkspaceIntegrationMutation_integration {
  __typename: "WorkspaceIntegration";
  id: Scalar.UUID;
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
  workspace: UpdateWorkspaceIntegrationMutation_integration_workspace;
}

export interface UpdateWorkspaceIntegrationMutation {
  integration: UpdateWorkspaceIntegrationMutation_integration;
}

export interface UpdateWorkspaceIntegrationMutationVariables {
  input: UpdateWorkspaceIntegrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFileUploadMutation
// ====================================================

export interface CreateFileUploadMutation_fileUpload {
  __typename: "CreateFileUploadResponse";
  signedUrl: string;
  publicUrl: string;
}

export interface CreateFileUploadMutation {
  fileUpload: CreateFileUploadMutation_fileUpload;
}

export interface CreateFileUploadMutationVariables {
  input: CreateFileUploadUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskPaymentsMutation
// ====================================================

export interface CreateTaskPaymentsMutation_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskPaymentsMutation_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateTaskPaymentsMutation_tasks_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateTaskPaymentsMutation_tasks_workspace_options | null;
  organization: CreateTaskPaymentsMutation_tasks_workspace_organization;
}

export interface CreateTaskPaymentsMutation_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTaskPaymentsMutation_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateTaskPaymentsMutation_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateTaskPaymentsMutation_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskPaymentsMutation_tasks_rewards_token_network;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod;
}

export interface CreateTaskPaymentsMutation_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskPaymentsMutation_tasks_rewards_payments_user;
  payment: CreateTaskPaymentsMutation_tasks_rewards_payments_payment;
}

export interface CreateTaskPaymentsMutation_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskPaymentsMutation_tasks_rewards_token;
  tokenId: string;
  payments: CreateTaskPaymentsMutation_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskPaymentsMutation_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface CreateTaskPaymentsMutation_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateTaskPaymentsMutation_tasks_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface CreateTaskPaymentsMutation_tasks_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface CreateTaskPaymentsMutation_tasks_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface CreateTaskPaymentsMutation_tasks_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskPaymentsMutation_tasks_applications_reward_token_network;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskPaymentsMutation_tasks_applications_reward_payments_user;
  payment: CreateTaskPaymentsMutation_tasks_applications_reward_payments_payment;
}

export interface CreateTaskPaymentsMutation_tasks_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskPaymentsMutation_tasks_applications_reward_token;
  tokenId: string;
  payments: CreateTaskPaymentsMutation_tasks_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskPaymentsMutation_tasks_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: CreateTaskPaymentsMutation_tasks_applications_user_details[];
}

export interface CreateTaskPaymentsMutation_tasks_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskPaymentsMutation_tasks_applications_thread_messages_sender;
}

export interface CreateTaskPaymentsMutation_tasks_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskPaymentsMutation_tasks_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskPaymentsMutation_tasks_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskPaymentsMutation_tasks_applications_thread_messages[];
  task: CreateTaskPaymentsMutation_tasks_applications_thread_task | null;
  workspace: CreateTaskPaymentsMutation_tasks_applications_thread_workspace | null;
}

export interface CreateTaskPaymentsMutation_tasks_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: CreateTaskPaymentsMutation_tasks_applications_reward | null;
  status: TaskApplicationStatus;
  user: CreateTaskPaymentsMutation_tasks_applications_user;
  thread: CreateTaskPaymentsMutation_tasks_applications_thread | null;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTaskPaymentsMutation_tasks_submissions_reward_token_network;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod_network;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTaskPaymentsMutation_tasks_submissions_reward_payments_user;
  payment: CreateTaskPaymentsMutation_tasks_submissions_reward_payments_payment;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTaskPaymentsMutation_tasks_submissions_reward_token;
  tokenId: string;
  payments: CreateTaskPaymentsMutation_tasks_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskPaymentsMutation_tasks_submissions_thread_messages_sender;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskPaymentsMutation_tasks_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskPaymentsMutation_tasks_submissions_thread_messages[];
  task: CreateTaskPaymentsMutation_tasks_submissions_thread_task | null;
  workspace: CreateTaskPaymentsMutation_tasks_submissions_thread_workspace | null;
}

export interface CreateTaskPaymentsMutation_tasks_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: CreateTaskPaymentsMutation_tasks_submissions_reward | null;
  user: CreateTaskPaymentsMutation_tasks_submissions_user;
  approver: CreateTaskPaymentsMutation_tasks_submissions_approver | null;
  thread: CreateTaskPaymentsMutation_tasks_submissions_thread | null;
}

export interface CreateTaskPaymentsMutation_tasks_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTaskPaymentsMutation_tasks_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTaskPaymentsMutation_tasks_nfts_payment_paymentMethod_network;
}

export interface CreateTaskPaymentsMutation_tasks_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTaskPaymentsMutation_tasks_nfts_payment_paymentMethod;
}

export interface CreateTaskPaymentsMutation_tasks_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: CreateTaskPaymentsMutation_tasks_nfts_payment;
}

export interface CreateTaskPaymentsMutation_tasks_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: CreateTaskPaymentsMutation_tasks_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface CreateTaskPaymentsMutation_tasks_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTaskPaymentsMutation_tasks_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateTaskPaymentsMutation_tasks_thread_messages_sender;
}

export interface CreateTaskPaymentsMutation_tasks_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateTaskPaymentsMutation_tasks_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateTaskPaymentsMutation_tasks_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateTaskPaymentsMutation_tasks_thread_messages[];
  task: CreateTaskPaymentsMutation_tasks_thread_task | null;
  workspace: CreateTaskPaymentsMutation_tasks_thread_workspace | null;
}

export interface CreateTaskPaymentsMutation_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: CreateTaskPaymentsMutation_tasks_templateTask | null;
  workspaceId: string;
  workspace: CreateTaskPaymentsMutation_tasks_workspace;
  parentTaskId: string | null;
  parentTask: CreateTaskPaymentsMutation_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: CreateTaskPaymentsMutation_tasks_subtasks[];
  tags: CreateTaskPaymentsMutation_tasks_tags[];
  skills: CreateTaskPaymentsMutation_tasks_skills[];
  assignees: CreateTaskPaymentsMutation_tasks_assignees[];
  owners: CreateTaskPaymentsMutation_tasks_owners[];
  creator: CreateTaskPaymentsMutation_tasks_creator | null;
  rewards: CreateTaskPaymentsMutation_tasks_rewards[];
  review: CreateTaskPaymentsMutation_tasks_review | null;
  reactions: CreateTaskPaymentsMutation_tasks_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: CreateTaskPaymentsMutation_tasks_githubPullRequests[];
  githubBranches: CreateTaskPaymentsMutation_tasks_githubBranches[];
  githubIssue: CreateTaskPaymentsMutation_tasks_githubIssue | null;
  notionPage: CreateTaskPaymentsMutation_tasks_notionPage | null;
  applications: CreateTaskPaymentsMutation_tasks_applications[];
  submissions: CreateTaskPaymentsMutation_tasks_submissions[];
  nfts: CreateTaskPaymentsMutation_tasks_nfts[];
  auditLog: CreateTaskPaymentsMutation_tasks_auditLog[];
  thread: CreateTaskPaymentsMutation_tasks_thread | null;
}

export interface CreateTaskPaymentsMutation {
  tasks: CreateTaskPaymentsMutation_tasks[];
}

export interface CreateTaskPaymentsMutationVariables {
  input: CreateTaskPaymentsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearTaskPaymentsMutation
// ====================================================

export interface ClearTaskPaymentsMutation_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface ClearTaskPaymentsMutation_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface ClearTaskPaymentsMutation_tasks_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: ClearTaskPaymentsMutation_tasks_workspace_options | null;
  organization: ClearTaskPaymentsMutation_tasks_workspace_organization;
}

export interface ClearTaskPaymentsMutation_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface ClearTaskPaymentsMutation_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface ClearTaskPaymentsMutation_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface ClearTaskPaymentsMutation_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: ClearTaskPaymentsMutation_tasks_rewards_token_network;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: ClearTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: ClearTaskPaymentsMutation_tasks_rewards_payments_payment_paymentMethod;
}

export interface ClearTaskPaymentsMutation_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: ClearTaskPaymentsMutation_tasks_rewards_payments_user;
  payment: ClearTaskPaymentsMutation_tasks_rewards_payments_payment;
}

export interface ClearTaskPaymentsMutation_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: ClearTaskPaymentsMutation_tasks_rewards_token;
  tokenId: string;
  payments: ClearTaskPaymentsMutation_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface ClearTaskPaymentsMutation_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface ClearTaskPaymentsMutation_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface ClearTaskPaymentsMutation_tasks_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface ClearTaskPaymentsMutation_tasks_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface ClearTaskPaymentsMutation_tasks_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface ClearTaskPaymentsMutation_tasks_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: ClearTaskPaymentsMutation_tasks_applications_reward_token_network;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: ClearTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod_network;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: ClearTaskPaymentsMutation_tasks_applications_reward_payments_payment_paymentMethod;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: ClearTaskPaymentsMutation_tasks_applications_reward_payments_user;
  payment: ClearTaskPaymentsMutation_tasks_applications_reward_payments_payment;
}

export interface ClearTaskPaymentsMutation_tasks_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: ClearTaskPaymentsMutation_tasks_applications_reward_token;
  tokenId: string;
  payments: ClearTaskPaymentsMutation_tasks_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface ClearTaskPaymentsMutation_tasks_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: ClearTaskPaymentsMutation_tasks_applications_user_details[];
}

export interface ClearTaskPaymentsMutation_tasks_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: ClearTaskPaymentsMutation_tasks_applications_thread_messages_sender;
}

export interface ClearTaskPaymentsMutation_tasks_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface ClearTaskPaymentsMutation_tasks_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface ClearTaskPaymentsMutation_tasks_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: ClearTaskPaymentsMutation_tasks_applications_thread_messages[];
  task: ClearTaskPaymentsMutation_tasks_applications_thread_task | null;
  workspace: ClearTaskPaymentsMutation_tasks_applications_thread_workspace | null;
}

export interface ClearTaskPaymentsMutation_tasks_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: ClearTaskPaymentsMutation_tasks_applications_reward | null;
  status: TaskApplicationStatus;
  user: ClearTaskPaymentsMutation_tasks_applications_user;
  thread: ClearTaskPaymentsMutation_tasks_applications_thread | null;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: ClearTaskPaymentsMutation_tasks_submissions_reward_token_network;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: ClearTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod_network;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: ClearTaskPaymentsMutation_tasks_submissions_reward_payments_payment_paymentMethod;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: ClearTaskPaymentsMutation_tasks_submissions_reward_payments_user;
  payment: ClearTaskPaymentsMutation_tasks_submissions_reward_payments_payment;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: ClearTaskPaymentsMutation_tasks_submissions_reward_token;
  tokenId: string;
  payments: ClearTaskPaymentsMutation_tasks_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: ClearTaskPaymentsMutation_tasks_submissions_thread_messages_sender;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface ClearTaskPaymentsMutation_tasks_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: ClearTaskPaymentsMutation_tasks_submissions_thread_messages[];
  task: ClearTaskPaymentsMutation_tasks_submissions_thread_task | null;
  workspace: ClearTaskPaymentsMutation_tasks_submissions_thread_workspace | null;
}

export interface ClearTaskPaymentsMutation_tasks_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: ClearTaskPaymentsMutation_tasks_submissions_reward | null;
  user: ClearTaskPaymentsMutation_tasks_submissions_user;
  approver: ClearTaskPaymentsMutation_tasks_submissions_approver | null;
  thread: ClearTaskPaymentsMutation_tasks_submissions_thread | null;
}

export interface ClearTaskPaymentsMutation_tasks_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface ClearTaskPaymentsMutation_tasks_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: ClearTaskPaymentsMutation_tasks_nfts_payment_paymentMethod_network;
}

export interface ClearTaskPaymentsMutation_tasks_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: ClearTaskPaymentsMutation_tasks_nfts_payment_paymentMethod;
}

export interface ClearTaskPaymentsMutation_tasks_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: ClearTaskPaymentsMutation_tasks_nfts_payment;
}

export interface ClearTaskPaymentsMutation_tasks_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: ClearTaskPaymentsMutation_tasks_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface ClearTaskPaymentsMutation_tasks_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ClearTaskPaymentsMutation_tasks_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: ClearTaskPaymentsMutation_tasks_thread_messages_sender;
}

export interface ClearTaskPaymentsMutation_tasks_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface ClearTaskPaymentsMutation_tasks_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface ClearTaskPaymentsMutation_tasks_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: ClearTaskPaymentsMutation_tasks_thread_messages[];
  task: ClearTaskPaymentsMutation_tasks_thread_task | null;
  workspace: ClearTaskPaymentsMutation_tasks_thread_workspace | null;
}

export interface ClearTaskPaymentsMutation_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: ClearTaskPaymentsMutation_tasks_templateTask | null;
  workspaceId: string;
  workspace: ClearTaskPaymentsMutation_tasks_workspace;
  parentTaskId: string | null;
  parentTask: ClearTaskPaymentsMutation_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: ClearTaskPaymentsMutation_tasks_subtasks[];
  tags: ClearTaskPaymentsMutation_tasks_tags[];
  skills: ClearTaskPaymentsMutation_tasks_skills[];
  assignees: ClearTaskPaymentsMutation_tasks_assignees[];
  owners: ClearTaskPaymentsMutation_tasks_owners[];
  creator: ClearTaskPaymentsMutation_tasks_creator | null;
  rewards: ClearTaskPaymentsMutation_tasks_rewards[];
  review: ClearTaskPaymentsMutation_tasks_review | null;
  reactions: ClearTaskPaymentsMutation_tasks_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: ClearTaskPaymentsMutation_tasks_githubPullRequests[];
  githubBranches: ClearTaskPaymentsMutation_tasks_githubBranches[];
  githubIssue: ClearTaskPaymentsMutation_tasks_githubIssue | null;
  notionPage: ClearTaskPaymentsMutation_tasks_notionPage | null;
  applications: ClearTaskPaymentsMutation_tasks_applications[];
  submissions: ClearTaskPaymentsMutation_tasks_submissions[];
  nfts: ClearTaskPaymentsMutation_tasks_nfts[];
  auditLog: ClearTaskPaymentsMutation_tasks_auditLog[];
  thread: ClearTaskPaymentsMutation_tasks_thread | null;
}

export interface ClearTaskPaymentsMutation {
  tasks: ClearTaskPaymentsMutation_tasks[];
}

export interface ClearTaskPaymentsMutationVariables {
  paymentId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTasksFromGithubIssuesMutation
// ====================================================

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateTasksFromGithubIssuesMutation_workspace_tasks_workspace_options | null;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_token_network;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_payment_paymentMethod;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_user;
  payment: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments_payment;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_token;
  tokenId: string;
  payments: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateTasksFromGithubIssuesMutation_workspace_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: CreateTasksFromGithubIssuesMutation_workspace_tasks_templateTask | null;
  workspaceId: string;
  workspace: CreateTasksFromGithubIssuesMutation_workspace_tasks_workspace;
  parentTaskId: string | null;
  parentTask: CreateTasksFromGithubIssuesMutation_workspace_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: CreateTasksFromGithubIssuesMutation_workspace_tasks_subtasks[];
  tags: CreateTasksFromGithubIssuesMutation_workspace_tasks_tags[];
  skills: CreateTasksFromGithubIssuesMutation_workspace_tasks_skills[];
  assignees: CreateTasksFromGithubIssuesMutation_workspace_tasks_assignees[];
  owners: CreateTasksFromGithubIssuesMutation_workspace_tasks_owners[];
  creator: CreateTasksFromGithubIssuesMutation_workspace_tasks_creator | null;
  rewards: CreateTasksFromGithubIssuesMutation_workspace_tasks_rewards[];
  review: CreateTasksFromGithubIssuesMutation_workspace_tasks_review | null;
  reactions: CreateTasksFromGithubIssuesMutation_workspace_tasks_reactions[];
}

export interface CreateTasksFromGithubIssuesMutation_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  tasks: CreateTasksFromGithubIssuesMutation_workspace_tasks[];
}

export interface CreateTasksFromGithubIssuesMutation {
  workspace: CreateTasksFromGithubIssuesMutation_workspace;
}

export interface CreateTasksFromGithubIssuesMutationVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskDiscordLinkMutation
// ====================================================

export interface CreateTaskDiscordLinkMutation {
  link: string;
}

export interface CreateTaskDiscordLinkMutationVariables {
  taskId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspacesFromNotionMutation
// ====================================================

export interface CreateWorkspacesFromNotionMutation_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromNotionMutation_organization_workspaces_options | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface CreateWorkspacesFromNotionMutation_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspacesFromNotionMutation_organization_workspaceTokenGates_token_network;
}

export interface CreateWorkspacesFromNotionMutation_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: CreateWorkspacesFromNotionMutation_organization_workspaceTokenGates_token;
}

export interface CreateWorkspacesFromNotionMutation_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateWorkspacesFromNotionMutation_organization_taskViews_filters_dueDate | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateWorkspacesFromNotionMutation_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateWorkspacesFromNotionMutation_organization_taskViews_filters[];
  sortBys: CreateWorkspacesFromNotionMutation_organization_taskViews_sortBys[];
}

export interface CreateWorkspacesFromNotionMutation_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspacesFromNotionMutation_organization_fundingSessions_token_network;
}

export interface CreateWorkspacesFromNotionMutation_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CreateWorkspacesFromNotionMutation_organization_fundingSessions_token;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromNotionMutation_organization_node_workspace_options | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_workspace_options | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_organization | null;
  workspace: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_workspace | null;
  skill: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node_skill | null;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children_node;
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_organization | null;
  workspace: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_workspace | null;
  skill: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_skill | null;
  children: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node_children[];
}

export interface CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren_node;
}

export interface CreateWorkspacesFromNotionMutation_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromNotionMutation_organization_node_organization | null;
  workspace: CreateWorkspacesFromNotionMutation_organization_node_workspace | null;
  skill: CreateWorkspacesFromNotionMutation_organization_node_skill | null;
  workspaceChildren: CreateWorkspacesFromNotionMutation_organization_node_workspaceChildren[];
}

export interface CreateWorkspacesFromNotionMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: CreateWorkspacesFromNotionMutation_organization_options | null;
  workspaceCount: number;
  workspaces: CreateWorkspacesFromNotionMutation_organization_workspaces[];
  workspaceSections: CreateWorkspacesFromNotionMutation_organization_workspaceSections[];
  tags: CreateWorkspacesFromNotionMutation_organization_tags[];
  details: CreateWorkspacesFromNotionMutation_organization_details[];
  workspaceTokenGates: CreateWorkspacesFromNotionMutation_organization_workspaceTokenGates[];
  taskViews: CreateWorkspacesFromNotionMutation_organization_taskViews[];
  fundingSessions: CreateWorkspacesFromNotionMutation_organization_fundingSessions[];
  node: CreateWorkspacesFromNotionMutation_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface CreateWorkspacesFromNotionMutation {
  organization: CreateWorkspacesFromNotionMutation_organization;
}

export interface CreateWorkspacesFromNotionMutationVariables {
  input: CreateWorkspacesFromNotionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspacesFromTrelloMutation
// ====================================================

export interface CreateWorkspacesFromTrelloMutation_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromTrelloMutation_organization_workspaces_options | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface CreateWorkspacesFromTrelloMutation_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspacesFromTrelloMutation_organization_workspaceTokenGates_token_network;
}

export interface CreateWorkspacesFromTrelloMutation_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: CreateWorkspacesFromTrelloMutation_organization_workspaceTokenGates_token;
}

export interface CreateWorkspacesFromTrelloMutation_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateWorkspacesFromTrelloMutation_organization_taskViews_filters_dueDate | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateWorkspacesFromTrelloMutation_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateWorkspacesFromTrelloMutation_organization_taskViews_filters[];
  sortBys: CreateWorkspacesFromTrelloMutation_organization_taskViews_sortBys[];
}

export interface CreateWorkspacesFromTrelloMutation_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspacesFromTrelloMutation_organization_fundingSessions_token_network;
}

export interface CreateWorkspacesFromTrelloMutation_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CreateWorkspacesFromTrelloMutation_organization_fundingSessions_token;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromTrelloMutation_organization_node_workspace_options | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_workspace_options | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_organization | null;
  workspace: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_workspace | null;
  skill: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node_skill | null;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children_node;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_organization | null;
  workspace: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_workspace | null;
  skill: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_skill | null;
  children: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node_children[];
}

export interface CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren_node;
}

export interface CreateWorkspacesFromTrelloMutation_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromTrelloMutation_organization_node_organization | null;
  workspace: CreateWorkspacesFromTrelloMutation_organization_node_workspace | null;
  skill: CreateWorkspacesFromTrelloMutation_organization_node_skill | null;
  workspaceChildren: CreateWorkspacesFromTrelloMutation_organization_node_workspaceChildren[];
}

export interface CreateWorkspacesFromTrelloMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: CreateWorkspacesFromTrelloMutation_organization_options | null;
  workspaceCount: number;
  workspaces: CreateWorkspacesFromTrelloMutation_organization_workspaces[];
  workspaceSections: CreateWorkspacesFromTrelloMutation_organization_workspaceSections[];
  tags: CreateWorkspacesFromTrelloMutation_organization_tags[];
  details: CreateWorkspacesFromTrelloMutation_organization_details[];
  workspaceTokenGates: CreateWorkspacesFromTrelloMutation_organization_workspaceTokenGates[];
  taskViews: CreateWorkspacesFromTrelloMutation_organization_taskViews[];
  fundingSessions: CreateWorkspacesFromTrelloMutation_organization_fundingSessions[];
  node: CreateWorkspacesFromTrelloMutation_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface CreateWorkspacesFromTrelloMutation {
  organization: CreateWorkspacesFromTrelloMutation_organization;
}

export interface CreateWorkspacesFromTrelloMutationVariables {
  input: CreateWorkspacesFromTrelloInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspacesFromGithubMutation
// ====================================================

export interface CreateWorkspacesFromGithubMutation_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromGithubMutation_organization_workspaces_options | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface CreateWorkspacesFromGithubMutation_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspacesFromGithubMutation_organization_workspaceTokenGates_token_network;
}

export interface CreateWorkspacesFromGithubMutation_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: CreateWorkspacesFromGithubMutation_organization_workspaceTokenGates_token;
}

export interface CreateWorkspacesFromGithubMutation_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateWorkspacesFromGithubMutation_organization_taskViews_filters_dueDate | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateWorkspacesFromGithubMutation_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateWorkspacesFromGithubMutation_organization_taskViews_filters[];
  sortBys: CreateWorkspacesFromGithubMutation_organization_taskViews_sortBys[];
}

export interface CreateWorkspacesFromGithubMutation_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateWorkspacesFromGithubMutation_organization_fundingSessions_token_network;
}

export interface CreateWorkspacesFromGithubMutation_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CreateWorkspacesFromGithubMutation_organization_fundingSessions_token;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromGithubMutation_organization_node_workspace_options | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_workspace_options | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_organization | null;
  workspace: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_workspace | null;
  skill: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node_skill | null;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children_node;
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_organization | null;
  workspace: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_workspace | null;
  skill: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_skill | null;
  children: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node_children[];
}

export interface CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren_node;
}

export interface CreateWorkspacesFromGithubMutation_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: CreateWorkspacesFromGithubMutation_organization_node_organization | null;
  workspace: CreateWorkspacesFromGithubMutation_organization_node_workspace | null;
  skill: CreateWorkspacesFromGithubMutation_organization_node_skill | null;
  workspaceChildren: CreateWorkspacesFromGithubMutation_organization_node_workspaceChildren[];
}

export interface CreateWorkspacesFromGithubMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: CreateWorkspacesFromGithubMutation_organization_options | null;
  workspaceCount: number;
  workspaces: CreateWorkspacesFromGithubMutation_organization_workspaces[];
  workspaceSections: CreateWorkspacesFromGithubMutation_organization_workspaceSections[];
  tags: CreateWorkspacesFromGithubMutation_organization_tags[];
  details: CreateWorkspacesFromGithubMutation_organization_details[];
  workspaceTokenGates: CreateWorkspacesFromGithubMutation_organization_workspaceTokenGates[];
  taskViews: CreateWorkspacesFromGithubMutation_organization_taskViews[];
  fundingSessions: CreateWorkspacesFromGithubMutation_organization_fundingSessions[];
  node: CreateWorkspacesFromGithubMutation_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface CreateWorkspacesFromGithubMutation {
  organization: CreateWorkspacesFromGithubMutation_organization;
}

export interface CreateWorkspacesFromGithubMutationVariables {
  input: CreateWorkspacesFromGithubInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUserToDiscordGuildMutation
// ====================================================

export interface AddUserToDiscordGuildMutation {
  added: boolean;
}

export interface AddUserToDiscordGuildMutationVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganizationRolesDiscordMutation
// ====================================================

export interface UpdateOrganizationRolesDiscordMutation_organization_roles_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface UpdateOrganizationRolesDiscordMutation_organization_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: UpdateOrganizationRolesDiscordMutation_organization_roles_rules[];
}

export interface UpdateOrganizationRolesDiscordMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  nodeId: string;
  roles: UpdateOrganizationRolesDiscordMutation_organization_roles[];
}

export interface UpdateOrganizationRolesDiscordMutation {
  organization: UpdateOrganizationRolesDiscordMutation_organization;
}

export interface UpdateOrganizationRolesDiscordMutationVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddRoleMutation
// ====================================================

export interface AddRoleMutation_addRole {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface AddRoleMutation {
  addRole: AddRoleMutation_addRole;
}

export interface AddRoleMutationVariables {
  roleId: Scalar.UUID;
  userId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveRoleMutation
// ====================================================

export interface RemoveRoleMutation_removeRole {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RemoveRoleMutation {
  removeRole: RemoveRoleMutation_removeRole;
}

export interface RemoveRoleMutationVariables {
  roleId: Scalar.UUID;
  userId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRoleMutation
// ====================================================

export interface CreateRoleMutation_role_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface CreateRoleMutation_role_node_parents_node_organization_roles_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface CreateRoleMutation_role_node_parents_node_organization_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: CreateRoleMutation_role_node_parents_node_organization_roles_rules[];
}

export interface CreateRoleMutation_role_node_parents_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  nodeId: string;
  roles: CreateRoleMutation_role_node_parents_node_organization_roles[];
}

export interface CreateRoleMutation_role_node_parents_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  organization: CreateRoleMutation_role_node_parents_node_organization | null;
}

export interface CreateRoleMutation_role_node_parents {
  __typename: "GraphEdge";
  node: CreateRoleMutation_role_node_parents_node;
}

export interface CreateRoleMutation_role_node {
  __typename: "GraphNode";
  parents: CreateRoleMutation_role_node_parents[];
}

export interface CreateRoleMutation_role {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: CreateRoleMutation_role_rules[];
  node: CreateRoleMutation_role_node;
}

export interface CreateRoleMutation {
  role: CreateRoleMutation_role;
}

export interface CreateRoleMutationVariables {
  input: CreateRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRoleMutation
// ====================================================

export interface UpdateRoleMutation_role_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface UpdateRoleMutation_role_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpdateRoleMutation_role_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateRoleMutation_role_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UpdateRoleMutation_role_compensation_reward_token_network;
}

export interface UpdateRoleMutation_role_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateRoleMutation_role_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UpdateRoleMutation_role_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UpdateRoleMutation_role_compensation_reward_payments_payment_paymentMethod_network;
}

export interface UpdateRoleMutation_role_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UpdateRoleMutation_role_compensation_reward_payments_payment_paymentMethod;
}

export interface UpdateRoleMutation_role_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UpdateRoleMutation_role_compensation_reward_payments_user;
  payment: UpdateRoleMutation_role_compensation_reward_payments_payment;
}

export interface UpdateRoleMutation_role_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UpdateRoleMutation_role_compensation_reward_token;
  tokenId: string;
  payments: UpdateRoleMutation_role_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UpdateRoleMutation_role_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: UpdateRoleMutation_role_compensation_reward;
}

export interface UpdateRoleMutation_role {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: UpdateRoleMutation_role_rules[];
  skills: UpdateRoleMutation_role_skills[];
  compensation: UpdateRoleMutation_role_compensation[];
  commitment: number | null;
  userCount: number | null;
}

export interface UpdateRoleMutation {
  role: UpdateRoleMutation_role;
}

export interface UpdateRoleMutationVariables {
  input: UpdateRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTokenToOrganizationMutation
// ====================================================

export interface AddTokenToOrganizationMutation_organization_tokens_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface AddTokenToOrganizationMutation_organization_tokens {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: AddTokenToOrganizationMutation_organization_tokens_network;
}

export interface AddTokenToOrganizationMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  tokens: AddTokenToOrganizationMutation_organization_tokens[];
}

export interface AddTokenToOrganizationMutation {
  organization: AddTokenToOrganizationMutation_organization;
}

export interface AddTokenToOrganizationMutationVariables {
  organizationId: Scalar.UUID;
  tokenId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveTokenFromOrganizationMutation
// ====================================================

export interface RemoveTokenFromOrganizationMutation_organization_tokens_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RemoveTokenFromOrganizationMutation_organization_tokens {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: RemoveTokenFromOrganizationMutation_organization_tokens_network;
}

export interface RemoveTokenFromOrganizationMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  tokens: RemoveTokenFromOrganizationMutation_organization_tokens[];
}

export interface RemoveTokenFromOrganizationMutation {
  organization: RemoveTokenFromOrganizationMutation_organization;
}

export interface RemoveTokenFromOrganizationMutationVariables {
  organizationId: Scalar.UUID;
  tokenId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRuleMutation
// ====================================================

export interface CreateRuleMutation_rule_role_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface CreateRuleMutation_rule_role {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: CreateRuleMutation_rule_role_rules[];
}

export interface CreateRuleMutation_rule {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
  role: CreateRuleMutation_rule_role;
}

export interface CreateRuleMutation {
  rule: CreateRuleMutation_rule;
}

export interface CreateRuleMutationVariables {
  input: CreateRuleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRuleMutation
// ====================================================

export interface DeleteRuleMutation_role_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface DeleteRuleMutation_role {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: DeleteRuleMutation_role_rules[];
}

export interface DeleteRuleMutation {
  role: DeleteRuleMutation_role;
}

export interface DeleteRuleMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTaskGatingDefault
// ====================================================

export interface SetTaskGatingDefault_setTaskGatingDefault_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface SetTaskGatingDefault_setTaskGatingDefault_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface SetTaskGatingDefault_setTaskGatingDefault_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: SetTaskGatingDefault_setTaskGatingDefault_taskGatingDefaults_claimRoles[];
  applyRoles: SetTaskGatingDefault_setTaskGatingDefault_taskGatingDefaults_applyRoles[];
}

export interface SetTaskGatingDefault_setTaskGatingDefault {
  __typename: "User";
  id: Scalar.UUID;
  taskGatingDefaults: SetTaskGatingDefault_setTaskGatingDefault_taskGatingDefaults[];
}

export interface SetTaskGatingDefault {
  setTaskGatingDefault: SetTaskGatingDefault_setTaskGatingDefault;
}

export interface SetTaskGatingDefaultVariables {
  input: TaskGatingDefaultInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserBookmarksMutation
// ====================================================

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_workspace_options | null;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_token_network;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod_network;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_user;
  payment: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments_payment;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_token;
  tokenId: string;
  payments: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_templateTask | null;
  workspaceId: string;
  workspace: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_workspace;
  parentTaskId: string | null;
  parentTask: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_subtasks[];
  tags: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_tags[];
  skills: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_skills[];
  assignees: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_assignees[];
  owners: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_owners[];
  creator: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_creator | null;
  rewards: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_rewards[];
  review: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_review | null;
  reactions: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task_reactions[];
}

export interface CreateUserBookmarksMutation_createUserBookmarks_bookmarks {
  __typename: "UserTaskBookmark";
  task: CreateUserBookmarksMutation_createUserBookmarks_bookmarks_task;
}

export interface CreateUserBookmarksMutation_createUserBookmarks {
  __typename: "User";
  id: Scalar.UUID;
  bookmarks: CreateUserBookmarksMutation_createUserBookmarks_bookmarks[];
}

export interface CreateUserBookmarksMutation {
  createUserBookmarks: CreateUserBookmarksMutation_createUserBookmarks;
}

export interface CreateUserBookmarksMutationVariables {
  taskIds: Scalar.UUID[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserBookmarksMutation
// ====================================================

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_workspace_options | null;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_token_network;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod_network;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_payment_paymentMethod;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_user;
  payment: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments_payment;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_token;
  tokenId: string;
  payments: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_templateTask | null;
  workspaceId: string;
  workspace: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_workspace;
  parentTaskId: string | null;
  parentTask: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_subtasks[];
  tags: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_tags[];
  skills: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_skills[];
  assignees: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_assignees[];
  owners: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_owners[];
  creator: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_creator | null;
  rewards: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_rewards[];
  review: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_review | null;
  reactions: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task_reactions[];
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks {
  __typename: "UserTaskBookmark";
  task: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks_task;
}

export interface RemoveUserBookmarksMutation_removeUserBookmarks {
  __typename: "User";
  id: Scalar.UUID;
  bookmarks: RemoveUserBookmarksMutation_removeUserBookmarks_bookmarks[];
}

export interface RemoveUserBookmarksMutation {
  removeUserBookmarks: RemoveUserBookmarksMutation_removeUserBookmarks;
}

export interface RemoveUserBookmarksMutationVariables {
  taskIds: Scalar.UUID[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthFromSessionMutation
// ====================================================

export interface AuthFromSessionMutation_authFromSession_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface AuthFromSessionMutation_authFromSession_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface AuthFromSessionMutation_authFromSession_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface AuthFromSessionMutation_authFromSession_user_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthFromSessionMutation_authFromSession_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface AuthFromSessionMutation_authFromSession_user_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthFromSessionMutation_authFromSession_user_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthFromSessionMutation_authFromSession_user_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: AuthFromSessionMutation_authFromSession_user_taskGatingDefaults_claimRoles[];
  applyRoles: AuthFromSessionMutation_authFromSession_user_taskGatingDefaults_applyRoles[];
}

export interface AuthFromSessionMutation_authFromSession_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface AuthFromSessionMutation_authFromSession_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: AuthFromSessionMutation_authFromSession_user_taskViews_filters_dueDate | null;
}

export interface AuthFromSessionMutation_authFromSession_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface AuthFromSessionMutation_authFromSession_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: AuthFromSessionMutation_authFromSession_user_taskViews_filters[];
  sortBys: AuthFromSessionMutation_authFromSession_user_taskViews_sortBys[];
}

export interface AuthFromSessionMutation_authFromSession_user_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface AuthFromSessionMutation_authFromSession_user_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface AuthFromSessionMutation_authFromSession_user_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: AuthFromSessionMutation_authFromSession_user_node_children_node_workspace_options | null;
}

export interface AuthFromSessionMutation_authFromSession_user_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthFromSessionMutation_authFromSession_user_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: AuthFromSessionMutation_authFromSession_user_node_children_node_organization | null;
  workspace: AuthFromSessionMutation_authFromSession_user_node_children_node_workspace | null;
  skill: AuthFromSessionMutation_authFromSession_user_node_children_node_skill | null;
}

export interface AuthFromSessionMutation_authFromSession_user_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: AuthFromSessionMutation_authFromSession_user_node_children_node;
}

export interface AuthFromSessionMutation_authFromSession_user_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: AuthFromSessionMutation_authFromSession_user_node_children[];
}

export interface AuthFromSessionMutation_authFromSession_user_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthFromSessionMutation_authFromSession_user_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthFromSessionMutation_authFromSession_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: AuthFromSessionMutation_authFromSession_user_details[];
  threepids: AuthFromSessionMutation_authFromSession_user_threepids[];
  featuredWork: AuthFromSessionMutation_authFromSession_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: AuthFromSessionMutation_authFromSession_user_skills[];
  prompts: AuthFromSessionMutation_authFromSession_user_prompts[];
  taskGatingDefaults: AuthFromSessionMutation_authFromSession_user_taskGatingDefaults[];
  taskViews: AuthFromSessionMutation_authFromSession_user_taskViews[];
  openTasksNotifications: boolean;
  node: AuthFromSessionMutation_authFromSession_user_node;
  reputationStakes: AuthFromSessionMutation_authFromSession_user_reputationStakes[];
  reputationSupporters: AuthFromSessionMutation_authFromSession_user_reputationSupporters[];
  reputationCredits: number;
}

export interface AuthFromSessionMutation_authFromSession {
  __typename: "AuthResponse";
  authToken: string;
  redirect: string | null;
  user: AuthFromSessionMutation_authFromSession_user;
}

export interface AuthFromSessionMutation {
  authFromSession: AuthFromSessionMutation_authFromSession;
}

export interface AuthFromSessionMutationVariables {
  sessionId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthWithEthereumMutation
// ====================================================

export interface AuthWithEthereumMutation_auth_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface AuthWithEthereumMutation_auth_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface AuthWithEthereumMutation_auth_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface AuthWithEthereumMutation_auth_user_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthWithEthereumMutation_auth_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface AuthWithEthereumMutation_auth_user_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthWithEthereumMutation_auth_user_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthWithEthereumMutation_auth_user_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: AuthWithEthereumMutation_auth_user_taskGatingDefaults_claimRoles[];
  applyRoles: AuthWithEthereumMutation_auth_user_taskGatingDefaults_applyRoles[];
}

export interface AuthWithEthereumMutation_auth_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface AuthWithEthereumMutation_auth_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: AuthWithEthereumMutation_auth_user_taskViews_filters_dueDate | null;
}

export interface AuthWithEthereumMutation_auth_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface AuthWithEthereumMutation_auth_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: AuthWithEthereumMutation_auth_user_taskViews_filters[];
  sortBys: AuthWithEthereumMutation_auth_user_taskViews_sortBys[];
}

export interface AuthWithEthereumMutation_auth_user_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface AuthWithEthereumMutation_auth_user_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface AuthWithEthereumMutation_auth_user_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: AuthWithEthereumMutation_auth_user_node_children_node_workspace_options | null;
}

export interface AuthWithEthereumMutation_auth_user_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthWithEthereumMutation_auth_user_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: AuthWithEthereumMutation_auth_user_node_children_node_organization | null;
  workspace: AuthWithEthereumMutation_auth_user_node_children_node_workspace | null;
  skill: AuthWithEthereumMutation_auth_user_node_children_node_skill | null;
}

export interface AuthWithEthereumMutation_auth_user_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: AuthWithEthereumMutation_auth_user_node_children_node;
}

export interface AuthWithEthereumMutation_auth_user_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: AuthWithEthereumMutation_auth_user_node_children[];
}

export interface AuthWithEthereumMutation_auth_user_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthWithEthereumMutation_auth_user_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthWithEthereumMutation_auth_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: AuthWithEthereumMutation_auth_user_details[];
  threepids: AuthWithEthereumMutation_auth_user_threepids[];
  featuredWork: AuthWithEthereumMutation_auth_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: AuthWithEthereumMutation_auth_user_skills[];
  prompts: AuthWithEthereumMutation_auth_user_prompts[];
  taskGatingDefaults: AuthWithEthereumMutation_auth_user_taskGatingDefaults[];
  taskViews: AuthWithEthereumMutation_auth_user_taskViews[];
  openTasksNotifications: boolean;
  node: AuthWithEthereumMutation_auth_user_node;
  reputationStakes: AuthWithEthereumMutation_auth_user_reputationStakes[];
  reputationSupporters: AuthWithEthereumMutation_auth_user_reputationSupporters[];
  reputationCredits: number;
}

export interface AuthWithEthereumMutation_auth {
  __typename: "AuthResponse";
  authToken: string;
  user: AuthWithEthereumMutation_auth_user;
}

export interface AuthWithEthereumMutation {
  auth: AuthWithEthereumMutation_auth;
}

export interface AuthWithEthereumMutationVariables {
  input: EthereumAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthWithSolanaMutation
// ====================================================

export interface AuthWithSolanaMutation_auth_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface AuthWithSolanaMutation_auth_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface AuthWithSolanaMutation_auth_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface AuthWithSolanaMutation_auth_user_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthWithSolanaMutation_auth_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface AuthWithSolanaMutation_auth_user_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthWithSolanaMutation_auth_user_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthWithSolanaMutation_auth_user_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: AuthWithSolanaMutation_auth_user_taskGatingDefaults_claimRoles[];
  applyRoles: AuthWithSolanaMutation_auth_user_taskGatingDefaults_applyRoles[];
}

export interface AuthWithSolanaMutation_auth_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface AuthWithSolanaMutation_auth_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: AuthWithSolanaMutation_auth_user_taskViews_filters_dueDate | null;
}

export interface AuthWithSolanaMutation_auth_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface AuthWithSolanaMutation_auth_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: AuthWithSolanaMutation_auth_user_taskViews_filters[];
  sortBys: AuthWithSolanaMutation_auth_user_taskViews_sortBys[];
}

export interface AuthWithSolanaMutation_auth_user_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface AuthWithSolanaMutation_auth_user_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface AuthWithSolanaMutation_auth_user_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: AuthWithSolanaMutation_auth_user_node_children_node_workspace_options | null;
}

export interface AuthWithSolanaMutation_auth_user_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthWithSolanaMutation_auth_user_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: AuthWithSolanaMutation_auth_user_node_children_node_organization | null;
  workspace: AuthWithSolanaMutation_auth_user_node_children_node_workspace | null;
  skill: AuthWithSolanaMutation_auth_user_node_children_node_skill | null;
}

export interface AuthWithSolanaMutation_auth_user_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: AuthWithSolanaMutation_auth_user_node_children_node;
}

export interface AuthWithSolanaMutation_auth_user_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: AuthWithSolanaMutation_auth_user_node_children[];
}

export interface AuthWithSolanaMutation_auth_user_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthWithSolanaMutation_auth_user_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthWithSolanaMutation_auth_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: AuthWithSolanaMutation_auth_user_details[];
  threepids: AuthWithSolanaMutation_auth_user_threepids[];
  featuredWork: AuthWithSolanaMutation_auth_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: AuthWithSolanaMutation_auth_user_skills[];
  prompts: AuthWithSolanaMutation_auth_user_prompts[];
  taskGatingDefaults: AuthWithSolanaMutation_auth_user_taskGatingDefaults[];
  taskViews: AuthWithSolanaMutation_auth_user_taskViews[];
  openTasksNotifications: boolean;
  node: AuthWithSolanaMutation_auth_user_node;
  reputationStakes: AuthWithSolanaMutation_auth_user_reputationStakes[];
  reputationSupporters: AuthWithSolanaMutation_auth_user_reputationSupporters[];
  reputationCredits: number;
}

export interface AuthWithSolanaMutation_auth {
  __typename: "AuthResponse";
  authToken: string;
  user: AuthWithSolanaMutation_auth_user;
}

export interface AuthWithSolanaMutation {
  auth: AuthWithSolanaMutation_auth;
}

export interface AuthWithSolanaMutationVariables {
  input: SolanaAuthInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AuthWithUsernameMutation
// ====================================================

export interface AuthWithUsernameMutation_auth_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface AuthWithUsernameMutation_auth_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface AuthWithUsernameMutation_auth_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface AuthWithUsernameMutation_auth_user_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthWithUsernameMutation_auth_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface AuthWithUsernameMutation_auth_user_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthWithUsernameMutation_auth_user_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface AuthWithUsernameMutation_auth_user_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: AuthWithUsernameMutation_auth_user_taskGatingDefaults_claimRoles[];
  applyRoles: AuthWithUsernameMutation_auth_user_taskGatingDefaults_applyRoles[];
}

export interface AuthWithUsernameMutation_auth_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface AuthWithUsernameMutation_auth_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: AuthWithUsernameMutation_auth_user_taskViews_filters_dueDate | null;
}

export interface AuthWithUsernameMutation_auth_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface AuthWithUsernameMutation_auth_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: AuthWithUsernameMutation_auth_user_taskViews_filters[];
  sortBys: AuthWithUsernameMutation_auth_user_taskViews_sortBys[];
}

export interface AuthWithUsernameMutation_auth_user_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface AuthWithUsernameMutation_auth_user_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface AuthWithUsernameMutation_auth_user_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: AuthWithUsernameMutation_auth_user_node_children_node_workspace_options | null;
}

export interface AuthWithUsernameMutation_auth_user_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface AuthWithUsernameMutation_auth_user_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: AuthWithUsernameMutation_auth_user_node_children_node_organization | null;
  workspace: AuthWithUsernameMutation_auth_user_node_children_node_workspace | null;
  skill: AuthWithUsernameMutation_auth_user_node_children_node_skill | null;
}

export interface AuthWithUsernameMutation_auth_user_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: AuthWithUsernameMutation_auth_user_node_children_node;
}

export interface AuthWithUsernameMutation_auth_user_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: AuthWithUsernameMutation_auth_user_node_children[];
}

export interface AuthWithUsernameMutation_auth_user_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthWithUsernameMutation_auth_user_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface AuthWithUsernameMutation_auth_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: AuthWithUsernameMutation_auth_user_details[];
  threepids: AuthWithUsernameMutation_auth_user_threepids[];
  featuredWork: AuthWithUsernameMutation_auth_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: AuthWithUsernameMutation_auth_user_skills[];
  prompts: AuthWithUsernameMutation_auth_user_prompts[];
  taskGatingDefaults: AuthWithUsernameMutation_auth_user_taskGatingDefaults[];
  taskViews: AuthWithUsernameMutation_auth_user_taskViews[];
  openTasksNotifications: boolean;
  node: AuthWithUsernameMutation_auth_user_node;
  reputationStakes: AuthWithUsernameMutation_auth_user_reputationStakes[];
  reputationSupporters: AuthWithUsernameMutation_auth_user_reputationSupporters[];
  reputationCredits: number;
}

export interface AuthWithUsernameMutation_auth {
  __typename: "AuthResponse";
  authToken: string;
  user: AuthWithUsernameMutation_auth_user;
}

export interface AuthWithUsernameMutation {
  auth: AuthWithUsernameMutation_auth;
}

export interface AuthWithUsernameMutationVariables {
  username: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFundingSessionMutation
// ====================================================

export interface CreateFundingSessionMutation_session_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateFundingSessionMutation_session_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateFundingSessionMutation_session_token_network;
}

export interface CreateFundingSessionMutation_session_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CreateFundingSessionMutation_session_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CreateFundingSessionMutation_session_organization_fundingSessions_token_network;
}

export interface CreateFundingSessionMutation_session_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CreateFundingSessionMutation_session_organization_fundingSessions_token;
}

export interface CreateFundingSessionMutation_session_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  fundingSessions: CreateFundingSessionMutation_session_organization_fundingSessions[];
}

export interface CreateFundingSessionMutation_session {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CreateFundingSessionMutation_session_token;
  organization: CreateFundingSessionMutation_session_organization;
}

export interface CreateFundingSessionMutation {
  session: CreateFundingSessionMutation_session;
}

export interface CreateFundingSessionMutationVariables {
  input: CreateFundingSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetFundingVoteMutation
// ====================================================

export interface SetFundingVoteMutation_vote_session_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface SetFundingVoteMutation_vote_session_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: SetFundingVoteMutation_vote_session_token_network;
}

export interface SetFundingVoteMutation_vote_session_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface SetFundingVoteMutation_vote_session_voters_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface SetFundingVoteMutation_vote_session_voters_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface SetFundingVoteMutation_vote_session_voters {
  __typename: "FundingSessionVoter";
  weight: number;
  points: number;
  votes: SetFundingVoteMutation_vote_session_voters_votes[];
  user: SetFundingVoteMutation_vote_session_voters_user;
}

export interface SetFundingVoteMutation_vote_session_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface SetFundingVoteMutation_vote_session_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: SetFundingVoteMutation_vote_session_workspaces_options | null;
}

export interface SetFundingVoteMutation_vote_session_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface SetFundingVoteMutation_vote_session_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: SetFundingVoteMutation_vote_session_rewards_token_network;
}

export interface SetFundingVoteMutation_vote_session_rewards_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
}

export interface SetFundingVoteMutation_vote_session_rewards_task {
  __typename: "Task";
  id: Scalar.UUID;
  parentTask: SetFundingVoteMutation_vote_session_rewards_task_parentTask | null;
}

export interface SetFundingVoteMutation_vote_session_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  token: SetFundingVoteMutation_vote_session_rewards_token;
  task: SetFundingVoteMutation_vote_session_rewards_task;
}

export interface SetFundingVoteMutation_vote_session {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: SetFundingVoteMutation_vote_session_token;
  votes: SetFundingVoteMutation_vote_session_votes[];
  voters: SetFundingVoteMutation_vote_session_voters[];
  workspaces: SetFundingVoteMutation_vote_session_workspaces[];
  rewards: SetFundingVoteMutation_vote_session_rewards[];
}

export interface SetFundingVoteMutation_vote {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
  session: SetFundingVoteMutation_vote_session;
}

export interface SetFundingVoteMutation {
  vote: SetFundingVoteMutation_vote;
}

export interface SetFundingVoteMutationVariables {
  input: FundingVoteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFundingSessionMutation
// ====================================================

export interface DeleteFundingSessionMutation_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface DeleteFundingSessionMutation_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: DeleteFundingSessionMutation_organization_fundingSessions_token_network;
}

export interface DeleteFundingSessionMutation_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: DeleteFundingSessionMutation_organization_fundingSessions_token;
}

export interface DeleteFundingSessionMutation_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  permalink: string;
  fundingSessions: DeleteFundingSessionMutation_organization_fundingSessions[];
}

export interface DeleteFundingSessionMutation {
  organization: DeleteFundingSessionMutation_organization;
}

export interface DeleteFundingSessionMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloseFundingSessionMutation
// ====================================================

export interface CloseFundingSessionMutation_session_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CloseFundingSessionMutation_session_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CloseFundingSessionMutation_session_token_network;
}

export interface CloseFundingSessionMutation_session_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface CloseFundingSessionMutation_session_voters_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface CloseFundingSessionMutation_session_voters_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CloseFundingSessionMutation_session_voters {
  __typename: "FundingSessionVoter";
  weight: number;
  points: number;
  votes: CloseFundingSessionMutation_session_voters_votes[];
  user: CloseFundingSessionMutation_session_voters_user;
}

export interface CloseFundingSessionMutation_session_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface CloseFundingSessionMutation_session_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: CloseFundingSessionMutation_session_workspaces_options | null;
}

export interface CloseFundingSessionMutation_session_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface CloseFundingSessionMutation_session_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: CloseFundingSessionMutation_session_rewards_token_network;
}

export interface CloseFundingSessionMutation_session_rewards_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
}

export interface CloseFundingSessionMutation_session_rewards_task {
  __typename: "Task";
  id: Scalar.UUID;
  parentTask: CloseFundingSessionMutation_session_rewards_task_parentTask | null;
}

export interface CloseFundingSessionMutation_session_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  token: CloseFundingSessionMutation_session_rewards_token;
  task: CloseFundingSessionMutation_session_rewards_task;
}

export interface CloseFundingSessionMutation_session {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: CloseFundingSessionMutation_session_token;
  votes: CloseFundingSessionMutation_session_votes[];
  voters: CloseFundingSessionMutation_session_voters[];
  workspaces: CloseFundingSessionMutation_session_workspaces[];
  rewards: CloseFundingSessionMutation_session_rewards[];
}

export interface CloseFundingSessionMutation {
  session: CloseFundingSessionMutation_session;
}

export interface CloseFundingSessionMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGraphNodeMutation
// ====================================================

export interface CreateGraphNodeMutation_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

export interface CreateGraphNodeMutation {
  node: CreateGraphNodeMutation_node;
}

export interface CreateGraphNodeMutationVariables {
  input: CreateGraphNodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateGraphNodeMutation
// ====================================================

export interface UpdateGraphNodeMutation_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

export interface UpdateGraphNodeMutation {
  node: UpdateGraphNodeMutation_node;
}

export interface UpdateGraphNodeMutationVariables {
  input: UpdateGraphNodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertGraphEdgeMutation
// ====================================================

export interface UpsertGraphEdgeMutation_edge_parent_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpsertGraphEdgeMutation_edge_parent_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UpsertGraphEdgeMutation_edge_parent_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UpsertGraphEdgeMutation_edge_parent_children_node_workspace_options | null;
}

export interface UpsertGraphEdgeMutation_edge_parent_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UpsertGraphEdgeMutation_edge_parent_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: UpsertGraphEdgeMutation_edge_parent_children_node_organization | null;
  workspace: UpsertGraphEdgeMutation_edge_parent_children_node_workspace | null;
  skill: UpsertGraphEdgeMutation_edge_parent_children_node_skill | null;
}

export interface UpsertGraphEdgeMutation_edge_parent_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: UpsertGraphEdgeMutation_edge_parent_children_node;
}

export interface UpsertGraphEdgeMutation_edge_parent {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: UpsertGraphEdgeMutation_edge_parent_children[];
}

export interface UpsertGraphEdgeMutation_edge {
  __typename: "GraphEdge";
  sortKey: string;
  parent: UpsertGraphEdgeMutation_edge_parent;
}

export interface UpsertGraphEdgeMutation {
  edge: UpsertGraphEdgeMutation_edge;
}

export interface UpsertGraphEdgeMutationVariables {
  input: UpsertGraphEdgeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGraphEdgeMutation
// ====================================================

export interface DeleteGraphEdgeMutation_edge_parent_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteGraphEdgeMutation_edge_parent_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface DeleteGraphEdgeMutation_edge_parent_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: DeleteGraphEdgeMutation_edge_parent_children_node_workspace_options | null;
}

export interface DeleteGraphEdgeMutation_edge_parent_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface DeleteGraphEdgeMutation_edge_parent_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: DeleteGraphEdgeMutation_edge_parent_children_node_organization | null;
  workspace: DeleteGraphEdgeMutation_edge_parent_children_node_workspace | null;
  skill: DeleteGraphEdgeMutation_edge_parent_children_node_skill | null;
}

export interface DeleteGraphEdgeMutation_edge_parent_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: DeleteGraphEdgeMutation_edge_parent_children_node;
}

export interface DeleteGraphEdgeMutation_edge_parent {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: DeleteGraphEdgeMutation_edge_parent_children[];
}

export interface DeleteGraphEdgeMutation_edge {
  __typename: "GraphEdge";
  parent: DeleteGraphEdgeMutation_edge_parent;
}

export interface DeleteGraphEdgeMutation {
  edge: DeleteGraphEdgeMutation_edge;
}

export interface DeleteGraphEdgeMutationVariables {
  input: DeleteGraphEdgeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrganizationIntegrationMutation
// ====================================================

export interface DeleteOrganizationIntegrationMutation_deleteOrganizationIntegration_integrations {
  __typename: "OrganizationIntegration";
  id: Scalar.UUID;
  type: OrganizationIntegrationType;
  config: Scalar.JSONObject | null;
}

export interface DeleteOrganizationIntegrationMutation_deleteOrganizationIntegration {
  __typename: "Organization";
  id: Scalar.UUID;
  integrations: DeleteOrganizationIntegrationMutation_deleteOrganizationIntegration_integrations[];
}

export interface DeleteOrganizationIntegrationMutation {
  deleteOrganizationIntegration: DeleteOrganizationIntegrationMutation_deleteOrganizationIntegration;
}

export interface DeleteOrganizationIntegrationMutationVariables {
  input: DeleteOrganizationIntegrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveNotificationMutation
// ====================================================

export interface ArchiveNotificationMutation_archiveNotification_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface ArchiveNotificationMutation_archiveNotification_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface ArchiveNotificationMutation_archiveNotification_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: ArchiveNotificationMutation_archiveNotification_task_workspace_options | null;
  organization: ArchiveNotificationMutation_archiveNotification_task_workspace_organization;
}

export interface ArchiveNotificationMutation_archiveNotification_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
  workspace: ArchiveNotificationMutation_archiveNotification_task_workspace;
}

export interface ArchiveNotificationMutation_archiveNotification_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface ArchiveNotificationMutation_archiveNotification_user {
  __typename: "User";
  id: Scalar.UUID;
  notificationUnreadCount: number;
}

export interface ArchiveNotificationMutation_archiveNotification {
  __typename: "Notification";
  id: Scalar.UUID;
  message: string;
  archivedAt: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  icon: string | null;
  iconColor: string | null;
  task: ArchiveNotificationMutation_archiveNotification_task | null;
  organization: ArchiveNotificationMutation_archiveNotification_organization | null;
  user: ArchiveNotificationMutation_archiveNotification_user;
}

export interface ArchiveNotificationMutation {
  archiveNotification: ArchiveNotificationMutation_archiveNotification;
}

export interface ArchiveNotificationMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkNotificationsReadMutation
// ====================================================

export interface MarkNotificationsReadMutation_markNotificationsRead {
  __typename: "User";
  id: Scalar.UUID;
  notificationUnreadCount: number;
}

export interface MarkNotificationsReadMutation {
  markNotificationsRead: MarkNotificationsReadMutation_markNotificationsRead;
}

export interface MarkNotificationsReadMutationVariables {
  readAt: Scalar.DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserPromptMutation
// ====================================================

export interface UpdateUserPromptMutation_prompt_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface UpdateUserPromptMutation_prompt_user {
  __typename: "User";
  id: Scalar.UUID;
  prompts: UpdateUserPromptMutation_prompt_user_prompts[];
}

export interface UpdateUserPromptMutation_prompt {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
  user: UpdateUserPromptMutation_prompt_user;
}

export interface UpdateUserPromptMutation {
  prompt: UpdateUserPromptMutation_prompt;
}

export interface UpdateUserPromptMutationVariables {
  input: UpdateUserPromptInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserPromptMutation
// ====================================================

export interface CreateUserPromptMutation_prompt_user_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface CreateUserPromptMutation_prompt_user {
  __typename: "User";
  id: Scalar.UUID;
  prompts: CreateUserPromptMutation_prompt_user_prompts[];
}

export interface CreateUserPromptMutation_prompt {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
  user: CreateUserPromptMutation_prompt_user;
}

export interface CreateUserPromptMutation {
  prompt: CreateUserPromptMutation_prompt;
}

export interface CreateUserPromptMutationVariables {
  flow: CreateUserPromptFlow;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserRankingVotesMutation
// ====================================================

export interface CreateUserRankingVotesMutation {
  createUserRankingVotes: boolean;
}

export interface CreateUserRankingVotesMutationVariables {
  inputs: CreateUserRankingVoteInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserSkillsMutation
// ====================================================

export interface SetUserSkillsMutation_setUserSkills_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface SetUserSkillsMutation_setUserSkills {
  __typename: "User";
  id: Scalar.UUID;
  skills: SetUserSkillsMutation_setUserSkills_skills[];
}

export interface SetUserSkillsMutation {
  setUserSkills: SetUserSkillsMutation_setUserSkills;
}

export interface SetUserSkillsMutationVariables {
  skillIds: Scalar.UUID[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetUserStakingMutation
// ====================================================

export interface SetUserStakingMutation_user {
  __typename: "User";
  id: Scalar.UUID;
  reputationScore: number;
}

export interface SetUserStakingMutation {
  user: SetUserStakingMutation_user;
}

export interface SetUserStakingMutationVariables {
  input: SetUserStakingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskViewMutation
// ====================================================

export interface CreateTaskViewMutation_taskView_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateTaskViewMutation_taskView_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateTaskViewMutation_taskView_filters_dueDate | null;
}

export interface CreateTaskViewMutation_taskView_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateTaskViewMutation_taskView_workspace_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateTaskViewMutation_taskView_workspace_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateTaskViewMutation_taskView_workspace_taskViews_filters_dueDate | null;
}

export interface CreateTaskViewMutation_taskView_workspace_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateTaskViewMutation_taskView_workspace_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateTaskViewMutation_taskView_workspace_taskViews_filters[];
  sortBys: CreateTaskViewMutation_taskView_workspace_taskViews_sortBys[];
}

export interface CreateTaskViewMutation_taskView_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskViews: CreateTaskViewMutation_taskView_workspace_taskViews[];
}

export interface CreateTaskViewMutation_taskView_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateTaskViewMutation_taskView_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateTaskViewMutation_taskView_user_taskViews_filters_dueDate | null;
}

export interface CreateTaskViewMutation_taskView_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateTaskViewMutation_taskView_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateTaskViewMutation_taskView_user_taskViews_filters[];
  sortBys: CreateTaskViewMutation_taskView_user_taskViews_sortBys[];
}

export interface CreateTaskViewMutation_taskView_user {
  __typename: "User";
  id: Scalar.UUID;
  taskViews: CreateTaskViewMutation_taskView_user_taskViews[];
}

export interface CreateTaskViewMutation_taskView_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateTaskViewMutation_taskView_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateTaskViewMutation_taskView_organization_taskViews_filters_dueDate | null;
}

export interface CreateTaskViewMutation_taskView_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateTaskViewMutation_taskView_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateTaskViewMutation_taskView_organization_taskViews_filters[];
  sortBys: CreateTaskViewMutation_taskView_organization_taskViews_sortBys[];
}

export interface CreateTaskViewMutation_taskView_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  taskViews: CreateTaskViewMutation_taskView_organization_taskViews[];
}

export interface CreateTaskViewMutation_taskView_workspaceSection_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface CreateTaskViewMutation_taskView_workspaceSection_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: CreateTaskViewMutation_taskView_workspaceSection_taskViews_filters_dueDate | null;
}

export interface CreateTaskViewMutation_taskView_workspaceSection_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface CreateTaskViewMutation_taskView_workspaceSection_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateTaskViewMutation_taskView_workspaceSection_taskViews_filters[];
  sortBys: CreateTaskViewMutation_taskView_workspaceSection_taskViews_sortBys[];
}

export interface CreateTaskViewMutation_taskView_workspaceSection {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  taskViews: CreateTaskViewMutation_taskView_workspaceSection_taskViews[];
}

export interface CreateTaskViewMutation_taskView {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: CreateTaskViewMutation_taskView_filters[];
  sortBys: CreateTaskViewMutation_taskView_sortBys[];
  workspace: CreateTaskViewMutation_taskView_workspace | null;
  user: CreateTaskViewMutation_taskView_user | null;
  organization: CreateTaskViewMutation_taskView_organization | null;
  workspaceSection: CreateTaskViewMutation_taskView_workspaceSection | null;
}

export interface CreateTaskViewMutation {
  taskView: CreateTaskViewMutation_taskView;
}

export interface CreateTaskViewMutationVariables {
  input: CreateTaskViewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskViewMutation
// ====================================================

export interface UpdateTaskViewMutation_taskView_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateTaskViewMutation_taskView_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateTaskViewMutation_taskView_filters_dueDate | null;
}

export interface UpdateTaskViewMutation_taskView_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateTaskViewMutation_taskView_workspace_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateTaskViewMutation_taskView_workspace_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateTaskViewMutation_taskView_workspace_taskViews_filters_dueDate | null;
}

export interface UpdateTaskViewMutation_taskView_workspace_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateTaskViewMutation_taskView_workspace_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateTaskViewMutation_taskView_workspace_taskViews_filters[];
  sortBys: UpdateTaskViewMutation_taskView_workspace_taskViews_sortBys[];
}

export interface UpdateTaskViewMutation_taskView_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskViews: UpdateTaskViewMutation_taskView_workspace_taskViews[];
}

export interface UpdateTaskViewMutation_taskView_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateTaskViewMutation_taskView_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateTaskViewMutation_taskView_user_taskViews_filters_dueDate | null;
}

export interface UpdateTaskViewMutation_taskView_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateTaskViewMutation_taskView_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateTaskViewMutation_taskView_user_taskViews_filters[];
  sortBys: UpdateTaskViewMutation_taskView_user_taskViews_sortBys[];
}

export interface UpdateTaskViewMutation_taskView_user {
  __typename: "User";
  id: Scalar.UUID;
  taskViews: UpdateTaskViewMutation_taskView_user_taskViews[];
}

export interface UpdateTaskViewMutation_taskView_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateTaskViewMutation_taskView_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateTaskViewMutation_taskView_organization_taskViews_filters_dueDate | null;
}

export interface UpdateTaskViewMutation_taskView_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateTaskViewMutation_taskView_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateTaskViewMutation_taskView_organization_taskViews_filters[];
  sortBys: UpdateTaskViewMutation_taskView_organization_taskViews_sortBys[];
}

export interface UpdateTaskViewMutation_taskView_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  taskViews: UpdateTaskViewMutation_taskView_organization_taskViews[];
}

export interface UpdateTaskViewMutation_taskView_workspaceSection_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UpdateTaskViewMutation_taskView_workspaceSection_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UpdateTaskViewMutation_taskView_workspaceSection_taskViews_filters_dueDate | null;
}

export interface UpdateTaskViewMutation_taskView_workspaceSection_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UpdateTaskViewMutation_taskView_workspaceSection_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateTaskViewMutation_taskView_workspaceSection_taskViews_filters[];
  sortBys: UpdateTaskViewMutation_taskView_workspaceSection_taskViews_sortBys[];
}

export interface UpdateTaskViewMutation_taskView_workspaceSection {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  taskViews: UpdateTaskViewMutation_taskView_workspaceSection_taskViews[];
}

export interface UpdateTaskViewMutation_taskView {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UpdateTaskViewMutation_taskView_filters[];
  sortBys: UpdateTaskViewMutation_taskView_sortBys[];
  workspace: UpdateTaskViewMutation_taskView_workspace | null;
  user: UpdateTaskViewMutation_taskView_user | null;
  organization: UpdateTaskViewMutation_taskView_organization | null;
  workspaceSection: UpdateTaskViewMutation_taskView_workspaceSection | null;
}

export interface UpdateTaskViewMutation {
  taskView: UpdateTaskViewMutation_taskView;
}

export interface UpdateTaskViewMutationVariables {
  input: UpdateTaskViewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskThreadMutation
// ====================================================

export interface UpdateTaskThreadMutation_task_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateTaskThreadMutation_task_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateTaskThreadMutation_task_thread_messages_sender;
}

export interface UpdateTaskThreadMutation_task_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateTaskThreadMutation_task_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateTaskThreadMutation_task_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateTaskThreadMutation_task_thread_messages[];
  task: UpdateTaskThreadMutation_task_thread_task | null;
  workspace: UpdateTaskThreadMutation_task_thread_workspace | null;
}

export interface UpdateTaskThreadMutation_task {
  __typename: "Task";
  id: Scalar.UUID;
  thread: UpdateTaskThreadMutation_task_thread | null;
}

export interface UpdateTaskThreadMutation {
  task: UpdateTaskThreadMutation_task;
}

export interface UpdateTaskThreadMutationVariables {
  input: UpdateTaskInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateThreadMutation
// ====================================================

export interface CreateThreadMutation_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateThreadMutation_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateThreadMutation_thread_messages_sender;
}

export interface CreateThreadMutation_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateThreadMutation_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateThreadMutation_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateThreadMutation_thread_messages[];
  task: CreateThreadMutation_thread_task | null;
  workspace: CreateThreadMutation_thread_workspace | null;
}

export interface CreateThreadMutation {
  thread: CreateThreadMutation_thread;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateThreadMessageMutation
// ====================================================

export interface CreateThreadMessageMutation_message_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateThreadMessageMutation_message_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface CreateThreadMessageMutation_message_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateThreadMessageMutation_message_thread_messages_sender;
}

export interface CreateThreadMessageMutation_message_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface CreateThreadMessageMutation_message_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface CreateThreadMessageMutation_message_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: CreateThreadMessageMutation_message_thread_messages[];
  task: CreateThreadMessageMutation_message_thread_task | null;
  workspace: CreateThreadMessageMutation_message_thread_workspace | null;
}

export interface CreateThreadMessageMutation_message {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: CreateThreadMessageMutation_message_sender;
  thread: CreateThreadMessageMutation_message_thread;
}

export interface CreateThreadMessageMutation {
  message: CreateThreadMessageMutation_message;
}

export interface CreateThreadMessageMutationVariables {
  input: CreateThreadMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateThreadMessageMutation
// ====================================================

export interface UpdateThreadMessageMutation_message_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateThreadMessageMutation_message_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UpdateThreadMessageMutation_message_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateThreadMessageMutation_message_thread_messages_sender;
}

export interface UpdateThreadMessageMutation_message_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface UpdateThreadMessageMutation_message_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface UpdateThreadMessageMutation_message_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: UpdateThreadMessageMutation_message_thread_messages[];
  task: UpdateThreadMessageMutation_message_thread_task | null;
  workspace: UpdateThreadMessageMutation_message_thread_workspace | null;
}

export interface UpdateThreadMessageMutation_message {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: UpdateThreadMessageMutation_message_sender;
  thread: UpdateThreadMessageMutation_message_thread;
}

export interface UpdateThreadMessageMutation {
  message: UpdateThreadMessageMutation_message;
}

export interface UpdateThreadMessageMutationVariables {
  input: UpdateThreadMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteThreadMessageMutation
// ====================================================

export interface DeleteThreadMessageMutation_message_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteThreadMessageMutation_message_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteThreadMessageMutation_message_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: DeleteThreadMessageMutation_message_thread_messages_sender;
}

export interface DeleteThreadMessageMutation_message_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface DeleteThreadMessageMutation_message_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface DeleteThreadMessageMutation_message_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: DeleteThreadMessageMutation_message_thread_messages[];
  task: DeleteThreadMessageMutation_message_thread_task | null;
  workspace: DeleteThreadMessageMutation_message_thread_workspace | null;
}

export interface DeleteThreadMessageMutation_message {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: DeleteThreadMessageMutation_message_sender;
  thread: DeleteThreadMessageMutation_message_thread;
}

export interface DeleteThreadMessageMutation {
  message: DeleteThreadMessageMutation_message;
}

export interface DeleteThreadMessageMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteThreepidMutation
// ====================================================

export interface DeleteThreepidMutation_deleteThreepid_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface DeleteThreepidMutation_deleteThreepid_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface DeleteThreepidMutation_deleteThreepid_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface DeleteThreepidMutation_deleteThreepid_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface DeleteThreepidMutation_deleteThreepid_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface DeleteThreepidMutation_deleteThreepid_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface DeleteThreepidMutation_deleteThreepid_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface DeleteThreepidMutation_deleteThreepid_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: DeleteThreepidMutation_deleteThreepid_taskGatingDefaults_claimRoles[];
  applyRoles: DeleteThreepidMutation_deleteThreepid_taskGatingDefaults_applyRoles[];
}

export interface DeleteThreepidMutation_deleteThreepid_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface DeleteThreepidMutation_deleteThreepid_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: DeleteThreepidMutation_deleteThreepid_taskViews_filters_dueDate | null;
}

export interface DeleteThreepidMutation_deleteThreepid_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface DeleteThreepidMutation_deleteThreepid_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: DeleteThreepidMutation_deleteThreepid_taskViews_filters[];
  sortBys: DeleteThreepidMutation_deleteThreepid_taskViews_sortBys[];
}

export interface DeleteThreepidMutation_deleteThreepid_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface DeleteThreepidMutation_deleteThreepid_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface DeleteThreepidMutation_deleteThreepid_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: DeleteThreepidMutation_deleteThreepid_node_children_node_workspace_options | null;
}

export interface DeleteThreepidMutation_deleteThreepid_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface DeleteThreepidMutation_deleteThreepid_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: DeleteThreepidMutation_deleteThreepid_node_children_node_organization | null;
  workspace: DeleteThreepidMutation_deleteThreepid_node_children_node_workspace | null;
  skill: DeleteThreepidMutation_deleteThreepid_node_children_node_skill | null;
}

export interface DeleteThreepidMutation_deleteThreepid_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: DeleteThreepidMutation_deleteThreepid_node_children_node;
}

export interface DeleteThreepidMutation_deleteThreepid_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: DeleteThreepidMutation_deleteThreepid_node_children[];
}

export interface DeleteThreepidMutation_deleteThreepid_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface DeleteThreepidMutation_deleteThreepid_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface DeleteThreepidMutation_deleteThreepid {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: DeleteThreepidMutation_deleteThreepid_details[];
  threepids: DeleteThreepidMutation_deleteThreepid_threepids[];
  featuredWork: DeleteThreepidMutation_deleteThreepid_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: DeleteThreepidMutation_deleteThreepid_skills[];
  prompts: DeleteThreepidMutation_deleteThreepid_prompts[];
  taskGatingDefaults: DeleteThreepidMutation_deleteThreepid_taskGatingDefaults[];
  taskViews: DeleteThreepidMutation_deleteThreepid_taskViews[];
  openTasksNotifications: boolean;
  node: DeleteThreepidMutation_deleteThreepid_node;
  reputationStakes: DeleteThreepidMutation_deleteThreepid_reputationStakes[];
  reputationSupporters: DeleteThreepidMutation_deleteThreepid_reputationSupporters[];
  reputationCredits: number;
}

export interface DeleteThreepidMutation {
  deleteThreepid: DeleteThreepidMutation_deleteThreepid;
}

export interface DeleteThreepidMutationVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateThreepidAuthSessionMutation
// ====================================================

export interface CreateThreepidAuthSessionMutation_session {
  __typename: "ThreepidAuthSession";
  id: Scalar.UUID;
}

export interface CreateThreepidAuthSessionMutation {
  session: CreateThreepidAuthSessionMutation_session;
}

export interface CreateThreepidAuthSessionMutationVariables {
  redirect?: string | null;
  type: ThreepidAuthSessionType;
  state?: Scalar.JSONObject | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspaceSectionMutation
// ====================================================

export interface CreateWorkspaceSectionMutation_section_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface CreateWorkspaceSectionMutation_section_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  workspaceSections: CreateWorkspaceSectionMutation_section_organization_workspaceSections[];
}

export interface CreateWorkspaceSectionMutation_section {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
  organization: CreateWorkspaceSectionMutation_section_organization;
}

export interface CreateWorkspaceSectionMutation {
  section: CreateWorkspaceSectionMutation_section;
}

export interface CreateWorkspaceSectionMutationVariables {
  input: CreateWorkspaceSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWorkspaceSectionMutation
// ====================================================

export interface UpdateWorkspaceSectionMutation_section_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface UpdateWorkspaceSectionMutation_section_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  workspaceSections: UpdateWorkspaceSectionMutation_section_organization_workspaceSections[];
}

export interface UpdateWorkspaceSectionMutation_section {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
  organization: UpdateWorkspaceSectionMutation_section_organization;
}

export interface UpdateWorkspaceSectionMutation {
  section: UpdateWorkspaceSectionMutation_section;
}

export interface UpdateWorkspaceSectionMutationVariables {
  input: UpdateWorkspaceSectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeQuery
// ====================================================

export interface MeQuery_me_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface MeQuery_me_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface MeQuery_me_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface MeQuery_me_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface MeQuery_me_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface MeQuery_me_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface MeQuery_me_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface MeQuery_me_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: MeQuery_me_taskGatingDefaults_claimRoles[];
  applyRoles: MeQuery_me_taskGatingDefaults_applyRoles[];
}

export interface MeQuery_me_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface MeQuery_me_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: MeQuery_me_taskViews_filters_dueDate | null;
}

export interface MeQuery_me_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface MeQuery_me_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: MeQuery_me_taskViews_filters[];
  sortBys: MeQuery_me_taskViews_sortBys[];
}

export interface MeQuery_me_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface MeQuery_me_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface MeQuery_me_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: MeQuery_me_node_children_node_workspace_options | null;
}

export interface MeQuery_me_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface MeQuery_me_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: MeQuery_me_node_children_node_organization | null;
  workspace: MeQuery_me_node_children_node_workspace | null;
  skill: MeQuery_me_node_children_node_skill | null;
}

export interface MeQuery_me_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: MeQuery_me_node_children_node;
}

export interface MeQuery_me_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: MeQuery_me_node_children[];
}

export interface MeQuery_me_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface MeQuery_me_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface MeQuery_me {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: MeQuery_me_details[];
  threepids: MeQuery_me_threepids[];
  featuredWork: MeQuery_me_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: MeQuery_me_skills[];
  prompts: MeQuery_me_prompts[];
  taskGatingDefaults: MeQuery_me_taskGatingDefaults[];
  taskViews: MeQuery_me_taskViews[];
  openTasksNotifications: boolean;
  node: MeQuery_me_node;
  reputationStakes: MeQuery_me_reputationStakes[];
  reputationSupporters: MeQuery_me_reputationSupporters[];
  reputationCredits: number;
}

export interface MeQuery {
  me: MeQuery_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserProfileByUsernameQuery
// ====================================================

export interface UserProfileByUsernameQuery_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UserProfileByUsernameQuery_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
}

export interface UserProfileByUsernameQuery_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface UserProfileByUsernameQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: UserProfileByUsernameQuery_user_details[];
  threepids: UserProfileByUsernameQuery_user_threepids[];
  featuredWork: UserProfileByUsernameQuery_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
}

export interface UserProfileByUsernameQuery {
  user: UserProfileByUsernameQuery_user;
}

export interface UserProfileByUsernameQueryVariables {
  username: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserProfileQuery
// ====================================================

export interface UserProfileQuery_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UserProfileQuery_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
}

export interface UserProfileQuery_user_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface UserProfileQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: UserProfileQuery_user_details[];
  threepids: UserProfileQuery_user_threepids[];
  featuredWork: UserProfileQuery_user_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
}

export interface UserProfileQuery {
  user: UserProfileQuery_user;
}

export interface UserProfileQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserRolesQuery
// ====================================================

export interface UserRolesQuery_user_roles_node_parents_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserRolesQuery_user_roles_node_parents_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  organization: UserRolesQuery_user_roles_node_parents_node_organization | null;
}

export interface UserRolesQuery_user_roles_node_parents {
  __typename: "GraphEdge";
  node: UserRolesQuery_user_roles_node_parents_node;
}

export interface UserRolesQuery_user_roles_node {
  __typename: "GraphNode";
  parents: UserRolesQuery_user_roles_node_parents[];
}

export interface UserRolesQuery_user_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  node: UserRolesQuery_user_roles_node;
}

export interface UserRolesQuery_user_userRoles {
  __typename: "UserRole";
  roleId: string;
  hidden: boolean;
}

export interface UserRolesQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  roles: UserRolesQuery_user_roles[];
  userRoles: UserRolesQuery_user_userRoles[];
}

export interface UserRolesQuery {
  user: UserRolesQuery_user;
}

export interface UserRolesQueryVariables {
  userId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTasksQuery
// ====================================================

export interface UserTasksQuery_user_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UserTasksQuery_user_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UserTasksQuery_user_tasks_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserTasksQuery_user_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UserTasksQuery_user_tasks_workspace_options | null;
  organization: UserTasksQuery_user_tasks_workspace_organization;
}

export interface UserTasksQuery_user_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface UserTasksQuery_user_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface UserTasksQuery_user_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface UserTasksQuery_user_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UserTasksQuery_user_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserTasksQuery_user_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserTasksQuery_user_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserTasksQuery_user_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UserTasksQuery_user_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: UserTasksQuery_user_tasks_rewards_token_network;
}

export interface UserTasksQuery_user_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserTasksQuery_user_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface UserTasksQuery_user_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: UserTasksQuery_user_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface UserTasksQuery_user_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: UserTasksQuery_user_tasks_rewards_payments_payment_paymentMethod;
}

export interface UserTasksQuery_user_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: UserTasksQuery_user_tasks_rewards_payments_user;
  payment: UserTasksQuery_user_tasks_rewards_payments_payment;
}

export interface UserTasksQuery_user_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: UserTasksQuery_user_tasks_rewards_token;
  tokenId: string;
  payments: UserTasksQuery_user_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface UserTasksQuery_user_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface UserTasksQuery_user_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface UserTasksQuery_user_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: UserTasksQuery_user_tasks_templateTask | null;
  workspaceId: string;
  workspace: UserTasksQuery_user_tasks_workspace;
  parentTaskId: string | null;
  parentTask: UserTasksQuery_user_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: UserTasksQuery_user_tasks_subtasks[];
  tags: UserTasksQuery_user_tasks_tags[];
  skills: UserTasksQuery_user_tasks_skills[];
  assignees: UserTasksQuery_user_tasks_assignees[];
  owners: UserTasksQuery_user_tasks_owners[];
  creator: UserTasksQuery_user_tasks_creator | null;
  rewards: UserTasksQuery_user_tasks_rewards[];
  review: UserTasksQuery_user_tasks_review | null;
  reactions: UserTasksQuery_user_tasks_reactions[];
}

export interface UserTasksQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  tasks: UserTasksQuery_user_tasks[];
}

export interface UserTasksQuery {
  user: UserTasksQuery_user;
}

export interface UserTasksQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTaskViewsQuery
// ====================================================

export interface UserTaskViewsQuery_user_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UserTaskViewsQuery_user_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UserTaskViewsQuery_user_taskViews_filters_dueDate | null;
}

export interface UserTaskViewsQuery_user_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UserTaskViewsQuery_user_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UserTaskViewsQuery_user_taskViews_filters[];
  sortBys: UserTaskViewsQuery_user_taskViews_sortBys[];
}

export interface UserTaskViewsQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  taskViews: UserTaskViewsQuery_user_taskViews[];
}

export interface UserTaskViewsQuery {
  user: UserTaskViewsQuery_user;
}

export interface UserTaskViewsQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAddressQuery
// ====================================================

export interface UserAddressQuery_user_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  address: string;
}

export interface UserAddressQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  threepids: UserAddressQuery_user_threepids[];
}

export interface UserAddressQuery {
  user: UserAddressQuery_user;
}

export interface UserAddressQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PermissionsQuery
// ====================================================

export interface PermissionsQuery {
  permissions: Scalar.JSONObject[];
}

export interface PermissionsQueryVariables {
  organizationId?: Scalar.UUID | null;
  unauthed?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationQuery
// ====================================================

export interface GetOrganizationQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationQuery {
  organization: GetOrganizationQuery_organization;
}

export interface GetOrganizationQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationDetailsQuery
// ====================================================

export interface GetOrganizationDetailsQuery_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface GetOrganizationDetailsQuery_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationDetailsQuery_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationDetailsQuery_organization_workspaces_options | null;
}

export interface GetOrganizationDetailsQuery_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface GetOrganizationDetailsQuery_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface GetOrganizationDetailsQuery_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface GetOrganizationDetailsQuery_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationDetailsQuery_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationDetailsQuery_organization_workspaceTokenGates_token_network;
}

export interface GetOrganizationDetailsQuery_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: GetOrganizationDetailsQuery_organization_workspaceTokenGates_token;
}

export interface GetOrganizationDetailsQuery_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface GetOrganizationDetailsQuery_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: GetOrganizationDetailsQuery_organization_taskViews_filters_dueDate | null;
}

export interface GetOrganizationDetailsQuery_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface GetOrganizationDetailsQuery_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: GetOrganizationDetailsQuery_organization_taskViews_filters[];
  sortBys: GetOrganizationDetailsQuery_organization_taskViews_sortBys[];
}

export interface GetOrganizationDetailsQuery_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationDetailsQuery_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationDetailsQuery_organization_fundingSessions_token_network;
}

export interface GetOrganizationDetailsQuery_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: GetOrganizationDetailsQuery_organization_fundingSessions_token;
}

export interface GetOrganizationDetailsQuery_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

export interface GetOrganizationDetailsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: GetOrganizationDetailsQuery_organization_options | null;
  workspaceCount: number;
  workspaces: GetOrganizationDetailsQuery_organization_workspaces[];
  workspaceSections: GetOrganizationDetailsQuery_organization_workspaceSections[];
  tags: GetOrganizationDetailsQuery_organization_tags[];
  details: GetOrganizationDetailsQuery_organization_details[];
  workspaceTokenGates: GetOrganizationDetailsQuery_organization_workspaceTokenGates[];
  taskViews: GetOrganizationDetailsQuery_organization_taskViews[];
  fundingSessions: GetOrganizationDetailsQuery_organization_fundingSessions[];
  node: GetOrganizationDetailsQuery_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface GetOrganizationDetailsQuery {
  organization: GetOrganizationDetailsQuery_organization;
}

export interface GetOrganizationDetailsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationWorkspacesQuery
// ====================================================

export interface GetOrganizationWorkspacesQuery_organization_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationWorkspacesQuery_organization_node_workspace_options | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_workspace_options | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_organization | null;
  workspace: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_workspace | null;
  skill: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node_skill | null;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children_node;
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_organization | null;
  workspace: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_workspace | null;
  skill: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_skill | null;
  children: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node_children[];
}

export interface GetOrganizationWorkspacesQuery_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren_node;
}

export interface GetOrganizationWorkspacesQuery_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetOrganizationWorkspacesQuery_organization_node_organization | null;
  workspace: GetOrganizationWorkspacesQuery_organization_node_workspace | null;
  skill: GetOrganizationWorkspacesQuery_organization_node_skill | null;
  workspaceChildren: GetOrganizationWorkspacesQuery_organization_node_workspaceChildren[];
}

export interface GetOrganizationWorkspacesQuery_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface GetOrganizationWorkspacesQuery_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface GetOrganizationWorkspacesQuery_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationWorkspacesQuery_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationWorkspacesQuery_organization_fundingSessions_token_network;
}

export interface GetOrganizationWorkspacesQuery_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: GetOrganizationWorkspacesQuery_organization_fundingSessions_token;
}

export interface GetOrganizationWorkspacesQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  node: GetOrganizationWorkspacesQuery_organization_node;
  workspaceSections: GetOrganizationWorkspacesQuery_organization_workspaceSections[];
  options: GetOrganizationWorkspacesQuery_organization_options | null;
  fundingSessions: GetOrganizationWorkspacesQuery_organization_fundingSessions[];
}

export interface GetOrganizationWorkspacesQuery {
  organization: GetOrganizationWorkspacesQuery_organization;
}

export interface GetOrganizationWorkspacesQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationOverviewQuery
// ====================================================

export interface GetOrganizationOverviewQuery_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_skills_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_skills {
  __typename: "SkillStatistic";
  count: number;
  skill: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_skills_skill;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace_options | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_organization | null;
  workspace: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace | null;
  skill: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node_skill | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren_node;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  workspaceChildren: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node_workspaceChildren[];
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_options | null;
  contributors: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_contributors[];
  skills: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_skills[];
  openTaskCount: number;
  doneTaskCount: number;
  totalTaskCount: number;
  node: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace_node;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  workspace: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node_workspace | null;
}

export interface GetOrganizationOverviewQuery_organization_node_workspaceChildren {
  __typename: "GraphEdge";
  node: GetOrganizationOverviewQuery_organization_node_workspaceChildren_node;
}

export interface GetOrganizationOverviewQuery_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  workspaceChildren: GetOrganizationOverviewQuery_organization_node_workspaceChildren[];
}

export interface GetOrganizationOverviewQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  workspaceSections: GetOrganizationOverviewQuery_organization_workspaceSections[];
  node: GetOrganizationOverviewQuery_organization_node;
}

export interface GetOrganizationOverviewQuery {
  organization: GetOrganizationOverviewQuery_organization;
}

export interface GetOrganizationOverviewQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceOverviewQuery
// ====================================================

export interface GetWorkspaceOverviewQuery_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceOverviewQuery_workspace_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceOverviewQuery_workspace_skills_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceOverviewQuery_workspace_skills {
  __typename: "SkillStatistic";
  count: number;
  skill: GetWorkspaceOverviewQuery_workspace_skills_skill;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_skills_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_skills {
  __typename: "SkillStatistic";
  count: number;
  skill: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_skills_skill;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace_options | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_organization | null;
  workspace: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_workspace | null;
  skill: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node_skill | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren_node;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  workspaceChildren: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node_workspaceChildren[];
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_options | null;
  contributors: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_contributors[];
  skills: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_skills[];
  openTaskCount: number;
  doneTaskCount: number;
  totalTaskCount: number;
  node: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace_node;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_organization | null;
  workspace: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_workspace | null;
  skill: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node_skill | null;
}

export interface GetWorkspaceOverviewQuery_workspace_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren_node;
}

export interface GetWorkspaceOverviewQuery_workspace_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  workspaceChildren: GetWorkspaceOverviewQuery_workspace_node_workspaceChildren[];
}

export interface GetWorkspaceOverviewQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceOverviewQuery_workspace_options | null;
  contributors: GetWorkspaceOverviewQuery_workspace_contributors[];
  skills: GetWorkspaceOverviewQuery_workspace_skills[];
  openTaskCount: number;
  doneTaskCount: number;
  totalTaskCount: number;
  node: GetWorkspaceOverviewQuery_workspace_node;
}

export interface GetWorkspaceOverviewQuery {
  workspace: GetWorkspaceOverviewQuery_workspace;
}

export interface GetWorkspaceOverviewQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationTaskViewsQuery
// ====================================================

export interface GetOrganizationTaskViewsQuery_organization_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface GetOrganizationTaskViewsQuery_organization_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationTaskViewsQuery_organization_workspaces_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetOrganizationTaskViewsQuery_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationTaskViewsQuery_organization_workspaces_options | null;
  taskTags: GetOrganizationTaskViewsQuery_organization_workspaces_taskTags[];
}

export interface GetOrganizationTaskViewsQuery_organization_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface GetOrganizationTaskViewsQuery_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface GetOrganizationTaskViewsQuery_organization_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface GetOrganizationTaskViewsQuery_organization_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationTaskViewsQuery_organization_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationTaskViewsQuery_organization_workspaceTokenGates_token_network;
}

export interface GetOrganizationTaskViewsQuery_organization_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: GetOrganizationTaskViewsQuery_organization_workspaceTokenGates_token;
}

export interface GetOrganizationTaskViewsQuery_organization_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface GetOrganizationTaskViewsQuery_organization_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: GetOrganizationTaskViewsQuery_organization_taskViews_filters_dueDate | null;
}

export interface GetOrganizationTaskViewsQuery_organization_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface GetOrganizationTaskViewsQuery_organization_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: GetOrganizationTaskViewsQuery_organization_taskViews_filters[];
  sortBys: GetOrganizationTaskViewsQuery_organization_taskViews_sortBys[];
}

export interface GetOrganizationTaskViewsQuery_organization_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationTaskViewsQuery_organization_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationTaskViewsQuery_organization_fundingSessions_token_network;
}

export interface GetOrganizationTaskViewsQuery_organization_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: GetOrganizationTaskViewsQuery_organization_fundingSessions_token;
}

export interface GetOrganizationTaskViewsQuery_organization_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

export interface GetOrganizationTaskViewsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: GetOrganizationTaskViewsQuery_organization_options | null;
  workspaceCount: number;
  workspaces: GetOrganizationTaskViewsQuery_organization_workspaces[];
  workspaceSections: GetOrganizationTaskViewsQuery_organization_workspaceSections[];
  tags: GetOrganizationTaskViewsQuery_organization_tags[];
  details: GetOrganizationTaskViewsQuery_organization_details[];
  workspaceTokenGates: GetOrganizationTaskViewsQuery_organization_workspaceTokenGates[];
  taskViews: GetOrganizationTaskViewsQuery_organization_taskViews[];
  fundingSessions: GetOrganizationTaskViewsQuery_organization_fundingSessions[];
  node: GetOrganizationTaskViewsQuery_organization_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

export interface GetOrganizationTaskViewsQuery {
  organization: GetOrganizationTaskViewsQuery_organization;
}

export interface GetOrganizationTaskViewsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationIntegrationsQuery
// ====================================================

export interface GetOrganizationIntegrationsQuery_organization_integrations {
  __typename: "OrganizationIntegration";
  id: Scalar.UUID;
  type: OrganizationIntegrationType;
  config: Scalar.JSONObject | null;
}

export interface GetOrganizationIntegrationsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  integrations: GetOrganizationIntegrationsQuery_organization_integrations[];
}

export interface GetOrganizationIntegrationsQuery {
  organization: GetOrganizationIntegrationsQuery_organization;
}

export interface GetOrganizationIntegrationsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationBySlugQuery
// ====================================================

export interface GetOrganizationBySlugQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationBySlugQuery {
  organization: GetOrganizationBySlugQuery_organization;
}

export interface GetOrganizationBySlugQueryVariables {
  organizationSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationUsersQuery
// ====================================================

export interface GetOrganizationUsersQuery_organization_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationUsersQuery_organization_admins {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationUsersQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  users: GetOrganizationUsersQuery_organization_users[];
  admins: GetOrganizationUsersQuery_organization_admins[];
}

export interface GetOrganizationUsersQuery {
  organization: GetOrganizationUsersQuery_organization;
}

export interface GetOrganizationUsersQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationUsersWithRolesQuery
// ====================================================

export interface GetOrganizationUsersWithRolesQuery_node_organization_users_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
}

export interface GetOrganizationUsersWithRolesQuery_node_organization_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  roles: GetOrganizationUsersWithRolesQuery_node_organization_users_roles[];
}

export interface GetOrganizationUsersWithRolesQuery_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  users: GetOrganizationUsersWithRolesQuery_node_organization_users[];
}

export interface GetOrganizationUsersWithRolesQuery_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  organization: GetOrganizationUsersWithRolesQuery_node_organization | null;
}

export interface GetOrganizationUsersWithRolesQuery {
  node: GetOrganizationUsersWithRolesQuery_node;
}

export interface GetOrganizationUsersWithRolesQueryVariables {
  nodeId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationContributorsQuery
// ====================================================

export interface GetOrganizationContributorsQuery_node_organization_users_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
}

export interface GetOrganizationContributorsQuery_node_organization_users_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  address: string;
}

export interface GetOrganizationContributorsQuery_node_organization_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  roles: GetOrganizationContributorsQuery_node_organization_users_roles[];
  threepids: GetOrganizationContributorsQuery_node_organization_users_threepids[];
}

export interface GetOrganizationContributorsQuery_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  users: GetOrganizationContributorsQuery_node_organization_users[];
}

export interface GetOrganizationContributorsQuery_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  organization: GetOrganizationContributorsQuery_node_organization | null;
}

export interface GetOrganizationContributorsQuery {
  node: GetOrganizationContributorsQuery_node;
}

export interface GetOrganizationContributorsQueryVariables {
  nodeId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationRolesQuery
// ====================================================

export interface GetOrganizationRolesQuery_organization_roles_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface GetOrganizationRolesQuery_organization_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: GetOrganizationRolesQuery_organization_roles_rules[];
}

export interface GetOrganizationRolesQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  nodeId: string;
  roles: GetOrganizationRolesQuery_organization_roles[];
}

export interface GetOrganizationRolesQuery {
  organization: GetOrganizationRolesQuery_organization;
}

export interface GetOrganizationRolesQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFeaturedOrganizationsQuery
// ====================================================

export interface GetFeaturedOrganizationsQuery_organizations {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetFeaturedOrganizationsQuery {
  organizations: GetFeaturedOrganizationsQuery_organizations[];
}

export interface GetFeaturedOrganizationsQueryVariables {
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPopularOrganizationsQuery
// ====================================================

export interface GetPopularOrganizationsQuery_organizations {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  userCount: number;
}

export interface GetPopularOrganizationsQuery {
  organizations: GetPopularOrganizationsQuery_organizations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationTokensQuery
// ====================================================

export interface GetOrganizationTokensQuery_organization_tokens_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationTokensQuery_organization_tokens {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationTokensQuery_organization_tokens_network;
}

export interface GetOrganizationTokensQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  tokens: GetOrganizationTokensQuery_organization_tokens[];
}

export interface GetOrganizationTokensQuery {
  organization: GetOrganizationTokensQuery_organization;
}

export interface GetOrganizationTokensQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationsUserFollowsOnDiscordQuery
// ====================================================

export interface GetOrganizationsUserFollowsOnDiscordQuery_organizations {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationsUserFollowsOnDiscordQuery {
  organizations: GetOrganizationsUserFollowsOnDiscordQuery_organizations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationTagsQuery
// ====================================================

export interface GetOrganizationTagsQuery_organization_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface GetOrganizationTagsQuery_organization_allTags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface GetOrganizationTagsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  tags: GetOrganizationTagsQuery_organization_tags[];
  allTags: GetOrganizationTagsQuery_organization_allTags[];
}

export interface GetOrganizationTagsQuery {
  organization: GetOrganizationTagsQuery_organization;
}

export interface GetOrganizationTagsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationTasksQuery
// ====================================================

export interface GetOrganizationTasksQuery_organization_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetOrganizationTasksQuery_organization_tasks_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetOrganizationTasksQuery_organization_tasks_workspace_options | null;
  organization: GetOrganizationTasksQuery_organization_tasks_workspace_organization;
}

export interface GetOrganizationTasksQuery_organization_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationTasksQuery_organization_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationTasksQuery_organization_tasks_rewards_token_network;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetOrganizationTasksQuery_organization_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetOrganizationTasksQuery_organization_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetOrganizationTasksQuery_organization_tasks_rewards_payments_user;
  payment: GetOrganizationTasksQuery_organization_tasks_rewards_payments_payment;
}

export interface GetOrganizationTasksQuery_organization_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetOrganizationTasksQuery_organization_tasks_rewards_token;
  tokenId: string;
  payments: GetOrganizationTasksQuery_organization_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetOrganizationTasksQuery_organization_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetOrganizationTasksQuery_organization_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetOrganizationTasksQuery_organization_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetOrganizationTasksQuery_organization_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetOrganizationTasksQuery_organization_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetOrganizationTasksQuery_organization_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetOrganizationTasksQuery_organization_tasks_subtasks[];
  tags: GetOrganizationTasksQuery_organization_tasks_tags[];
  skills: GetOrganizationTasksQuery_organization_tasks_skills[];
  assignees: GetOrganizationTasksQuery_organization_tasks_assignees[];
  owners: GetOrganizationTasksQuery_organization_tasks_owners[];
  creator: GetOrganizationTasksQuery_organization_tasks_creator | null;
  rewards: GetOrganizationTasksQuery_organization_tasks_rewards[];
  review: GetOrganizationTasksQuery_organization_tasks_review | null;
  reactions: GetOrganizationTasksQuery_organization_tasks_reactions[];
}

export interface GetOrganizationTasksQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  tasks: GetOrganizationTasksQuery_organization_tasks[];
}

export interface GetOrganizationTasksQuery {
  organization: GetOrganizationTasksQuery_organization;
}

export interface GetOrganizationTasksQueryVariables {
  organizationId: Scalar.UUID;
  filter?: TaskFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationTaskTagsQuery
// ====================================================

export interface GetOrganizationTaskTagsQuery_organization_workspaces_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetOrganizationTaskTagsQuery_organization_workspaces {
  __typename: "Workspace";
  taskTags: GetOrganizationTaskTagsQuery_organization_workspaces_taskTags[];
}

export interface GetOrganizationTaskTagsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  workspaces: GetOrganizationTaskTagsQuery_organization_workspaces[];
}

export interface GetOrganizationTaskTagsQuery {
  organization: GetOrganizationTaskTagsQuery_organization;
}

export interface GetOrganizationTaskTagsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceSectionTasksQuery
// ====================================================

export interface GetWorkspaceSectionTasksQuery_section_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceSectionTasksQuery_section_tasks_workspace_options | null;
  organization: GetWorkspaceSectionTasksQuery_section_tasks_workspace_organization;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetWorkspaceSectionTasksQuery_section_tasks_rewards_token_network;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_user;
  payment: GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments_payment;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetWorkspaceSectionTasksQuery_section_tasks_rewards_token;
  tokenId: string;
  payments: GetWorkspaceSectionTasksQuery_section_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetWorkspaceSectionTasksQuery_section_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetWorkspaceSectionTasksQuery_section_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetWorkspaceSectionTasksQuery_section_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetWorkspaceSectionTasksQuery_section_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetWorkspaceSectionTasksQuery_section_tasks_subtasks[];
  tags: GetWorkspaceSectionTasksQuery_section_tasks_tags[];
  skills: GetWorkspaceSectionTasksQuery_section_tasks_skills[];
  assignees: GetWorkspaceSectionTasksQuery_section_tasks_assignees[];
  owners: GetWorkspaceSectionTasksQuery_section_tasks_owners[];
  creator: GetWorkspaceSectionTasksQuery_section_tasks_creator | null;
  rewards: GetWorkspaceSectionTasksQuery_section_tasks_rewards[];
  review: GetWorkspaceSectionTasksQuery_section_tasks_review | null;
  reactions: GetWorkspaceSectionTasksQuery_section_tasks_reactions[];
}

export interface GetWorkspaceSectionTasksQuery_section {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  tasks: GetWorkspaceSectionTasksQuery_section_tasks[];
}

export interface GetWorkspaceSectionTasksQuery {
  section: GetWorkspaceSectionTasksQuery_section;
}

export interface GetWorkspaceSectionTasksQueryVariables {
  slug: string;
  filter?: TaskFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceQuery
// ====================================================

export interface GetWorkspaceQuery_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceQuery_workspace_options | null;
}

export interface GetWorkspaceQuery {
  workspace: GetWorkspaceQuery_workspace;
}

export interface GetWorkspaceQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceDetailsQuery
// ====================================================

export interface GetWorkspaceDetailsQuery_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceDetailsQuery_workspace_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceDetailsQuery_workspace_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetWorkspaceDetailsQuery_workspace_tokenGates_token_network;
}

export interface GetWorkspaceDetailsQuery_workspace_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: GetWorkspaceDetailsQuery_workspace_tokenGates_token;
}

export interface GetWorkspaceDetailsQuery_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceDetailsQuery_workspace_taskSections {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface GetWorkspaceDetailsQuery_workspace_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface GetWorkspaceDetailsQuery_workspace_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: GetWorkspaceDetailsQuery_workspace_taskViews_filters_dueDate | null;
}

export interface GetWorkspaceDetailsQuery_workspace_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface GetWorkspaceDetailsQuery_workspace_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: GetWorkspaceDetailsQuery_workspace_taskViews_filters[];
  sortBys: GetWorkspaceDetailsQuery_workspace_taskViews_sortBys[];
}

export interface GetWorkspaceDetailsQuery_workspace_parent_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceDetailsQuery_workspace_parent {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceDetailsQuery_workspace_parent_options | null;
}

export interface GetWorkspaceDetailsQuery_workspace_activityThread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceDetailsQuery_workspace_activityThread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: GetWorkspaceDetailsQuery_workspace_activityThread_messages_sender;
}

export interface GetWorkspaceDetailsQuery_workspace_activityThread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface GetWorkspaceDetailsQuery_workspace_activityThread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface GetWorkspaceDetailsQuery_workspace_activityThread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: GetWorkspaceDetailsQuery_workspace_activityThread_messages[];
  task: GetWorkspaceDetailsQuery_workspace_activityThread_task | null;
  workspace: GetWorkspaceDetailsQuery_workspace_activityThread_workspace | null;
}

export interface GetWorkspaceDetailsQuery_workspace_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceDetailsQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceDetailsQuery_workspace_options | null;
  tokenGates: GetWorkspaceDetailsQuery_workspace_tokenGates[];
  organization: GetWorkspaceDetailsQuery_workspace_organization;
  taskSections: GetWorkspaceDetailsQuery_workspace_taskSections[];
  taskViews: GetWorkspaceDetailsQuery_workspace_taskViews[];
  parent: GetWorkspaceDetailsQuery_workspace_parent | null;
  activityThread: GetWorkspaceDetailsQuery_workspace_activityThread | null;
  contributors: GetWorkspaceDetailsQuery_workspace_contributors[];
}

export interface GetWorkspaceDetailsQuery {
  workspace: GetWorkspaceDetailsQuery_workspace;
}

export interface GetWorkspaceDetailsQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceBySlugQuery
// ====================================================

export interface GetWorkspaceBySlugQuery_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceBySlugQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceBySlugQuery_workspace_options | null;
}

export interface GetWorkspaceBySlugQuery {
  workspace: GetWorkspaceBySlugQuery_workspace;
}

export interface GetWorkspaceBySlugQueryVariables {
  workspaceSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceIdBySlugQuery
// ====================================================

export interface GetWorkspaceIdBySlugQuery {
  workspaceId: Scalar.UUID;
}

export interface GetWorkspaceIdBySlugQueryVariables {
  workspaceSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceTasksQuery
// ====================================================

export interface GetWorkspaceTasksQuery_workspace_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceTasksQuery_workspace_tasks_workspace_options | null;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetWorkspaceTasksQuery_workspace_tasks_rewards_token_network;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_user;
  payment: GetWorkspaceTasksQuery_workspace_tasks_rewards_payments_payment;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetWorkspaceTasksQuery_workspace_tasks_rewards_token;
  tokenId: string;
  payments: GetWorkspaceTasksQuery_workspace_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetWorkspaceTasksQuery_workspace_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetWorkspaceTasksQuery_workspace_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetWorkspaceTasksQuery_workspace_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetWorkspaceTasksQuery_workspace_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetWorkspaceTasksQuery_workspace_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetWorkspaceTasksQuery_workspace_tasks_subtasks[];
  tags: GetWorkspaceTasksQuery_workspace_tasks_tags[];
  skills: GetWorkspaceTasksQuery_workspace_tasks_skills[];
  assignees: GetWorkspaceTasksQuery_workspace_tasks_assignees[];
  owners: GetWorkspaceTasksQuery_workspace_tasks_owners[];
  creator: GetWorkspaceTasksQuery_workspace_tasks_creator | null;
  rewards: GetWorkspaceTasksQuery_workspace_tasks_rewards[];
  review: GetWorkspaceTasksQuery_workspace_tasks_review | null;
  reactions: GetWorkspaceTasksQuery_workspace_tasks_reactions[];
}

export interface GetWorkspaceTasksQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  tasks: GetWorkspaceTasksQuery_workspace_tasks[];
}

export interface GetWorkspaceTasksQuery {
  workspace: GetWorkspaceTasksQuery_workspace;
}

export interface GetWorkspaceTasksQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceTasksExportQuery
// ====================================================

export interface GetWorkspaceTasksExportQuery_workspace_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceTasksExportQuery_workspace_tasks_workspace_options | null;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_assignees_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  address: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  threepids: GetWorkspaceTasksExportQuery_workspace_tasks_assignees_threepids[];
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_token_network;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_user;
  payment: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments_payment;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_token;
  tokenId: string;
  payments: GetWorkspaceTasksExportQuery_workspace_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetWorkspaceTasksExportQuery_workspace_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetWorkspaceTasksExportQuery_workspace_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetWorkspaceTasksExportQuery_workspace_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetWorkspaceTasksExportQuery_workspace_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetWorkspaceTasksExportQuery_workspace_tasks_subtasks[];
  tags: GetWorkspaceTasksExportQuery_workspace_tasks_tags[];
  skills: GetWorkspaceTasksExportQuery_workspace_tasks_skills[];
  assignees: GetWorkspaceTasksExportQuery_workspace_tasks_assignees[];
  owners: GetWorkspaceTasksExportQuery_workspace_tasks_owners[];
  creator: GetWorkspaceTasksExportQuery_workspace_tasks_creator | null;
  rewards: GetWorkspaceTasksExportQuery_workspace_tasks_rewards[];
  review: GetWorkspaceTasksExportQuery_workspace_tasks_review | null;
  reactions: GetWorkspaceTasksExportQuery_workspace_tasks_reactions[];
  permalink: string;
}

export interface GetWorkspaceTasksExportQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  tasks: GetWorkspaceTasksExportQuery_workspace_tasks[];
}

export interface GetWorkspaceTasksExportQuery {
  workspace: GetWorkspaceTasksExportQuery_workspace;
}

export interface GetWorkspaceTasksExportQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceTaskTagsQuery
// ====================================================

export interface GetWorkspaceTaskTagsQuery_workspace_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetWorkspaceTaskTagsQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  taskTags: GetWorkspaceTaskTagsQuery_workspace_taskTags[];
}

export interface GetWorkspaceTaskTagsQuery {
  workspace: GetWorkspaceTaskTagsQuery_workspace;
}

export interface GetWorkspaceTaskTagsQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTaskQuery
// ====================================================

export interface GetTaskQuery_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetTaskQuery_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetTaskQuery_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetTaskQuery_task_workspace_options | null;
}

export interface GetTaskQuery_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetTaskQuery_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetTaskQuery_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetTaskQuery_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetTaskQuery_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskQuery_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskQuery_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskQuery_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskQuery_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetTaskQuery_task_rewards_token_network;
}

export interface GetTaskQuery_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskQuery_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskQuery_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTaskQuery_task_rewards_payments_payment_paymentMethod_network;
}

export interface GetTaskQuery_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetTaskQuery_task_rewards_payments_payment_paymentMethod;
}

export interface GetTaskQuery_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetTaskQuery_task_rewards_payments_user;
  payment: GetTaskQuery_task_rewards_payments_payment;
}

export interface GetTaskQuery_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetTaskQuery_task_rewards_token;
  tokenId: string;
  payments: GetTaskQuery_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetTaskQuery_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetTaskQuery_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetTaskQuery_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetTaskQuery_task_templateTask | null;
  workspaceId: string;
  workspace: GetTaskQuery_task_workspace;
  parentTaskId: string | null;
  parentTask: GetTaskQuery_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetTaskQuery_task_subtasks[];
  tags: GetTaskQuery_task_tags[];
  skills: GetTaskQuery_task_skills[];
  assignees: GetTaskQuery_task_assignees[];
  owners: GetTaskQuery_task_owners[];
  creator: GetTaskQuery_task_creator | null;
  rewards: GetTaskQuery_task_rewards[];
  review: GetTaskQuery_task_review | null;
  reactions: GetTaskQuery_task_reactions[];
}

export interface GetTaskQuery {
  task: GetTaskQuery_task;
}

export interface GetTaskQueryVariables {
  taskId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTaskDetailsQuery
// ====================================================

export interface GetTaskDetailsQuery_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetTaskDetailsQuery_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetTaskDetailsQuery_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetTaskDetailsQuery_task_workspace_options | null;
  organization: GetTaskDetailsQuery_task_workspace_organization;
}

export interface GetTaskDetailsQuery_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetTaskDetailsQuery_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetTaskDetailsQuery_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetTaskDetailsQuery_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetTaskDetailsQuery_task_rewards_token_network;
}

export interface GetTaskDetailsQuery_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTaskDetailsQuery_task_rewards_payments_payment_paymentMethod_network;
}

export interface GetTaskDetailsQuery_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetTaskDetailsQuery_task_rewards_payments_payment_paymentMethod;
}

export interface GetTaskDetailsQuery_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetTaskDetailsQuery_task_rewards_payments_user;
  payment: GetTaskDetailsQuery_task_rewards_payments_payment;
}

export interface GetTaskDetailsQuery_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetTaskDetailsQuery_task_rewards_token;
  tokenId: string;
  payments: GetTaskDetailsQuery_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetTaskDetailsQuery_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetTaskDetailsQuery_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetTaskDetailsQuery_task_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface GetTaskDetailsQuery_task_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface GetTaskDetailsQuery_task_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface GetTaskDetailsQuery_task_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface GetTaskDetailsQuery_task_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetTaskDetailsQuery_task_applications_reward_token_network;
}

export interface GetTaskDetailsQuery_task_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTaskDetailsQuery_task_applications_reward_payments_payment_paymentMethod_network;
}

export interface GetTaskDetailsQuery_task_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetTaskDetailsQuery_task_applications_reward_payments_payment_paymentMethod;
}

export interface GetTaskDetailsQuery_task_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetTaskDetailsQuery_task_applications_reward_payments_user;
  payment: GetTaskDetailsQuery_task_applications_reward_payments_payment;
}

export interface GetTaskDetailsQuery_task_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetTaskDetailsQuery_task_applications_reward_token;
  tokenId: string;
  payments: GetTaskDetailsQuery_task_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetTaskDetailsQuery_task_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface GetTaskDetailsQuery_task_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: GetTaskDetailsQuery_task_applications_user_details[];
}

export interface GetTaskDetailsQuery_task_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: GetTaskDetailsQuery_task_applications_thread_messages_sender;
}

export interface GetTaskDetailsQuery_task_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface GetTaskDetailsQuery_task_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface GetTaskDetailsQuery_task_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: GetTaskDetailsQuery_task_applications_thread_messages[];
  task: GetTaskDetailsQuery_task_applications_thread_task | null;
  workspace: GetTaskDetailsQuery_task_applications_thread_workspace | null;
}

export interface GetTaskDetailsQuery_task_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: GetTaskDetailsQuery_task_applications_reward | null;
  status: TaskApplicationStatus;
  user: GetTaskDetailsQuery_task_applications_user;
  thread: GetTaskDetailsQuery_task_applications_thread | null;
}

export interface GetTaskDetailsQuery_task_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetTaskDetailsQuery_task_submissions_reward_token_network;
}

export interface GetTaskDetailsQuery_task_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTaskDetailsQuery_task_submissions_reward_payments_payment_paymentMethod_network;
}

export interface GetTaskDetailsQuery_task_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetTaskDetailsQuery_task_submissions_reward_payments_payment_paymentMethod;
}

export interface GetTaskDetailsQuery_task_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetTaskDetailsQuery_task_submissions_reward_payments_user;
  payment: GetTaskDetailsQuery_task_submissions_reward_payments_payment;
}

export interface GetTaskDetailsQuery_task_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetTaskDetailsQuery_task_submissions_reward_token;
  tokenId: string;
  payments: GetTaskDetailsQuery_task_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetTaskDetailsQuery_task_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: GetTaskDetailsQuery_task_submissions_thread_messages_sender;
}

export interface GetTaskDetailsQuery_task_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface GetTaskDetailsQuery_task_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface GetTaskDetailsQuery_task_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: GetTaskDetailsQuery_task_submissions_thread_messages[];
  task: GetTaskDetailsQuery_task_submissions_thread_task | null;
  workspace: GetTaskDetailsQuery_task_submissions_thread_workspace | null;
}

export interface GetTaskDetailsQuery_task_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: GetTaskDetailsQuery_task_submissions_reward | null;
  user: GetTaskDetailsQuery_task_submissions_user;
  approver: GetTaskDetailsQuery_task_submissions_approver | null;
  thread: GetTaskDetailsQuery_task_submissions_thread | null;
}

export interface GetTaskDetailsQuery_task_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTaskDetailsQuery_task_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTaskDetailsQuery_task_nfts_payment_paymentMethod_network;
}

export interface GetTaskDetailsQuery_task_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetTaskDetailsQuery_task_nfts_payment_paymentMethod;
}

export interface GetTaskDetailsQuery_task_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: GetTaskDetailsQuery_task_nfts_payment;
}

export interface GetTaskDetailsQuery_task_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: GetTaskDetailsQuery_task_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface GetTaskDetailsQuery_task_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskDetailsQuery_task_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: GetTaskDetailsQuery_task_thread_messages_sender;
}

export interface GetTaskDetailsQuery_task_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface GetTaskDetailsQuery_task_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface GetTaskDetailsQuery_task_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: GetTaskDetailsQuery_task_thread_messages[];
  task: GetTaskDetailsQuery_task_thread_task | null;
  workspace: GetTaskDetailsQuery_task_thread_workspace | null;
}

export interface GetTaskDetailsQuery_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetTaskDetailsQuery_task_templateTask | null;
  workspaceId: string;
  workspace: GetTaskDetailsQuery_task_workspace;
  parentTaskId: string | null;
  parentTask: GetTaskDetailsQuery_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetTaskDetailsQuery_task_subtasks[];
  tags: GetTaskDetailsQuery_task_tags[];
  skills: GetTaskDetailsQuery_task_skills[];
  assignees: GetTaskDetailsQuery_task_assignees[];
  owners: GetTaskDetailsQuery_task_owners[];
  creator: GetTaskDetailsQuery_task_creator | null;
  rewards: GetTaskDetailsQuery_task_rewards[];
  review: GetTaskDetailsQuery_task_review | null;
  reactions: GetTaskDetailsQuery_task_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: GetTaskDetailsQuery_task_githubPullRequests[];
  githubBranches: GetTaskDetailsQuery_task_githubBranches[];
  githubIssue: GetTaskDetailsQuery_task_githubIssue | null;
  notionPage: GetTaskDetailsQuery_task_notionPage | null;
  applications: GetTaskDetailsQuery_task_applications[];
  submissions: GetTaskDetailsQuery_task_submissions[];
  nfts: GetTaskDetailsQuery_task_nfts[];
  auditLog: GetTaskDetailsQuery_task_auditLog[];
  thread: GetTaskDetailsQuery_task_thread | null;
}

export interface GetTaskDetailsQuery {
  task: GetTaskDetailsQuery_task;
}

export interface GetTaskDetailsQueryVariables {
  taskId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTaskReactionUsersQuery
// ====================================================

export interface GetTaskReactionUsersQuery_task_reactions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTaskReactionUsersQuery_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
  user: GetTaskReactionUsersQuery_task_reactions_user;
}

export interface GetTaskReactionUsersQuery_task {
  __typename: "Task";
  id: Scalar.UUID;
  reactions: GetTaskReactionUsersQuery_task_reactions[];
}

export interface GetTaskReactionUsersQuery {
  task: GetTaskReactionUsersQuery_task;
}

export interface GetTaskReactionUsersQueryVariables {
  taskId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaginatedTasksQuery
// ====================================================

export interface GetPaginatedTasksQuery_paginated_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetPaginatedTasksQuery_paginated_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetPaginatedTasksQuery_paginated_tasks_workspace_options | null;
}

export interface GetPaginatedTasksQuery_paginated_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetPaginatedTasksQuery_paginated_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetPaginatedTasksQuery_paginated_tasks_rewards_token_network;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetPaginatedTasksQuery_paginated_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetPaginatedTasksQuery_paginated_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetPaginatedTasksQuery_paginated_tasks_rewards_payments_user;
  payment: GetPaginatedTasksQuery_paginated_tasks_rewards_payments_payment;
}

export interface GetPaginatedTasksQuery_paginated_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetPaginatedTasksQuery_paginated_tasks_rewards_token;
  tokenId: string;
  payments: GetPaginatedTasksQuery_paginated_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetPaginatedTasksQuery_paginated_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetPaginatedTasksQuery_paginated_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetPaginatedTasksQuery_paginated_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetPaginatedTasksQuery_paginated_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetPaginatedTasksQuery_paginated_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetPaginatedTasksQuery_paginated_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetPaginatedTasksQuery_paginated_tasks_subtasks[];
  tags: GetPaginatedTasksQuery_paginated_tasks_tags[];
  skills: GetPaginatedTasksQuery_paginated_tasks_skills[];
  assignees: GetPaginatedTasksQuery_paginated_tasks_assignees[];
  owners: GetPaginatedTasksQuery_paginated_tasks_owners[];
  creator: GetPaginatedTasksQuery_paginated_tasks_creator | null;
  rewards: GetPaginatedTasksQuery_paginated_tasks_rewards[];
  review: GetPaginatedTasksQuery_paginated_tasks_review | null;
  reactions: GetPaginatedTasksQuery_paginated_tasks_reactions[];
}

export interface GetPaginatedTasksQuery_paginated {
  __typename: "TaskSearchResponse";
  total: number;
  cursor: string | null;
  tasks: GetPaginatedTasksQuery_paginated_tasks[];
}

export interface GetPaginatedTasksQuery {
  paginated: GetPaginatedTasksQuery_paginated;
}

export interface GetPaginatedTasksQueryVariables {
  filter: SearchTasksInput;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTaskCountQuery
// ====================================================

export interface GetTaskCountQuery {
  count: number;
}

export interface GetTaskCountQueryVariables {
  filter: CountTasksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaginatedTasksWithOrganizationQuery
// ====================================================

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_workspace_options | null;
  organization: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_workspace_organization;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_token_network;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_user;
  payment: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments_payment;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_token;
  tokenId: string;
  payments: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_subtasks[];
  tags: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_tags[];
  skills: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_skills[];
  assignees: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_assignees[];
  owners: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_owners[];
  creator: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_creator | null;
  rewards: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_rewards[];
  review: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_review | null;
  reactions: GetPaginatedTasksWithOrganizationQuery_paginated_tasks_reactions[];
}

export interface GetPaginatedTasksWithOrganizationQuery_paginated {
  __typename: "TaskSearchResponse";
  total: number;
  cursor: string | null;
  tasks: GetPaginatedTasksWithOrganizationQuery_paginated_tasks[];
}

export interface GetPaginatedTasksWithOrganizationQuery {
  paginated: GetPaginatedTasksWithOrganizationQuery_paginated;
}

export interface GetPaginatedTasksWithOrganizationQueryVariables {
  filter: SearchTasksInput;
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTasksToPayQuery
// ====================================================

export interface GetTasksToPayQuery_tasks_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetTasksToPayQuery_tasks_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetTasksToPayQuery_tasks_workspace_paymentMethods_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTasksToPayQuery_tasks_workspace_paymentMethods {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTasksToPayQuery_tasks_workspace_paymentMethods_network;
}

export interface GetTasksToPayQuery_tasks_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetTasksToPayQuery_tasks_workspace_options | null;
  paymentMethods: GetTasksToPayQuery_tasks_workspace_paymentMethods[];
}

export interface GetTasksToPayQuery_tasks_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetTasksToPayQuery_tasks_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetTasksToPayQuery_tasks_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetTasksToPayQuery_tasks_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetTasksToPayQuery_tasks_assignees_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  address: string;
}

export interface GetTasksToPayQuery_tasks_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  threepids: GetTasksToPayQuery_tasks_assignees_threepids[];
}

export interface GetTasksToPayQuery_tasks_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTasksToPayQuery_tasks_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTasksToPayQuery_tasks_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTasksToPayQuery_tasks_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetTasksToPayQuery_tasks_rewards_token_network;
}

export interface GetTasksToPayQuery_tasks_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetTasksToPayQuery_tasks_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetTasksToPayQuery_tasks_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetTasksToPayQuery_tasks_rewards_payments_payment_paymentMethod_network;
}

export interface GetTasksToPayQuery_tasks_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetTasksToPayQuery_tasks_rewards_payments_payment_paymentMethod;
}

export interface GetTasksToPayQuery_tasks_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetTasksToPayQuery_tasks_rewards_payments_user;
  payment: GetTasksToPayQuery_tasks_rewards_payments_payment;
}

export interface GetTasksToPayQuery_tasks_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetTasksToPayQuery_tasks_rewards_token;
  tokenId: string;
  payments: GetTasksToPayQuery_tasks_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetTasksToPayQuery_tasks_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetTasksToPayQuery_tasks_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetTasksToPayQuery_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetTasksToPayQuery_tasks_templateTask | null;
  workspaceId: string;
  workspace: GetTasksToPayQuery_tasks_workspace;
  parentTaskId: string | null;
  parentTask: GetTasksToPayQuery_tasks_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetTasksToPayQuery_tasks_subtasks[];
  tags: GetTasksToPayQuery_tasks_tags[];
  skills: GetTasksToPayQuery_tasks_skills[];
  assignees: GetTasksToPayQuery_tasks_assignees[];
  owners: GetTasksToPayQuery_tasks_owners[];
  creator: GetTasksToPayQuery_tasks_creator | null;
  rewards: GetTasksToPayQuery_tasks_rewards[];
  review: GetTasksToPayQuery_tasks_review | null;
  reactions: GetTasksToPayQuery_tasks_reactions[];
}

export interface GetTasksToPayQuery {
  tasks: GetTasksToPayQuery_tasks[];
}

export interface GetTasksToPayQueryVariables {
  input: GetTasksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceIntegrationsQuery
// ====================================================

export interface GetWorkspaceIntegrationsQuery_workspace_integrations {
  __typename: "WorkspaceIntegration";
  id: Scalar.UUID;
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
}

export interface GetWorkspaceIntegrationsQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  integrations: GetWorkspaceIntegrationsQuery_workspace_integrations[];
}

export interface GetWorkspaceIntegrationsQuery {
  workspace: GetWorkspaceIntegrationsQuery_workspace;
}

export interface GetWorkspaceIntegrationsQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInviteQuery
// ====================================================

export interface GetInviteQuery_invite_inviter {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetInviteQuery_invite_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetInviteQuery_invite_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetInviteQuery_invite_workspace_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetInviteQuery_invite_workspace_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetInviteQuery_invite_workspace_tokenGates_token_network;
}

export interface GetInviteQuery_invite_workspace_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: GetInviteQuery_invite_workspace_tokenGates_token;
}

export interface GetInviteQuery_invite_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetInviteQuery_invite_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetInviteQuery_invite_workspace_options | null;
  tokenGates: GetInviteQuery_invite_workspace_tokenGates[];
  organization: GetInviteQuery_invite_workspace_organization;
}

export interface GetInviteQuery_invite_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
}

export interface GetInviteQuery_invite {
  __typename: "Invite";
  id: Scalar.UUID;
  permalink: string;
  inviter: GetInviteQuery_invite_inviter;
  organization: GetInviteQuery_invite_organization | null;
  workspace: GetInviteQuery_invite_workspace | null;
  task: GetInviteQuery_invite_task | null;
  permission: RulePermission;
}

export interface GetInviteQuery {
  invite: GetInviteQuery_invite | null;
}

export interface GetInviteQueryVariables {
  inviteId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaymentNetworksQuery
// ====================================================

export interface GetPaymentNetworksQuery_networks_tokens {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
}

export interface GetPaymentNetworksQuery_networks {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
  tokens: GetPaymentNetworksQuery_networks_tokens[];
}

export interface GetPaymentNetworksQuery {
  networks: GetPaymentNetworksQuery_networks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationGithubReposQuery
// ====================================================

export interface GetOrganizationGithubReposQuery_repos {
  __typename: "GithubRepo";
  id: string;
  name: string;
  organization: string;
  integrationId: Scalar.UUID;
}

export interface GetOrganizationGithubReposQuery {
  repos: GetOrganizationGithubReposQuery_repos[];
}

export interface GetOrganizationGithubReposQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationGithubRepoLabelsQuery
// ====================================================

export interface GetOrganizationGithubRepoLabelsQuery_labels {
  __typename: "GithubLabel";
  id: string;
  name: string;
}

export interface GetOrganizationGithubRepoLabelsQuery {
  labels: GetOrganizationGithubRepoLabelsQuery_labels[];
}

export interface GetOrganizationGithubRepoLabelsQueryVariables {
  repo: string;
  organization: string;
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationDiscordChannelsQuery
// ====================================================

export interface GetOrganizationDiscordChannelsQuery_channels {
  __typename: "DiscordIntegrationChannel";
  id: string;
  name: string;
  integrationId: Scalar.UUID;
  permissions: string[];
}

export interface GetOrganizationDiscordChannelsQuery {
  channels: GetOrganizationDiscordChannelsQuery_channels[] | null;
}

export interface GetOrganizationDiscordChannelsQueryVariables {
  organizationId: Scalar.UUID;
  discordParentChannelId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTrelloBoardsQuery
// ====================================================

export interface GetTrelloBoardsQuery_trelloBoards {
  __typename: "TrelloBoard";
  id: string;
  name: string;
}

export interface GetTrelloBoardsQuery {
  trelloBoards: GetTrelloBoardsQuery_trelloBoards[];
}

export interface GetTrelloBoardsQueryVariables {
  threepidId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNotionDatabasesQuery
// ====================================================

export interface GetNotionDatabasesQuery_notionDatabases {
  __typename: "NotionDatabase";
  id: string;
  name: string;
  integrationId: Scalar.UUID;
}

export interface GetNotionDatabasesQuery {
  notionDatabases: GetNotionDatabasesQuery_notionDatabases[];
}

export interface GetNotionDatabasesQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDiscordGuildMembershipStateQuery
// ====================================================

export interface GetDiscordGuildMembershipStateQuery {
  state: DiscordGuildMembershipState;
}

export interface GetDiscordGuildMembershipStateQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDiscordGuildRolesQuery
// ====================================================

export interface GetDiscordGuildRolesQuery_roles {
  __typename: "DiscordIntegrationRole";
  id: string;
  name: string;
}

export interface GetDiscordGuildRolesQuery {
  roles: GetDiscordGuildRolesQuery_roles[] | null;
}

export interface GetDiscordGuildRolesQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRelevantUsersQuery
// ====================================================

export interface GetRelevantUsersQuery_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetRelevantUsersQuery {
  users: GetRelevantUsersQuery_users[];
}

export interface GetRelevantUsersQueryVariables {
  input: GetRelevantUsersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFundingSessionQuery
// ====================================================

export interface GetFundingSessionQuery_session_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetFundingSessionQuery_session_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetFundingSessionQuery_session_token_network;
}

export interface GetFundingSessionQuery_session_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface GetFundingSessionQuery_session_voters_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface GetFundingSessionQuery_session_voters_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetFundingSessionQuery_session_voters {
  __typename: "FundingSessionVoter";
  weight: number;
  points: number;
  votes: GetFundingSessionQuery_session_voters_votes[];
  user: GetFundingSessionQuery_session_voters_user;
}

export interface GetFundingSessionQuery_session_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetFundingSessionQuery_session_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetFundingSessionQuery_session_workspaces_options | null;
}

export interface GetFundingSessionQuery_session_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetFundingSessionQuery_session_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetFundingSessionQuery_session_rewards_token_network;
}

export interface GetFundingSessionQuery_session_rewards_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
}

export interface GetFundingSessionQuery_session_rewards_task {
  __typename: "Task";
  id: Scalar.UUID;
  parentTask: GetFundingSessionQuery_session_rewards_task_parentTask | null;
}

export interface GetFundingSessionQuery_session_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  token: GetFundingSessionQuery_session_rewards_token;
  task: GetFundingSessionQuery_session_rewards_task;
}

export interface GetFundingSessionQuery_session {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: GetFundingSessionQuery_session_token;
  votes: GetFundingSessionQuery_session_votes[];
  voters: GetFundingSessionQuery_session_voters[];
  workspaces: GetFundingSessionQuery_session_workspaces[];
  rewards: GetFundingSessionQuery_session_rewards[];
}

export interface GetFundingSessionQuery {
  session: GetFundingSessionQuery_session;
}

export interface GetFundingSessionQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGraphNodeQuery
// ====================================================

export interface GetGraphNodeQuery_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetGraphNodeQuery_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetGraphNodeQuery_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetGraphNodeQuery_node_workspace_options | null;
}

export interface GetGraphNodeQuery_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetGraphNodeQuery_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetGraphNodeQuery_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetGraphNodeQuery_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetGraphNodeQuery_node_children_node_workspace_options | null;
}

export interface GetGraphNodeQuery_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetGraphNodeQuery_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetGraphNodeQuery_node_children_node_organization | null;
  workspace: GetGraphNodeQuery_node_children_node_workspace | null;
  skill: GetGraphNodeQuery_node_children_node_skill | null;
}

export interface GetGraphNodeQuery_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetGraphNodeQuery_node_children_node;
}

export interface GetGraphNodeQuery_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetGraphNodeQuery_node_organization | null;
  workspace: GetGraphNodeQuery_node_workspace | null;
  skill: GetGraphNodeQuery_node_skill | null;
  children: GetGraphNodeQuery_node_children[];
}

export interface GetGraphNodeQuery {
  node: GetGraphNodeQuery_node;
}

export interface GetGraphNodeQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGraphNodeBySlugQuery
// ====================================================

export interface GetGraphNodeBySlugQuery_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetGraphNodeBySlugQuery_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetGraphNodeBySlugQuery_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetGraphNodeBySlugQuery_node_workspace_options | null;
}

export interface GetGraphNodeBySlugQuery_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetGraphNodeBySlugQuery_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetGraphNodeBySlugQuery_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetGraphNodeBySlugQuery_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetGraphNodeBySlugQuery_node_children_node_workspace_options | null;
}

export interface GetGraphNodeBySlugQuery_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetGraphNodeBySlugQuery_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetGraphNodeBySlugQuery_node_children_node_organization | null;
  workspace: GetGraphNodeBySlugQuery_node_children_node_workspace | null;
  skill: GetGraphNodeBySlugQuery_node_children_node_skill | null;
}

export interface GetGraphNodeBySlugQuery_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: GetGraphNodeBySlugQuery_node_children_node;
}

export interface GetGraphNodeBySlugQuery_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GetGraphNodeBySlugQuery_node_organization | null;
  workspace: GetGraphNodeBySlugQuery_node_workspace | null;
  skill: GetGraphNodeBySlugQuery_node_skill | null;
  children: GetGraphNodeBySlugQuery_node_children[];
}

export interface GetGraphNodeBySlugQuery {
  node: GetGraphNodeBySlugQuery_node;
}

export interface GetGraphNodeBySlugQueryVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyNotificationsQuery
// ====================================================

export interface MyNotificationsQuery_me_notificationReadMarker {
  __typename: "NotificationReadMarker";
  readAt: Scalar.DateTime;
}

export interface MyNotificationsQuery_me_notifications_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface MyNotificationsQuery_me_notifications_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface MyNotificationsQuery_me_notifications_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: MyNotificationsQuery_me_notifications_task_workspace_options | null;
  organization: MyNotificationsQuery_me_notifications_task_workspace_organization;
}

export interface MyNotificationsQuery_me_notifications_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
  workspace: MyNotificationsQuery_me_notifications_task_workspace;
}

export interface MyNotificationsQuery_me_notifications_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface MyNotificationsQuery_me_notifications {
  __typename: "Notification";
  id: Scalar.UUID;
  message: string;
  archivedAt: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  icon: string | null;
  iconColor: string | null;
  task: MyNotificationsQuery_me_notifications_task | null;
  organization: MyNotificationsQuery_me_notifications_organization | null;
}

export interface MyNotificationsQuery_me {
  __typename: "User";
  id: Scalar.UUID;
  notificationUnreadCount: number;
  notificationReadMarker: MyNotificationsQuery_me_notificationReadMarker | null;
  notifications: MyNotificationsQuery_me_notifications[];
}

export interface MyNotificationsQuery {
  me: MyNotificationsQuery_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyNotificationUnreadCountQuery
// ====================================================

export interface MyNotificationUnreadCountQuery_me {
  __typename: "User";
  id: Scalar.UUID;
  notificationUnreadCount: number;
}

export interface MyNotificationUnreadCountQuery {
  me: MyNotificationUnreadCountQuery_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationRoleDetailsQuery
// ====================================================

export interface GetOrganizationRoleDetailsQuery_organization_roles_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_token_network;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_payment_paymentMethod_network;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_payment_paymentMethod;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_user;
  payment: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments_payment;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_token;
  tokenId: string;
  payments: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: GetOrganizationRoleDetailsQuery_organization_roles_compensation_reward;
}

export interface GetOrganizationRoleDetailsQuery_organization_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: GetOrganizationRoleDetailsQuery_organization_roles_rules[];
  skills: GetOrganizationRoleDetailsQuery_organization_roles_skills[];
  compensation: GetOrganizationRoleDetailsQuery_organization_roles_compensation[];
  commitment: number | null;
  userCount: number | null;
}

export interface GetOrganizationRoleDetailsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  nodeId: string;
  roles: GetOrganizationRoleDetailsQuery_organization_roles[];
}

export interface GetOrganizationRoleDetailsQuery {
  organization: GetOrganizationRoleDetailsQuery_organization;
}

export interface GetOrganizationRoleDetailsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLandingPageGrantsQuery
// ====================================================

export interface GetLandingPageGrantsQuery_node_children_node_organization_paginatedTasks_tasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
  subtaskCount: number;
}

export interface GetLandingPageGrantsQuery_node_children_node_organization_paginatedTasks {
  __typename: "TaskSearchResponse";
  total: number;
  tasks: GetLandingPageGrantsQuery_node_children_node_organization_paginatedTasks_tasks[];
}

export interface GetLandingPageGrantsQuery_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  paginatedTasks: GetLandingPageGrantsQuery_node_children_node_organization_paginatedTasks;
}

export interface GetLandingPageGrantsQuery_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  organization: GetLandingPageGrantsQuery_node_children_node_organization | null;
}

export interface GetLandingPageGrantsQuery_node_children {
  __typename: "GraphEdge";
  node: GetLandingPageGrantsQuery_node_children_node;
}

export interface GetLandingPageGrantsQuery_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  children: GetLandingPageGrantsQuery_node_children[];
}

export interface GetLandingPageGrantsQuery {
  node: GetLandingPageGrantsQuery_node;
}

export interface GetLandingPageGrantsQueryVariables {
  nodeId: Scalar.UUID;
  filter: SearchTasksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspacePaymentMethodsQuery
// ====================================================

export interface GetWorkspacePaymentMethodsQuery_workspace_paymentMethods_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetWorkspacePaymentMethodsQuery_workspace_paymentMethods {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetWorkspacePaymentMethodsQuery_workspace_paymentMethods_network;
}

export interface GetWorkspacePaymentMethodsQuery_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  paymentMethods: GetWorkspacePaymentMethodsQuery_workspace_paymentMethods[];
}

export interface GetWorkspacePaymentMethodsQuery {
  workspace: GetWorkspacePaymentMethodsQuery_workspace;
}

export interface GetWorkspacePaymentMethodsQueryVariables {
  workspaceId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrganizationPaymentMethodsQuery
// ====================================================

export interface GetOrganizationPaymentMethodsQuery_organization_workspaces_paymentMethods_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOrganizationPaymentMethodsQuery_organization_workspaces_paymentMethods {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetOrganizationPaymentMethodsQuery_organization_workspaces_paymentMethods_network;
}

export interface GetOrganizationPaymentMethodsQuery_organization_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  paymentMethods: GetOrganizationPaymentMethodsQuery_organization_workspaces_paymentMethods[];
}

export interface GetOrganizationPaymentMethodsQuery_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  workspaces: GetOrganizationPaymentMethodsQuery_organization_workspaces[];
}

export interface GetOrganizationPaymentMethodsQuery {
  organization: GetOrganizationPaymentMethodsQuery_organization;
}

export interface GetOrganizationPaymentMethodsQueryVariables {
  organizationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRankableUsersQuery
// ====================================================

export interface GetRankableUsersQuery_rankable_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetRankableUsersQuery_rankable {
  __typename: "RankableUser";
  weight: number;
  user: GetRankableUsersQuery_rankable_user;
}

export interface GetRankableUsersQuery {
  rankable: GetRankableUsersQuery_rankable[];
}

export interface GetRankableUsersQueryVariables {
  organizationId?: Scalar.UUID | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRoleWithUsersQuery
// ====================================================

export interface GetRoleWithUsersQuery_role_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface GetRoleWithUsersQuery_role_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetRoleWithUsersQuery_role_compensation_reward_token_network;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetRoleWithUsersQuery_role_compensation_reward_payments_payment_paymentMethod_network;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetRoleWithUsersQuery_role_compensation_reward_payments_payment_paymentMethod;
}

export interface GetRoleWithUsersQuery_role_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetRoleWithUsersQuery_role_compensation_reward_payments_user;
  payment: GetRoleWithUsersQuery_role_compensation_reward_payments_payment;
}

export interface GetRoleWithUsersQuery_role_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetRoleWithUsersQuery_role_compensation_reward_token;
  tokenId: string;
  payments: GetRoleWithUsersQuery_role_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetRoleWithUsersQuery_role_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: GetRoleWithUsersQuery_role_compensation_reward;
}

export interface GetRoleWithUsersQuery_role_users {
  __typename: "User";
  id: Scalar.UUID;
}

export interface GetRoleWithUsersQuery_role {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: GetRoleWithUsersQuery_role_rules[];
  skills: GetRoleWithUsersQuery_role_skills[];
  compensation: GetRoleWithUsersQuery_role_compensation[];
  commitment: number | null;
  userCount: number | null;
  users: GetRoleWithUsersQuery_role_users[];
}

export interface GetRoleWithUsersQuery {
  role: GetRoleWithUsersQuery_role;
}

export interface GetRoleWithUsersQueryVariables {
  roleId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOpenRolesQuery
// ====================================================

export interface GetOpenRolesQuery_roles_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetOpenRolesQuery_roles_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOpenRolesQuery_roles_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetOpenRolesQuery_roles_compensation_reward_token_network;
}

export interface GetOpenRolesQuery_roles_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOpenRolesQuery_roles_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetOpenRolesQuery_roles_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetOpenRolesQuery_roles_compensation_reward_payments_payment_paymentMethod_network;
}

export interface GetOpenRolesQuery_roles_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetOpenRolesQuery_roles_compensation_reward_payments_payment_paymentMethod;
}

export interface GetOpenRolesQuery_roles_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetOpenRolesQuery_roles_compensation_reward_payments_user;
  payment: GetOpenRolesQuery_roles_compensation_reward_payments_payment;
}

export interface GetOpenRolesQuery_roles_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetOpenRolesQuery_roles_compensation_reward_token;
  tokenId: string;
  payments: GetOpenRolesQuery_roles_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetOpenRolesQuery_roles_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: GetOpenRolesQuery_roles_compensation_reward;
}

export interface GetOpenRolesQuery_roles_node_parents_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetOpenRolesQuery_roles_node_parents_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  organization: GetOpenRolesQuery_roles_node_parents_node_organization | null;
}

export interface GetOpenRolesQuery_roles_node_parents {
  __typename: "GraphEdge";
  node: GetOpenRolesQuery_roles_node_parents_node;
}

export interface GetOpenRolesQuery_roles_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  parents: GetOpenRolesQuery_roles_node_parents[];
}

export interface GetOpenRolesQuery_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  commitment: number | null;
  skills: GetOpenRolesQuery_roles_skills[];
  compensation: GetOpenRolesQuery_roles_compensation[];
  node: GetOpenRolesQuery_roles_node;
}

export interface GetOpenRolesQuery {
  roles: GetOpenRolesQuery_roles[];
}

export interface GetOpenRolesQueryVariables {
  limit: number;
  skillIds?: Scalar.UUID[] | null;
  commitments?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSkillsQuery
// ====================================================

export interface GetSkillsQuery_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetSkillsQuery {
  skills: GetSkillsQuery_skills[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBookmarksQuery
// ====================================================

export interface GetBookmarksQuery_user_bookmarks_task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetBookmarksQuery_user_bookmarks_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetBookmarksQuery_user_bookmarks_task_workspace_options | null;
}

export interface GetBookmarksQuery_user_bookmarks_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GetBookmarksQuery_user_bookmarks_task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: GetBookmarksQuery_user_bookmarks_task_rewards_token_network;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: GetBookmarksQuery_user_bookmarks_task_rewards_payments_payment_paymentMethod_network;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: GetBookmarksQuery_user_bookmarks_task_rewards_payments_payment_paymentMethod;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: GetBookmarksQuery_user_bookmarks_task_rewards_payments_user;
  payment: GetBookmarksQuery_user_bookmarks_task_rewards_payments_payment;
}

export interface GetBookmarksQuery_user_bookmarks_task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: GetBookmarksQuery_user_bookmarks_task_rewards_token;
  tokenId: string;
  payments: GetBookmarksQuery_user_bookmarks_task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface GetBookmarksQuery_user_bookmarks_task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface GetBookmarksQuery_user_bookmarks_task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface GetBookmarksQuery_user_bookmarks_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: GetBookmarksQuery_user_bookmarks_task_templateTask | null;
  workspaceId: string;
  workspace: GetBookmarksQuery_user_bookmarks_task_workspace;
  parentTaskId: string | null;
  parentTask: GetBookmarksQuery_user_bookmarks_task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: GetBookmarksQuery_user_bookmarks_task_subtasks[];
  tags: GetBookmarksQuery_user_bookmarks_task_tags[];
  skills: GetBookmarksQuery_user_bookmarks_task_skills[];
  assignees: GetBookmarksQuery_user_bookmarks_task_assignees[];
  owners: GetBookmarksQuery_user_bookmarks_task_owners[];
  creator: GetBookmarksQuery_user_bookmarks_task_creator | null;
  rewards: GetBookmarksQuery_user_bookmarks_task_rewards[];
  review: GetBookmarksQuery_user_bookmarks_task_review | null;
  reactions: GetBookmarksQuery_user_bookmarks_task_reactions[];
}

export interface GetBookmarksQuery_user_bookmarks {
  __typename: "UserTaskBookmark";
  task: GetBookmarksQuery_user_bookmarks_task;
  userId: string;
  taskId: string;
}

export interface GetBookmarksQuery_user {
  __typename: "User";
  id: Scalar.UUID;
  bookmarks: GetBookmarksQuery_user_bookmarks[];
}

export interface GetBookmarksQuery {
  user: GetBookmarksQuery_user;
}

export interface GetBookmarksQueryVariables {
  id: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersByScoreQuery
// ====================================================

export interface GetUsersByScoreQuery_getUsersByScore_users {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  reputationScore: number;
}

export interface GetUsersByScoreQuery_getUsersByScore {
  __typename: "PaginatedUsers";
  users: GetUsersByScoreQuery_getUsersByScore_users[] | null;
  total: number;
  hasMore: boolean;
}

export interface GetUsersByScoreQuery {
  getUsersByScore: GetUsersByScoreQuery_getUsersByScore;
}

export interface GetUsersByScoreQueryVariables {
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceSectionDetailsQuery
// ====================================================

export interface GetWorkspaceSectionDetailsQuery_section_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface GetWorkspaceSectionDetailsQuery_section_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: GetWorkspaceSectionDetailsQuery_section_taskViews_filters_dueDate | null;
}

export interface GetWorkspaceSectionDetailsQuery_section_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface GetWorkspaceSectionDetailsQuery_section_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: GetWorkspaceSectionDetailsQuery_section_taskViews_filters[];
  sortBys: GetWorkspaceSectionDetailsQuery_section_taskViews_sortBys[];
}

export interface GetWorkspaceSectionDetailsQuery_section_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GetWorkspaceSectionDetailsQuery_section_workspaces_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface GetWorkspaceSectionDetailsQuery_section_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GetWorkspaceSectionDetailsQuery_section_workspaces_options | null;
  taskTags: GetWorkspaceSectionDetailsQuery_section_workspaces_taskTags[];
}

export interface GetWorkspaceSectionDetailsQuery_section {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
  taskViews: GetWorkspaceSectionDetailsQuery_section_taskViews[];
  workspaces: GetWorkspaceSectionDetailsQuery_section_workspaces[];
}

export interface GetWorkspaceSectionDetailsQuery {
  section: GetWorkspaceSectionDetailsQuery_section;
}

export interface GetWorkspaceSectionDetailsQueryVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EntityDetail
// ====================================================

export interface EntityDetail {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuditLogEvent
// ====================================================

export interface AuditLogEvent_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface AuditLogEvent {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: AuditLogEvent_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationWithTokens
// ====================================================

export interface OrganizationWithTokens_tokens_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface OrganizationWithTokens_tokens {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: OrganizationWithTokens_tokens_network;
}

export interface OrganizationWithTokens {
  __typename: "Organization";
  id: Scalar.UUID;
  tokens: OrganizationWithTokens_tokens[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationTag
// ====================================================

export interface OrganizationTag {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskSection
// ====================================================

export interface TaskSection {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationIntegration
// ====================================================

export interface OrganizationIntegration {
  __typename: "OrganizationIntegration";
  id: Scalar.UUID;
  type: OrganizationIntegrationType;
  config: Scalar.JSONObject | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationWithIntegrations
// ====================================================

export interface OrganizationWithIntegrations_integrations {
  __typename: "OrganizationIntegration";
  id: Scalar.UUID;
  type: OrganizationIntegrationType;
  config: Scalar.JSONObject | null;
}

export interface OrganizationWithIntegrations {
  __typename: "Organization";
  id: Scalar.UUID;
  integrations: OrganizationWithIntegrations_integrations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceIntegration
// ====================================================

export interface WorkspaceIntegration {
  __typename: "WorkspaceIntegration";
  id: Scalar.UUID;
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceTokenGate
// ====================================================

export interface WorkspaceTokenGate_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface WorkspaceTokenGate_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: WorkspaceTokenGate_token_network;
}

export interface WorkspaceTokenGate {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: WorkspaceTokenGate_token;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Invite
// ====================================================

export interface Invite_inviter {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface Invite_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface Invite_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface Invite_workspace_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface Invite_workspace_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: Invite_workspace_tokenGates_token_network;
}

export interface Invite_workspace_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: Invite_workspace_tokenGates_token;
}

export interface Invite_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface Invite_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: Invite_workspace_options | null;
  tokenGates: Invite_workspace_tokenGates[];
  organization: Invite_workspace_organization;
}

export interface Invite_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
}

export interface Invite {
  __typename: "Invite";
  id: Scalar.UUID;
  permalink: string;
  inviter: Invite_inviter;
  organization: Invite_organization | null;
  workspace: Invite_workspace | null;
  task: Invite_task | null;
  permission: RulePermission;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceDetails
// ====================================================

export interface WorkspaceDetails_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface WorkspaceDetails_tokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface WorkspaceDetails_tokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: WorkspaceDetails_tokenGates_token_network;
}

export interface WorkspaceDetails_tokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: WorkspaceDetails_tokenGates_token;
}

export interface WorkspaceDetails_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface WorkspaceDetails_taskSections {
  __typename: "TaskSection";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
  workspaceId: string;
}

export interface WorkspaceDetails_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface WorkspaceDetails_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: WorkspaceDetails_taskViews_filters_dueDate | null;
}

export interface WorkspaceDetails_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface WorkspaceDetails_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: WorkspaceDetails_taskViews_filters[];
  sortBys: WorkspaceDetails_taskViews_sortBys[];
}

export interface WorkspaceDetails_parent_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface WorkspaceDetails_parent {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: WorkspaceDetails_parent_options | null;
}

export interface WorkspaceDetails_activityThread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface WorkspaceDetails_activityThread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: WorkspaceDetails_activityThread_messages_sender;
}

export interface WorkspaceDetails_activityThread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface WorkspaceDetails_activityThread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface WorkspaceDetails_activityThread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: WorkspaceDetails_activityThread_messages[];
  task: WorkspaceDetails_activityThread_task | null;
  workspace: WorkspaceDetails_activityThread_workspace | null;
}

export interface WorkspaceDetails_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface WorkspaceDetails {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: WorkspaceDetails_options | null;
  tokenGates: WorkspaceDetails_tokenGates[];
  organization: WorkspaceDetails_organization;
  taskSections: WorkspaceDetails_taskSections[];
  taskViews: WorkspaceDetails_taskViews[];
  parent: WorkspaceDetails_parent | null;
  activityThread: WorkspaceDetails_activityThread | null;
  contributors: WorkspaceDetails_contributors[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskReview
// ====================================================

export interface TaskReview {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GithubPullRequest
// ====================================================

export interface GithubPullRequest {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GithubIssue
// ====================================================

export interface GithubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GithubBranch
// ====================================================

export interface GithubBranch {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GithubRepo
// ====================================================

export interface GithubRepo {
  __typename: "GithubRepo";
  id: string;
  name: string;
  organization: string;
  integrationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DiscordIntegrationChannel
// ====================================================

export interface DiscordIntegrationChannel {
  __typename: "DiscordIntegrationChannel";
  id: string;
  name: string;
  integrationId: Scalar.UUID;
  permissions: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DiscordIntegrationRole
// ====================================================

export interface DiscordIntegrationRole {
  __typename: "DiscordIntegrationRole";
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskApplication
// ====================================================

export interface TaskApplication_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskApplication_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskApplication_reward_token_network;
}

export interface TaskApplication_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskApplication_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskApplication_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskApplication_reward_payments_payment_paymentMethod_network;
}

export interface TaskApplication_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskApplication_reward_payments_payment_paymentMethod;
}

export interface TaskApplication_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskApplication_reward_payments_user;
  payment: TaskApplication_reward_payments_payment;
}

export interface TaskApplication_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskApplication_reward_token;
  tokenId: string;
  payments: TaskApplication_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface TaskApplication_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface TaskApplication_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: TaskApplication_user_details[];
}

export interface TaskApplication_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskApplication_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: TaskApplication_thread_messages_sender;
}

export interface TaskApplication_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface TaskApplication_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface TaskApplication_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: TaskApplication_thread_messages[];
  task: TaskApplication_thread_task | null;
  workspace: TaskApplication_thread_workspace | null;
}

export interface TaskApplication {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: TaskApplication_reward | null;
  status: TaskApplicationStatus;
  user: TaskApplication_user;
  thread: TaskApplication_thread | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskSubmission
// ====================================================

export interface TaskSubmission_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskSubmission_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskSubmission_reward_token_network;
}

export interface TaskSubmission_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskSubmission_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskSubmission_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskSubmission_reward_payments_payment_paymentMethod_network;
}

export interface TaskSubmission_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskSubmission_reward_payments_payment_paymentMethod;
}

export interface TaskSubmission_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskSubmission_reward_payments_user;
  payment: TaskSubmission_reward_payments_payment;
}

export interface TaskSubmission_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskSubmission_reward_token;
  tokenId: string;
  payments: TaskSubmission_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface TaskSubmission_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskSubmission_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskSubmission_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskSubmission_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: TaskSubmission_thread_messages_sender;
}

export interface TaskSubmission_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface TaskSubmission_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface TaskSubmission_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: TaskSubmission_thread_messages[];
  task: TaskSubmission_thread_task | null;
  workspace: TaskSubmission_thread_workspace | null;
}

export interface TaskSubmission {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: TaskSubmission_reward | null;
  user: TaskSubmission_user;
  approver: TaskSubmission_approver | null;
  thread: TaskSubmission_thread | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskReaction
// ====================================================

export interface TaskReaction {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Task
// ====================================================

export interface Task_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface Task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface Task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: Task_workspace_options | null;
}

export interface Task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface Task_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface Task_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface Task_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface Task_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface Task_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface Task_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface Task_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface Task_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: Task_rewards_token_network;
}

export interface Task_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface Task_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface Task_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: Task_rewards_payments_payment_paymentMethod_network;
}

export interface Task_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: Task_rewards_payments_payment_paymentMethod;
}

export interface Task_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: Task_rewards_payments_user;
  payment: Task_rewards_payments_payment;
}

export interface Task_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: Task_rewards_token;
  tokenId: string;
  payments: Task_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface Task_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface Task_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface Task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: Task_templateTask | null;
  workspaceId: string;
  workspace: Task_workspace;
  parentTaskId: string | null;
  parentTask: Task_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: Task_subtasks[];
  tags: Task_tags[];
  skills: Task_skills[];
  assignees: Task_assignees[];
  owners: Task_owners[];
  creator: Task_creator | null;
  rewards: Task_rewards[];
  review: Task_review | null;
  reactions: Task_reactions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskWithOrganization
// ====================================================

export interface TaskWithOrganization_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface TaskWithOrganization_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface TaskWithOrganization_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskWithOrganization_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: TaskWithOrganization_workspace_options | null;
  organization: TaskWithOrganization_workspace_organization;
}

export interface TaskWithOrganization_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface TaskWithOrganization_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface TaskWithOrganization_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface TaskWithOrganization_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface TaskWithOrganization_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskWithOrganization_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskWithOrganization_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskWithOrganization_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskWithOrganization_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskWithOrganization_rewards_token_network;
}

export interface TaskWithOrganization_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskWithOrganization_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskWithOrganization_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskWithOrganization_rewards_payments_payment_paymentMethod_network;
}

export interface TaskWithOrganization_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskWithOrganization_rewards_payments_payment_paymentMethod;
}

export interface TaskWithOrganization_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskWithOrganization_rewards_payments_user;
  payment: TaskWithOrganization_rewards_payments_payment;
}

export interface TaskWithOrganization_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskWithOrganization_rewards_token;
  tokenId: string;
  payments: TaskWithOrganization_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface TaskWithOrganization_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface TaskWithOrganization_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface TaskWithOrganization {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: TaskWithOrganization_templateTask | null;
  workspaceId: string;
  workspace: TaskWithOrganization_workspace;
  parentTaskId: string | null;
  parentTask: TaskWithOrganization_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: TaskWithOrganization_subtasks[];
  tags: TaskWithOrganization_tags[];
  skills: TaskWithOrganization_skills[];
  assignees: TaskWithOrganization_assignees[];
  owners: TaskWithOrganization_owners[];
  creator: TaskWithOrganization_creator | null;
  rewards: TaskWithOrganization_rewards[];
  review: TaskWithOrganization_review | null;
  reactions: TaskWithOrganization_reactions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskNFT
// ====================================================

export interface TaskNFT_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskNFT_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskNFT_payment_paymentMethod_network;
}

export interface TaskNFT_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskNFT_payment_paymentMethod;
}

export interface TaskNFT {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: TaskNFT_payment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskDetails
// ====================================================

export interface TaskDetails_templateTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface TaskDetails_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface TaskDetails_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: TaskDetails_workspace_options | null;
  organization: TaskDetails_workspace_organization;
}

export interface TaskDetails_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
}

export interface TaskDetails_subtasks {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

export interface TaskDetails_tags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface TaskDetails_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface TaskDetails_assignees {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_owners {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_creator {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskDetails_rewards_token_network;
}

export interface TaskDetails_rewards_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_rewards_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_rewards_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskDetails_rewards_payments_payment_paymentMethod_network;
}

export interface TaskDetails_rewards_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskDetails_rewards_payments_payment_paymentMethod;
}

export interface TaskDetails_rewards_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskDetails_rewards_payments_user;
  payment: TaskDetails_rewards_payments_payment;
}

export interface TaskDetails_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskDetails_rewards_token;
  tokenId: string;
  payments: TaskDetails_rewards_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface TaskDetails_review {
  __typename: "TaskReview";
  id: Scalar.UUID;
  message: string | null;
  rating: number | null;
  createdAt: Scalar.DateTime;
}

export interface TaskDetails_reactions {
  __typename: "TaskReaction";
  id: Scalar.UUID;
  userId: string;
  reaction: string;
}

export interface TaskDetails_githubPullRequests {
  __typename: "GithubPullRequest";
  id: Scalar.UUID;
  title: string;
  link: string;
  status: GithubPullRequestStatus;
  number: number;
  branchName: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
}

export interface TaskDetails_githubBranches {
  __typename: "GithubBranch";
  id: Scalar.UUID;
  name: string;
  link: string;
  repo: string;
  organization: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
}

export interface TaskDetails_githubIssue {
  __typename: "GithubIssue";
  id: Scalar.UUID;
  number: number;
  link: string | null;
}

export interface TaskDetails_notionPage {
  __typename: "NotionPage";
  permalink: string;
}

export interface TaskDetails_applications_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_applications_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskDetails_applications_reward_token_network;
}

export interface TaskDetails_applications_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_applications_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_applications_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskDetails_applications_reward_payments_payment_paymentMethod_network;
}

export interface TaskDetails_applications_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskDetails_applications_reward_payments_payment_paymentMethod;
}

export interface TaskDetails_applications_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskDetails_applications_reward_payments_user;
  payment: TaskDetails_applications_reward_payments_payment;
}

export interface TaskDetails_applications_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskDetails_applications_reward_token;
  tokenId: string;
  payments: TaskDetails_applications_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface TaskDetails_applications_user_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface TaskDetails_applications_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  details: TaskDetails_applications_user_details[];
}

export interface TaskDetails_applications_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_applications_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: TaskDetails_applications_thread_messages_sender;
}

export interface TaskDetails_applications_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface TaskDetails_applications_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface TaskDetails_applications_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: TaskDetails_applications_thread_messages[];
  task: TaskDetails_applications_thread_task | null;
  workspace: TaskDetails_applications_thread_workspace | null;
}

export interface TaskDetails_applications {
  __typename: "TaskApplication";
  id: Scalar.UUID;
  message: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  userId: string;
  discordThreadUrl: string | null;
  reward: TaskDetails_applications_reward | null;
  status: TaskApplicationStatus;
  user: TaskDetails_applications_user;
  thread: TaskDetails_applications_thread | null;
}

export interface TaskDetails_submissions_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_submissions_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskDetails_submissions_reward_token_network;
}

export interface TaskDetails_submissions_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_submissions_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_submissions_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskDetails_submissions_reward_payments_payment_paymentMethod_network;
}

export interface TaskDetails_submissions_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskDetails_submissions_reward_payments_payment_paymentMethod;
}

export interface TaskDetails_submissions_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskDetails_submissions_reward_payments_user;
  payment: TaskDetails_submissions_reward_payments_payment;
}

export interface TaskDetails_submissions_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskDetails_submissions_reward_token;
  tokenId: string;
  payments: TaskDetails_submissions_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface TaskDetails_submissions_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_submissions_approver {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_submissions_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_submissions_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: TaskDetails_submissions_thread_messages_sender;
}

export interface TaskDetails_submissions_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface TaskDetails_submissions_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface TaskDetails_submissions_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: TaskDetails_submissions_thread_messages[];
  task: TaskDetails_submissions_thread_task | null;
  workspace: TaskDetails_submissions_thread_workspace | null;
}

export interface TaskDetails_submissions {
  __typename: "TaskSubmission";
  id: Scalar.UUID;
  content: string;
  createdAt: Scalar.DateTime;
  updatedAt: Scalar.DateTime;
  taskId: string;
  userId: string;
  status: TaskSubmissionStatus;
  reward: TaskDetails_submissions_reward | null;
  user: TaskDetails_submissions_user;
  approver: TaskDetails_submissions_approver | null;
  thread: TaskDetails_submissions_thread | null;
}

export interface TaskDetails_nfts_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskDetails_nfts_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskDetails_nfts_payment_paymentMethod_network;
}

export interface TaskDetails_nfts_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskDetails_nfts_payment_paymentMethod;
}

export interface TaskDetails_nfts {
  __typename: "TaskNFT";
  id: Scalar.UUID;
  tokenId: number;
  createdAt: Scalar.DateTime;
  contractAddress: string;
  explorerUrl: string;
  payment: TaskDetails_nfts_payment;
}

export interface TaskDetails_auditLog_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_auditLog {
  __typename: "AuditLogEvent";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  user: TaskDetails_auditLog_user | null;
  sessionId: Scalar.UUID | null;
  diff: Scalar.JSONObject[];
}

export interface TaskDetails_thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskDetails_thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: TaskDetails_thread_messages_sender;
}

export interface TaskDetails_thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface TaskDetails_thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface TaskDetails_thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: TaskDetails_thread_messages[];
  task: TaskDetails_thread_task | null;
  workspace: TaskDetails_thread_workspace | null;
}

export interface TaskDetails {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  description: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  sortKey: string;
  storyPoints: number | null;
  dueDate: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  doneAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  template: boolean;
  templateTaskId: string | null;
  templateTask: TaskDetails_templateTask | null;
  workspaceId: string;
  workspace: TaskDetails_workspace;
  parentTaskId: string | null;
  parentTask: TaskDetails_parentTask | null;
  sectionId: string | null;
  number: number;
  gating: TaskGatingType;
  openToBids: boolean;
  submissionCount: number;
  applicationCount: number;
  maxWinners: number | null;
  subtasks: TaskDetails_subtasks[];
  tags: TaskDetails_tags[];
  skills: TaskDetails_skills[];
  assignees: TaskDetails_assignees[];
  owners: TaskDetails_owners[];
  creator: TaskDetails_creator | null;
  rewards: TaskDetails_rewards[];
  review: TaskDetails_review | null;
  reactions: TaskDetails_reactions[];
  featured: boolean;
  gitBranchName: string;
  applicationLink: string | null;
  applicationTemplate: string | null;
  submissionTemplate: string | null;
  permalink: string;
  githubPullRequests: TaskDetails_githubPullRequests[];
  githubBranches: TaskDetails_githubBranches[];
  githubIssue: TaskDetails_githubIssue | null;
  notionPage: TaskDetails_notionPage | null;
  applications: TaskDetails_applications[];
  submissions: TaskDetails_submissions[];
  nfts: TaskDetails_nfts[];
  auditLog: TaskDetails_auditLog[];
  thread: TaskDetails_thread | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserProfile
// ====================================================

export interface UserProfile_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UserProfile_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
}

export interface UserProfile_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface UserProfile {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: UserProfile_details[];
  threepids: UserProfile_threepids[];
  featuredWork: UserProfile_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskGatingDefault
// ====================================================

export interface TaskGatingDefault_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface TaskGatingDefault_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface TaskGatingDefault {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: TaskGatingDefault_claimRoles[];
  applyRoles: TaskGatingDefault_applyRoles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserDetails
// ====================================================

export interface UserDetails_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface UserDetails_threepids {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
  id: string;
}

export interface UserDetails_featuredWork {
  __typename: "FeaturedWork";
  imageUrl: string | null;
  link: string;
  title: string;
  description: string;
}

export interface UserDetails_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UserDetails_prompts {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

export interface UserDetails_taskGatingDefaults_claimRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface UserDetails_taskGatingDefaults_applyRoles {
  __typename: "Role";
  id: Scalar.UUID;
}

export interface UserDetails_taskGatingDefaults {
  __typename: "TaskGatingDefault";
  id: Scalar.UUID;
  userId: string;
  workspaceId: string;
  type: TaskGatingType;
  claimRoles: UserDetails_taskGatingDefaults_claimRoles[];
  applyRoles: UserDetails_taskGatingDefaults_applyRoles[];
}

export interface UserDetails_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface UserDetails_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: UserDetails_taskViews_filters_dueDate | null;
}

export interface UserDetails_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface UserDetails_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: UserDetails_taskViews_filters[];
  sortBys: UserDetails_taskViews_sortBys[];
}

export interface UserDetails_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface UserDetails_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface UserDetails_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: UserDetails_node_children_node_workspace_options | null;
}

export interface UserDetails_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface UserDetails_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: UserDetails_node_children_node_organization | null;
  workspace: UserDetails_node_children_node_workspace | null;
  skill: UserDetails_node_children_node_skill | null;
}

export interface UserDetails_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: UserDetails_node_children_node;
}

export interface UserDetails_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: UserDetails_node_children[];
}

export interface UserDetails_reputationStakes {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface UserDetails_reputationSupporters {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

export interface UserDetails {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
  bio: string | null;
  details: UserDetails_details[];
  threepids: UserDetails_threepids[];
  featuredWork: UserDetails_featuredWork[] | null;
  reputationScore: number;
  revenueShare: number;
  createdAt: Scalar.DateTime;
  skills: UserDetails_skills[];
  prompts: UserDetails_prompts[];
  taskGatingDefaults: UserDetails_taskGatingDefaults[];
  taskViews: UserDetails_taskViews[];
  openTasksNotifications: boolean;
  node: UserDetails_node;
  reputationStakes: UserDetails_reputationStakes[];
  reputationSupporters: UserDetails_reputationSupporters[];
  reputationCredits: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationDetails
// ====================================================

export interface OrganizationDetails_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  mintTaskNFTs: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface OrganizationDetails_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface OrganizationDetails_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: OrganizationDetails_workspaces_options | null;
}

export interface OrganizationDetails_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface OrganizationDetails_tags {
  __typename: "OrganizationTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
}

export interface OrganizationDetails_details {
  __typename: "EntityDetail";
  id: Scalar.UUID;
  type: EntityDetailType;
  value: string;
}

export interface OrganizationDetails_workspaceTokenGates_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface OrganizationDetails_workspaceTokenGates_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: OrganizationDetails_workspaceTokenGates_token_network;
}

export interface OrganizationDetails_workspaceTokenGates {
  __typename: "WorkspaceTokenGate";
  id: Scalar.UUID;
  role: WorkspaceRole;
  workspaceId: string;
  token: OrganizationDetails_workspaceTokenGates_token;
}

export interface OrganizationDetails_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface OrganizationDetails_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: OrganizationDetails_taskViews_filters_dueDate | null;
}

export interface OrganizationDetails_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface OrganizationDetails_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: OrganizationDetails_taskViews_filters[];
  sortBys: OrganizationDetails_taskViews_sortBys[];
}

export interface OrganizationDetails_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface OrganizationDetails_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: OrganizationDetails_fundingSessions_token_network;
}

export interface OrganizationDetails_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: OrganizationDetails_fundingSessions_token;
}

export interface OrganizationDetails_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

export interface OrganizationDetails {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  options: OrganizationDetails_options | null;
  workspaceCount: number;
  workspaces: OrganizationDetails_workspaces[];
  workspaceSections: OrganizationDetails_workspaceSections[];
  tags: OrganizationDetails_tags[];
  details: OrganizationDetails_details[];
  workspaceTokenGates: OrganizationDetails_workspaceTokenGates[];
  taskViews: OrganizationDetails_taskViews[];
  fundingSessions: OrganizationDetails_fundingSessions[];
  node: OrganizationDetails_node;
  timeToPayment: number | null;
  totalPaid: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FundingVote
// ====================================================

export interface FundingVote {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FundingSession
// ====================================================

export interface FundingSession_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface FundingSession_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: FundingSession_token_network;
}

export interface FundingSession {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: FundingSession_token;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FundingSessionDetails
// ====================================================

export interface FundingSessionDetails_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface FundingSessionDetails_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: FundingSessionDetails_token_network;
}

export interface FundingSessionDetails_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface FundingSessionDetails_voters_votes {
  __typename: "FundingVote";
  id: Scalar.UUID;
  taskId: string;
  points: number;
  userId: string;
  sessionId: string;
}

export interface FundingSessionDetails_voters_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface FundingSessionDetails_voters {
  __typename: "FundingSessionVoter";
  weight: number;
  points: number;
  votes: FundingSessionDetails_voters_votes[];
  user: FundingSessionDetails_voters_user;
}

export interface FundingSessionDetails_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface FundingSessionDetails_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: FundingSessionDetails_workspaces_options | null;
}

export interface FundingSessionDetails_rewards_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface FundingSessionDetails_rewards_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: FundingSessionDetails_rewards_token_network;
}

export interface FundingSessionDetails_rewards_task_parentTask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
}

export interface FundingSessionDetails_rewards_task {
  __typename: "Task";
  id: Scalar.UUID;
  parentTask: FundingSessionDetails_rewards_task_parentTask | null;
}

export interface FundingSessionDetails_rewards {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  token: FundingSessionDetails_rewards_token;
  task: FundingSessionDetails_rewards_task;
}

export interface FundingSessionDetails {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: FundingSessionDetails_token;
  votes: FundingSessionDetails_votes[];
  voters: FundingSessionDetails_voters[];
  workspaces: FundingSessionDetails_workspaces[];
  rewards: FundingSessionDetails_rewards[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GraphNode
// ====================================================

export interface GraphNode {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GraphNodeDetails
// ====================================================

export interface GraphNodeDetails_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GraphNodeDetails_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GraphNodeDetails_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GraphNodeDetails_workspace_options | null;
}

export interface GraphNodeDetails_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GraphNodeDetails {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GraphNodeDetails_organization | null;
  workspace: GraphNodeDetails_workspace | null;
  skill: GraphNodeDetails_skill | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GraphNodeChildrenDetails
// ====================================================

export interface GraphNodeChildrenDetails_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface GraphNodeChildrenDetails_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface GraphNodeChildrenDetails_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: GraphNodeChildrenDetails_children_node_workspace_options | null;
}

export interface GraphNodeChildrenDetails_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface GraphNodeChildrenDetails_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: GraphNodeChildrenDetails_children_node_organization | null;
  workspace: GraphNodeChildrenDetails_children_node_workspace | null;
  skill: GraphNodeChildrenDetails_children_node_skill | null;
}

export interface GraphNodeChildrenDetails_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: GraphNodeChildrenDetails_children_node;
}

export interface GraphNodeChildrenDetails {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  children: GraphNodeChildrenDetails_children[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NotionDatabase
// ====================================================

export interface NotionDatabase {
  __typename: "NotionDatabase";
  id: string;
  name: string;
  integrationId: Scalar.UUID;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Notification
// ====================================================

export interface Notification_task_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface Notification_task_workspace_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface Notification_task_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: Notification_task_workspace_options | null;
  organization: Notification_task_workspace_organization;
}

export interface Notification_task {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  permalink: string;
  workspace: Notification_task_workspace;
}

export interface Notification_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface Notification {
  __typename: "Notification";
  id: Scalar.UUID;
  message: string;
  archivedAt: Scalar.DateTime | null;
  createdAt: Scalar.DateTime;
  icon: string | null;
  iconColor: string | null;
  task: Notification_task | null;
  organization: Notification_organization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Organization
// ====================================================

export interface Organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationRoles
// ====================================================

export interface OrganizationRoles_roles_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface OrganizationRoles_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: OrganizationRoles_roles_rules[];
}

export interface OrganizationRoles {
  __typename: "Organization";
  id: Scalar.UUID;
  nodeId: string;
  roles: OrganizationRoles_roles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationRoleDetails
// ====================================================

export interface OrganizationRoleDetails_roles_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface OrganizationRoleDetails_roles_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface OrganizationRoleDetails_roles_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface OrganizationRoleDetails_roles_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: OrganizationRoleDetails_roles_compensation_reward_token_network;
}

export interface OrganizationRoleDetails_roles_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface OrganizationRoleDetails_roles_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface OrganizationRoleDetails_roles_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: OrganizationRoleDetails_roles_compensation_reward_payments_payment_paymentMethod_network;
}

export interface OrganizationRoleDetails_roles_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: OrganizationRoleDetails_roles_compensation_reward_payments_payment_paymentMethod;
}

export interface OrganizationRoleDetails_roles_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: OrganizationRoleDetails_roles_compensation_reward_payments_user;
  payment: OrganizationRoleDetails_roles_compensation_reward_payments_payment;
}

export interface OrganizationRoleDetails_roles_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: OrganizationRoleDetails_roles_compensation_reward_token;
  tokenId: string;
  payments: OrganizationRoleDetails_roles_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface OrganizationRoleDetails_roles_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: OrganizationRoleDetails_roles_compensation_reward;
}

export interface OrganizationRoleDetails_roles {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: OrganizationRoleDetails_roles_rules[];
  skills: OrganizationRoleDetails_roles_skills[];
  compensation: OrganizationRoleDetails_roles_compensation[];
  commitment: number | null;
  userCount: number | null;
}

export interface OrganizationRoleDetails {
  __typename: "Organization";
  id: Scalar.UUID;
  nodeId: string;
  roles: OrganizationRoleDetails_roles[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrganizationWorkspaces
// ====================================================

export interface OrganizationWorkspaces_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface OrganizationWorkspaces_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface OrganizationWorkspaces_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: OrganizationWorkspaces_node_workspace_options | null;
}

export interface OrganizationWorkspaces_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: OrganizationWorkspaces_node_workspaceChildren_node_workspace_options | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_children_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_children_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_children_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: OrganizationWorkspaces_node_workspaceChildren_node_children_node_workspace_options | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_children_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_children_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: OrganizationWorkspaces_node_workspaceChildren_node_children_node_organization | null;
  workspace: OrganizationWorkspaces_node_workspaceChildren_node_children_node_workspace | null;
  skill: OrganizationWorkspaces_node_workspaceChildren_node_children_node_skill | null;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node_children {
  __typename: "GraphEdge";
  sortKey: string;
  node: OrganizationWorkspaces_node_workspaceChildren_node_children_node;
}

export interface OrganizationWorkspaces_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: OrganizationWorkspaces_node_workspaceChildren_node_organization | null;
  workspace: OrganizationWorkspaces_node_workspaceChildren_node_workspace | null;
  skill: OrganizationWorkspaces_node_workspaceChildren_node_skill | null;
  children: OrganizationWorkspaces_node_workspaceChildren_node_children[];
}

export interface OrganizationWorkspaces_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: OrganizationWorkspaces_node_workspaceChildren_node;
}

export interface OrganizationWorkspaces_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: OrganizationWorkspaces_node_organization | null;
  workspace: OrganizationWorkspaces_node_workspace | null;
  skill: OrganizationWorkspaces_node_skill | null;
  workspaceChildren: OrganizationWorkspaces_node_workspaceChildren[];
}

export interface OrganizationWorkspaces_workspaceSections {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

export interface OrganizationWorkspaces_options {
  __typename: "OrganizationOptions";
  roadmap: boolean | null;
  roles: boolean | null;
  hideLeaderboards: boolean | null;
}

export interface OrganizationWorkspaces_fundingSessions_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface OrganizationWorkspaces_fundingSessions_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: OrganizationWorkspaces_fundingSessions_token_network;
}

export interface OrganizationWorkspaces_fundingSessions {
  __typename: "FundingSession";
  id: Scalar.UUID;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  closedAt: Scalar.DateTime | null;
  amount: string;
  permalink: string;
  organizationId: string;
  token: OrganizationWorkspaces_fundingSessions_token;
}

export interface OrganizationWorkspaces {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
  node: OrganizationWorkspaces_node;
  workspaceSections: OrganizationWorkspaces_workspaceSections[];
  options: OrganizationWorkspaces_options | null;
  fundingSessions: OrganizationWorkspaces_fundingSessions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentNetwork
// ====================================================

export interface PaymentNetwork {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentToken
// ====================================================

export interface PaymentToken {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethod
// ====================================================

export interface PaymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface PaymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: PaymentMethod_network;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Payment
// ====================================================

export interface Payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface Payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: Payment_paymentMethod_network;
}

export interface Payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: Payment_paymentMethod;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Rule
// ====================================================

export interface Rule {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Role
// ====================================================

export interface Role {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Threepid
// ====================================================

export interface Threepid {
  __typename: "Threepid";
  source: ThreepidSource;
  threepid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleWithRules
// ====================================================

export interface RoleWithRules_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface RoleWithRules {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: RoleWithRules_rules[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleCompensation
// ====================================================

export interface RoleCompensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RoleCompensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: RoleCompensation_reward_token_network;
}

export interface RoleCompensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RoleCompensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RoleCompensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: RoleCompensation_reward_payments_payment_paymentMethod_network;
}

export interface RoleCompensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: RoleCompensation_reward_payments_payment_paymentMethod;
}

export interface RoleCompensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: RoleCompensation_reward_payments_user;
  payment: RoleCompensation_reward_payments_payment;
}

export interface RoleCompensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: RoleCompensation_reward_token;
  tokenId: string;
  payments: RoleCompensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface RoleCompensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: RoleCompensation_reward;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleDetails
// ====================================================

export interface RoleDetails_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface RoleDetails_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface RoleDetails_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RoleDetails_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: RoleDetails_compensation_reward_token_network;
}

export interface RoleDetails_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RoleDetails_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RoleDetails_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: RoleDetails_compensation_reward_payments_payment_paymentMethod_network;
}

export interface RoleDetails_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: RoleDetails_compensation_reward_payments_payment_paymentMethod;
}

export interface RoleDetails_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: RoleDetails_compensation_reward_payments_user;
  payment: RoleDetails_compensation_reward_payments_payment;
}

export interface RoleDetails_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: RoleDetails_compensation_reward_token;
  tokenId: string;
  payments: RoleDetails_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface RoleDetails_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: RoleDetails_compensation_reward;
}

export interface RoleDetails {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: RoleDetails_rules[];
  skills: RoleDetails_skills[];
  compensation: RoleDetails_compensation[];
  commitment: number | null;
  userCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoleWithUsers
// ====================================================

export interface RoleWithUsers_rules {
  __typename: "Rule";
  id: Scalar.UUID;
  permission: RulePermission;
  inverted: boolean;
  taskId: string | null;
  workspaceId: string | null;
  fundingSessionId: string | null;
}

export interface RoleWithUsers_skills {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface RoleWithUsers_compensation_reward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RoleWithUsers_compensation_reward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: RoleWithUsers_compensation_reward_token_network;
}

export interface RoleWithUsers_compensation_reward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface RoleWithUsers_compensation_reward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface RoleWithUsers_compensation_reward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: RoleWithUsers_compensation_reward_payments_payment_paymentMethod_network;
}

export interface RoleWithUsers_compensation_reward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: RoleWithUsers_compensation_reward_payments_payment_paymentMethod;
}

export interface RoleWithUsers_compensation_reward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: RoleWithUsers_compensation_reward_payments_user;
  payment: RoleWithUsers_compensation_reward_payments_payment;
}

export interface RoleWithUsers_compensation_reward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: RoleWithUsers_compensation_reward_token;
  tokenId: string;
  payments: RoleWithUsers_compensation_reward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

export interface RoleWithUsers_compensation {
  __typename: "RoleCompensation";
  id: Scalar.UUID;
  roleId: string;
  frequency: CompensationFrequency;
  reward: RoleWithUsers_compensation_reward;
}

export interface RoleWithUsers_users {
  __typename: "User";
  id: Scalar.UUID;
}

export interface RoleWithUsers {
  __typename: "Role";
  id: Scalar.UUID;
  name: string;
  color: string;
  icon: string | null;
  source: RoleSource | null;
  fallback: boolean;
  userId: string | null;
  description: string | null;
  applicationLink: string | null;
  featured: boolean;
  sortKey: string;
  deletedAt: Scalar.DateTime | null;
  rules: RoleWithUsers_rules[];
  skills: RoleWithUsers_skills[];
  compensation: RoleWithUsers_compensation[];
  commitment: number | null;
  userCount: number | null;
  users: RoleWithUsers_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Skill
// ====================================================

export interface Skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StakedReputation
// ====================================================

export interface StakedReputation {
  __typename: "StakedReputation";
  stakerId: string;
  stakeeId: string;
  stakedCredits: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskViewFilter
// ====================================================

export interface TaskViewFilter_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface TaskViewFilter {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: TaskViewFilter_dueDate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskViewSortBy
// ====================================================

export interface TaskViewSortBy {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskView
// ====================================================

export interface TaskView_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface TaskView_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: TaskView_filters_dueDate | null;
}

export interface TaskView_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface TaskView {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: TaskView_filters[];
  sortBys: TaskView_sortBys[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Subtask
// ====================================================

export interface Subtask {
  __typename: "Task";
  id: Scalar.UUID;
  name: string;
  status: TaskStatus;
  sortKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskTag
// ====================================================

export interface TaskTag {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TaskReward
// ====================================================

export interface TaskReward_token_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskReward_token {
  __typename: "PaymentToken";
  id: Scalar.UUID;
  exp: number;
  type: PaymentTokenType;
  name: string;
  symbol: string;
  address: string | null;
  identifier: string | null;
  usdPrice: number | null;
  networkId: string;
  visibility: PaymentTokenVisibility;
  imageUrl: string | null;
  network: TaskReward_token_network;
}

export interface TaskReward_payments_user {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface TaskReward_payments_payment_paymentMethod_network {
  __typename: "PaymentNetwork";
  id: Scalar.UUID;
  slug: string;
  name: string;
  type: PaymentNetworkType;
  config: Scalar.JSONObject;
  sortKey: string;
}

export interface TaskReward_payments_payment_paymentMethod {
  __typename: "PaymentMethod";
  id: Scalar.UUID;
  type: PaymentMethodType;
  address: string;
  network: TaskReward_payments_payment_paymentMethod_network;
}

export interface TaskReward_payments_payment {
  __typename: "Payment";
  id: Scalar.UUID;
  status: PaymentStatus;
  data: Scalar.JSONObject | null;
  createdAt: Scalar.DateTime;
  paymentMethod: TaskReward_payments_payment_paymentMethod;
}

export interface TaskReward_payments {
  __typename: "TaskRewardPayment";
  id: Scalar.UUID;
  amount: string;
  user: TaskReward_payments_user;
  payment: TaskReward_payments_payment;
}

export interface TaskReward {
  __typename: "TaskReward";
  id: Scalar.UUID;
  amount: string;
  peggedToUsd: boolean;
  fundingSessionId: string | null;
  token: TaskReward_token;
  tokenId: string;
  payments: TaskReward_payments[];
  count: number | null;
  type: CompensationFrequency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ThreadMessage
// ====================================================

export interface ThreadMessage_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface ThreadMessage {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: ThreadMessage_sender;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Thread
// ====================================================

export interface Thread_messages_sender {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface Thread_messages {
  __typename: "ThreadMessage";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  content: string;
  threadId: string;
  senderId: string;
  sender: Thread_messages_sender;
}

export interface Thread_task {
  __typename: "Task";
  id: Scalar.UUID;
  workspaceId: string;
}

export interface Thread_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
}

export interface Thread {
  __typename: "Thread";
  id: Scalar.UUID;
  createdAt: Scalar.DateTime;
  messages: Thread_messages[];
  task: Thread_task | null;
  workspace: Thread_workspace | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: User
// ====================================================

export interface User {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserPrompt
// ====================================================

export interface UserPrompt {
  __typename: "UserPrompt";
  id: Scalar.UUID;
  type: string;
  createdAt: Scalar.DateTime;
  completedAt: Scalar.DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceOverview
// ====================================================

export interface WorkspaceOverview_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface WorkspaceOverview_contributors {
  __typename: "User";
  id: Scalar.UUID;
  username: string;
  imageUrl: string | null;
  permalink: string;
  nodeId: string;
}

export interface WorkspaceOverview_skills_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface WorkspaceOverview_skills {
  __typename: "SkillStatistic";
  count: number;
  skill: WorkspaceOverview_skills_skill;
}

export interface WorkspaceOverview_node_workspaceChildren_node_organization {
  __typename: "Organization";
  id: Scalar.UUID;
  name: string;
  imageUrl: string | null;
  slug: string;
  tagline: string | null;
  description: string | null;
  permalink: string;
  nodeId: string;
}

export interface WorkspaceOverview_node_workspaceChildren_node_workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface WorkspaceOverview_node_workspaceChildren_node_workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: WorkspaceOverview_node_workspaceChildren_node_workspace_options | null;
}

export interface WorkspaceOverview_node_workspaceChildren_node_skill {
  __typename: "Skill";
  id: Scalar.UUID;
  name: string;
  emoji: string | null;
  imageUrl: string | null;
}

export interface WorkspaceOverview_node_workspaceChildren_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  type: GraphNodeType;
  name: string | null;
  icon: string | null;
  imageUrl: string | null;
  createdAt: Scalar.DateTime;
  permalink: string | null;
  description: string | null;
  organization: WorkspaceOverview_node_workspaceChildren_node_organization | null;
  workspace: WorkspaceOverview_node_workspaceChildren_node_workspace | null;
  skill: WorkspaceOverview_node_workspaceChildren_node_skill | null;
}

export interface WorkspaceOverview_node_workspaceChildren {
  __typename: "GraphEdge";
  sortKey: string;
  node: WorkspaceOverview_node_workspaceChildren_node;
}

export interface WorkspaceOverview_node {
  __typename: "GraphNode";
  id: Scalar.UUID;
  workspaceChildren: WorkspaceOverview_node_workspaceChildren[];
}

export interface WorkspaceOverview {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: WorkspaceOverview_options | null;
  contributors: WorkspaceOverview_contributors[];
  skills: WorkspaceOverview_skills[];
  openTaskCount: number;
  doneTaskCount: number;
  totalTaskCount: number;
  node: WorkspaceOverview_node;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Workspace
// ====================================================

export interface Workspace_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface Workspace {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: Workspace_options | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceSection
// ====================================================

export interface WorkspaceSection {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceSectionDetails
// ====================================================

export interface WorkspaceSectionDetails_taskViews_filters_dueDate {
  __typename: "DateRangeFilter";
  gte: Scalar.DateTime | null;
  lt: Scalar.DateTime | null;
}

export interface WorkspaceSectionDetails_taskViews_filters {
  __typename: "TaskViewFilter";
  type: TaskViewFilterType;
  tagIds: Scalar.UUID[] | null;
  roleIds: Scalar.UUID[] | null;
  ownerIds: Scalar.UUID[] | null;
  assigneeIds: (Scalar.UUID | null)[] | null;
  applicantIds: Scalar.UUID[] | null;
  statuses: TaskStatus[] | null;
  priorities: TaskPriority[] | null;
  skillIds: Scalar.UUID[] | null;
  subtasks: boolean | null;
  templateIds: Scalar.UUID[] | null;
  organizationIds: Scalar.UUID[] | null;
  dueDate: WorkspaceSectionDetails_taskViews_filters_dueDate | null;
}

export interface WorkspaceSectionDetails_taskViews_sortBys {
  __typename: "TaskViewSortBy";
  field: TaskViewSortByField;
  direction: TaskViewSortByDirection;
}

export interface WorkspaceSectionDetails_taskViews {
  __typename: "TaskView";
  id: Scalar.UUID;
  name: string;
  slug: string;
  type: TaskViewType;
  icon: string | null;
  groupBy: TaskViewGroupBy;
  permalink: string;
  workspaceId: Scalar.UUID | null;
  workspaceSectionId: Scalar.UUID | null;
  organizationId: Scalar.UUID | null;
  userId: Scalar.UUID | null;
  fields: TaskViewField[];
  sortKey: string;
  filters: WorkspaceSectionDetails_taskViews_filters[];
  sortBys: WorkspaceSectionDetails_taskViews_sortBys[];
}

export interface WorkspaceSectionDetails_workspaces_options {
  __typename: "WorkspaceOptions";
  showCommunitySuggestions: boolean | null;
}

export interface WorkspaceSectionDetails_workspaces_taskTags {
  __typename: "TaskTag";
  id: Scalar.UUID;
  label: string;
  color: string;
  createdAt: Scalar.DateTime;
  deletedAt: Scalar.DateTime | null;
  workspaceId: string;
}

export interface WorkspaceSectionDetails_workspaces {
  __typename: "Workspace";
  id: Scalar.UUID;
  slug: string;
  name: string;
  icon: string | null;
  type: WorkspaceType;
  status: WorkspaceStatus;
  description: string | null;
  startAt: Scalar.DateTime | null;
  endAt: Scalar.DateTime | null;
  deletedAt: Scalar.DateTime | null;
  organizationId: string;
  permalink: string;
  sectionId: string | null;
  parentId: string | null;
  sortKey: string;
  roadmapSortKey: string;
  options: WorkspaceSectionDetails_workspaces_options | null;
  taskTags: WorkspaceSectionDetails_workspaces_taskTags[];
}

export interface WorkspaceSectionDetails {
  __typename: "WorkspaceSection";
  id: Scalar.UUID;
  name: string;
  slug: string;
  layout: WorkspaceSectionLayout;
  sortKey: string;
  organizationId: string;
  taskViews: WorkspaceSectionDetails_taskViews[];
  workspaces: WorkspaceSectionDetails_workspaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CompensationFrequency {
  DAY = "DAY",
  FIXED = "FIXED",
  HOUR = "HOUR",
  MONTH = "MONTH",
  STORY_POINT = "STORY_POINT",
  WEEK = "WEEK",
}

export enum CreateUserPromptFlow {
  CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
}

export enum DiscordGuildMembershipState {
  HAS_SCOPE = "HAS_SCOPE",
  MEMBER = "MEMBER",
  MISSING_SCOPE = "MISSING_SCOPE",
}

export enum EntityDetailType {
  discord = "discord",
  github = "github",
  linkedin = "linkedin",
  location = "location",
  twitter = "twitter",
  website = "website",
}

export enum GithubPullRequestStatus {
  CLOSED = "CLOSED",
  DRAFT = "DRAFT",
  MERGED = "MERGED",
  OPEN = "OPEN",
}

export enum GraphNodeType {
  COLLECTION = "COLLECTION",
  ORGANIZATION = "ORGANIZATION",
  ROLE = "ROLE",
  SKILL = "SKILL",
  TASK = "TASK",
  USER = "USER",
  WORKSPACE = "WORKSPACE",
}

export enum Language {
  CHINESE = "CHINESE",
  ENGLISH = "ENGLISH",
}

export enum OrganizationIntegrationType {
  COORDINAPE = "COORDINAPE",
  DISCORD = "DISCORD",
  GITHUB = "GITHUB",
  NOTION = "NOTION",
}

export enum PaymentMethodType {
  GNOSIS_SAFE = "GNOSIS_SAFE",
  HIRO = "HIRO",
  METAMASK = "METAMASK",
  PHANTOM = "PHANTOM",
  UTOPIA = "UTOPIA",
}

export enum PaymentNetworkType {
  ETHEREUM = "ETHEREUM",
  SOLANA = "SOLANA",
  STACKS = "STACKS",
}

export enum PaymentStatus {
  CONFIRMED = "CONFIRMED",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
}

export enum PaymentTokenType {
  ERC1155 = "ERC1155",
  ERC20 = "ERC20",
  ERC721 = "ERC721",
  NATIVE = "NATIVE",
  SPL_TOKEN = "SPL_TOKEN",
  STACKS_TOKEN = "STACKS_TOKEN",
}

export enum PaymentTokenVisibility {
  ALWAYS = "ALWAYS",
  IF_HAS_BALANCE = "IF_HAS_BALANCE",
}

export enum RelevantUsersModes {
  ASSIGNEES = "ASSIGNEES",
  OWNERS = "OWNERS",
}

export enum RoleSource {
  DISCORD = "DISCORD",
}

export enum RulePermission {
  APPLY_TO_TASKS = "APPLY_TO_TASKS",
  MANAGE_FUNDING = "MANAGE_FUNDING",
  MANAGE_ORGANIZATION = "MANAGE_ORGANIZATION",
  MANAGE_PROJECTS = "MANAGE_PROJECTS",
  MANAGE_TASKS = "MANAGE_TASKS",
  VIEW_PROJECTS = "VIEW_PROJECTS",
}

export enum TaskApplicationStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum TaskGatingType {
  APPLICATION = "APPLICATION",
  ASSIGNEES = "ASSIGNEES",
  OPEN_SUBMISSION = "OPEN_SUBMISSION",
  ROLES = "ROLES",
}

export enum TaskPriority {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  NONE = "NONE",
  URGENT = "URGENT",
}

export enum TaskStatus {
  BACKLOG = "BACKLOG",
  COMMUNITY_SUGGESTIONS = "COMMUNITY_SUGGESTIONS",
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  TODO = "TODO",
}

export enum TaskSubmissionStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum TaskViewField {
  assignees = "assignees",
  button = "button",
  createdAt = "createdAt",
  creator = "creator",
  doneAt = "doneAt",
  dueDate = "dueDate",
  gating = "gating",
  name = "name",
  number = "number",
  organization = "organization",
  owners = "owners",
  priority = "priority",
  reward = "reward",
  skills = "skills",
  status = "status",
  tags = "tags",
  template = "template",
  workspace = "workspace",
}

export enum TaskViewFilterType {
  APPLICANTS = "APPLICANTS",
  ASSIGNEES = "ASSIGNEES",
  DUE_DATE = "DUE_DATE",
  ORGANIZATION = "ORGANIZATION",
  OWNERS = "OWNERS",
  PRIORITIES = "PRIORITIES",
  ROLES = "ROLES",
  SKILLS = "SKILLS",
  STATUSES = "STATUSES",
  SUBTASKS = "SUBTASKS",
  TAGS = "TAGS",
  TEMPLATE = "TEMPLATE",
}

export enum TaskViewGroupBy {
  status = "status",
}

export enum TaskViewSortByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TaskViewSortByField {
  createdAt = "createdAt",
  doneAt = "doneAt",
  dueDate = "dueDate",
  priority = "priority",
  reward = "reward",
  sortKey = "sortKey",
  votes = "votes",
}

export enum TaskViewType {
  BOARD = "BOARD",
  GRID = "GRID",
  LIST = "LIST",
  VOTING = "VOTING",
}

export enum ThreepidAuthSessionType {
  DISCORD = "DISCORD",
  GITHUB = "GITHUB",
  NOTION = "NOTION",
  TRELLO = "TRELLO",
}

export enum ThreepidSource {
  discord = "discord",
  github = "github",
  hiro = "hiro",
  metamask = "metamask",
  notion = "notion",
  phantom = "phantom",
  trello = "trello",
}

export enum WorkspaceIntegrationFeature {
  DISCORD_POST_ACTIVITY_FEED = "DISCORD_POST_ACTIVITY_FEED",
  DISCORD_POST_COMMUNITY_SUGGESTIONS_STATUS_BOARD_MESSAGE = "DISCORD_POST_COMMUNITY_SUGGESTIONS_STATUS_BOARD_MESSAGE",
  DISCORD_POST_COMMUNITY_SUGGESTIONS_TO_CHANNEL = "DISCORD_POST_COMMUNITY_SUGGESTIONS_TO_CHANNEL",
  DISCORD_POST_NEW_TASKS_TO_CHANNEL = "DISCORD_POST_NEW_TASKS_TO_CHANNEL",
  DISCORD_POST_STATUS_BOARD_MESSAGE = "DISCORD_POST_STATUS_BOARD_MESSAGE",
  DISCORD_POST_TASK_UPDATES_TO_CHANNEL = "DISCORD_POST_TASK_UPDATES_TO_CHANNEL",
  DISCORD_POST_TASK_UPDATES_TO_THREAD_PER_TASK = "DISCORD_POST_TASK_UPDATES_TO_THREAD_PER_TASK",
  GITHUB_CREATE_TASKS_FROM_ISSUES = "GITHUB_CREATE_TASKS_FROM_ISSUES",
  GITHUB_SHOW_BRANCHES_AND_PULL_REQUESTS = "GITHUB_SHOW_BRANCHES_AND_PULL_REQUESTS",
  NOTION_BOUNTY_FIELD = "NOTION_BOUNTY_FIELD",
}

export enum WorkspaceIntegrationType {
  DISCORD = "DISCORD",
  GITHUB = "GITHUB",
  NOTION = "NOTION",
}

export enum WorkspaceRole {
  ADMIN = "ADMIN",
  CONTRIBUTOR = "CONTRIBUTOR",
}

export enum WorkspaceSectionLayout {
  GRID = "GRID",
  LIST = "LIST",
}

export enum WorkspaceStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum WorkspaceType {
  FOLDER = "FOLDER",
  PROJECT = "PROJECT",
}

export interface CountTasksInput {
  public?: boolean | null;
  name?: string | null;
  languages?: Language[] | null;
  statuses?: TaskStatus[] | null;
  priorities?: TaskPriority[] | null;
  hasReward?: boolean | null;
  reward?: FloatNumberFilter | null;
  template?: boolean | null;
  templateIds?: Scalar.UUID[] | null;
  skillIds?: Scalar.UUID[] | null;
  roleIds?: Scalar.UUID[] | null;
  assigned?: boolean | null;
  assigneeIds?: (Scalar.UUID | null)[] | null;
  ownerIds?: (Scalar.UUID | null)[] | null;
  tagIds?: Scalar.UUID[] | null;
  applicantIds?: Scalar.UUID[] | null;
  workspaceIds?: Scalar.UUID[] | null;
  parentTaskId?: Scalar.UUID | null;
  organizationIds?: Scalar.UUID[] | null;
  workspaceSectionIds?: Scalar.UUID[] | null;
  featured?: boolean | null;
  networks?: PaymentNetworkType[] | null;
  doneAt?: DateRangeFilterInput | null;
  dueDate?: DateRangeFilterInput | null;
}

export interface CreateFileUploadUrlInput {
  fileName: string;
  contentType: string;
}

export interface CreateFundingSessionInput {
  organizationId: Scalar.UUID;
  amount: string;
  tokenId: Scalar.UUID;
  workspaceIds: Scalar.UUID[];
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
}

export interface CreateGraphNodeInput {
  name?: string | null;
  icon?: string | null;
  imageUrl?: string | null;
  description?: string | null;
  type: GraphNodeType;
  parentIds?: Scalar.UUID[] | null;
  childIds?: Scalar.UUID[] | null;
}

export interface CreateInviteInput {
  organizationId?: Scalar.UUID | null;
  workspaceId?: Scalar.UUID | null;
  taskId?: Scalar.UUID | null;
  permission: RulePermission;
}

export interface CreateOrganizationInput {
  name: string;
  imageUrl?: string | null;
  options?: OrganizationOptionsInput | null;
}

export interface CreateOrganizationIntegrationInput {
  type: OrganizationIntegrationType;
  config?: Scalar.JSONObject | null;
  organizationId: Scalar.UUID;
}

export interface CreateOrganizationTagInput {
  label: string;
  color: string;
  organizationId: Scalar.UUID;
}

export interface CreatePaymentMethodInput {
  type: PaymentMethodType;
  address: string;
  networkId: Scalar.UUID;
  workspaceId: Scalar.UUID;
}

export interface CreatePaymentTokenInput {
  type: PaymentTokenType;
  address: string;
  identifier?: string | null;
  name: string;
  symbol: string;
  exp: number;
  networkId: Scalar.UUID;
}

export interface CreateRoleInput {
  name: string;
  icon?: string | null;
  color: string;
  parentId: Scalar.UUID;
  userId?: Scalar.UUID | null;
  userIds?: Scalar.UUID[] | null;
}

export interface CreateRuleInput {
  permission: RulePermission;
  inverted?: boolean | null;
  roleId: Scalar.UUID;
  taskId?: Scalar.UUID | null;
  workspaceId?: Scalar.UUID | null;
  fundingSessionId?: Scalar.UUID | null;
}

export interface CreateTaskApplicationInput {
  taskId: Scalar.UUID;
  userId: Scalar.UUID;
  message?: string | null;
  startDate: Scalar.DateTime;
  endDate: Scalar.DateTime;
  reward?: UpdateTaskRewardInput | null;
}

export interface CreateTaskContext {
  notionPageId?: string | null;
}

export interface CreateTaskInput {
  name: string;
  description?: string | null;
  template?: boolean | null;
  workspaceId: Scalar.UUID;
  parentTaskId?: Scalar.UUID | null;
  templateTaskId?: Scalar.UUID | null;
  tagIds?: Scalar.UUID[] | null;
  skillIds?: Scalar.UUID[] | null;
  assigneeIds?: Scalar.UUID[] | null;
  ownerIds?: Scalar.UUID[] | null;
  storyPoints?: number | null;
  status: TaskStatus;
  priority?: TaskPriority | null;
  gating?: TaskGatingType | null;
  rewards?: UpdateTaskRewardInput[] | null;
  openToBids?: boolean | null;
  submissionTemplate?: string | null;
  applicationTemplate?: string | null;
  applicationLink?: string | null;
  dueDate?: Scalar.DateTime | null;
  context?: CreateTaskContext | null;
  maxWinners?: number | null;
}

export interface CreateTaskPaymentsInput {
  payments: TaskRewardPaymentInput[];
  paymentMethodId: Scalar.UUID;
  data?: Scalar.JSONObject | null;
}

export interface CreateTaskSectionInput {
  name: string;
  workspaceId: Scalar.UUID;
  status: TaskStatus;
}

export interface CreateTaskSubmissionInput {
  taskId: Scalar.UUID;
  content: string;
  reward?: UpdateTaskRewardInput | null;
}

export interface CreateTaskTagInput {
  label: string;
  color: string;
  workspaceId: Scalar.UUID;
}

export interface CreateTaskViewInput {
  workspaceId?: Scalar.UUID | null;
  workspaceSectionId?: Scalar.UUID | null;
  userId?: Scalar.UUID | null;
  organizationId?: Scalar.UUID | null;
  name: string;
  icon?: string | null;
  type: TaskViewType;
  groupBy?: TaskViewGroupBy | null;
  filters: TaskViewFilterInput[];
  sortBys: TaskViewSortByInput[];
  fields?: TaskViewField[] | null;
}

export interface CreateThreadMessageInput {
  threadId: Scalar.UUID;
  content: string;
}

export interface CreateUserRankingVoteInput {
  position: number;
  initialPosition: number;
  dragged: boolean;
  userId: Scalar.UUID;
  type?: string | null;
  description?: string | null;
}

export interface CreateWorkspaceInput {
  name: string;
  icon?: string | null;
  type?: WorkspaceType | null;
  organizationId: Scalar.UUID;
  sectionId?: Scalar.UUID | null;
  options?: WorkspaceOptionsInput | null;
  parentId?: Scalar.UUID | null;
  startAt?: Scalar.DateTime | null;
  endAt?: Scalar.DateTime | null;
}

export interface CreateWorkspaceIntegrationInput {
  type: WorkspaceIntegrationType;
  feature: WorkspaceIntegrationFeature;
  config: Scalar.JSONObject;
  workspaceId: Scalar.UUID;
  organizationIntegrationId?: Scalar.UUID | null;
}

export interface CreateWorkspaceSectionInput {
  name: string;
  organizationId: Scalar.UUID;
}

export interface CreateWorkspacesFromGithubInput {
  organizationId: Scalar.UUID;
  repoIds: string[];
  labelIds?: string[] | null;
}

export interface CreateWorkspacesFromNotionInput {
  organizationId: Scalar.UUID;
  databaseIds: string[];
}

export interface CreateWorkspacesFromTrelloInput {
  organizationId: Scalar.UUID;
  threepidId: Scalar.UUID;
  boardIds: string[];
}

export interface DateRangeFilterInput {
  lt?: Scalar.DateTime | null;
  lte?: Scalar.DateTime | null;
  gt?: Scalar.DateTime | null;
  gte?: Scalar.DateTime | null;
}

export interface DeleteGraphEdgeInput {
  childId: Scalar.UUID;
  parentId: Scalar.UUID;
}

export interface DeleteOrganizationIntegrationInput {
  type: OrganizationIntegrationType;
  organizationId: Scalar.UUID;
}

export interface EthereumAuthInput {
  message: string;
  signature: string;
  address: string;
}

export interface FeaturedWorkInput {
  imageUrl?: string | null;
  link: string;
  title: string;
  description: string;
}

export interface FloatNumberFilter {
  exists?: boolean | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
}

export interface FundingVoteInput {
  sessionId: Scalar.UUID;
  taskId: Scalar.UUID;
  weight: number;
}

export interface GetRelevantUsersInput {
  workspaceId: Scalar.UUID;
  mode: RelevantUsersModes;
}

export interface GetTasksInput {
  doneAtAfter?: Scalar.DateTime | null;
  doneAtBefore?: Scalar.DateTime | null;
  statuses?: TaskStatus[] | null;
  limit?: number | null;
  userId?: Scalar.UUID | null;
  ids?: Scalar.UUID[] | null;
  rewardNotNull?: boolean | null;
}

export interface OrganizationOptionsInput {
  roadmap?: boolean | null;
  roles?: boolean | null;
  mintTaskNFTs?: boolean | null;
  hideLeaderboards?: boolean | null;
}

export interface SearchTasksInput {
  public?: boolean | null;
  name?: string | null;
  languages?: Language[] | null;
  statuses?: TaskStatus[] | null;
  priorities?: TaskPriority[] | null;
  hasReward?: boolean | null;
  reward?: FloatNumberFilter | null;
  template?: boolean | null;
  templateIds?: Scalar.UUID[] | null;
  skillIds?: Scalar.UUID[] | null;
  roleIds?: Scalar.UUID[] | null;
  assigned?: boolean | null;
  assigneeIds?: (Scalar.UUID | null)[] | null;
  ownerIds?: (Scalar.UUID | null)[] | null;
  tagIds?: Scalar.UUID[] | null;
  applicantIds?: Scalar.UUID[] | null;
  workspaceIds?: Scalar.UUID[] | null;
  parentTaskId?: Scalar.UUID | null;
  organizationIds?: Scalar.UUID[] | null;
  workspaceSectionIds?: Scalar.UUID[] | null;
  featured?: boolean | null;
  networks?: PaymentNetworkType[] | null;
  doneAt?: DateRangeFilterInput | null;
  dueDate?: DateRangeFilterInput | null;
  sortBy: TaskViewSortByInput;
}

export interface SetOrganizationDetailInput {
  type: EntityDetailType;
  value?: string | null;
  organizationId: Scalar.UUID;
}

export interface SetUserDetailInput {
  type: EntityDetailType;
  value?: string | null;
}

export interface SetUserStakingInput {
  stakedCredits: number;
  stakeeId: Scalar.UUID;
}

export interface SolanaAuthInput {
  message: string;
  signature: number[];
  address: string;
}

export interface TaskFilterInput {
  doneAtAfter?: Scalar.DateTime | null;
  doneAtBefore?: Scalar.DateTime | null;
  statuses?: TaskStatus[] | null;
  limit?: number | null;
  userId?: Scalar.UUID | null;
}

export interface TaskGatingDefaultInput {
  workspaceId: Scalar.UUID;
  type?: TaskGatingType | null;
  applyRoleIds?: Scalar.UUID[] | null;
  claimRoleIds?: Scalar.UUID[] | null;
}

export interface TaskReactionInput {
  reaction: string;
  taskId: Scalar.UUID;
}

export interface TaskRewardPaymentInput {
  userId: Scalar.UUID;
  rewardId: Scalar.UUID;
  tokenId: Scalar.UUID;
  amount: string;
}

export interface TaskViewFilterInput {
  type: TaskViewFilterType;
  subtasks?: boolean | null;
  tagIds?: Scalar.UUID[] | null;
  assigneeIds?: (Scalar.UUID | null)[] | null;
  ownerIds?: Scalar.UUID[] | null;
  applicantIds?: Scalar.UUID[] | null;
  roleIds?: Scalar.UUID[] | null;
  skillIds?: Scalar.UUID[] | null;
  statuses?: TaskStatus[] | null;
  dueDate?: DateRangeFilterInput | null;
  priorities?: TaskPriority[] | null;
  templateIds?: Scalar.UUID[] | null;
  organizationIds?: Scalar.UUID[] | null;
}

export interface TaskViewSortByInput {
  direction: TaskViewSortByDirection;
  field: TaskViewSortByField;
}

export interface UpdateGraphNodeInput {
  id: Scalar.UUID;
  name?: string | null;
  icon?: string | null;
  imageUrl?: string | null;
  description?: string | null;
  type?: GraphNodeType | null;
}

export interface UpdateOrganizationInput {
  id: Scalar.UUID;
  name?: string | null;
  tagline?: string | null;
  description?: string | null;
  tagIds?: Scalar.UUID[] | null;
  imageUrl?: string | null;
  options?: OrganizationOptionsInput | null;
}

export interface UpdatePaymentMethodInput {
  id: Scalar.UUID;
  deletedAt: Scalar.DateTime;
}

export interface UpdateRoleCompensationInput {
  reward: UpdateTaskRewardInput;
  frequency: CompensationFrequency;
}

export interface UpdateRoleInput {
  id: Scalar.UUID;
  color?: string | null;
  name?: string | null;
  icon?: string | null;
  description?: string | null;
  skillIds?: Scalar.UUID[] | null;
  applicationLink?: string | null;
  commitment?: number | null;
  compensation?: UpdateRoleCompensationInput[] | null;
  featured?: boolean | null;
  sortKey?: string | null;
  deletedAt?: string | null;
}

export interface UpdateTaskApplicationInput {
  id: Scalar.UUID;
  taskId: Scalar.UUID;
  userId: Scalar.UUID;
  status?: string | null;
  deletedAt?: Scalar.DateTime | null;
  threadId?: Scalar.UUID | null;
}

export interface UpdateTaskInput {
  id: Scalar.UUID;
  name?: string | null;
  description?: string | null;
  parentTaskId?: Scalar.UUID | null;
  sectionId?: Scalar.UUID | null;
  workspaceId?: Scalar.UUID | null;
  sortKey?: string | null;
  tagIds?: Scalar.UUID[] | null;
  skillIds?: Scalar.UUID[] | null;
  assigneeIds?: Scalar.UUID[] | null;
  ownerIds?: Scalar.UUID[] | null;
  storyPoints?: number | null;
  status?: TaskStatus | null;
  priority?: TaskPriority | null;
  gating?: TaskGatingType | null;
  rewards?: UpdateTaskRewardInput[] | null;
  openToBids?: boolean | null;
  review?: UpdateTaskReviewInput | null;
  submissionTemplate?: string | null;
  applicationTemplate?: string | null;
  applicationLink?: string | null;
  dueDate?: Scalar.DateTime | null;
  featured?: boolean | null;
  threadId?: Scalar.UUID | null;
  maxWinners?: number | null;
}

export interface UpdateTaskReviewInput {
  message?: string | null;
  rating?: number | null;
  reviewerId?: Scalar.UUID | null;
}

export interface UpdateTaskRewardInput {
  amount: string;
  tokenId: Scalar.UUID;
  peggedToUsd?: boolean | null;
  count?: number | null;
  type?: CompensationFrequency | null;
}

export interface UpdateTaskSectionInput {
  id: Scalar.UUID;
  workspaceId: Scalar.UUID;
  name?: string | null;
  sortKey?: string | null;
  deletedAt?: Scalar.DateTime | null;
}

export interface UpdateTaskSubmissionInput {
  id: Scalar.UUID;
  taskId: Scalar.UUID;
  approverId?: Scalar.UUID | null;
  content?: string | null;
  deletedAt?: Scalar.DateTime | null;
  status?: TaskSubmissionStatus | null;
  reward?: UpdateTaskRewardInput | null;
  threadId?: Scalar.UUID | null;
}

export interface UpdateTaskTagInput {
  id: Scalar.UUID;
  workspaceId: Scalar.UUID;
  label?: string | null;
  color?: string | null;
  deletedAt?: Scalar.DateTime | null;
}

export interface UpdateTaskViewInput {
  id: Scalar.UUID;
  name?: string | null;
  icon?: string | null;
  type?: TaskViewType | null;
  groupBy?: TaskViewGroupBy | null;
  filters?: TaskViewFilterInput[] | null;
  sortBys?: TaskViewSortByInput[] | null;
  fields?: TaskViewField[] | null;
  sortKey?: string | null;
  deletedAt?: Scalar.DateTime | null;
}

export interface UpdateThreadMessageInput {
  id: Scalar.UUID;
  content?: string | null;
}

export interface UpdateUserInput {
  username?: string | null;
  bio?: string | null;
  imageUrl?: string | null;
  openTasksNotifications?: boolean | null;
  featuredWork?: FeaturedWorkInput[] | null;
  revenueShare?: number | null;
}

export interface UpdateUserPromptInput {
  id: Scalar.UUID;
  completedAt?: Scalar.DateTime | null;
  state?: Scalar.JSONObject | null;
}

export interface UpdateUserRoleInput {
  userId: Scalar.UUID;
  roleId: Scalar.UUID;
  hidden?: boolean | null;
}

export interface UpdateWorkspaceInput {
  id: Scalar.UUID;
  name?: string | null;
  description?: string | null;
  icon?: string | null;
  type?: WorkspaceType | null;
  options?: WorkspaceOptionsInput | null;
  deletedAt?: Scalar.DateTime | null;
  sortKey?: string | null;
  roadmapSortKey?: string | null;
  sectionId?: Scalar.UUID | null;
  parentId?: Scalar.UUID | null;
  activityThreadId?: Scalar.UUID | null;
  startAt?: Scalar.DateTime | null;
  endAt?: Scalar.DateTime | null;
  status?: WorkspaceStatus | null;
}

export interface UpdateWorkspaceIntegrationInput {
  id: Scalar.UUID;
  deletedAt?: Scalar.DateTime | null;
}

export interface UpdateWorkspaceSectionInput {
  id: Scalar.UUID;
  organizationId: Scalar.UUID;
  name?: string | null;
  layout?: WorkspaceSectionLayout | null;
  sortKey?: string | null;
  deletedAt?: Scalar.DateTime | null;
}

export interface UpsertGraphEdgeInput {
  childId: Scalar.UUID;
  parentId: Scalar.UUID;
  sortKey?: string | null;
  hidden?: string | null;
}

export interface WorkspaceOptionsInput {
  showCommunitySuggestions?: boolean | null;
}

export interface WorkspaceTokenGateInput {
  workspaceId: Scalar.UUID;
  tokenId: Scalar.UUID;
  role: WorkspaceRole;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
