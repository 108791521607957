import React, { ReactNode, useCallback } from "react";
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";
import { getSortKeyBetween } from "@dewo/app/containers/task/board/util";
import { HolderOutlined } from "@ant-design/icons";

type ReorderListItem<K extends string> = {
  id: string;
  isDragDisabled?: boolean;
} & {
  [P in K]: string;
};

interface Props<K extends string, T extends ReorderListItem<K>> {
  dataSource: T[];
  fieldName: K;
  renderLabel(item: T, index: number): ReactNode;
  onReorder(item: T, newSortKey: string): any;
}

export function ReorderList<K extends string, T extends ReorderListItem<K>>({
  dataSource,
  fieldName,
  renderLabel,
  onReorder,
}: Props<K, T>) {
  const handleDragEnd = useCallback<DragDropContextProps["onDragEnd"]>(
    async (result) => {
      if (result.reason !== "DROP" || !result.destination) return;

      const itemId = result.draggableId;
      const item = dataSource.find((i) => i.id === itemId);
      if (!item) return;

      const indexExcludingItself = (() => {
        const newIndex = result.destination.index;
        const oldIndex = result.source.index;
        // To get the items above and below the currently dropped card
        // we need to offset the new index by 1 if the card was dragged
        // from above in the same lane. The card we're dragging from
        // above makes all other items move up one step
        if (oldIndex < newIndex) return newIndex + 1;
        return newIndex;
      })();

      const itemAbove = dataSource[indexExcludingItself - 1];
      const itemBelow = dataSource[indexExcludingItself];

      const sortKey = getSortKeyBetween(
        itemAbove,
        itemBelow,
        (i) => i[fieldName]
      );

      await onReorder(item, sortKey);
    },
    [dataSource, fieldName, onReorder]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {dataSource.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={item.isDragDisabled}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div
                      className="ant-btn ant-btn-text"
                      // @ts-expect-error
                      disabled={item.isDragDisabled}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "unset",
                        textAlign: "left",
                        paddingBottom: 4,
                      }}
                    >
                      {!item.isDragDisabled && (
                        <HolderOutlined className="text-secondary" />
                      )}
                      {renderLabel(item, index)}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
