import React, { CSSProperties, FC, useCallback } from "react";
import DefaultTooltip from "rich-markdown-editor/dist/components/Tooltip";
import { isLocalURL } from "next/dist/shared/lib/router/router";
import router from "next/router";
import { RichMarkdownComponent } from "./RichMarkdownComponent";
import { theme } from "./theme";
import { useAmplitude } from "@dewo/app/util/analytics/AmplitudeContext";
import { useEmbeds } from "./embeds";

interface RichMarkdownViewerProps {
  value: string;
  style?: CSSProperties;
}

export const RichMarkdownViewer: FC<RichMarkdownViewerProps> = ({
  value,
  style,
}) => {
  const embeds = useEmbeds();
  const { logEvent } = useAmplitude();
  const handleLinkClick = useCallback(
    (href: string) => {
      if (isLocalURL(href)) {
        router.push(href);
      } else {
        logEvent("Click link in rich markdown", { href });
        window.open(href, "_blank");
      }
    },
    [logEvent]
  );

  return (
    <RichMarkdownComponent
      tooltip={DefaultTooltip}
      defaultValue={value}
      value={value}
      embeds={embeds}
      theme={theme}
      placeholder=""
      onClickLink={handleLinkClick}
      readOnly={true}
      style={style}
      disableExtensions={["emoji"]}
    />
  );
};
