import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
  createThread,
  createThreadMessage,
  deleteThreadMessage,
  updateThreadMessage,
} from "@dewo/app/graphql/mutations/thread";
import {
  CreateThreadMessageInput,
  CreateThreadMessageMutation,
  CreateThreadMessageMutationVariables,
  CreateThreadMutation,
  DeleteThreadMessageMutation,
  DeleteThreadMessageMutationVariables,
  ThreadMessage,
  UpdateTaskThreadMutation,
  UpdateTaskThreadMutationVariables,
  UpdateThreadMessageInput,
  UpdateThreadMessageMutation,
  UpdateThreadMessageMutationVariables,
} from "@dewo/app/graphql/types";
import { updateTaskThread } from "@dewo/app/graphql/mutations/task";

export function useCreateThread() {
  const [mutation] = useMutation<CreateThreadMutation>(createThread);
  return useCallback(async () => {
    const res = await mutation();
    if (!res.data) throw new Error(JSON.stringify(res.errors));
    return res.data.thread;
  }, [mutation]);
}

export function useCreateThreadMessage(): (
  input: CreateThreadMessageInput
) => Promise<ThreadMessage> {
  const [mutation] = useMutation<
    CreateThreadMessageMutation,
    CreateThreadMessageMutationVariables
  >(createThreadMessage);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.message;
    },
    [mutation]
  );
}

export function useUpdateThreadMessage(): (
  input: UpdateThreadMessageInput
) => Promise<ThreadMessage> {
  const [mutation] = useMutation<
    UpdateThreadMessageMutation,
    UpdateThreadMessageMutationVariables
  >(updateThreadMessage);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.message;
    },
    [mutation]
  );
}

export function useDeleteThreadMessage(): (
  id: string
) => Promise<ThreadMessage> {
  const [mutation] = useMutation<
    DeleteThreadMessageMutation,
    DeleteThreadMessageMutationVariables
  >(deleteThreadMessage);
  return useCallback(
    async (id) => {
      const res = await mutation({ variables: { id } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
      return res.data.message;
    },
    [mutation]
  );
}

export function useUpdateTaskThread(): (
  taskId: string,
  threadId: string
) => Promise<void> {
  const [mutation] = useMutation<
    UpdateTaskThreadMutation,
    UpdateTaskThreadMutationVariables
  >(updateTaskThread);
  return useCallback(
    async (taskId, threadId) => {
      const res = await mutation({
        variables: { input: { id: taskId, threadId } },
      });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}
