import gql from "graphql-tag";
import * as Fragments from "./fragments";
import { network, paymentMethod, token } from "./fragments/payment";
import { workspace } from "./fragments/workspace";
import { taskTag } from "./fragments/task";
import { user } from "./fragments/user";
import { workspaceSectionDetails } from "./fragments/workspace";
import { roleDetails, roleWithRules, rule } from "./fragments/role";
import { organization, organizationRoles } from "./fragments/organization";
import { organizationWorkspaces } from "./fragments/organization.workspaces";

export const updateUser = gql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      ...UserDetails
    }
  }

  ${Fragments.userDetails}
`;

export const updateUserRole = gql`
  mutation UpdateUserRoleMutation($input: UpdateUserRoleInput!) {
    user: updateUserRole(input: $input) {
      id
      userRoles {
        roleId
        hidden
      }
    }
  }
`;

export const createOrganization = gql`
  mutation CreateOrganizationMutation($input: CreateOrganizationInput!) {
    organization: createOrganization(input: $input) {
      ...Organization
    }
  }

  ${organization}
`;

export const updateOrganization = gql`
  mutation UpdateOrganizationMutation($input: UpdateOrganizationInput!) {
    organization: updateOrganization(input: $input) {
      ...OrganizationDetails
    }
  }

  ${Fragments.organizationDetails}
`;

export const deleteOrganization = gql`
  mutation DeleteOrganizationMutation($id: UUID!) {
    deleteOrganization(id: $id)
  }
`;

export const createOrganizationTag = gql`
  mutation CreateOrganizationTagMutation($input: CreateOrganizationTagInput!) {
    organizationTag: createOrganizationTag(input: $input) {
      ...OrganizationTag
      organization {
        id
        tags {
          ...OrganizationTag
        }
        allTags {
          ...OrganizationTag
        }
      }
    }
  }

  ${Fragments.organizationTag}
`;

export const createWorkspace = gql`
  mutation CreateWorkspaceMutation($input: CreateWorkspaceInput!) {
    workspace: createWorkspace(input: $input) {
      ...WorkspaceDetails
      organization {
        ...OrganizationDetails
        ...OrganizationWorkspaces
      }
      section {
        ...WorkspaceSectionDetails
      }
    }
  }

  ${Fragments.workspaceDetails}
  ${Fragments.organizationDetails}
  ${organizationWorkspaces}
  ${workspaceSectionDetails}
`;

export const updateWorkspace = gql`
  mutation UpdateWorkspaceMutation($input: UpdateWorkspaceInput!) {
    workspace: updateWorkspace(input: $input) {
      ...WorkspaceDetails
    }
  }

  ${Fragments.workspaceDetails}
`;

export const deleteWorkspace = gql`
  mutation DeleteWorkspaceMutation($input: UpdateWorkspaceInput!) {
    workspace: updateWorkspace(input: $input) {
      id
      organization {
        ...OrganizationDetails
        ...OrganizationWorkspaces
      }
    }
  }

  ${Fragments.organizationDetails}
  ${organizationWorkspaces}
`;

export const createTaskSection = gql`
  mutation CreateTaskSectionMutation($input: CreateTaskSectionInput!) {
    section: createTaskSection(input: $input) {
      id
      workspace {
        id
        taskSections {
          ...TaskSection
        }
      }
    }
  }

  ${Fragments.taskSection}
`;

export const updateTaskSection = gql`
  mutation UpdateTaskSectionMutation($input: UpdateTaskSectionInput!) {
    section: updateTaskSection(input: $input) {
      ...TaskSection
    }
  }

  ${Fragments.taskSection}
`;

export const deleteTaskSection = gql`
  mutation DeleteTaskSectionMutation($input: UpdateTaskSectionInput!) {
    section: updateTaskSection(input: $input) {
      id
      workspace {
        id
        taskSections {
          ...TaskSection
        }
      }
    }
  }

  ${Fragments.taskSection}
`;

export const createTask = gql`
  mutation CreateTaskMutation($input: CreateTaskInput!) {
    task: createTask(input: $input) {
      ...Task
    }
  }

  ${Fragments.task}
`;

export const updateTask = gql`
  mutation UpdateTaskMutation($input: UpdateTaskInput!) {
    task: updateTask(input: $input) {
      ...Task
    }
  }

  ${Fragments.task}
`;

export const createTaskApplication = gql`
  mutation CreateTaskApplicationMutation($input: CreateTaskApplicationInput!) {
    application: createTaskApplication(input: $input) {
      ...TaskApplication
      task {
        ...TaskDetails
      }
    }
  }

  ${Fragments.taskDetails}
  ${Fragments.taskApplication}
`;

export const updateTaskApplication = gql`
  mutation UpdateTaskApplicationMutation($input: UpdateTaskApplicationInput!) {
    task: updateTaskApplication(input: $input) {
      ...TaskDetails
    }
  }

  ${Fragments.taskDetails}
`;

export const createTaskSubmission = gql`
  mutation CreateTaskSubmissionMutation($input: CreateTaskSubmissionInput!) {
    createTaskSubmission(input: $input) {
      id
      task {
        ...TaskDetails
      }
    }
  }

  ${Fragments.taskDetails}
`;

export const updateTaskSubmission = gql`
  mutation UpdateTaskSubmissionMutation($input: UpdateTaskSubmissionInput!) {
    updateTaskSubmission(input: $input) {
      id
      task {
        ...TaskDetails
      }
    }
  }

  ${Fragments.taskDetails}
`;

export const deleteTask = gql`
  mutation DeleteTaskMutation($taskId: UUID!) {
    task: deleteTask(id: $taskId) {
      id
      deletedAt
    }
  }
`;

export const createTaskReaction = gql`
  mutation CreateTaskReactionMutation($input: TaskReactionInput!) {
    task: createTaskReaction(input: $input) {
      id
      reactions {
        ...TaskReaction
      }
    }
  }

  ${Fragments.taskReaction}
`;

export const deleteTaskReaction = gql`
  mutation DeleteTaskReactionMutation($input: TaskReactionInput!) {
    task: deleteTaskReaction(input: $input) {
      id
      reactions {
        ...TaskReaction
      }
    }
  }

  ${Fragments.taskReaction}
`;

export const createTaskTag = gql`
  mutation CreateTaskTagMutation($input: CreateTaskTagInput!) {
    taskTag: createTaskTag(input: $input) {
      ...TaskTag
      workspace {
        id
        taskTags {
          ...TaskTag
        }
      }
    }
  }

  ${taskTag}
`;

export const updateTaskTag = gql`
  mutation UpdateTaskTagMutation($input: UpdateTaskTagInput!) {
    taskTag: updateTaskTag(input: $input) {
      ...TaskTag
      workspace {
        id
        taskTags {
          ...TaskTag
        }
      }
    }
  }

  ${taskTag}
`;

export const createWorkspaceIntegration = gql`
  mutation CreateWorkspaceIntegrationMutation(
    $input: CreateWorkspaceIntegrationInput!
  ) {
    integration: createWorkspaceIntegration(input: $input) {
      ...WorkspaceIntegration
      workspace {
        id
        integrations {
          ...WorkspaceIntegration
        }
      }
    }
  }

  ${Fragments.workspaceIntegration}
`;

export const createOrganizationIntegration = gql`
  mutation CreateOrganizationIntegrationMutation(
    $input: CreateOrganizationIntegrationInput!
  ) {
    integration: createOrganizationIntegration(input: $input) {
      ...OrganizationIntegration
      organization {
        id
        integrations {
          ...OrganizationIntegration
        }
      }
    }
  }

  ${Fragments.organizationIntegration}
`;

export const setUserDetail = gql`
  mutation SetUserDetailMutation($input: SetUserDetailInput!) {
    organization: setUserDetail(input: $input) {
      ...User
      details {
        ...EntityDetail
      }
    }
  }

  ${user}
  ${Fragments.entityDetail}
`;

export const setOrganizationDetail = gql`
  mutation SetOrganizationDetailMutation($input: SetOrganizationDetailInput!) {
    organization: setOrganizationDetail(input: $input) {
      ...Organization
      details {
        ...EntityDetail
      }
    }
  }

  ${organization}
  ${Fragments.entityDetail}
`;

export const createWorkspaceTokenGate = gql`
  mutation CreateWorkspaceTokenGateMutation($input: WorkspaceTokenGateInput!) {
    tokenGate: createWorkspaceTokenGate(input: $input) {
      id
      workspace {
        id
        tokenGates {
          ...WorkspaceTokenGate
        }
      }
    }
  }

  ${Fragments.workspaceTokenGate}
`;

export const deleteWorkspaceTokenGate = gql`
  mutation DeleteWorkspaceTokenGateMutation($input: WorkspaceTokenGateInput!) {
    tokenGate: deleteWorkspaceTokenGate(input: $input) {
      id
      tokenGates {
        ...WorkspaceTokenGate
      }
    }
  }

  ${Fragments.workspaceTokenGate}
`;

export const createInvite = gql`
  mutation CreateInviteMutation($input: CreateInviteInput!) {
    invite: createInvite(input: $input) {
      id
      permalink
    }
  }
`;

export const acceptInvite = gql`
  mutation AcceptInviteMutation($inviteId: UUID!) {
    invite: acceptInvite(id: $inviteId) {
      id
      organization {
        id
        users {
          ...User
        }
      }
      workspace {
        id
        name
        organization {
          id
          users {
            ...User
          }
        }
      }
    }
  }

  ${user}
`;

export const joinWorkspaceWithToken = gql`
  mutation JoinWorkspaceWithTokenMutation($workspaceId: UUID!) {
    workspace: joinWorkspaceWithToken(workspaceId: $workspaceId) {
      ...Workspace
    }
  }

  ${workspace}
`;

export const createPaymentMethod = gql`
  mutation CreatePaymentMethodMutation($input: CreatePaymentMethodInput!) {
    paymentMethod: createPaymentMethod(input: $input) {
      ...PaymentMethod
      workspace {
        id
        paymentMethods {
          ...PaymentMethod
        }
      }
    }
  }

  ${paymentMethod}
`;

export const createPaymentToken = gql`
  mutation CreatePaymentTokenMutation($input: CreatePaymentTokenInput!) {
    token: createPaymentToken(input: $input) {
      ...PaymentToken
      network {
        ...PaymentNetwork
        tokens {
          ...PaymentToken
        }
      }
    }
  }

  ${token}
  ${network}
`;

export const updatePaymentMethod = gql`
  mutation UpdatePaymentMethodMutation($input: UpdatePaymentMethodInput!) {
    paymentMethod: updatePaymentMethod(input: $input) {
      ...PaymentMethod
      workspace {
        id
        paymentMethods {
          ...PaymentMethod
        }
      }
    }
  }

  ${paymentMethod}
`;

export const updateWorkspaceIntegration = gql`
  mutation UpdateWorkspaceIntegrationMutation(
    $input: UpdateWorkspaceIntegrationInput!
  ) {
    integration: updateWorkspaceIntegration(input: $input) {
      ...WorkspaceIntegration
      workspace {
        id
        integrations {
          ...WorkspaceIntegration
        }
      }
    }
  }

  ${Fragments.workspaceIntegration}
`;

export const createFileUploadUrl = gql`
  mutation CreateFileUploadMutation($input: CreateFileUploadUrlInput!) {
    fileUpload: createFileUploadUrl(input: $input) {
      signedUrl
      publicUrl
    }
  }
`;

export const createTaskPayments = gql`
  mutation CreateTaskPaymentsMutation($input: CreateTaskPaymentsInput!) {
    tasks: createTaskPayments(input: $input) {
      ...TaskDetails
    }
  }

  ${Fragments.taskDetails}
`;

export const clearTaskPayments = gql`
  mutation ClearTaskPaymentsMutation($paymentId: UUID!) {
    tasks: clearTaskPayments(paymentId: $paymentId) {
      ...TaskDetails
    }
  }

  ${Fragments.taskDetails}
`;

export const createTasksFromGithubIssues = gql`
  mutation CreateTasksFromGithubIssuesMutation($workspaceId: UUID!) {
    workspace: createTasksFromGithubIssues(workspaceId: $workspaceId) {
      id
      tasks {
        ...Task
      }
    }
  }

  ${Fragments.task}
`;

export const createTaskDiscordLink = gql`
  mutation CreateTaskDiscordLinkMutation($taskId: UUID!) {
    link: createTaskDiscordLink(taskId: $taskId)
  }
`;

export const createWorkspacesFromNotion = gql`
  mutation CreateWorkspacesFromNotionMutation(
    $input: CreateWorkspacesFromNotionInput!
  ) {
    organization: createWorkspacesFromNotion(input: $input) {
      ...OrganizationDetails
      ...OrganizationWorkspaces
    }
  }

  ${Fragments.organizationDetails}
  ${organizationWorkspaces}
`;

export const createWorkspacesFromTrello = gql`
  mutation CreateWorkspacesFromTrelloMutation(
    $input: CreateWorkspacesFromTrelloInput!
  ) {
    organization: createWorkspacesFromTrello(input: $input) {
      ...OrganizationDetails
      ...OrganizationWorkspaces
    }
  }

  ${Fragments.organizationDetails}
  ${organizationWorkspaces}
`;

export const createWorkspacesFromGithub = gql`
  mutation CreateWorkspacesFromGithubMutation(
    $input: CreateWorkspacesFromGithubInput!
  ) {
    organization: createWorkspacesFromGithub(input: $input) {
      ...OrganizationDetails
      ...OrganizationWorkspaces
    }
  }

  ${Fragments.organizationDetails}
  ${organizationWorkspaces}
`;

export const addUserToDiscordGuild = gql`
  mutation AddUserToDiscordGuildMutation($organizationId: UUID!) {
    added: addUserToDiscordGuild(organizationId: $organizationId)
  }
`;

export const updateOrganizationDiscordRoles = gql`
  mutation UpdateOrganizationRolesDiscordMutation($organizationId: UUID!) {
    organization: updateOrganizationDiscordRoles(
      organizationId: $organizationId
    ) {
      ...OrganizationRoles
    }
  }

  ${organizationRoles}
`;

export const addRole = gql`
  mutation AddRoleMutation($roleId: UUID!, $userId: UUID!) {
    addRole(roleId: $roleId, userId: $userId) {
      ...User
    }
  }

  ${user}
`;

export const removeRole = gql`
  mutation RemoveRoleMutation($roleId: UUID!, $userId: UUID!) {
    removeRole(roleId: $roleId, userId: $userId) {
      ...User
    }
  }

  ${user}
`;

export const createRole = gql`
  mutation CreateRoleMutation($input: CreateRoleInput!) {
    role: createRole(input: $input) {
      ...RoleWithRules
      node {
        parents {
          node: parent {
            id
            organization {
              ...OrganizationRoles
            }
          }
        }
      }
    }
  }

  ${roleWithRules}
  ${organizationRoles}
`;

export const updateRole = gql`
  mutation UpdateRoleMutation($input: UpdateRoleInput!) {
    role: updateRole(input: $input) {
      ...RoleDetails
    }
  }

  ${roleDetails}
`;

export const addTokenToOrganization = gql`
  mutation AddTokenToOrganizationMutation(
    $organizationId: UUID!
    $tokenId: UUID!
  ) {
    organization: addTokenToOrganization(
      organizationId: $organizationId
      tokenId: $tokenId
    ) {
      ...OrganizationWithTokens
    }
  }

  ${Fragments.organizationWithTokens}
`;

export const removeTokenFromOrganization = gql`
  mutation RemoveTokenFromOrganizationMutation(
    $organizationId: UUID!
    $tokenId: UUID!
  ) {
    organization: removeTokenFromOrganization(
      organizationId: $organizationId
      tokenId: $tokenId
    ) {
      ...OrganizationWithTokens
    }
  }

  ${Fragments.organizationWithTokens}
`;

export const createRule = gql`
  mutation CreateRuleMutation($input: CreateRuleInput!) {
    rule: createRule(input: $input) {
      ...Rule
      role {
        ...RoleWithRules
      }
    }
  }

  ${rule}
  ${roleWithRules}
`;

export const deleteRule = gql`
  mutation DeleteRuleMutation($id: UUID!) {
    role: deleteRule(id: $id) {
      ...RoleWithRules
    }
  }

  ${roleWithRules}
`;

export const setTaskGatingDefault = gql`
  mutation SetTaskGatingDefault($input: TaskGatingDefaultInput!) {
    setTaskGatingDefault(input: $input) {
      id
      taskGatingDefaults {
        ...TaskGatingDefault
      }
    }
  }

  ${Fragments.taskGatingDefault}
`;

export const createUserBookmarks = gql`
  mutation CreateUserBookmarksMutation($taskIds: [UUID!]!) {
    createUserBookmarks(taskIds: $taskIds) {
      id
      bookmarks {
        task {
          ...Task
        }
      }
    }
  }

  ${Fragments.task}
`;

export const removeUserBookmarks = gql`
  mutation RemoveUserBookmarksMutation($taskIds: [UUID!]!) {
    removeUserBookmarks(taskIds: $taskIds) {
      id
      bookmarks {
        task {
          ...Task
        }
      }
    }
  }

  ${Fragments.task}
`;
