import React, { FC, useCallback, useMemo } from "react";
import {
  Form,
  Button,
  Input,
  Typography,
  DatePicker,
  Switch,
  Row,
  Select,
} from "antd";
import { useCreateWorkspace } from "../hooks";
import {
  RulePermission,
  WorkspaceType,
  WorkspaceSection,
  WorkspaceDetails,
} from "@dewo/app/graphql/types";
import {
  useOrganizationDetails,
  useOrganizationUsers,
} from "../../organization/hooks";
import _ from "lodash";
import { useRunning } from "@dewo/app/util/hooks";
import { useSyncRules } from "../../rbac/hooks";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useForm, useWatch } from "antd/lib/form/Form";
import { WorkspaceTypeCheckbox } from "./WorkspaceTypeCheckbox";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import moment from "moment";
import { UserSelect } from "@dewo/app/components/form/UserSelect";
import { FormSection } from "@dewo/app/components/form/FormSection";
import {
  useDefaultAbility,
  usePermission,
} from "@dewo/app/contexts/PermissionsContext";
import { useCreateWorkspaceSection } from "../section/hooks";
import styles from "./WorkspaceCreateForm.module.less";
import { EmojiPickerIcon } from "@dewo/app/components/emoji/EmojiPickerIcon";

type WorkspaceCreateFormType = WorkspaceType | "SECTION";

export interface FormValues {
  name: string;
  icon?: string;
  organizationId: string;
  sectionId?: string | null;
  parentId?: string;
  type: WorkspaceCreateFormType;
  private: boolean;
  dates?: [moment.Moment | null, moment.Moment | null];
  managerIds?: string[];
}

const nameByType: Record<WorkspaceCreateFormType, string> = {
  [WorkspaceType.FOLDER]: "Space",
  [WorkspaceType.PROJECT]: "Project",
  SECTION: "Section",
};

export interface WorkspaceCreateFormProps {
  organizationId: string;
  initialValues?: Partial<FormValues>;
  onWorkspaceCreated(workspace: WorkspaceDetails): unknown;
  onWorkspaceSectionCreated(section: WorkspaceSection): unknown;
  mode?: "duplicate" | "create";
}

export const WorkspaceCreateForm: FC<WorkspaceCreateFormProps> = ({
  organizationId,
  initialValues,
  onWorkspaceCreated,
  onWorkspaceSectionCreated,
  mode = "create",
}) => {
  const [form] = useForm<FormValues>();
  const { organization } = useOrganizationDetails(organizationId);
  const { admins, users } = useOrganizationUsers(organizationId);
  const allUsers = useMemo(
    () =>
      !!admins && !!users ? _.uniqBy([...admins, ...users], "id") : undefined,
    [admins, users]
  );

  const type = useWatch("type", form);
  const isPrivate = useWatch("private", form);

  const createWorkspace = useCreateWorkspace();
  const createSection = useCreateWorkspaceSection();
  const refetchDefaultAbility = useDefaultAbility(organizationId).refetch;

  const { user } = useAuthContext();
  const syncRules = useSyncRules();
  const [handleSubmit, submitting] = useRunning(
    useCallback(
      async (values: FormValues) => {
        if (values.type === "SECTION") {
          const section = await createSection({
            name: values.name,
            organizationId,
          });
          await onWorkspaceSectionCreated(section);
        } else {
          const workspace = await createWorkspace({
            name: values.name,
            icon: values.icon,
            type: values.type,
            startAt: !!values.dates?.[0]
              ? values.dates[0].toISOString()
              : undefined,
            endAt: !!values.dates?.[1]
              ? values.dates[1].toISOString()
              : undefined,
            sectionId:
              values.sectionId !== undefined
                ? values.sectionId
                : organization?.workspaceSections[0]?.id,
            organizationId: values.organizationId,
            parentId: values.parentId,
            options: { showCommunitySuggestions: true },
          });

          await Promise.all([
            !!values.private &&
              syncRules({
                organizationId,
                roleIds: [],
                userIds: [user!.id],
                permission: RulePermission.VIEW_PROJECTS,
                context: { workspaceId: workspace.id },
                disallowEveryoneElse: true,
              }).then(refetchDefaultAbility),
            workspace.type === WorkspaceType.PROJECT &&
              values.managerIds?.length &&
              syncRules({
                organizationId,
                roleIds: [],
                userIds: values.managerIds,
                permission: RulePermission.MANAGE_PROJECTS,
                context: { workspaceId: workspace.id },
              }),
          ]);

          await onWorkspaceCreated(workspace);
        }
      },
      [
        createWorkspace,
        createSection,
        onWorkspaceCreated,
        onWorkspaceSectionCreated,
        syncRules,
        refetchDefaultAbility,
        organizationId,
        organization?.workspaceSections,
        user,
      ]
    )
  );
  const sections = useMemo(
    () => [
      ...(organization?.workspaceSections.map((section) => ({
        label: section.name,
        value: section.id,
      })) ?? []),
    ],
    [organization?.workspaceSections]
  );

  const initialValuesWithDefaults = useMemo<Partial<FormValues>>(
    () => ({
      organizationId,
      type: WorkspaceType.FOLDER,
      private: false,
      sectionId: sections[0]?.value,
      ...initialValues,
    }),
    [organizationId, initialValues, sections]
  );

  const shouldAllowSelectingType = !initialValues?.type;
  const canCreateSection = usePermission("create", "WorkspaceSection");

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValuesWithDefaults}
      className={styles.form}
      onFinish={handleSubmit}
    >
      <Form.Item
        label={[nameByType[type], "Name"].join(" ")}
        name="name"
        rules={[{ required: true, message: "Please enter a name" }]}
      >
        <Input
          placeholder="Enter a name..."
          autoFocus
          prefix={
            type !== "SECTION" && (
              <Form.Item name="icon" style={{ margin: 0 }}>
                <EmojiPickerIcon />
              </Form.Item>
            )
          }
        />
      </Form.Item>
      <Form.Item name="type" hidden={!shouldAllowSelectingType}>
        <WorkspaceTypeCheckbox
          showSection={canCreateSection && !initialValues?.parentId}
        />
      </Form.Item>
      <HeadlessCollapse expanded={type === WorkspaceType.PROJECT}>
        <Form.Item label="Project Owner" name="managerIds">
          <UserSelect
            users={allUsers}
            loading={!allUsers}
            mode="multiple"
            placeholder="Select project owner..."
          />
        </Form.Item>
        <Form.Item label="Dates" name="dates">
          <DatePicker.RangePicker style={{ width: "100%" }} />
        </Form.Item>
      </HeadlessCollapse>
      {mode === "create" && (
        <HeadlessCollapse expanded={type !== "SECTION"}>
          <FormSection label="Visibility">
            <Typography.Text type="secondary">
              {isPrivate
                ? `You can change who can view your ${nameByType[type]} later in the workspace settings`
                : `Anyone can view this ${nameByType[type]} and its tasks`}
            </Typography.Text>
            <Row align="middle" style={{ columnGap: 8 }}>
              <Typography.Text>Private</Typography.Text>
              <Form.Item
                name="private"
                valuePropName="checked"
                style={{ margin: 0 }}
              >
                <Switch />
              </Form.Item>
            </Row>
          </FormSection>
        </HeadlessCollapse>
      )}
      <Form.Item
        name="sectionId"
        hidden={
          mode === "create" ||
          type === "SECTION" ||
          !organization?.workspaceSections.length
        }
        label="Section"
      >
        <Select options={sections} />
      </Form.Item>
      <Form.Item name="sectionId" hidden />
      <Form.Item name="parentId" hidden />
      <Form.Item name="organizationId" hidden rules={[{ required: true }]} />
      <Button
        type="primary"
        size="large"
        htmlType="submit"
        block
        loading={submitting}
      >
        Create
      </Button>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", marginTop: 16 }}
      >
        All settings above can be changed later!
      </Typography.Paragraph>
    </Form>
  );
};
