import React from "react";
import Icon from "@ant-design/icons";

const MedalSvg = () => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00045 10.1226L6.0008 7.12205C5.3159 7.6714 4.4463 8 3.5 8C2.5542 8 1.6851 7.67175 1.00034 7.12295L1.00126 10.1226C1.00126 10.4264 1.34373 10.6041 1.59218 10.4291L3.5004 9.0853L5.4096 10.4292C5.65805 10.6041 6.00045 10.4264 6.00045 10.1226ZM7 4C7 2.06701 5.433 0.5 3.5 0.5C1.56701 0.5 0 2.06701 0 4C0 5.933 1.56701 7.5 3.5 7.5C5.433 7.5 7 5.933 7 4ZM3.6144 2.32117L4.0707 3.2475L5.09265 3.39518C5.1973 3.4103 5.23915 3.53891 5.1634 3.61271L4.42425 4.33294L4.59875 5.35065C4.6166 5.45485 4.5072 5.5343 4.41365 5.485L3.50005 5.0038L2.5864 5.485C2.49283 5.5343 2.38337 5.4548 2.40133 5.35055L2.5767 4.33294L1.8367 3.61276C1.76089 3.53899 1.8027 3.41031 1.90739 3.39518L2.92935 3.2475L3.38565 2.32117C3.4324 2.22628 3.5677 2.22628 3.6144 2.32117Z"
      fill="currentColor"
    />
  </svg>
);

export const MedalIcon = (props: any) => (
  <Icon component={MedalSvg} {...props} />
);
