import { CloseCircleOutlined } from "@ant-design/icons";
import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { Notification } from "@dewo/app/graphql/types";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { useRunning } from "@dewo/app/util/hooks";
import { useNavigateToTaskFn } from "@dewo/app/util/navigation";
import { Tooltip, Card, List, Button, Typography } from "antd";
import classNames from "classnames";
import React, { FC, useCallback, MouseEvent } from "react";
import { useArchiveNotification } from "./hooks";
import { NotificationBreadcrumb } from "./NotificationBreadcrumb";
import styles from "./NotificationList.module.less";
import { NotificationListItemIcon } from "./NotificationListItemIcon";

interface Props {
  notification: Notification;
  read: boolean;
}

export const NotificationListItem: FC<Props> = ({ notification, read }) => {
  const navigateToTask = useNavigateToTaskFn();
  const handleClick = useCallback(() => {
    if (!!notification.task) {
      navigateToTask(notification.task.id);
    }
  }, [navigateToTask, notification.task]);

  const archive = useArchiveNotification(notification.id);
  const [handleArchive, archiving] = useRunning(
    useCallback(
      (e: MouseEvent<unknown>) => {
        stopPropagation(e);
        return archive();
      },
      [archive]
    )
  );

  const organization =
    notification.organization ?? notification.task?.workspace.organization;

  const title = notification.task?.name ?? notification.organization?.name;

  return (
    <Card
      bordered={false}
      size="small"
      style={{ marginBottom: 8 }}
      className="hover:component-highlight hover:cursor-pointer"
      onClick={handleClick}
    >
      <List.Item className={styles.listItem}>
        <List.Item.Meta
          avatar={
            !!organization && <OrganizationAvatar organization={organization} />
          }
          title={
            <Typography.Text type={read ? "secondary" : undefined}>
              {title}
            </Typography.Text>
          }
          description={
            <>
              <Typography.Text
                className={styles.notificationMessage}
                type={read ? "secondary" : undefined}
              >
                <NotificationListItemIcon notification={notification} />
                {notification.message}
              </Typography.Text>
              <NotificationBreadcrumb notification={notification} />
            </>
          }
        />
        <Tooltip title="Archive this notification">
          <Button
            type="text"
            icon={<CloseCircleOutlined />}
            loading={archiving}
            className={classNames(styles.archiveButton, "text-secondary")}
            onClick={handleArchive}
          />
        </Tooltip>
      </List.Item>
    </Card>
  );
};
