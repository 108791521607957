import { User } from "@dewo/app/graphql/types";
import { Avatar, Select, SelectProps, Typography } from "antd";
import React, { CSSProperties, FC, ReactNode } from "react";
import { UserSelectOption } from "./UserSelectOption";
import styles from "./UserSelect.module.less";
import { DefaultOptionType } from "antd/lib/select";
import { UserDeleteOutlined } from "@ant-design/icons";

interface Props {
  id?: string;
  mode?: "multiple" | undefined;
  placeholder?: ReactNode;
  users: User[] | undefined;
  disabled?: boolean;
  value?: string[];
  loading?: boolean;
  showUnassigned?: boolean;
  style?: CSSProperties;
  onChange?(value: string[]): void;
  onClear?(): void;
  onDropdownVisibleChange?(open: boolean): void;
  dropdownRender?: SelectProps["dropdownRender"];
}

export const UserSelect: FC<Props> = ({
  id,
  mode,
  placeholder,
  users,
  disabled,
  value,
  loading,
  showUnassigned,
  style,
  onChange,
  onClear,
  onDropdownVisibleChange,
  dropdownRender,
}) => {
  const unassigned = (
    <>
      <Avatar size="small" icon={<UserDeleteOutlined />} />
      <Typography.Text style={{ marginLeft: 8 }}>Unassigned</Typography.Text>
    </>
  );

  return (
    <Select
      id={id}
      mode={mode}
      showSearch
      className={styles.select}
      disabled={disabled}
      allowClear
      placeholder={placeholder}
      value={value}
      loading={loading}
      style={style}
      onChange={onChange}
      onClear={onClear}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={dropdownRender}
      optionFilterProp="name"
      options={[
        ...(showUnassigned ? [{ value: null, label: unassigned }] : []),
        ...(users ?? []).map(
          (user): DefaultOptionType => ({
            value: user.id,
            name: user.username,
            label: <UserSelectOption user={user} />,
          })
        ),
      ]}
      tagRender={(props) => {
        if (props.value === null) return unassigned;
        return <>{props.label}</>;
      }}
    />
  );
};
