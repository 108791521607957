import React, { FC, useMemo } from "react";
import { Task, UpdateTaskRewardInput } from "@dewo/app/graphql/types";
import { EditableTag } from "@dewo/app/components/tags/EditableTag";
import { MultipleTaskRewardsTag } from "../TaskRewardTag";
import { DollarOutlined } from "@ant-design/icons";
import { TaskOpenToBidsTag } from "@dewo/app/components/task/TaskOpenToBidsTag";
import { useCanUpdateTaskReward } from "./hooks";
import { useWorkspace } from "@dewo/app/containers/workspace/hooks";
import { useOrganizationTokens } from "@dewo/app/containers/organization/hooks";

interface Props {
  task?: Task;
  workspaceId: string;
  rewards: UpdateTaskRewardInput[];
  openToBids?: boolean;
  onClick(): void;
}

const TaskRewardTagFromFormValues: FC<{
  rewards: UpdateTaskRewardInput[];
  workspaceId: string;
  onClick(): void;
}> = ({ rewards, workspaceId, onClick }) => {
  const { workspace } = useWorkspace(workspaceId);
  const tokens = useOrganizationTokens(workspace?.organizationId);
  const rewardsToRender = useMemo(
    () =>
      rewards
        .map((reward) => ({
          amount: reward.amount,
          peggedToUsd: !!reward.peggedToUsd,
          token: tokens?.find((t) => t.id === reward.tokenId)!,
          type: reward.type ?? undefined,
          count: reward.count,
        }))
        .filter((reward) => !!reward.token),
    [rewards, tokens]
  );
  return (
    <MultipleTaskRewardsTag
      rewards={rewardsToRender}
      style={{ border: "1px solid white", cursor: "pointer" }}
      onClick={onClick}
    />
  );
};

export const TaskFormRewardTag: FC<Props> = ({
  task,
  workspaceId,
  rewards,
  openToBids,
  onClick,
}) => {
  const canUpdate = useCanUpdateTaskReward(task);
  if (!canUpdate && !!task) {
    if (openToBids && !task.rewards.length) return <TaskOpenToBidsTag />;
    return <MultipleTaskRewardsTag rewards={task.rewards} />;
  }

  if (!!rewards?.length) {
    return (
      <TaskRewardTagFromFormValues
        rewards={rewards}
        workspaceId={workspaceId}
        onClick={onClick}
      />
    );
  }

  if (openToBids) {
    return (
      <TaskOpenToBidsTag
        style={{ border: "1px solid white", cursor: "pointer" }}
        onClick={onClick}
      />
    );
  }

  return (
    <EditableTag
      icon={<DollarOutlined className="text-secondary" />}
      onClick={onClick}
    >
      Add Bounty
    </EditableTag>
  );
};
