import { UserSkillSelect } from "@dewo/app/containers/user/UserSkillSelect";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useRunning } from "@dewo/app/util/hooks";
import { Button, Space, Typography } from "antd";
import React, { FC, useCallback } from "react";
import { UserPromptStepComponentProps } from "../../types";

export const OnboardingSkills: FC<UserPromptStepComponentProps> = ({
  onNext,
}) => {
  const hasSkills = !!useAuthContext().user?.skills.length;
  const [handleNext, loadingNext] = useRunning(
    useCallback(() => onNext(), [onNext])
  );
  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Skills
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        Select your skills and Dework will show you matching bounties and tasks
      </Typography.Paragraph>
      <UserSkillSelect style={{ width: "100%" }} placeholder="Select skills" />
      <div style={{ flex: 1 }} />
      <Space direction="vertical" size="small" align="center">
        {hasSkills && (
          <Button
            size="large"
            type="primary"
            className="mx-auto"
            loading={loadingNext}
            name="Onboarding Skills: next"
            onClick={handleNext}
          >
            Next
          </Button>
        )}
        <Button
          type="text"
          className="text-secondary"
          name="Onboarding Skills: skip"
          onClick={handleNext}
        >
          Skip
        </Button>
      </Space>
    </>
  );
};
