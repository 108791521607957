import React, { FC, ReactNode, useCallback, useMemo } from "react";
import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import {
  ThreepidAuthSessionType,
  ThreepidSource,
} from "@dewo/app/graphql/types";
import { Constants } from "@dewo/app/util/constants";
import { Button, ButtonProps } from "antd";
import { MetamaskIcon } from "@dewo/app/components/icons/Metamask";
import { NotionIcon } from "@dewo/app/components/icons/Notion";
import { HiroIcon } from "@dewo/app/components/icons/Hiro";
import { TrelloIcon } from "@dewo/app/components/icons/Trello";
import { useRouter } from "next/router";
import { PhantomIcon } from "@dewo/app/components/icons/Phantom";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import classNames from "classnames";
import styles from "./ThreepidAuthButton.module.less";
import { useCreateThreepidAuthSession } from "../hooks";
import { GithubOutlined } from "@ant-design/icons";
// const styles: any = {};

export const renderThreepidIcon: Record<ThreepidSource, ReactNode> = {
  [ThreepidSource.discord]: <DiscordIcon />,
  [ThreepidSource.github]: <GithubOutlined />,
  [ThreepidSource.metamask]: <MetamaskIcon />,
  [ThreepidSource.phantom]: <PhantomIcon />,
  [ThreepidSource.notion]: <NotionIcon />,
  [ThreepidSource.trello]: <TrelloIcon />,
  [ThreepidSource.hiro]: <HiroIcon />,
};

export const getThreepidName: Record<ThreepidSource, string> = {
  [ThreepidSource.discord]: "Discord",
  [ThreepidSource.github]: "GitHub",
  [ThreepidSource.metamask]: "Metamask",
  [ThreepidSource.phantom]: "Phantom",
  [ThreepidSource.notion]: "Notion",
  [ThreepidSource.trello]: "Trello",
  [ThreepidSource.hiro]: "Hiro Wallet",
};

const authSessionTypeBySource: Partial<
  Record<ThreepidSource, ThreepidAuthSessionType>
> = {
  [ThreepidSource.discord]: ThreepidAuthSessionType.DISCORD,
  [ThreepidSource.github]: ThreepidAuthSessionType.GITHUB,
};

interface Props extends Omit<ButtonProps, "href"> {
  source: ThreepidSource;
  redirect?: string;
}

export const ThreepidAuthButton: FC<Props> = ({
  source,
  redirect,
  className,
  ...buttonProps
}) => {
  const { user } = useAuthContext();
  const connected = useMemo(
    () => user?.threepids?.some((t) => t.source === source),
    [user, source]
  );

  const router = useRouter();
  const createSession = useCreateThreepidAuthSession();
  const handleClick = useCallback(async () => {
    const sessionId = await createSession(
      authSessionTypeBySource[source]!,
      redirect ?? router.asPath
    );

    window.location.href = `${Constants.GRAPHQL_API_URL}/auth/${source}?state=${sessionId}`;
  }, [createSession, router.asPath, source, redirect]);

  return (
    <Button
      htmlType="button"
      icon={renderThreepidIcon[source]}
      onClick={handleClick}
      {...buttonProps}
      disabled={buttonProps.disabled || connected}
      className={classNames({ className, [styles.connected]: connected })}
    />
  );
};
