import { Dropdown, DropDownProps, Input, Menu } from "antd";
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { eatClick, stopPropagation } from "../../util/eatClick";

type Value = string | number;

export interface DropdownSelectOption<K extends Value> {
  value: K;
  label: ReactNode;
  disabled?: boolean;
  data?: string;
  children?: DropdownSelectOption<K>[];
}

interface DropdownSelectProps<K extends Value = Value, T extends K | K[] = K> {
  value?: T;
  mode: "default" | "multiple";
  placement?: DropDownProps["placement"];
  disabled?: boolean;
  children: ReactNode;
  options?: DropdownSelectOption<K>[];
  onChange?(value: T): void;
  onVisibleChange?(visible: boolean): void;
  showSearch?: boolean;
  menuStyle?: CSSProperties;
  footer?: ReactNode;
}

export function DropdownSelect<K extends Value, T extends K | K[] = K>({
  value,
  mode,
  options,
  disabled,
  placement,
  children,
  onChange,
  onVisibleChange,
  menuStyle,
  footer,
  showSearch = false,
}: DropdownSelectProps<K, T>) {
  const isSelected = useCallback(
    (v: K) => {
      if (mode === "default") return value === v;
      if (Array.isArray(value)) return value.includes(v);
      return false;
    },
    [value, mode]
  );

  const handleSelect = useCallback(
    (v: K) => {
      if (mode === "default") {
        onChange?.(v as T);
      } else {
        const array = value as Value[] | undefined;
        if (!!array?.includes(v as Value)) {
          onChange?.((array?.filter((x) => x !== v) as T) ?? []);
        } else {
          onChange?.([...(array ?? []), v] as T);
        }
      }
    },
    [value, mode, onChange]
  );

  const [filter, setFilter] = useState("");

  const filteredOptions = useMemo(() => {
    if (!options) return [];
    if (!filter) return options;

    const lowerCasedFilter = filter.toLowerCase();
    return options.filter(({ data }) =>
      data?.toLowerCase()?.includes(lowerCasedFilter)
    );
  }, [filter, options]);

  const renderMenuItem = (option: DropdownSelectOption<K>) =>
    !!option.children?.length ? (
      <Menu.SubMenu
        key={option.value}
        disabled={option.disabled}
        title={option.label}
        className={
          isSelected(option.value)
            ? "ant-select-item-option-selected"
            : undefined
        }
        onTitleClick={() => handleSelect(option.value)}
      >
        {option.children.map(renderMenuItem)}
      </Menu.SubMenu>
    ) : (
      <Menu.Item
        key={option.value}
        disabled={option.disabled}
        className={
          isSelected(option.value)
            ? "ant-select-item-option-selected"
            : undefined
        }
        onClick={() => handleSelect(option.value)}
      >
        {option.label}
      </Menu.Item>
    );

  return (
    <Dropdown
      destroyPopupOnHide
      placement={placement}
      disabled={disabled}
      trigger={["click"]}
      // @ts-ignore
      onClick={eatClick}
      onVisibleChange={onVisibleChange}
      overlay={
        <Menu
          onClick={(e) => stopPropagation(e.domEvent)}
          style={{ ...menuStyle, maxHeight: 264, overflowY: "auto" }}
        >
          {showSearch && (
            <div style={{ padding: "5px 12px" }}>
              <Input
                autoFocus
                value={filter}
                onChange={(e) => setFilter(e.currentTarget.value)}
                onClick={stopPropagation}
                placeholder="Search..."
              />
            </div>
          )}
          {filteredOptions.map(renderMenuItem)}
          {footer}
        </Menu>
      }
      children={children}
    />
  );
}
