import gql from "graphql-tag";
import * as Fragments from "./fragments";
import { network, token } from "./fragments/payment";
import { workspace, workspaceSection } from "./fragments/workspace";
import * as TaskFragments from "./fragments/task";
import { taskTag } from "./fragments/task";
import { user } from "./fragments/user";
import { organization, organizationRoles } from "./fragments/organization";
import { skill } from "./fragments/skill";
import { role } from "./fragments/role";
import { organizationWorkspaces } from "./fragments/organization.workspaces";
import { workspaceOverview } from "./fragments/workspace.overview";
import { notionDatabase } from "./fragments/integrations";

export const me = gql`
  query MeQuery {
    me {
      ...UserDetails
    }
  }

  ${Fragments.userDetails}
`;

export const userProfileByUsername = gql`
  query UserProfileByUsernameQuery($username: String!) {
    user: getUserByUsername(username: $username) {
      ...UserProfile
    }
  }

  ${Fragments.userProfile}
`;

export const userProfile = gql`
  query UserProfileQuery($id: UUID!) {
    user: getUser(id: $id) {
      ...UserProfile
    }
  }

  ${Fragments.userProfile}
`;

export const userRoles = gql`
  query UserRolesQuery($userId: UUID!) {
    user: getUser(id: $userId) {
      ...User
      roles {
        ...Role
        node {
          parents {
            node: parent {
              id
              organization {
                ...Organization
              }
            }
          }
        }
      }
      userRoles {
        roleId
        hidden
      }
    }
  }

  ${user}
  ${role}
  ${organization}
`;

export const userTasks = gql`
  query UserTasksQuery($id: UUID!) {
    user: getUser(id: $id) {
      id
      tasks {
        ...TaskWithOrganization
      }
    }
  }

  ${Fragments.taskWithOrganization}
`;

export const userTaskViews = gql`
  query UserTaskViewsQuery($id: UUID!) {
    user: getUser(id: $id) {
      id
      taskViews {
        ...TaskView
      }
    }
  }

  ${TaskFragments.taskView}
`;

export const userAddress = gql`
  query UserAddressQuery($id: UUID!) {
    user: getUser(id: $id) {
      id
      threepids {
        source
        address: threepid
      }
    }
  }
`;

export const permissions = gql`
  query PermissionsQuery($organizationId: UUID, $unauthed: Boolean) {
    permissions: getPermissions(
      organizationId: $organizationId
      unauthed: $unauthed
    )
  }
`;

export const getOrganization = gql`
  query GetOrganizationQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...Organization
    }
  }

  ${organization}
`;

export const organizationDetails = gql`
  query GetOrganizationDetailsQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...OrganizationDetails
    }
  }

  ${Fragments.organizationDetails}
`;

export const getOrganizationWorkspaces = gql`
  query GetOrganizationWorkspacesQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...OrganizationWorkspaces
    }
  }

  ${organizationWorkspaces}
`;

export const getOrganizationOverview = gql`
  query GetOrganizationOverviewQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      id
      workspaceSections {
        ...WorkspaceSection
      }
      node {
        id
        workspaceChildren: children(types: [WORKSPACE]) {
          node: child {
            id
            workspace {
              ...WorkspaceOverview
            }
          }
        }
      }
    }
  }

  ${workspaceOverview}
  ${workspaceSection}
  ${user}
  ${skill}
`;

export const getWorkspaceOverview = gql`
  query GetWorkspaceOverviewQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      ...WorkspaceOverview
      node {
        id
        workspaceChildren: children(types: [WORKSPACE]) {
          node: child {
            id
            workspace {
              ...WorkspaceOverview
            }
          }
        }
      }
    }
  }

  ${workspaceOverview}
`;

export const organizationTaskViews = gql`
  query GetOrganizationTaskViewsQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...OrganizationDetails
      workspaces {
        id
        taskTags {
          ...TaskTag
        }
      }
    }
  }

  ${Fragments.organizationDetails}
  ${taskTag}
`;

export const organizationIntegrations = gql`
  query GetOrganizationIntegrationsQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      id
      integrations {
        ...OrganizationIntegration
      }
    }
  }

  ${Fragments.organizationIntegration}
`;

export const organizationBySlug = gql`
  query GetOrganizationBySlugQuery($organizationSlug: String!) {
    organization: getOrganizationBySlug(slug: $organizationSlug) {
      ...Organization
    }
  }

  ${organization}
`;

export const organizationUsers = gql`
  query GetOrganizationUsersQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      id
      users {
        ...User
      }
      admins {
        ...User
      }
    }
  }

  ${user}
`;

export const organizationUsersWithRoles = gql`
  query GetOrganizationUsersWithRolesQuery($nodeId: UUID!) {
    node: getGraphNode(id: $nodeId) {
      id
      organization {
        id
        users {
          ...User
          roles(parentId: $nodeId) {
            ...Role
          }
        }
      }
    }
  }

  ${user}
  ${role}
`;

export const organizationContributors = gql`
  query GetOrganizationContributorsQuery($nodeId: UUID!) {
    node: getGraphNode(id: $nodeId) {
      id
      organization {
        id
        users {
          ...User
          roles(parentId: $nodeId) {
            ...Role
          }
          threepids {
            source
            address: threepid
          }
        }
      }
    }
  }

  ${user}
  ${role}
`;

export const getOrganizationRoles = gql`
  query GetOrganizationRolesQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...OrganizationRoles
    }
  }

  ${organizationRoles}
`;

export const featuredOrganizations = gql`
  query GetFeaturedOrganizationsQuery($limit: Int!) {
    organizations: getFeaturedOrganizations(limit: $limit) {
      ...Organization
    }
  }

  ${organization}
`;

export const popularOrganizations = gql`
  query GetPopularOrganizationsQuery {
    organizations: getPopularOrganizations {
      ...Organization
      userCount
    }
  }

  ${organization}
`;

export const organizationTokens = gql`
  query GetOrganizationTokensQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      ...OrganizationWithTokens
    }
  }

  ${Fragments.organizationWithTokens}
`;

export const organizationsUserFollowsOnDiscord = gql`
  query GetOrganizationsUserFollowsOnDiscordQuery {
    organizations: getOrganizationsUserFollowsOnDiscord {
      ...Organization
    }
  }

  ${organization}
`;

export const organizationTags = gql`
  query GetOrganizationTagsQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      id
      tags {
        ...OrganizationTag
      }
      allTags {
        ...OrganizationTag
      }
    }
  }

  ${Fragments.organizationTag}
`;

export const organizationTasks = gql`
  query GetOrganizationTasksQuery(
    $organizationId: UUID!
    $filter: TaskFilterInput
  ) {
    organization: getOrganization(id: $organizationId) {
      id
      tasks(filter: $filter) {
        ...TaskWithOrganization
      }
    }
  }

  ${Fragments.taskWithOrganization}
`;

export const organizationTaskTags = gql`
  query GetOrganizationTaskTagsQuery($organizationId: UUID!) {
    organization: getOrganization(id: $organizationId) {
      id
      workspaces {
        taskTags {
          ...TaskTag
        }
      }
    }
  }

  ${taskTag}
`;

export const workspaceSectionTasks = gql`
  query GetWorkspaceSectionTasksQuery(
    $slug: String!
    $filter: TaskFilterInput
  ) {
    section: getWorkspaceSectionBySlug(slug: $slug) {
      id
      tasks(filter: $filter) {
        ...TaskWithOrganization
      }
    }
  }

  ${Fragments.taskWithOrganization}
`;

export const getWorkspace = gql`
  query GetWorkspaceQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      ...Workspace
    }
  }

  ${workspace}
`;

export const workspaceDetails = gql`
  query GetWorkspaceDetailsQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      ...WorkspaceDetails
    }
  }

  ${Fragments.workspaceDetails}
`;

export const workspaceBySlug = gql`
  query GetWorkspaceBySlugQuery($workspaceSlug: String!) {
    workspace: getWorkspaceBySlug(slug: $workspaceSlug) {
      ...Workspace
    }
  }

  ${workspace}
`;

export const workspaceIdBySlug = gql`
  query GetWorkspaceIdBySlugQuery($workspaceSlug: String!) {
    workspaceId: getWorkspaceIdBySlug(slug: $workspaceSlug)
  }
`;

export const workspaceTasks = gql`
  query GetWorkspaceTasksQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      id
      tasks {
        ...Task
      }
    }
  }

  ${Fragments.task}
`;

export const workspaceTasksExport = gql`
  query GetWorkspaceTasksExportQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      id
      tasks {
        ...Task
        permalink
        creator {
          ...User
        }
        assignees {
          ...User
          threepids {
            source
            address: threepid
          }
        }
      }
    }
  }

  ${Fragments.task}
  ${user}
`;

export const workspaceTaskTags = gql`
  query GetWorkspaceTaskTagsQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      id
      taskTags {
        ...TaskTag
      }
    }
  }

  ${taskTag}
`;

export const getTask = gql`
  query GetTaskQuery($taskId: UUID!) {
    task: getTask(id: $taskId) {
      ...Task
    }
  }

  ${Fragments.task}
`;

export const getTaskDetails = gql`
  query GetTaskDetailsQuery($taskId: UUID!) {
    task: getTask(id: $taskId) {
      ...TaskDetails
    }
  }

  ${Fragments.taskDetails}
`;

export const taskReactionUsers = gql`
  query GetTaskReactionUsersQuery($taskId: UUID!) {
    task: getTask(id: $taskId) {
      id
      reactions {
        ...TaskReaction
        user {
          ...User
        }
      }
    }
  }

  ${Fragments.taskReaction}
  ${user}
`;

export const paginatedTasks = gql`
  query GetPaginatedTasksQuery($filter: SearchTasksInput!, $cursor: String) {
    paginated: getPaginatedTasks(filter: $filter, cursor: $cursor) {
      total
      cursor
      tasks {
        ...Task
      }
    }
  }

  ${Fragments.task}
`;

export const countTasks = gql`
  query GetTaskCountQuery($filter: CountTasksInput!) {
    count: getTaskCount(filter: $filter)
  }
`;

export const paginatedTasksWithOrganization = gql`
  query GetPaginatedTasksWithOrganizationQuery(
    $filter: SearchTasksInput!
    $cursor: String
  ) {
    paginated: getPaginatedTasks(filter: $filter, cursor: $cursor) {
      total
      cursor
      tasks {
        ...TaskWithOrganization
      }
    }
  }

  ${Fragments.taskWithOrganization}
`;

export const tasksToPay = gql`
  query GetTasksToPayQuery($input: GetTasksInput!) {
    tasks: getTasks(input: $input) {
      ...Task
      assignees {
        ...User
        threepids {
          source
          address: threepid
        }
      }
      workspace {
        id
        paymentMethods {
          ...PaymentMethod
        }
      }
    }
  }

  ${Fragments.task}
  ${user}
`;

export const workspaceIntegrations = gql`
  query GetWorkspaceIntegrationsQuery($workspaceId: UUID!) {
    workspace: getWorkspace(id: $workspaceId) {
      id
      integrations {
        ...WorkspaceIntegration
      }
    }
  }

  ${Fragments.workspaceIntegration}
`;

export const invite = gql`
  query GetInviteQuery($inviteId: UUID!) {
    invite: getInvite(id: $inviteId) {
      ...Invite
    }
  }

  ${Fragments.invite}
`;

export const paymentNetworks = gql`
  query GetPaymentNetworksQuery {
    networks: getPaymentNetworks {
      ...PaymentNetwork
      tokens {
        ...PaymentToken
      }
    }
  }

  ${network}
  ${token}
`;

export const organizationGithubRepos = gql`
  query GetOrganizationGithubReposQuery($organizationId: UUID!) {
    repos: getGithubRepos(organizationId: $organizationId) {
      ...GithubRepo
    }
  }

  ${Fragments.githubRepo}
`;

export const organizationGithubRepoLabels = gql`
  query GetOrganizationGithubRepoLabelsQuery(
    $repo: String!
    $organization: String!
    $organizationId: UUID!
  ) {
    labels: getGithubLabels(
      repo: $repo
      organization: $organization
      organizationId: $organizationId
    ) {
      id
      name
    }
  }
`;

export const organizationDiscordChannels = gql`
  query GetOrganizationDiscordChannelsQuery(
    $organizationId: UUID!
    $discordParentChannelId: String
  ) {
    channels: getDiscordIntegrationChannels(
      organizationId: $organizationId
      discordParentChannelId: $discordParentChannelId
    ) {
      ...DiscordIntegrationChannel
    }
  }

  ${Fragments.discordIntegrationChannel}
`;

export const trelloBoards = gql`
  query GetTrelloBoardsQuery($threepidId: UUID!) {
    trelloBoards: getTrelloBoards(threepidId: $threepidId) {
      id
      name
    }
  }
`;

export const getNotionDatabases = gql`
  query GetNotionDatabasesQuery($organizationId: UUID!) {
    notionDatabases: getNotionDatabases(organizationId: $organizationId) {
      ...NotionDatabase
    }
  }

  ${notionDatabase}
`;

export const getDiscordGuildMembershipState = gql`
  query GetDiscordGuildMembershipStateQuery($organizationId: UUID!) {
    state: getDiscordGuildMembershipState(organizationId: $organizationId)
  }
`;

export const getDiscordGuildRoles = gql`
  query GetDiscordGuildRolesQuery($organizationId: UUID!) {
    roles: getDiscordGuildRoles(organizationId: $organizationId) {
      ...DiscordIntegrationRole
    }
  }

  ${Fragments.discordIntegrationRole}
`;

export const getRelevantUsers = gql`
  query GetRelevantUsersQuery($input: GetRelevantUsersInput!) {
    users: getRelevantUsers(input: $input) {
      ...User
    }
  }

  ${user}
`;
