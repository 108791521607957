import React, { FC, ReactNode } from "react";
import { Button, Divider } from "antd";
import { useToggle, UseToggleHook } from "../util/hooks";
import { HeadlessCollapse } from "./HeadlessCollapse";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

interface Props {
  toggle?: UseToggleHook;
  label: string;
  children: ReactNode;
}

export const MoreSectionCollapse: FC<Props> = ({ children, label, toggle }) => {
  const fallbackToggle = useToggle();
  const actualToggle = toggle ?? fallbackToggle;
  return (
    <>
      <Divider plain style={{ marginBottom: 0 }}>
        <Button
          type="text"
          style={{ padding: "0 8px", height: "unset" }}
          className="text-secondary"
          onClick={actualToggle.toggle}
        >
          {label}
          {actualToggle.isOn ? <UpOutlined /> : <DownOutlined />}
        </Button>
      </Divider>
      <HeadlessCollapse
        expanded={actualToggle.isOn}
        style={{ marginBottom: 16 }}
      >
        {children}
      </HeadlessCollapse>
    </>
  );
};
