import React from "react";
import Icon from "@ant-design/icons";

const PhantomSvg = () => {
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 128 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.471 52.0091H106.139C106.139 23.2846 82.3791 0 53.0684 0C24.1204 0 0.585366 22.7145 0.0093645 50.9471C-0.586549 80.1302 27.3517 105.472 57.1345 105.472H60.8807C87.1378 105.472 122.331 85.329 127.829 60.7858C128.845 56.2616 125.198 52.0091 120.471 52.0091ZM31.7705 53.2886C31.7705 57.1299 28.5648 60.2715 24.6452 60.2715C20.7255 60.2715 17.5198 57.1285 17.5198 53.2886V41.992C17.5198 38.1507 20.7255 35.0091 24.6452 35.0091C28.5648 35.0091 31.7705 38.1507 31.7705 41.992V53.2886ZM56.513 53.2886C56.513 57.1299 53.3073 60.2715 49.3877 60.2715C45.468 60.2715 42.2623 57.1285 42.2623 53.2886V41.992C42.2623 38.1507 45.4694 35.0091 49.3877 35.0091C53.3073 35.0091 56.513 38.1507 56.513 41.992V53.2886Z"
        fill="white"
      />
    </svg>
  );
};

export const PhantomIcon = (props: any) => (
  <Icon component={PhantomSvg} {...props} />
);
