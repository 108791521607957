import { FileOutlined } from "@ant-design/icons";
import {
  DropdownSelect,
  DropdownSelectOption,
} from "@dewo/app/components/form/DropdownSelect";
import { EditableTag } from "@dewo/app/components/tags/EditableTag";
import { TaskStatus } from "@dewo/app/graphql/types";
import _ from "lodash";
import { useRouter } from "next/router";
import React, { FC, useCallback, useMemo } from "react";
import { useWorkspaceTaskTemplates } from "../../workspace/hooks";

interface Props {
  workspaceId: string;
  templateId: string | undefined;
  isSuggestion: boolean;
  placeholder?: string;
}

export const TaskTemplatePicker: FC<Props> = ({
  workspaceId,
  templateId,
  isSuggestion,
  placeholder = "Template",
}) => {
  const router = useRouter();
  const templates = useWorkspaceTaskTemplates(workspaceId);
  const selected = useMemo(
    () => templates?.find((t) => t.id === templateId),
    [templates, templateId]
  );

  const templatesToShow = useMemo(
    () =>
      templates?.filter(
        (t) => (t.status === TaskStatus.COMMUNITY_SUGGESTIONS) === isSuggestion
      ),
    [templates, isSuggestion]
  );

  const options = useMemo<DropdownSelectOption<string>[]>(
    () =>
      (templatesToShow ?? [])
        .map((t) => ({ value: t.id, label: t.name }))
        .concat({ value: "", label: "No template..." }),
    [templatesToShow]
  );

  const handleChange = useCallback(
    (templateId: string) =>
      !!templateId
        ? router.replace({ query: { ...router.query, template: templateId } })
        : router.replace({ query: _.omit(router.query, "template") }),
    [router]
  );

  if (!templatesToShow?.length) return null;
  return (
    <DropdownSelect mode="default" options={options} onChange={handleChange}>
      <EditableTag
        icon={<FileOutlined />}
        style={{
          backgroundColor: "transparent",
          paddingTop: 2,
          paddingBottom: 2,
          marginBottom: 4,
        }}
        className="text-secondary"
      >
        {selected?.name ?? placeholder}
      </EditableTag>
    </DropdownSelect>
  );
};
