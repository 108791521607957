import React from "react";
import Icon from "@ant-design/icons";

const IncognitoSvg = () => (
  <svg version="1.1" viewBox="0 0 700 700">
    <g>
      <path
        d="m486.53 293.11c-21.777 0.0625-42.844 7.75-59.547 21.719-16.703 13.973-27.988 33.352-31.902 54.777-29.281-9.0156-60.598-9.0156-89.879 0-5.6641-31.02-26.637-57.062-55.73-69.211s-62.359-8.75-88.398 9.0312c-26.039 17.781-41.312 47.527-40.586 79.047 0.72266 31.523 17.348 60.535 44.176 77.102 26.828 16.562 60.215 18.43 88.719 4.957 28.508-13.473 48.262-40.453 52.492-71.695 28.609-10.863 60.207-10.863 88.816 0 3.2734 23.727 15.574 45.277 34.34 60.16 18.762 14.887 42.547 21.957 66.395 19.746 23.848-2.2109 45.926-13.539 61.633-31.621 15.707-18.082 23.832-41.523 22.688-65.449-1.1484-23.922-11.477-46.484-28.84-62.98-17.363-16.496-40.422-25.66-64.375-25.582z"
        fill="currentColor"
      />
      <path
        d="m591.75 216.44h-40.879l-25.82-92.457c-6.8867-24.641-29.961-43.176-53.703-43.176h-242.7c-23.742 0-46.816 18.535-53.703 43.176l-25.816 92.457h-40.883c-7.7305 0-14 6.2656-14 14 0 7.7305 6.2695 14 14 14h483.51c7.7305 0 14-6.2695 14-14 0-7.7344-6.2695-14-14-14z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const IncognitoIcon = (props: any) => (
  <Icon component={IncognitoSvg} {...props} />
);
