import { Task } from "@dewo/app/graphql/types";
import { Row, Tag, Typography } from "antd";
import moment from "moment";
import React, { CSSProperties, FC } from "react";
import { UserAvatar } from "../avatars/UserAvatar";

interface Props {
  task: Task;
  style?: CSSProperties;
}

export const TaskCreatorSubtitle: FC<Props> = ({ task, style }) => (
  <Row align="middle" style={style}>
    {!!task.templateTask && (
      <Tag title={task.templateTask.name}>{task.templateTask.name}</Tag>
    )}
    {!!task.creator && (
      <Typography.Text type="secondary">
        {`${moment(task.createdAt).fromNow()} by `}
        <UserAvatar
          key={task.creator.id}
          user={task.creator}
          size={16}
          linkToProfile
          tooltip={{ title: "View profile" }}
        />
        {` ${task.creator.username}`}
      </Typography.Text>
    )}
  </Row>
);
