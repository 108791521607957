import { FormSection } from "@dewo/app/components/form/FormSection";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { PaymentToken } from "@dewo/app/graphql/types";
import { Col, Form, InputNumber, Row } from "antd";
import { InternalNamePath } from "antd/lib/form/interface";
import _ from "lodash";
import React, { FC } from "react";

interface Props {
  path: InternalNamePath;
  name: string | number;
  countLabel?: string;
  token: PaymentToken;
  hourlyRateDisabled?: boolean;
}

export const TaskRewardHourlyFormItems: FC<Props> = ({
  path,
  name,
  token,
  hourlyRateDisabled,
  countLabel = "Estimated Hours",
}) => {
  const form = Form.useFormInstance();
  const peggedToUsd = Form.useWatch([...path, "peggedToUsd"], form);
  const amount = Form.useWatch([...path, "amount"], form);
  const count = Form.useWatch([...path, "count"], form);
  const total = _.round(amount * count, 2);

  const amountUnitString = !!token
    ? peggedToUsd
      ? `$ in ${token.symbol}`
      : token.symbol
    : undefined;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col sm={24} md={12}>
          <Form.Item
            name={[name, "amount"]}
            style={{ marginBottom: 0 }}
            label={"Hourly rate"}
            rules={[{ required: true, message: "Please enter rate" }]}
          >
            <InputNumber
              min={0}
              disabled={hourlyRateDisabled}
              placeholder="Enter amount..."
              style={{ width: "100%" }}
              addonAfter={
                !!amountUnitString ? `${amountUnitString} / hour` : undefined
              }
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item name={[name, "count"]} label={countLabel}>
            <InputNumber
              className="w-full"
              min={0}
              placeholder="Enter hours..."
            />
          </Form.Item>
        </Col>
      </Row>
      <HeadlessCollapse expanded={!!total}>
        <FormSection label="Total (calculated)">
          <InputNumber
            value={total}
            disabled
            className="w-full"
            addonAfter={amountUnitString}
          />
        </FormSection>
      </HeadlessCollapse>
    </>
  );
};
