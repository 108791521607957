import { CaretRightOutlined } from "@ant-design/icons";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { RichMarkdownViewer } from "@dewo/app/components/richMarkdown/RichMarkdownViewer";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Card, Col, Row, Timeline, Typography } from "antd";
import moment from "moment";
import React, { FC } from "react";
import {
  TaskTextTimelineItem,
  TaskThreadMessageTimelineItem,
} from "./task/hooks";
import styles from "./ThreadTimeline.module.less";
import { ThreadMessageOptionButton } from "./ThreadMessageOptionButton";

export const ThreadTimeline: FC = ({ children }) => {
  return <Timeline className={styles.timeline}>{children}</Timeline>;
};

export const ThreadTextComponent: FC<{ item: TaskTextTimelineItem }> = ({
  item,
}) => {
  const details = useToggle();
  return (
    <>
      <Row align="middle" className={styles.textItem}>
        <Typography.Text style={{ flex: 1 }}>
          {item.text}
          {!!item.details && (
            <Button
              style={{ marginLeft: 8 }}
              icon={<CaretRightOutlined rotate={details.isOn ? 90 : 0} />}
              type="text"
              size="small"
              onClick={details.toggle}
            />
          )}
        </Typography.Text>
        <Typography.Text type="secondary" className="ant-typography-caption">
          {moment(item.date).format("lll")}
        </Typography.Text>
      </Row>
      <HeadlessCollapse expanded={details.isOn}>
        <Col style={{ opacity: 0.6, padding: 0 }}>{item.details}</Col>
      </HeadlessCollapse>
    </>
  );
};

export const ThreadMessageComponent: FC<{
  item: TaskThreadMessageTimelineItem;
}> = ({ item }) => (
  <Card size="small" bordered={false}>
    <Row align="middle">
      <Typography.Text className="font-semibold">
        {item.message.sender.username}
      </Typography.Text>
      <Typography.Text
        type="secondary"
        style={{ marginLeft: 12, flex: 1 }}
        className="ant-typography-caption"
      >
        {moment(item.message.createdAt).fromNow()}
      </Typography.Text>
      <ThreadMessageOptionButton thread={item.thread} message={item.message} />
    </Row>
    <RichMarkdownViewer value={item.message.content} />
  </Card>
);
