import { Notification } from "@dewo/app/graphql/types";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { Breadcrumb, Tooltip } from "antd";
import classNames from "classnames";
import moment from "moment";
import Link from "next/link";
import React, { FC, ReactNode, useMemo } from "react";
import styles from "./NotificationList.module.less";

export const NotificationBreadcrumb: FC<{ notification: Notification }> = ({
  notification,
}) => {
  const notificationSpecificBreadcrumbs = useMemo((): ReactNode => {
    if (!!notification.task) {
      return (
        <>
          <Link href={notification.task.workspace.organization.permalink}>
            <Breadcrumb.Item
              href={notification.task.workspace.organization.permalink}
              onClick={stopPropagation}
            >
              {notification.task.workspace.organization.name}
            </Breadcrumb.Item>
          </Link>

          <Link href={notification.task.workspace.permalink}>
            <Breadcrumb.Item
              href={notification.task.workspace.permalink}
              onClick={stopPropagation}
            >
              {notification.task.workspace.name}
            </Breadcrumb.Item>
          </Link>
        </>
      );
    }

    return null;
  }, [notification]);

  return (
    <Breadcrumb
      separator=""
      className={classNames(
        "ant-typography-caption",
        "text-secondary",
        styles.breadcrumb
      )}
    >
      <Breadcrumb.Item onClick={stopPropagation}>
        <Tooltip
          title={moment(notification.createdAt).calendar()}
          placement="bottom"
        >
          {moment(notification.createdAt).fromNow()}
        </Tooltip>
      </Breadcrumb.Item>
      {!!notificationSpecificBreadcrumbs && (
        <Breadcrumb.Separator children="·" />
      )}
      {notificationSpecificBreadcrumbs}
    </Breadcrumb>
  );
};
