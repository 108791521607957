import { HolderOutlined, NumberOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { ReorderList } from "@dewo/app/components/reorder/ReorderList";
import { createUserRankingVotes } from "@dewo/app/graphql/mutations/ranking";
import { getRankableUsers } from "@dewo/app/graphql/queries/ranking";
import {
  CreateUserRankingVotesMutation,
  CreateUserRankingVotesMutationVariables,
  GetRankableUsersQuery,
  User,
} from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { Button, Divider, Spin, Typography } from "antd";
import _ from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { UserPromptStepComponentProps } from "../types";

const NUM_USERS = 7;

interface RankableUser {
  id: string;
  user: User;
  sortKey: string;
  dragged: boolean;
}

interface RankableDivider {
  id: string;
  sortKey: string;
  isDragDisabled: true;
}

type Rankable = RankableUser | RankableDivider;

function useRankables(): RankableUser[] | undefined {
  const { data } = useQuery<GetRankableUsersQuery>(getRankableUsers);
  return useMemo(
    () =>
      data?.rankable
        .slice(0, NUM_USERS)
        .sort(() => Math.random() - 0.5)
        .map((r, index) => ({
          id: r.user.id,
          user: r.user,
          dragged: false,
          sortKey: String(index + 1).padStart(6, "0"),
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!data?.rankable]
  );
}

const Content: FC<{ rankable: Rankable[]; onNext(): Promise<void> }> = ({
  rankable: initialRankable,
  onNext,
}) => {
  const [rankable, setRankable] = useState<Rankable[]>(() => [
    {
      id: "divider",
      sortKey: String(1).padStart(7, "0"),
      isDragDisabled: true,
    },
    ...initialRankable,
  ]);
  const handleReorder = useCallback(
    (reordered: Rankable, sortKey: string) =>
      setRankable((prev) =>
        _.sortBy(
          [
            ...prev.filter((p) => p.id !== reordered.id),
            { ...reordered, sortKey, dragged: true },
          ],
          "sortKey"
        )
      ),
    []
  );

  const dividerIndex = useMemo(
    () => rankable.findIndex((r) => r.id === "divider"),
    [rankable]
  );

  const [mutation] = useMutation<
    CreateUserRankingVotesMutation,
    CreateUserRankingVotesMutationVariables
  >(createUserRankingVotes);
  const [submit, submitting] = useRunning(
    useCallback(async () => {
      await mutation({
        variables: {
          inputs: rankable
            .filter((r): r is RankableUser => "user" in r)
            .map((r, index) => ({
              position: index,
              initialPosition: initialRankable.findIndex(
                (ir) => ir.id === r.id
              ),
              dragged: r.dragged,
              userId: r.user.id,
            })),
        },
      });
      await onNext();
    }, [onNext, rankable, initialRankable, mutation])
  );

  return (
    <section className="mx-auto w-full" style={{ maxWidth: 320 }}>
      <HeadlessCollapse expanded={dividerIndex === 0}>
        <Typography.Paragraph
          type="secondary"
          style={{ marginBottom: 0, paddingLeft: 16, paddingRight: 16 }}
        >
          <span style={{ marginRight: 8 }}>
            <HolderOutlined />
          </span>
          Drag people here
        </Typography.Paragraph>
      </HeadlessCollapse>

      <ReorderList
        dataSource={rankable ?? []}
        fieldName="sortKey"
        renderLabel={(item, index) =>
          "user" in item ? (
            <>
              <UserAvatar
                size="small"
                user={item.user}
                style={{ marginRight: 8 }}
              />
              <Typography.Text style={{ flex: 1 }}>
                {item.user.username}
              </Typography.Text>
              {index < dividerIndex && (
                <Typography.Text type="secondary" style={{ width: 32 }}>
                  <NumberOutlined />
                  {index + 1}
                </Typography.Text>
              )}
            </>
          ) : (
            <Divider />
          )
        }
        onReorder={handleReorder}
      />
      <Button
        type="primary"
        loading={submitting}
        block
        disabled={dividerIndex !== rankable.length - 1}
        style={{ marginTop: 24 }}
        onClick={submit}
      >
        Done
      </Button>
    </section>
  );
};

export const RankingClosestUsers: FC<UserPromptStepComponentProps> = ({
  onNext,
}) => {
  const rankable = useRankables();
  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Anonymous Ranking
      </Typography.Title>
      <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
        Below are some of the people you have worked the most with recently. You
        can anonymously rank them based on{" "}
        <Typography.Text strong>
          how much you would recommend them
        </Typography.Text>
        . This is completely anonymous and the people below will not see your
        ranking.
      </Typography.Paragraph>

      <Typography.Paragraph style={{ textAlign: "center" }}>
        Your Ranking
      </Typography.Paragraph>

      {!!rankable ? (
        <Content rankable={rankable} onNext={onNext} />
      ) : (
        <div style={{ display: "grid" }}>
          <Spin />
        </div>
      )}
    </>
  );
};
