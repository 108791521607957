import { LoginButton } from "@dewo/app/containers/auth/buttons/LoginButton";
import { TaskFormValues } from "@dewo/app/containers/task/form/types";
import { NestedGraphNodeMenuItem } from "@dewo/app/containers/graph/NestedGraphNodeMenuItem";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import {
  GraphNodeChildrenDetails,
  GraphNodeDetails,
  OrganizationWorkspaces,
  Workspace,
} from "@dewo/app/graphql/types";
import { Button, ButtonProps, Dropdown, Menu } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useCallback, useMemo } from "react";

interface Props extends ButtonProps {
  parentNode: OrganizationWorkspaces["node"] | undefined;
  values: Partial<TaskFormValues>;
  filter?(node: GraphNodeDetails): boolean;
}

const createLink = (workspace: Workspace, values: Partial<TaskFormValues>) =>
  `${workspace.permalink}/create?values=${encodeURIComponent(
    JSON.stringify(values)
  )}`;

export const CreateTaskButtonWithWorkspaceDropdown: FC<Props> = ({
  parentNode,
  filter,
  values,
  ...buttonProps
}) => {
  const router = useRouter();
  const authenticated = !!useAuthContext().user;

  const handleOpen = useCallback(
    (node: GraphNodeDetails) =>
      !!node.workspace && router.push(createLink(node.workspace, values)),
    [router, values]
  );

  const onlyOneOption = useMemo(() => {
    const filtered = parentNode?.workspaceChildren.filter(
      (edge) => !filter || filter(edge.node)
    );
    if (filtered?.length !== 1) return false;

    const children =
      (parentNode?.workspaceChildren[0].node as any as GraphNodeChildrenDetails)
        .children ?? [];
    const filteredChildren = !!filter
      ? children.filter((edge) => filter(edge.node))
      : children;
    if (!!filteredChildren.length) return false;
    return true;
  }, [filter, parentNode]);

  if (!authenticated) {
    return <LoginButton {...buttonProps} />;
  }

  if (onlyOneOption) {
    const workspace = parentNode?.workspaceChildren[0].node.workspace;
    if (!workspace) return <Button {...buttonProps} />;
    return (
      <Link href={createLink(workspace, values)}>
        <Button {...buttonProps} />
      </Link>
    );
  }

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottom"
      overlay={
        <Menu>
          {parentNode?.workspaceChildren
            .filter((edge) => !filter || filter(edge.node))
            .map((edge) => (
              <NestedGraphNodeMenuItem
                key={edge.node.id}
                node={edge.node}
                filter={filter}
                onMenuItemClick={handleOpen}
              />
            ))}
        </Menu>
      }
    >
      <Button {...buttonProps} />
    </Dropdown>
  );
};
