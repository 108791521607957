import { LockOutlined, PlusOutlined } from "@ant-design/icons";
import { useIsWorkspacePrivate } from "@dewo/app/containers/rbac/hooks";
import { WorkspaceName } from "@dewo/app/containers/workspace/WorkspaceName";
import { Can } from "@dewo/app/contexts/PermissionsContext";
import { Workspace } from "@dewo/app/graphql/types";
import { eatClick } from "@dewo/app/util/eatClick";
import { Button } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import styles from "../Menu.module.less";

interface Props {
  workspace: Workspace;
  showAddButton: boolean;
  onCreateWorkspace(): void;
}

export const WorkspaceTreeTitle: FC<Props> = ({
  workspace,
  showAddButton,
  onCreateWorkspace,
}) => {
  const isPrivate = useIsWorkspacePrivate(workspace, workspace?.organizationId);
  return (
    <>
      <WorkspaceName
        className="font-semibold"
        style={{ flex: 1 }}
        workspace={workspace}
        showPrivate={false}
      />
      {showAddButton && (
        <Can I="create" a="Workspace">
          <div onClick={eatClick}>
            <Button
              type="text"
              size="small"
              className={classNames(
                "text-secondary",
                styles.visibleOnMenuItemHover
              )}
              icon={<PlusOutlined />}
              onClick={onCreateWorkspace}
            />
          </div>
        </Can>
      )}
      {isPrivate && (
        <LockOutlined
          className="text-secondary"
          style={{ display: "grid", placeItems: "center", marginLeft: 4 }}
        />
      )}
    </>
  );
};
