import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  TaskDetails,
  TaskGatingType,
  TaskStatus,
} from "@dewo/app/graphql/types";
import { Form, Space } from "antd";
import React, { FC, useMemo } from "react";
import { PayButton } from "../board/PayButton";
import { useShouldShowInlinePayButton } from "../board/util";
import { ApplyToTaskButton } from "./apply/ApplyToTaskButton";
import { ClaimTaskButton } from "./claim/ClaimTaskButton";
import { CreateSubmissionButton } from "./submit/CreateSubmissionButton";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { useTaskDetails } from "../hooks";
import { OpenDiscordButton } from "@dewo/app/components/buttons/OpenDiscordButton";
import { TaskDiscordButton } from "./TaskDiscordButton";
import { TaskGithubIssueButton } from "./TaskGithubIssueButton";
import { AcceptSuggestionButton } from "./accept/AcceptSuggestionButton";
import { UpvoteButton } from "../voting/UpvoteButton";
import { TaskNotionPageButton } from "./TaskNotionPageButton";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import { TaskFormValues } from "../form/types";
import { DollarCircleOutlined } from "@ant-design/icons";

interface Props {
  task: TaskDetails;
  className?: string;
}

export const TaskActionSection: FC<Props> = ({ task, className }) => {
  const { user } = useAuthContext();
  const form = useFormInstance<TaskFormValues>();

  const claimRoleIds = Form.useWatch("claimRoleIds", form);
  const canClaim =
    usePermission("update", task, "assigneeIds") && !!claimRoleIds?.length;

  const canApply = usePermission("apply", task);

  const canAcceptSuggestedTask = usePermission("update", task, "status");
  const canSubmit = usePermission("submit", task);
  const shouldShowInlinePayButton = useShouldShowInlinePayButton(task);
  const taskDetails = useTaskDetails(task.id)?.task;
  const myApplication = useMemo(
    () => user && taskDetails?.applications.find((ta) => ta.userId === user.id),
    [user, taskDetails?.applications]
  );

  const content = (() => {
    if (shouldShowInlinePayButton) {
      return (
        <PayButton
          key="pay"
          size="large"
          icon={<DollarCircleOutlined />}
          task={task}
        >
          Pay
        </PayButton>
      );
    }
    if (
      [TaskStatus.TODO, TaskStatus.IN_PROGRESS, TaskStatus.IN_REVIEW].includes(
        task.status
      ) &&
      canSubmit &&
      (task.assignees.length || task.gating === TaskGatingType.OPEN_SUBMISSION)
    ) {
      return (
        <CreateSubmissionButton
          key="submit"
          size="large"
          type="primary"
          task={task}
        />
      );
    }

    if (task.status === TaskStatus.TODO && canClaim && !task.assignees.length) {
      return (
        <ClaimTaskButton key="claim" size="large" type="primary" task={task} />
      );
    }

    if (task.status === TaskStatus.TODO && !task.assignees.length) {
      return canClaim ? (
        <ClaimTaskButton key="claim" size="large" type="primary" task={task}>
          Claim task
          <QuestionmarkTooltip
            marginLeft={8}
            title="You can claim this task because you have the permission to manage it"
          />
        </ClaimTaskButton>
      ) : (
        <>
          {canApply && (
            <ApplyToTaskButton
              key="apply"
              size="large"
              type="primary"
              block
              task={task}
            />
          )}

          {myApplication?.discordThreadUrl && (
            <OpenDiscordButton
              key="discord"
              type="primary"
              size="large"
              href={myApplication.discordThreadUrl}
            >
              Discuss with reviewer
            </OpenDiscordButton>
          )}
        </>
      );
    }

    if (task.status === TaskStatus.COMMUNITY_SUGGESTIONS) {
      return (
        <>
          <UpvoteButton size="large" task={task} />
          {canAcceptSuggestedTask && (
            <AcceptSuggestionButton
              size="large"
              type="primary"
              block
              task={task}
            />
          )}
        </>
      );
    }
  })();

  return (
    <Space wrap className={className}>
      {[
        content,
        !myApplication?.discordThreadUrl && (
          <TaskDiscordButton
            key="discord"
            type={content ? "default" : "primary"}
            size="large"
            task={task}
          >
            Discuss
          </TaskDiscordButton>
        ),
        task.githubIssue?.link && (
          <TaskGithubIssueButton size="large" task={task} />
        ),
        task.notionPage && <TaskNotionPageButton size="large" task={task} />,
      ].filter((b) => !!b)}
    </Space>
  );
};
