import gql from "graphql-tag";
import { organization } from "./organization";
import { workspace } from "./workspace";

export const notification = gql`
  fragment Notification on Notification {
    id
    message
    archivedAt
    createdAt
    icon
    iconColor
    task {
      id
      name
      permalink
      workspace {
        ...Workspace
        organization {
          ...Organization
        }
      }
    }
    organization {
      ...Organization
    }
  }

  ${workspace}
  ${organization}
`;
