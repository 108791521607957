import React, { FC } from "react";
import { Button, Space, Tooltip } from "antd";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { deworkSocialLinks } from "@dewo/app/util/constants";
import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import { useIsEmbedded } from "@dewo/app/util/navigation";
import { QuestionCircleOutlined, TwitterOutlined } from "@ant-design/icons";
import { SupportChat } from "@dewo/app/components/SupportChat";

export const FloatingFooterButtons: FC = () => {
  const { user } = useAuthContext();
  const embedded = useIsEmbedded();

  if (!user) return null;
  if (embedded) return null;
  return (
    <Space style={{ position: "absolute", bottom: 16, right: 16 }}>
      <a
        href={deworkSocialLinks.discord}
        target="_blank"
        rel="noreferrer"
        className="hide-xs"
      >
        <Button
          icon={<DiscordIcon />}
          shape="circle"
          name="Discord Floating Footer Button"
        />
      </a>
      <a
        href={deworkSocialLinks.twitter}
        target="_blank"
        rel="noreferrer"
        className="hide-xs"
      >
        <Button
          icon={<TwitterOutlined />}
          shape="circle"
          name="Twitter Floating Footer Button"
        />
      </a>
      <Tooltip title="Read Documentation" placement="topRight">
        <a
          href={deworkSocialLinks.gitbook.index}
          target="_blank"
          rel="noreferrer"
          className="hide-xs"
        >
          <Button
            icon={<QuestionCircleOutlined />}
            shape="circle"
            name="Documentation Floating Footer Button"
          />
        </a>
      </Tooltip>
      <SupportChat />
    </Space>
  );
};
