import { Avatar, Button, Form, Input, Row, Typography } from "antd";
import React, { FC, useCallback } from "react";
import { useForm, useWatch } from "antd/lib/form/Form";
import {
  CreateOrganizationInput,
  TaskGatingType,
  TaskPriority,
  TaskStatus,
  WorkspaceType,
} from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { TeamOutlined } from "@ant-design/icons";
import { UserPromptStepComponentProps } from "../../types";
import { ImageUploadInput } from "@dewo/app/containers/fileUploads/ImageUploadInput";
import { useCreateOrganization } from "@dewo/app/containers/organization/hooks";
import { useCreateWorkspaceSection } from "@dewo/app/containers/workspace/section/hooks";
import { useCreateWorkspace } from "@dewo/app/containers/workspace/hooks";
import { useRouter } from "next/router";
import { useCreateTask } from "@dewo/app/containers/task/hooks";
import onboardingTasks from "./onboardingTasks.json";

export const OnboardingOrganizationProfileWithMainWorkspace: FC<
  UserPromptStepComponentProps
> = (props) => (
  <OnboardingOrganizationProfile {...props} shouldCreateMainWorkspace />
);

export const OnboardingOrganizationProfileWithOnboardingTasks: FC<
  UserPromptStepComponentProps
> = (props) => (
  <OnboardingOrganizationProfile {...props} shouldCreateOnboardingTasks />
);

export const OnboardingOrganizationProfile: FC<
  UserPromptStepComponentProps & {
    shouldCreateMainWorkspace?: boolean;
    shouldCreateOnboardingTasks?: boolean;
  }
> = ({
  shouldCreateMainWorkspace,
  shouldCreateOnboardingTasks,
  onNext,
  onCancel,
}) => {
  const [form] = useForm<CreateOrganizationInput>();
  const imageUrl = useWatch("imageUrl", form);

  const createOrganization = useCreateOrganization();
  const createSection = useCreateWorkspaceSection();
  const createWorkspace = useCreateWorkspace();
  const createTask = useCreateTask();
  const router = useRouter();

  const [submit, submitting] = useRunning(
    useCallback(
      async (values: CreateOrganizationInput) => {
        const organization = await createOrganization(values);

        if (shouldCreateMainWorkspace || shouldCreateOnboardingTasks) {
          const section = await createSection({
            name: "Spaces",
            organizationId: organization.id,
          });
          const workspace = await createWorkspace({
            name: "Main Space",
            type: WorkspaceType.FOLDER,
            sectionId: section.id,
            organizationId: organization.id,
            options: { showCommunitySuggestions: true },
          });

          if (shouldCreateOnboardingTasks) {
            for (const task of onboardingTasks) {
              await createTask({
                name: task.name,
                description: task.description,
                status: task.status as TaskStatus,
                priority: task.priority as TaskPriority,
                workspaceId: workspace.id,
                gating: TaskGatingType.ASSIGNEES,
              });
            }
          }

          await onNext();
          await router.push(
            workspace.taskViews[0]?.permalink ?? workspace.permalink
          );
        } else {
          await onNext();
        }
      },
      [
        onNext,
        createOrganization,
        createSection,
        createWorkspace,
        createTask,
        router,
        shouldCreateMainWorkspace,
        shouldCreateOnboardingTasks,
      ]
    )
  );

  const [cancel, cancelling] = useRunning(onCancel);

  return (
    <Form
      form={form}
      className="mx-auto w-full"
      style={{
        flex: 1,
        gap: 8,
        display: "flex",
        flexDirection: "column",
        maxWidth: 368,
      }}
      onFinish={submit}
    >
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        What's the name of your DAO/project?
      </Typography.Title>
      <Row
        align="middle"
        justify="center"
        style={{ rowGap: 16, flex: 1, flexDirection: "column" }}
      >
        <Form.Item name="imageUrl">
          <ImageUploadInput>
            <Avatar
              size={128}
              style={{ fontSize: 36 }}
              src={imageUrl}
              className="bg-component hover:component-highlight hover:cursor-pointer"
              icon={<TeamOutlined style={{ opacity: 0.5 }} />}
            />
          </ImageUploadInput>
        </Form.Item>

        <Form.Item
          name="name"
          style={{ width: "100%", textAlign: "center" }}
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input
            size="large"
            autoComplete="off"
            spellCheck={false}
            style={{ textAlign: "center" }}
            className="ant-typography-h3"
            placeholder="Enter name..."
          />
        </Form.Item>
      </Row>

      <Button
        block
        size="large"
        type="primary"
        htmlType="submit"
        loading={submitting}
        style={{ alignSelf: "center" }}
        name="Onboarding Organization Profile: next"
      >
        Next
      </Button>

      <Button
        type="text"
        size="large"
        className="text-secondary"
        name="Onboarding Organization Profile: cancel"
        loading={cancelling}
        onClick={cancel}
      >
        Cancel
      </Button>
    </Form>
  );
};
