import gql from "graphql-tag";
import { nodeDetails } from "./graph";
import { skill } from "./skill";
import { user } from "./user";
import { workspace } from "./workspace";

export const workspaceOverview = gql`
  fragment WorkspaceOverview on Workspace {
    ...Workspace
    contributors {
      ...User
    }
    skills {
      count
      skill {
        ...Skill
      }
    }
    openTaskCount: taskCount(
      filter: { statuses: [TODO], assigneeIds: [null], parentTaskId: null }
    )
    doneTaskCount: taskCount(filter: { statuses: [DONE], parentTaskId: null })
    totalTaskCount: taskCount(filter: { parentTaskId: null })
    node {
      id
      workspaceChildren: children(types: [WORKSPACE]) {
        sortKey
        node: child {
          ...GraphNodeDetails
        }
      }
    }
  }
  ${workspace}
  ${skill}
  ${user}
  ${nodeDetails}
`;
