import gql from "graphql-tag";
import { user } from "../fragments/user";

export const getRankableUsers = gql`
  query GetRankableUsersQuery($organizationId: UUID) {
    rankable: getRankableUsers(organizationId: $organizationId) {
      weight
      user {
        ...User
      }
    }
  }

  ${user}
`;
