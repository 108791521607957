import { SkillSelect } from "@dewo/app/components/form/SkillSelect";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { SelectProps } from "antd";
import React, { FC, useCallback, useState } from "react";
import { useSetUserSkills } from "../skills/hooks";

export const UserSkillSelect: FC<SelectProps> = (props) => {
  const { user } = useAuthContext();
  const [skillIds, setSkillIds] = useState(
    () => user?.skills.map((s) => s.id) ?? []
  );

  const setUserSkills = useSetUserSkills();
  const handleBlur = useCallback(
    () => setUserSkills(skillIds),
    [setUserSkills, skillIds]
  );

  return (
    <SkillSelect
      {...props}
      value={skillIds}
      onChange={setSkillIds}
      onBlur={handleBlur}
    />
  );
};
