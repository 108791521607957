import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import { useOnboardingTip } from "@dewo/app/components/OnboardingAlert";
import { OpenDiscordButton } from "@dewo/app/components/buttons/OpenDiscordButton";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import {
  DiscordGuildMembershipState,
  TaskDetails,
  ThreepidAuthSessionType,
  WorkspaceIntegrationFeature,
} from "@dewo/app/graphql/types";
import { Constants } from "@dewo/app/util/constants";
import { Button, ButtonProps, message, Popconfirm } from "antd";
import { useRouter } from "next/router";
import React, { FC, useCallback } from "react";
import {
  useAddUserToDiscordGuild,
  useDiscordGuildMembershipState,
} from "../../integrations/hooks";
import { useWorkspaceIntegrations } from "../../workspace/hooks";
import { useCreateTaskDiscordLink } from "../hooks";
import { useCreateThreepidAuthSession } from "../../auth/hooks";

interface Props extends ButtonProps {
  task: TaskDetails;
}

export const TaskDiscordButton: FC<Props> = ({ task, ...rest }) => {
  const router = useRouter();

  const canSetupDiscordIntegration = usePermission("update", task.workspace);
  const discordSetupPrompt = useOnboardingTip(
    "DiscordIntegration.v1.discussTask"
  );

  const integrations = useWorkspaceIntegrations(
    task.workspace.parentId ?? task.workspace.id
  );
  const hasDiscordIntegration = integrations?.some(
    (i) =>
      i.feature ===
      WorkspaceIntegrationFeature.DISCORD_POST_TASK_UPDATES_TO_THREAD_PER_TASK
  );

  const membershipState = useDiscordGuildMembershipState(
    task.workspace.organizationId
  );
  const addUserToDiscordGuild = useAddUserToDiscordGuild(
    task.workspace.organizationId
  );
  const createDiscordLink = useCreateTaskDiscordLink();
  const handleCreateDiscordLink = useCallback(async () => {
    try {
      if (membershipState === DiscordGuildMembershipState.HAS_SCOPE) {
        await addUserToDiscordGuild().catch(() => {});
      }
      const link = await createDiscordLink(task.id);
      return link;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
      throw e;
    }
  }, [addUserToDiscordGuild, createDiscordLink, task.id, membershipState]);

  const createSession = useCreateThreepidAuthSession();
  const handleConnectDiscord = useCallback(async () => {
    const sessionId = await createSession(
      ThreepidAuthSessionType.DISCORD,
      router.asPath
    );

    window.location.href = `${Constants.GRAPHQL_API_URL}/auth/discord-join-guild?state=${sessionId}`;
  }, [createSession, router.asPath]);

  if (!membershipState) return null;

  if (!hasDiscordIntegration) {
    if (canSetupDiscordIntegration && !discordSetupPrompt.hidden) {
      return (
        <Popconfirm
          icon={<DiscordIcon />}
          title="Connect a Discord channel to Dework to automatically create threads to discuss tasks"
          okText="Setup"
          onConfirm={() =>
            router.push(`${task.workspace.permalink}/settings/discord`)
          }
          cancelText="Don't show again"
          cancelButtonProps={{ type: "text", className: "text-secondary" }}
          onCancel={discordSetupPrompt.onClose}
        >
          <Button icon={<DiscordIcon />} {...rest} />
        </Popconfirm>
      );
    } else {
      return null;
    }
  }

  if (membershipState === DiscordGuildMembershipState.MISSING_SCOPE) {
    return (
      <Button icon={<DiscordIcon />} onClick={handleConnectDiscord} {...rest}>
        Discuss
      </Button>
    );
  }

  return (
    <OpenDiscordButton href={handleCreateDiscordLink} {...rest}>
      Discuss
    </OpenDiscordButton>
  );
};
