import { HeadlessCollapse } from "@dewo/app/components/HeadlessCollapse";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { RichMarkdownEditor } from "@dewo/app/components/richMarkdown/RichMarkdownEditor";
import { useToggle } from "@dewo/app/util/hooks";
import { Form, Row, Space, Switch, Typography } from "antd";
import React, { FC, useCallback } from "react";

interface Props {
  value?: string | null;
  onChange?(value?: string | null): void;
}

const ApplicationTemplateFieldComponent: FC<Props> = ({ value, onChange }) => {
  const expanded = useToggle(!!value);
  const handleChange = useCallback(
    (checked: boolean) => {
      expanded.setToggle(checked);
      if (!checked) onChange?.(null);
    },
    [expanded, onChange]
  );
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row align="middle" style={{ columnGap: 8 }}>
        <Switch checked={expanded.isOn} size="small" onChange={handleChange} />
        <QuestionmarkTooltip
          marginLeft={8}
          title="Want applicants to answer certain questions or follow a specific format in their application? Enable this to create an application form that applicants can follow."
        >
          <Typography.Text>{"Custom application form"}</Typography.Text>
        </QuestionmarkTooltip>
      </Row>
      <HeadlessCollapse expanded={expanded.isOn}>
        <RichMarkdownEditor
          key={String(expanded.isOn)}
          initialValue={value ?? ""}
          placeholder="Write task application form here. Type '/' for commands."
          editable
          bordered
          mode="update"
          onChange={onChange}
        />
      </HeadlessCollapse>
    </Space>
  );
};

export const ApplicationTemplateField: FC<Props> = (props) => (
  <Form.Item
    name="applicationTemplate"
    style={{ marginBottom: 0 }}
    children={<ApplicationTemplateFieldComponent {...props} />}
  />
);
