import { TaskDetails, Task, TaskStatus } from "@dewo/app/graphql/types";
import { Modal } from "antd";
import React, { FC, useCallback } from "react";
import { useUpdateTask } from "../hooks";
import { TaskPicker } from "@dewo/app/components/TaskPicker";

interface Props {
  task: TaskDetails;
  visible: boolean;
  onClose(): void;
}

export const ReparentTaskContent: FC<{ task: Task; onDone: () => void }> = ({
  task,
  onDone,
}) => {
  const updateTask = useUpdateTask();
  const handleReparentTask = useCallback(
    async (parentTask: Task) => {
      await updateTask({ id: task.id, parentTaskId: parentTask.id });
      onDone();
    },
    [onDone, task.id, updateTask]
  );

  const filterSelfAndSubtasks = useCallback(
    (t: Task) =>
      t.id !== task.id &&
      t.parentTaskId !== task.id &&
      t.id !== task.parentTaskId,
    [task.id, task.parentTaskId]
  );
  const sortByStatus = useCallback((a: Task, b: Task): number => {
    const statusToNumber = (s: string) =>
      ({
        [TaskStatus.TODO]: 0,
        [TaskStatus.IN_PROGRESS]: 1,
        [TaskStatus.IN_REVIEW]: 2,
        [TaskStatus.BACKLOG]: 3,
        [TaskStatus.DONE]: 4,
      }[s] ?? 5);

    return statusToNumber(a.status) - statusToNumber(b.status);
  }, []);

  return (
    <TaskPicker
      filter={filterSelfAndSubtasks}
      sort={sortByStatus}
      placeholder="Search for a task to make the parent"
      workspaceId={task.workspaceId}
      onPick={handleReparentTask}
    />
  );
};

export const ReparentTaskModal: FC<Props> = ({ task, visible, onClose }) => (
  <Modal
    closable={false}
    onCancel={onClose}
    footer={null}
    bodyStyle={{ padding: 8 }}
    visible={visible}
  >
    <ReparentTaskContent onDone={onClose} task={task} />
  </Modal>
);
