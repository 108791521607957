import { TaskDetails } from "@dewo/app/graphql/types";
import { Button, Dropdown, Menu, message, Typography } from "antd";
import { useRouter } from "next/router";
import React, { FC, useCallback } from "react";
import { useDeleteTask, useTaskRoles, useUpdateTask } from "../hooks";
import CopyToClipboard from "react-copy-to-clipboard";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { useCloseTaskDetails } from "@dewo/app/util/navigation";
import { toTaskFormValues } from "../form/util";
import { DeleteMenuItem } from "./DeleteMenuItem";
import { CreateProjectMenuItem } from "./CreateProjectMenuItem";
import { MoveTaskSubmenu } from "./MoveTaskSubmenu";
import {
  CopyOutlined,
  LinkOutlined,
  MoreOutlined,
  NodeCollapseOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useToggle } from "@dewo/app/util/hooks";
import { ReparentTaskModal } from "./ReparentTaskModal";
import { useSubtasks } from "../views/hooks";
import { useIsDev } from "../../user/hooks";

interface Props {
  task: TaskDetails;
}

export const TaskOptionsButton: FC<Props> = ({ task }) => {
  const canCreate = usePermission("create", {
    __typename: "Task",
    workspaceId: task.workspaceId,
    parentTaskId: task.id,
    status: task.status,
  });
  const canMove = usePermission("update", task, "workspaceId");
  const canReparent = usePermission("update", task, "parentTaskId");
  const canDelete = usePermission("delete", task);
  const canCreateWorkspace = usePermission("create", "Workspace");
  const deleteTask = useDeleteTask();
  const router = useRouter();
  const closeTaskDetails = useCloseTaskDetails();
  const handleDeleteTask = useCallback(async () => {
    await deleteTask(task.id);
    closeTaskDetails();
  }, [deleteTask, task, closeTaskDetails]);

  const copiedToClipboard = useCallback(
    () => message.success({ content: "Copied to clipboard" }),
    []
  );

  const { applyRoles, claimRoles } = useTaskRoles(task);
  const selectParentTask = useToggle();

  const subtasks = useSubtasks(task.id).tasks;
  const handleDuplicate = useCallback(() => {
    if (!applyRoles || !claimRoles) return;
    router.push(
      `${task.workspace.permalink}/create?values=${encodeURIComponent(
        JSON.stringify(
          toTaskFormValues(
            { ...task, name: `${task.name} (Copy)` },
            claimRoles,
            applyRoles,
            subtasks ?? []
          )
        )
      )}`
    );
  }, [applyRoles, claimRoles, router, subtasks, task]);

  const updateTask = useUpdateTask();
  const toggleFeatured = useCallback(
    () => updateTask({ id: task.id, featured: !task.featured }),
    [task, updateTask]
  );

  const isDev = useIsDev();

  return (
    <>
      <Dropdown
        key="avatar"
        placement="bottomRight"
        trigger={["click"]}
        overlay={
          <Menu>
            <CopyToClipboard text={task.permalink} onCopy={copiedToClipboard}>
              <Menu.Item
                icon={<LinkOutlined />}
                children={<Typography.Text>Copy task link</Typography.Text>}
              />
            </CopyToClipboard>
            {canCreate && (
              <Menu.Item
                icon={<CopyOutlined />}
                children={<Typography.Text>Duplicate</Typography.Text>}
                onClick={handleDuplicate}
              />
            )}
            {canReparent && (
              <Menu.Item
                children="Set parent task"
                icon={<NodeCollapseOutlined />}
                onClick={selectParentTask.toggleOn}
              />
            )}
            {canMove && <MoveTaskSubmenu task={task} />}
            {canCreateWorkspace && <CreateProjectMenuItem task={task} />}
            {canDelete && <DeleteMenuItem onClick={handleDeleteTask} />}
            {isDev && (
              <Menu.Item
                icon={<StarOutlined />}
                children={task.featured ? "Unfeature" : "Feature"}
                onClick={toggleFeatured}
              />
            )}
          </Menu>
        }
      >
        <Button
          type="text"
          icon={<MoreOutlined />}
          className="dewo-task-options-button"
        />
      </Dropdown>
      <ReparentTaskModal
        task={task}
        visible={selectParentTask.isOn}
        onClose={selectParentTask.toggleOff}
      />
    </>
  );
};
