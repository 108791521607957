import { TaskReward } from "@dewo/app/graphql/types";
import { Tag, TagProps, Tooltip } from "antd";
import React, { FC, useMemo } from "react";
import { formatTaskReward, formatTaskRewardAsUSD } from "../hooks";
import { AtLeast } from "@dewo/app/types/general";
import { DollarOutlined, PlusOutlined } from "@ant-design/icons";

interface Props extends TagProps {
  reward: AtLeast<TaskReward, "amount" | "token">;
  tooltip?: string;
  formatStyle?: "total";
}

export const TaskRewardTag: FC<Props> = ({ reward, ...props }) => (
  <MultipleTaskRewardsTag
    rewards={useMemo(() => [reward], [reward])}
    {...props}
  />
);

interface MultipleProps extends TagProps {
  rewards: AtLeast<TaskReward, "amount" | "token">[];
  tooltip?: string;
  formatStyle?: "total";
}

function defaultTooltip(
  rewards: AtLeast<TaskReward, "amount" | "token">[]
): string {
  const asUsd = rewards
    .filter((r) => !r.peggedToUsd)
    .map(formatTaskRewardAsUSD)
    .filter((s) => !!s)
    .join(", ");
  const tokens = rewards
    .map((r) =>
      formatTaskReward(r, r.token.network, {
        showFull: true,
        summarize: false,
      })
    )
    .join(", ");
  return !!asUsd ? `${tokens} (${asUsd})` : tokens;
}

export const MultipleTaskRewardsTag: FC<MultipleProps> = ({
  rewards,
  tooltip = defaultTooltip(rewards),
  style = { backgroundColor: "white", color: "black" },
  formatStyle,
  ...tagProps
}) =>
  rewards.length > 0 ? (
    <Tooltip
      title={tooltip}
      overlayInnerStyle={{ textAlign: "center" }}
      overlayClassName="ant-typography-caption"
    >
      <Tag
        style={{
          ...style,
          minWidth: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        {...tagProps}
      >
        {rewards
          .map((reward) => [
            reward.token.imageUrl ? (
              <img
                key={[reward.id, "icon"].join(":")}
                width={16}
                height={16}
                style={{ marginRight: 2 }}
                alt={reward.token.symbol}
                src={reward.token.imageUrl!}
              />
            ) : (
              <DollarOutlined key={[reward.id, "icon"].join(":")} />
            ),
            <span key={[reward.id, "text"].join(":")}>
              {formatTaskReward(reward, undefined, {
                showFull: false,
                summarize: formatStyle === "total",
              })}
            </span>,
            <PlusOutlined
              key={[reward.id, "plus"].join(":")}
              className="ant-typography-caption"
            />,
          ])
          .flat()
          .slice(0, -1)}
      </Tag>
    </Tooltip>
  ) : null;
