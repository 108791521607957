import React, { FC, useCallback } from "react";
import { DragDropContext, DragDropContextProps } from "react-beautiful-dnd";
import { Divider } from "antd";
import { TaskStatus } from "@dewo/app/graphql/types";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { useNavigateToTaskFn } from "@dewo/app/util/navigation";
import { NewSubtaskInput } from "./NewSubtaskInput";
import { SubtaskTable, SubtaskTableRowData } from "./SubtaskTable";
import { SubtaskFormValues } from "./types";

interface Props {
  workspaceId: string;
  value?: SubtaskTableRowData[];
  onChange?(value: SubtaskTableRowData[]): void;
}

export const SubtaskCreateInput: FC<Props> = ({
  workspaceId,
  value: subtasks,
  onChange,
}) => {
  const canCreateSubtask = usePermission("create", {
    status: TaskStatus.TODO,
    workspaceId,
    __typename: "Task",
  });

  const addSubtask = useCallback(
    async (values: SubtaskFormValues) => {
      onChange?.([
        ...(subtasks ?? []),
        {
          key: Date.now().toString(),
          name: values.name,
          description: values.description,
          assigneeIds: [],
          status: TaskStatus.TODO,
          dueDate: values?.dueDate ?? null,
        },
      ]);
    },
    [onChange, subtasks]
  );

  const handleDragEnd = useCallback<DragDropContextProps["onDragEnd"]>(
    async (result) => {
      if (result.reason !== "DROP" || !result.destination || !subtasks) return;

      const {
        source: { index: oldIndex },
        destination: { index: newIndex },
      } = result;

      const updatedValue = [...subtasks];
      const temp = updatedValue[oldIndex];
      updatedValue.splice(oldIndex, 1);
      updatedValue.splice(newIndex, 0, temp);
      onChange?.(updatedValue);
    },
    [subtasks, onChange]
  );

  const handleChange = useCallback(
    async (
      changed: Partial<SubtaskTableRowData>,
      prevRow: SubtaskTableRowData
    ) =>
      onChange?.(
        subtasks?.map((s) =>
          s.key === prevRow.key ? { ...s, ...changed } : s
        ) ?? []
      ),
    [onChange, subtasks]
  );

  const handleDelete = useCallback(
    async (row: SubtaskTableRowData) =>
      onChange?.(subtasks?.filter((r) => r.key !== row.key) ?? []),
    [onChange, subtasks]
  );

  const navigateToTask = useNavigateToTaskFn();
  const handleClick = useCallback(
    (row: SubtaskTableRowData) => !!row.task && navigateToTask(row.task.id),
    [navigateToTask]
  );

  return (
    <>
      {!!subtasks?.length && (
        <Divider style={{ marginBottom: 0 }}>Subtasks</Divider>
      )}
      {!!subtasks && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <SubtaskTable
            rows={subtasks}
            size="small"
            editable={!!canCreateSubtask}
            onClick={handleClick}
            showHeader={false}
            workspaceId={workspaceId}
            canCreateSubtask={!!canCreateSubtask}
            onChange={handleChange}
            onDelete={handleDelete}
          />
        </DragDropContext>
      )}
      {canCreateSubtask && <NewSubtaskInput onSubmit={addSubtask} />}
    </>
  );
};
