import React, { FC } from "react";
import { EmbedDescriptor } from "rich-markdown-editor/dist/types";
import { EmbedComponentProps } from "./types";

export const FigmaDocumentComponent: FC<EmbedComponentProps> = ({ attrs }) => (
  <iframe
    title="Figma Embed"
    height="300"
    width="100%"
    src={`https://www.figma.com/embed?embed_host=dework&url=${attrs.href}`}
    allowFullScreen
  />
);

export const FigmaDocumentEmbed: EmbedDescriptor = {
  title: "Figma Preview",
  keywords: "figma",
  defaultHidden: false,
  matcher: (url) =>
    /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/.test(
      url
    ),
  component: FigmaDocumentComponent,
};
