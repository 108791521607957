import { Collapse } from "antd";
import classNames from "classnames";
import React, { CSSProperties, FC, PropsWithChildren } from "react";
import { useMounted } from "../util/hooks";
import styles from "./HeadlessCollapse.module.less";

interface Props {
  expanded: boolean;
  expandOnCreation?: boolean;
  className?: string;
  style?: CSSProperties;
}
export const HeadlessCollapse: FC<PropsWithChildren<Props>> = ({
  expanded,
  expandOnCreation,
  children,
  className,
  style,
}) => {
  const isMounted = useMounted();
  const activeState = !isMounted && expandOnCreation ? false : expanded;
  return (
    <Collapse
      activeKey={activeState ? ["1"] : []}
      bordered={false}
      style={style}
      className={classNames(styles.collapse, className)}
    >
      <Collapse.Panel header="" key="1">
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
