import { NumberOutlined } from "@ant-design/icons";
import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { Task, TaskDetails } from "@dewo/app/graphql/types";
import { useNavigateToTaskFn } from "@dewo/app/util/navigation";
import { Breadcrumb } from "antd";
import React, { FC } from "react";
import style from "./TaskBreadcrumb.module.less";

interface Props {
  task: Task | TaskDetails;
  showWorkspaceLink?: boolean;
}

export const TaskBreadcrumb: FC<Props> = ({
  task,
  showWorkspaceLink = true,
}) => {
  const navigateToTask = useNavigateToTaskFn();

  return (
    <Breadcrumb className={style.breadcrumb} style={{ marginLeft: 4 }}>
      {showWorkspaceLink && "organization" in task.workspace && (
        <>
          <Breadcrumb.Item href={task.workspace.organization.permalink}>
            <OrganizationAvatar
              size={16}
              tooltip={{ visible: false }}
              organization={task.workspace.organization}
              style={{ marginRight: 4 }}
            />
            <span>{task.workspace.organization.name}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href={task.workspace.permalink}
            className="text-secondary"
          >
            {task.workspace.name}
          </Breadcrumb.Item>
        </>
      )}
      {!!task.parentTask && (
        <Breadcrumb.Item
          href="#"
          onClick={() => navigateToTask(task.parentTask!.id)}
        >
          {task.parentTask.name}
        </Breadcrumb.Item>
      )}
      <Breadcrumb.Item className="text-secondary">
        <NumberOutlined style={{ opacity: 0.3 }} />
        {task.number}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};
